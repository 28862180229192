
export class LoginConstants {
  static readonly LOGIN = 'Log In';
  static readonly EMAIL_ADDRESS = 'Email Address';
  static readonly PLACEHOLDER_EMAIL_ADDRESS = 'jane.doe@example.com';
  static readonly REQUIRED_FIELD = 'This field is required';
  static readonly VALID_EMAIL_ID = 'Please provide a valid email address';
  static readonly PASSWORD = 'Password';
  static readonly PLACEHOLDER_PASSWORD = 'Enter Password';
  static readonly MAXIMUM_CHAR = 'Maximum 50 characters allowed';
  static readonly FORGOT_PASSWORD = 'Forgot Password?';
  static readonly CREATE_ACCOUNT = 'Create an Account';
  static readonly CREATE_ACCOUNT_BUTTON = 'Create an Account';
  static readonly USER_NOT_CONFIRMED = 'User is not confirmed.';
  static readonly INCORRECT_CREDDENTIALS = 'Incorrect username or password.';
  static readonly USER_SET_PASSWORD = 'User needs to set password.';
  static readonly INCORRECT_EMAIL_PASSWORD = 'Your email address or password is incorrect';
  static readonly LOGGED_IN_SUCCESSFULLY = 'Logged In Successfully';
  static readonly PASSWORD_MIN_LENGTH = 'Passwords must contain at least 8 characters';
  static readonly DESCRIPTION_CREATEACCOUNT = 'IPOS Central allows you to customize, install and monitor Intel IPOS on Intel-powered devices in your inventory.';

}
