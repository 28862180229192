import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from '../../../../app/common/constants/global.constant';

@Component({
  selector: 'iposcen-duplicate',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.scss']
})
export class DuplicateComponent implements OnInit {

  duplicateGamingLaptops: string;
  cancel: string;
  duplicateProfile: string;
  profName: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.duplicateGamingLaptops = GlobalConstants.DUPLICATE_GAMING_LAPTOPS;
    this.cancel = GlobalConstants.CANCEL;
    this.duplicateProfile = GlobalConstants.DUPLICATE_PROFILE;
    this.profName = GlobalConstants.PROFILE_NAME;
  }

  close(): void {
    this.activeModal.close();
  }

}
