import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RouterConstants } from '../../../core/routing/constants/app-paths.constant';
import { CreateAccountService } from '../../../core/routing/services/createAccount/create-account.service';
import { ConfirmRegistrationConstants } from './constants/confirm-registration.constant';

@Component({
  selector: 'iposcen-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit, OnDestroy {

  confirmationCode: string;
  regForm: FormGroup;
  email: string;
  modalTitle: string;
  modalBody: string;
  errorMessage: string;
  loginhref: string;
  closeResult: string;
  modalDiv: string;
  button: string;
  heading: string;
  code: string;
  resend: string;
  firstName: string;
  login: string;
  accountReq: string;
  notification: string;
  messageNotification: string;
  accountCreated: string;
  emailNotification: string;
  cnfMsgApproved: string;
  cnfMsgUnapproved: string;
  mailSent: string;
  alertFormat: string;
  isApprovedDomain: boolean;
  placeholderConfirmationCode: string;
  approvedDomainList: string[] = [];
  msgNotifyList: any;
  private sub: any;

  constructor(public service: CreateAccountService,
    public router: Router,
    public route: ActivatedRoute,
    public formbuilder: FormBuilder,
    public modalService: NgbModal) {
  }

  ngOnInit() {
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.notification = ConfirmRegistrationConstants.MESSAGE_NOTIFY_TO_USER;
    this.approvedDomainList = ConfirmRegistrationConstants.APPROVED_DOMAIN_LIST;
    this.isApprovedDomain = this.approvedDomainList.includes(sessionStorage.getItem('email')
      .split('@')[1]);
    this.modalTitle = this.isApprovedDomain ? ConfirmRegistrationConstants.ACCOUNT_CREATED
      : ConfirmRegistrationConstants.ACCOUNT_REQUESTED;
    this.firstName = sessionStorage.getItem('firstName');
    this.emailNotification = sessionStorage.getItem('email');
    this.placeholderConfirmationCode = ConfirmRegistrationConstants.PLACEHOLDER_CONFIRMATION_CODE;
    this.modalBody = (this.isApprovedDomain ? ConfirmRegistrationConstants.CONF_MESSAGE_APPROVED
      : ConfirmRegistrationConstants.CONF_MESSAGE_UNAPPROVED).replace('<firstName>', this.firstName);
    this.messageNotification = this.notification.replace('<firstName>', this.firstName);
    this.messageNotification = this.messageNotification.replace('<[email]@[domain].com>', this.emailNotification);
    this.resend = ConfirmRegistrationConstants.RESEND_CODE;
    this.login = ConfirmRegistrationConstants.LOGIN;
    this.code = ConfirmRegistrationConstants.CONF_CODE;
    this.heading = ConfirmRegistrationConstants.CONF_REG_HEADING;
    this.button = ConfirmRegistrationConstants.CONFIRM_BUTTON;
    this.sub = this.route.params.subscribe(params => {
      this.email = params.email;
      this.regForm = this.formbuilder.group({
        confirmationCode: new FormControl('', [Validators.required])
      });

    });

    this.errorMessage = undefined;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onConfirmRegistration(): void {
    this.errorMessage = undefined;
    this.confirmationCode = this.regForm.get('confirmationCode').value;
    this.service.confirmRegistration(this.email, this.confirmationCode, this);
  }

  resendCode(): void {
    this.service.resendCode(this.email, this);
  }

  cognitoCallback(message: string, result: any) {
    if (message !== undefined) { // error
      this.alertFormat = 'alert alert-danger';
      if (message === ConfirmRegistrationConstants.CODE_RESENT) {
        this.alertFormat = 'alert alert-secondary';
      }
      this.errorMessage = message;

    } else {
      this.router.navigateByUrl(RouterConstants.ACCOUNT_CREATED_LINK);
    }
  }

}
