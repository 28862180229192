import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { environment } from './../../../../../environments/environment';
import { AuthConstants } from './constants/auth.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpMethodsservice: HttpMethodsService) { }

  // Generates (or retrieves) a Cognito ID. Supplying multiple logins will create an implicit linked account.
  getId(idToken: string) {
    const url = AuthConstants.COGNITO_IDENTITY_URL;
    const postObject = {
      IdentityPoolId: environment.identityPoolId,
      Logins: {
        [AuthConstants.PROVIDER_LOGIN_KEY]: idToken
      }
    };

    return this.httpMethodsservice.auth_post(url, postObject, 'Id')
      .pipe(map((res: any) =>
        res));
  }

  // Returns credentials for the provided identity ID. Any provided logins will be validated against
  // supported login providers. If the token is for cognito-identity.amazonaws.com, it will be passed
  // through to AWS Security Token Service with the appropriate role for the token.
  getCredentialsForIdentity(idToken: string, identityId: string) {
    const url = AuthConstants.COGNITO_IDENTITY_URL;
    const postObject = {
      IdentityId: identityId,
      Logins: {
        [AuthConstants.PROVIDER_LOGIN_KEY]: idToken
      }
    };

    return this.httpMethodsservice.auth_post(url, postObject, 'credentialsForIdentity')
      .pipe(map((res: any) =>
        res));
  }

}
