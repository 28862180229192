export class StoreApi {
    static readonly SLASH_RETAILSTORE = '/retailer-store';
    static readonly SLASH_STORE = '/store';
    static readonly SLASH_USERS = '/users';
    static readonly STORES = '/stores'
    static readonly SLASH = '/';
    static readonly SLASH_RETAILER = '/retailers'
    static readonly SLASH_STORE_SEARCH = '/stores?'
    static readonly EQUALS = '=';

}

export class StoreSearch{
    static readonly  STORE_NAME_SEARCH = 'storeNameSearch';
    static readonly RETAILER_NAME_SEARCH='retailerNameSearch'; 
    static readonly COUNTRY_SEARCH='countrySearch'; 
}
