export class RetailStoreConstants {
    static readonly SLASH_RETAILER_STORE = '/retailer-store';
    static readonly SLASH_RETAILER = '/retailer';
    static readonly SLASH_USER_ID = '/users';
    static readonly SLASH = '/';
    static readonly RETAILER_URL = '/retailers';
    static readonly SEARCH_URL = '?searchby=';
    static readonly SORTBY = '?sortby=';
    static readonly SORT_TYPE = '&sorttype=';

}