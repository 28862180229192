import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../common/constants/global.constant';

@Component({
  selector: 'iposcen-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  tabIndex: number;
  currentdetailPage: string;
  constructor(private router: Router, private location: Location) { }

  ngOnInit(): void {
    this.tabIndex = 0;
    this.currentdetailPage = this.router.url;
    const pageName = this.currentdetailPage.lastIndexOf('/');
    if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_RETAILHQS) {
      this.tabIndex = 0;
    }
    else if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_STORES) {
      this.tabIndex = 1;
    }
    else if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_ACTIVEUSERS) {
      this.tabIndex = 2;
    }
    // else if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_OEMS) {
    //   this.tabIndex = 3;
    // }
    // else if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_PENDINGUSERS) {
    //   this.tabIndex = 4;
    // }
    // else if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_DOMAINWHITELIST) {
    //   this.tabIndex = 5;
    // }
  }
  setIndex(ind: number) {
    if (ind === 0) {
      this.router.navigateByUrl(GlobalConstants.SLASH + GlobalConstants.USERS + GlobalConstants.SLASH + GlobalConstants.USERS_RETAILHQS)
    } else if (ind === 1) {
      this.router.navigateByUrl(GlobalConstants.SLASH + GlobalConstants.USERS + GlobalConstants.SLASH + GlobalConstants.USERS_STORES);
    }
    else if (ind === 2) {
      this.router.navigateByUrl(GlobalConstants.SLASH + GlobalConstants.USERS + GlobalConstants.SLASH + GlobalConstants.USERS_ACTIVEUSERS);
    }
  }
 
}
