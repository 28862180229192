import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from '../../core/routing/services/login/login.service.ts.service';
import { RouterConstants } from '../../core/routing/constants/app-paths.constant';
import { DetailService } from '../../core/routing/services/details/detail.service';
import { Router } from '@angular/router';
@Component({
  selector: 'iposcen-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  firstName: string;

  constructor(
    private detailService: DetailService,
    private userServie: LoginService
  ) { }

  ngOnInit(): void {
    this.firstName = sessionStorage.getItem('firstName');
  }

  ngOnDestroy(): void {
    // sessionStorage.clear(); TODO - Not sure of the reason being this session clear on home component destroy,
    // since any navigation to other component will trigger this.
  }

  logout() {
    this.userServie.logout();
    this.detailService.setLayoutToMain(true);
  }
}
