<!-- Main section -->
<section class="main_section al_storelogo">
    <div  class="CP_storeLogos">
      <div class="data_header justify-content-end"  [ngClass] = "(otherStoreLogos?.length || defaultStoreLogos?.length) ? '' : 'display-block'">
        <span *ngIf="!otherStoreLogos?.length && !defaultStoreLogos?.length">Customize your IPOS package with your store logo. Uploaded logos will be saved to the Asset Library.</span>
        <div class="data_action">
          <div id="uploadButton">
            <label>
              <input #imageInput id="uploadLogo" type="file"
                accept="image/jpg,image/jfif,image/x-png,image/jpeg,image/gif" (change)="processFile(imageInput)" hidden>
              <span class="btn btn-primary btn-sm left_icon_btn"><svg width="16" height="17" viewBox="0 0 16 17"
                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.5 8.25822C0.632608 8.25822 0.759785 8.3109 0.853553 8.40467C0.947322 8.49844 1 8.62561 1 8.75822V12.2582C1 12.5234 1.10536 12.7778 1.29289 12.9653C1.48043 13.1529 1.73478 13.2582 2 13.2582H14C14.2652 13.2582 14.5196 13.1529 14.7071 12.9653C14.8946 12.7778 15 12.5234 15 12.2582V8.75822C15 8.62561 15.0527 8.49844 15.1464 8.40467C15.2402 8.3109 15.3674 8.25822 15.5 8.25822C15.6326 8.25822 15.7598 8.3109 15.8536 8.40467C15.9473 8.49844 16 8.62561 16 8.75822V12.2582C16 12.7887 15.7893 13.2974 15.4142 13.6724C15.0391 14.0475 14.5304 14.2582 14 14.2582H2C1.46957 14.2582 0.960859 14.0475 0.585786 13.6724C0.210714 13.2974 0 12.7887 0 12.2582V8.75822C0 8.62561 0.0526784 8.49844 0.146447 8.40467C0.240215 8.3109 0.367392 8.25822 0.5 8.25822ZM5 5.11222C5.09376 5.20596 5.22092 5.25862 5.3535 5.25862C5.48608 5.25862 5.61324 5.20596 5.707 5.11222L8 2.81822L10.293 5.11122C10.3391 5.15898 10.3943 5.19707 10.4553 5.22327C10.5163 5.24948 10.5819 5.26327 10.6483 5.26385C10.7147 5.26442 10.7805 5.25177 10.842 5.22663C10.9034 5.20149 10.9593 5.16437 11.0062 5.11742C11.0531 5.07047 11.0903 5.01465 11.1154 4.9532C11.1406 4.89175 11.1532 4.82591 11.1526 4.75952C11.152 4.69313 11.1383 4.62752 11.1121 4.56652C11.0858 4.50552 11.0478 4.45035 11 4.40422L8.354 1.75822C8.30755 1.71166 8.25238 1.67472 8.19163 1.64951C8.13089 1.6243 8.06577 1.61133 8 1.61133C7.93423 1.61133 7.86911 1.6243 7.80837 1.64951C7.74762 1.67472 7.69245 1.71166 7.646 1.75822L5 4.40422C4.95344 4.45067 4.91649 4.50584 4.89129 4.56659C4.86608 4.62733 4.85311 4.69246 4.85311 4.75822C4.85311 4.82399 4.86608 4.88911 4.89129 4.94986C4.91649 5.0106 4.95344 5.06578 5 5.11222Z" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8 2.25781C8.13261 2.25781 8.25979 2.31049 8.35355 2.40426C8.44732 2.49803 8.5 2.6252 8.5 2.75781V10.7578C8.5 10.8904 8.44732 11.0176 8.35355 11.1114C8.25979 11.2051 8.13261 11.2578 8 11.2578C7.86739 11.2578 7.74021 11.2051 7.64645 11.1114C7.55268 11.0176 7.5 10.8904 7.5 10.7578V2.75781C7.5 2.6252 7.55268 2.49803 7.64645 2.40426C7.74021 2.31049 7.86739 2.25781 8 2.25781Z" />
                </svg> Upload Logo</span>
            </label>
            <ng-template #popContent>Images must be JPG, PNG, JPEG <div> JFIF or GIF format.</div>
            </ng-template>
            <img class="info_icon" src="assets/images/info_circle_icon.svg" alt="hover for help" [placement] = "(otherStoreLogos?.length || defaultStoreLogos?.length) ? 'top-right' : 'top'"
              [ngbPopover]="popContent" triggers="mouseenter:mouseleave" />
              <div *ngIf="isImageTypeError" class="uploadfile_err_msg">Image must be <strong>JPG, PNG </strong> or <div> <strong>GIF </strong> format.</div></div>
              <div *ngIf="isImageSizeError && isimageFormat" class="uploadfile_err_msg">File exceeds maximimum size <div><strong> (3 MB).</strong></div></div>
          </div>
        </div>
      </div>

      <!-- Data content with logo tiles in a row -->
      <div class="data_content">
        <div class="border-right" *ngIf="defaultStoreLogos?.length < 1 && otherStoreLogos?.length">
          <span class="logo_category">Store Logo</span>
          <div class="default_logo_tile border-0">

            <div class="accordion">
              <!-- <span class="logo_category">Default Logos</span>  -->
              <div class="default_logos_border">
                <div class="card">
                  <div class="default_img_icon"></div>
                  <div class="default_card_text">Set a logo to display on IPOS.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <!-- Default logo tile -->
            <div class="border-right" *ngIf="defaultStoreLogos?.length > 0 ">
              <span class="logo_category">Store Logo</span>
              <div class="default_logo_tile border-0">
                <div *ngFor="let storeLogo of defaultStoreLogos">
                  <div class="card" [style.background-color]="storeLogoBgColor">
                    <div class="store_logo_img">
                      <img [src]="storeLogo.imageData" width="174px" height="68px" alt="Image Logo" />
                    </div>
                    <div class="store_logo_caption_default_logo small_text">{{storeLogo.fileName}}</div>
                  </div>
                </div>
            <!-- Default logo tile/card ends here -->
          </div>
        </div>
        <div class="other_logos_column" *ngIf="otherStoreLogos && otherStoreLogos?.length">
          <span class="logo_category">Recent Logos</span>
          <div class="other_logos_tiles">
            <!-- Other Logo - 2 -->
            <!-- <div *ngFor="let storeLogo of otherStoreLogos"> -->
            <div class="card" *ngFor="let storeLogo of otherStoreLogos" (click)="setStoreLogo(storeLogo)">
              <div class="store_logo_img">
                <img [src]="storeLogo.imageData" width="174px" height="68px" alt="Image Logo" />
              </div>
              <div class="store_logo_caption small_text">{{storeLogo.fileName}}</div>
            </div>
          </div>
        </div>
      </div>

       <!-- Color Picker -->
       <div class="form-group mt-c3" *ngIf="otherStoreLogos?.length || defaultStoreLogos?.length">
        <label for="">Background color</label>
        <div>
        <input [(colorPicker)]="storeLogoBgColor" [cpPosition]="'top-right'"[style.background]="storeLogoBgColor"
          (colorPickerChange)="updateBgColor($event)" class="color_picker_button default" name="bgcolor" />
        </div>
      </div>

      <!-- Buttons -->
      <div class="button_wrapper justify-content-end">
        <button type="button" class="btn btn-outline-primary btn-lg" (click)="sendToProfile(skip)">
          Skip
        </button>
        <button type="button" class="btn btn-primary btn-lg" (click)="sendToProfile(send)"
        [disabled]= "this.defaultStoreLogos.length === 0">
          Save & Proceed
        </button>
      </div>

    </div>
  <!-- </div> -->
</section>
