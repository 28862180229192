import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterConstants } from '../../../../core/routing/constants/app-paths.constant';
import { ConfirmRegistrationConstants } from '../constants/confirm-registration.constant';
import { AccountCreatedConstants } from './constants/account-created.const';

@Component({
  selector: 'iposcen-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss']
})
export class AccountCreatedComponent implements OnInit {
  isApprovedDomain: boolean;
  approvedDomainList: string[] = [];
  modalTitle: string;
  firstName: string;
  emailNotification: string;
  modalBody: string;
  loginhref: string;
  href: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate([RouterConstants.HOME_PAGE_LINK]);
    }, AccountCreatedConstants.AUTO_CLOSING_SECONDS);
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.href = RouterConstants.HOME_PAGE_LINK;
    this.approvedDomainList = ConfirmRegistrationConstants.APPROVED_DOMAIN_LIST;
    this.isApprovedDomain = this.approvedDomainList.includes(sessionStorage.getItem('email')
      .split('@')[1]);
    this.modalTitle = this.isApprovedDomain ? ConfirmRegistrationConstants.ACCOUNT_CREATED
      : ConfirmRegistrationConstants.ACCOUNT_REQUESTED;
    this.firstName = sessionStorage.getItem('firstName');
    this.emailNotification = sessionStorage.getItem('email');
    this.modalBody = (this.isApprovedDomain ? ConfirmRegistrationConstants.CONF_MESSAGE_APPROVED
      : ConfirmRegistrationConstants.CONF_MESSAGE_UNAPPROVED).replace('<firstName>', this.firstName);
  }

  close(): void {
    this.router.navigate([RouterConstants.HOME_PAGE_LINK]);
  }

}
