<div [ngClass]="{'account_page': layOut}">
  <iposcen-header *ngIf="layOut"></iposcen-header>
  <div *ngIf="!layOut">
    <!-- Navbar starts here -->

    <nav class="navbar navbar-expand-lg navbar-dark first_nav" aria-labelledby="home">
      <a class="navbar-brand" href="#">
        <img src="assets/images/Intel.png" alt="Intel Logo" />
        <span class="logo_text">IPOS CENTRAL</span>
      </a>
      <!-- Navbar toggle button - medium and below devices -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#firstNavigation"
        aria-controls="firstNavigation" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="firstNavigation">
        <!-- The following ul belongs to 2nd nav - visible on medium and below devices -->

        <ul class="navbar-nav ml-auto secondnav_duplicate d-block d-sm-block d-md-block d-lg-none d-xl-none">
          <li class="nav-item active">
            <a class="nav-link active" [routerLink]="homePageHref">Home</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link active" [routerLink] = "storesHref">Users</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="profileHref">Profiles</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="usersRetailHref">Users</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="assetLibraryHref">Asset Library</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Download Standard IPOS</a>
          </li>
        </ul>
        <!-- The following ul belongs to 1st navbar-->

        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link gtmLearnMore" href="#">
              <img src="assets/images/firstnav_help_icon.svg" alt="learn more" />
              &nbsp;Learn more
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link gtmLang" href="#">
              <img src="assets/images/firstnav_lang_icon.svg" alt="language" />
              &nbsp;Language
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link gtmNotifications" href="#">
              <img src="assets/images/firstnav_notifications_icon.svg" alt="notifications" />
              &nbsp;Notifications
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link gtmMyAccount" href="#">
              <img src="assets/images/firstnav_account_icon.svg" alt="my account" />
              &nbsp;My Account
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link gtmLogOut" href="#">
              <img src="assets/images/firstnav_logout_icon.svg" alt="logout" />
              &nbsp;Log Out
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Both navbar collapse into one for medium and below devices -->

    <nav class="navbar navbar-expand-lg navbar-light second_nav">
      <div class="collapse navbar-collapse" id="secondNavigation">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#" [routerLink]="homePageHref" (click)="setIndex(0)" [ngClass]="{'active': homePageTabIndex === 0 }">Home</a>
          </li>
          <li class="nav-item">
            
            <a class="nav-link" href="#" [routerLink]="usersRetailHref" (click)="setIndex(1)" [ngClass]="{'active': homePageTabIndex === 1 }">Users</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="profileHref" (click)="setIndex(2)"
              [ngClass]="{'active': homePageTabIndex === 2 }">Profiles</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="assetLibraryHref" (click)="setIndex(3)"
              [ngClass]="{'active': homePageTabIndex === 3 }">Asset Library</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="setIndex(4)" [ngClass]="{'active': homePageTabIndex === 4 }" [innerHTML]="downloadIPOS"></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <!-- Navbar ends here -->
  <router-outlet></router-outlet>
</div>