export class SelectLanguageConstants {
    static readonly LANGUAGE_HEADING = 'LANGUAGE';
    static readonly LANGUAGE = 'language';
    static readonly HELP = 'Help';
    static readonly CANCEL = 'Cancel';
    static readonly SELECT_LANGUAGE = 'Select Language';
    static readonly LOGIN_DEFAULT = 'login';
    static readonly CREATE_ACCOUNT = 'createAccount';
    static readonly LANGUAGE_SUB_HEADING_DESC = 'Select which language you want to use for IPOS Central. You can always change this later by selecting "Language" in the header.';
}
