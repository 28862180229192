import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PromoFormFactor } from '../promo-content/interfaces/promo-content.i';
import * as formFactorsConfigData from './../../../../assets/config/form-factors.json';
import { FormFactorsConfig } from './interfaces/form-factors.i';

@Component({
  selector: 'iposcen-form-factors',
  templateUrl: './form-factors.component.html',
  styleUrls: ['./form-factors.component.scss']
})
export class FormFactorsComponent implements OnInit {
  @Input() contentName: PromoFormFactor;
  @Output() readonly applyFormFactor = new EventEmitter();
  allDevicesForm: FormGroup;
  manufacturersForm: FormGroup;
  deviceTypesForm: FormGroup;
  processorsForm: FormGroup;
  generationsForm: FormGroup;
  selectedFormFactors: PromoFormFactor;
  formFactorsConfigData: FormFactorsConfig = (formFactorsConfigData as any).default;
  @ViewChild('formFactorsModal') private modalContent: TemplateRef<FormFactorsComponent>;
  private modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {
    // console.log('formFactors:: ' + JSON.stringify(this.formFactors));
    this.selectedFormFactors = this.contentName;
    this.allDevicesForm = this.formBuilder.group({
      allDevices: new FormArray([])
    });
    this.manufacturersForm = this.formBuilder.group({
      manufacturers: new FormArray([], minSelectedCheckboxes(1))
    });
    this.deviceTypesForm = this.formBuilder.group({
      deviceTypes: new FormArray([], minSelectedCheckboxes(1))
    });
    this.processorsForm = this.formBuilder.group({
      processors: new FormArray([], minSelectedCheckboxes(1))
    });
    this.generationsForm = this.formBuilder.group({
      generations: new FormArray([], minSelectedCheckboxes(1))
    });

    this.addCheckboxes();
  }

  get allDevicesFormArray() {
    return this.allDevicesForm.controls.allDevices as FormArray;
  }

  get manufacturersFormArray() {
    return this.manufacturersForm.controls.manufacturers as FormArray;
  }

  get deviceTypesFormArray() {
    return this.deviceTypesForm.controls.deviceTypes as FormArray;
  }

  get processorsFormArray() {
    return this.processorsForm.controls.processors as FormArray;
  }

  get generationsFormArray() {
    return this.generationsForm.controls.generations as FormArray;
  }

  addCheckboxes() {
    this.allDevicesFormArray.push(new FormControl(true));
    this.formFactorsConfigData.manufacturers.forEach(manufacturer => {
      if (this.selectedFormFactors && this.selectedFormFactors.manufacturer.indexOf(manufacturer.name) >= 0) {
        this.manufacturersFormArray.push(new FormControl(true));
      } else {
        this.manufacturersFormArray.push(new FormControl(false));
      }
    });
    this.formFactorsConfigData.deviceTypes.forEach(deviceType => {
      if (this.selectedFormFactors && this.selectedFormFactors.devices.indexOf(deviceType.name) >= 0) {
        this.deviceTypesFormArray.push(new FormControl(true));
      } else {
        this.deviceTypesFormArray.push(new FormControl(false));
      }
    });
    this.formFactorsConfigData.processors.forEach(processor => {
      if (this.selectedFormFactors && this.selectedFormFactors.processors.indexOf(processor.name) >= 0) {
        this.processorsFormArray.push(new FormControl(true));
      } else {
        this.processorsFormArray.push(new FormControl(false));
      }
    });
    this.formFactorsConfigData.generation.forEach(generation => {
      if (this.selectedFormFactors && this.selectedFormFactors.generations.indexOf(generation.name) >= 0) {
        this.generationsFormArray.push(new FormControl(true));
      } else {
        this.generationsFormArray.push(new FormControl(false));
      }
    });
  }

  ngOnInit(): void {
  }

  open() {
    this.modalRef = this.modalService.open(this.modalContent);
    this.modalRef.result.then();
  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  deleteContent() {
    this.applyFormFactor.emit('123');
  }

  formSubmit() {

    if (this.allDevicesForm.value.allDevices ||
      (this.manufacturersForm.valid && this.deviceTypesForm.valid && this.processorsForm.valid && this.generationsForm.valid)) {
      // console.log('this.allDevicesForm.value.allDevices:: ' + this.allDevicesForm.value.allDevices[0]);
      // console.log('Type of this.allDevicesForm.value.allDevices:: ' + typeof(this.allDevicesForm.value.allDevices));

      // console.log('this.formFactorsForm.value.manufacturers:: ' + this.manufacturersForm.value.manufacturers);
      const selectedManufacturers = this.manufacturersForm.value.manufacturers
        .map((checked, i) => checked ? this.formFactorsConfigData.manufacturers[i].name : null)
        .filter(v => v !== null);
      // console.log('selectedManufacturers:: ' + selectedManufacturers);

      // console.log('this.formFactorsForm.value.manufacturers:: ' + this.manufacturersForm.value.manufacturers);
      const selectedDeviceTypes = this.deviceTypesForm.value.deviceTypes
        .map((checked, i) => checked ? this.formFactorsConfigData.deviceTypes[i].name : null)
        .filter(v => v !== null);
      // console.log('selectedDeviceTypes:: ' + selectedDeviceTypes);

      // console.log('this.formFactorsForm.value.manufacturers:: ' + this.manufacturersForm.value.manufacturers);
      const selectedProcessors = this.processorsForm.value.processors
        .map((checked, i) => checked ? this.formFactorsConfigData.processors[i].name : null)
        .filter(v => v !== null);
      // console.log('selectedProcessors:: ' + selectedProcessors);

      // console.log('this.formFactorsForm.value.manufacturers:: ' + this.manufacturersForm.value.manufacturers);
      const selectedGenerations = this.generationsForm.value.generations
        .map((checked, i) => checked ? this.formFactorsConfigData.generation[i].name : null)
        .filter(v => v !== null);
      // console.log('selectedGenerations:: ' + selectedGenerations);

      this.selectedFormFactors = {
        allDevices: this.allDevicesForm.value.allDevices[0],
        manufacturer: selectedManufacturers,
        devices: selectedDeviceTypes,
        processors: selectedProcessors,
        generations: selectedGenerations
      };

      console.log('this.selectedFormFactors:: ' + JSON.stringify(this.selectedFormFactors));

      this.applyFormFactor.emit(this.selectedFormFactors);
    } else {
      console.log('some form data is invalid..');
    }
  }
}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
