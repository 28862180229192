<!-- Create account page content -->
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <a class="central_logo" [href]="loginhref"> <img class="img-fluid" alt="IPOS Central LOGO" /> </a>
      <div class="card create_acnt_card">
        <form [formGroup]="creatAccForm" (change)="disableBtn()" (keyup)="disableBtn()" (ngSubmit)="onRegister()">
          <div class="create_acnt_header" [innerHTML]="mandatoryCreateAccount"></div>
          <div class="text_center" [innerHTML]="mandatoryIndicates"></div>

          <!-- Form fields starts here -->

          <div class="form_section">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="firstName" [innerHTML]="mandatoryFirstName"></label>
                <input [ngClass]="{
                  'has-error':
                    isSubmitted && formControls.firstName.errors
                }" type="text" class="form-control" id="firstName" formControlName="firstName"
                  [placeholder]="placeholderFirstName" maxlength="50" required />
                <em class="err_icon" *ngIf="isSubmitted && formControls.firstName.errors"></em>
                <div *ngIf="isSubmitted && formControls.firstName.errors" class="help-block">
                  <div id="firstNameMsg" class="formfield_err_msg"
                    *ngIf="creatAccForm.controls.firstName.errors.required" [innerHTML]="mandatoryfield"></div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="lastName" [innerHTML]="mandatoryLastName"></label>
                <input [ngClass]="{
                  'has-error':
                    isSubmitted && formControls.lastName.errors
                }" type="text" class="form-control" id="lastName" formControlName="lastName"
                  [placeholder]="placehoiderLastName" maxlength="50" required />
                <em class="err_icon" *ngIf="isSubmitted && formControls.lastName.errors"></em>
                <div *ngIf="isSubmitted && formControls.lastName.errors" class="help-block">
                  <div id="lastNameMsg" class="formfield_err_msg" *ngIf="creatAccForm.controls.lastName.errors.required"
                    [innerHTML]="mandatoryfield"></div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="emailId" [innerHTML]="mandatoryEmail"></label>
                <input [ngClass]="{
                  'has-error':
                    isSubmitted && formControls.email.errors
                }" type="email" class="form-control" id="emailId" formControlName="email"
                  [placeholder]="placeholderEmail" maxlength="50" required />
                <em class="err_icon" *ngIf="isSubmitted && formControls.email.errors"></em>
                <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
                  <div id="emailErrMsg" class="formfield_err_msg" *ngIf="creatAccForm.controls.email.errors.required"
                    [innerHTML]="mandatoryfield"></div>
                  <div class="formfield_err_msg" *ngIf="creatAccForm.controls.email.errors.pattern"
                    [innerHTML]="mandatoryEmailValid"></div>
                </div>
              </div>
            </div>
          </div>

      <div class="form_section">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="password" [innerHTML]="mandatoryPassword"></label>
            <input [ngClass]="{
              'has-error':
                isSubmitted && formControls.password.errors
            }" [type]="fieldTextType ? 'text' : 'password'" class="form-control" id="password" maxlength="30" formControlName="password"
            [placeholder]="placeholderPassword" required />
            <em class="err_icon"
            *ngIf="isSubmitted && formControls.password.errors"></em>
            <fa-icon *ngIf="formControls.password.value
                        " class="input-icon" [icon]="displayEyePassword" [ngClass]="{
                          'eye-slash': fieldTextType,
                          'eye': !fieldTextType
                        }" (click)="toggleFieldTextType()"></fa-icon>
                

            <div *ngIf="isSubmitted && formControls.password.errors" class="help-block">
              <div id="emailErrMsg" class="formfield_err_msg" *ngIf="creatAccForm.controls.password.errors.required"
                [innerHTML]="mandatoryfield"></div>
              <div id="emailErrMsg" class="formfield_err_msg" *ngIf="creatAccForm.controls.password.errors.pattern"
                [innerHTML]="pwdLength"></div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="cnfPassword" [innerHTML]="mandatoryConfPassword"></label>
              <input [ngClass]="{
                'has-error':
                  isSubmitted && formControls.cnfPassword.errors
              }" [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control" id="cnfPassword"
                formControlName="cnfPassword" [placeholder]="placeholderCnfPassword" maxlength="30" required />
                <em class="err_icon"
                *ngIf="isSubmitted && formControls.cnfPassword.errors"></em>
                  <fa-icon *ngIf="formControls.cnfPassword.value" class="input-icon" [icon]="displayEyeCnfPassword"
                    [ngClass]="{
                          'eye-slash': repeatFieldTextType,
                          'eye': !repeatFieldTextType
                        }" (click)="toggleRepeatFieldTextType()"></fa-icon>
                 

            <div *ngIf="isSubmitted && formControls.cnfPassword.errors" class="help-block">
              <div id="cnfReqErrMsg" class="formfield_err_msg" *ngIf="creatAccForm.controls.cnfPassword.errors.required"
                [innerHTML]="mandatoryfield"></div>
              <div class="formfield_err_msg" *ngIf="creatAccForm.controls.cnfPassword.errors.confirmedValidator"
                [innerHTML]="mandatoryPasswordMatch"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="form_section">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="countrySelect" [innerHTML]="mandatoryCountrySelect"></label>
              <select [ngClass]="{
                'has-error':
                  isSubmitted && formControls.countrySelect.errors
              }" class="form-control" id="countrySelect" formControlName="countrySelect" required>
                <option [innerHTML]="defaultText" value=""></option>
                <option *ngFor="let country of countries; trackBy: trackByCountry" value="country.CountryName">
                  {{ country.CountryName }}
                </option>
              </select>
              <em class="err_icon"
              *ngIf="isSubmitted && formControls.countrySelect.errors"></em>
            <div *ngIf="isSubmitted && formControls.countrySelect.errors" class="help-block">
              <div id="emailErrMsg" class="formfield_err_msg"
                *ngIf="creatAccForm.controls.countrySelect.errors.required" [innerHTML]="mandatoryfield"></div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="retailerName" [innerHTML]="mandatoryRetailerName"></label>
              <input [ngClass]="{
                'has-error':
                  isSubmitted && formControls.retailerName.errors
              }" type="text" class="form-control" formControlName="retailerName" id="retailerName"
                [placeholder]="placeholderRetailerName" maxlength="50" required />
                  <em class="err_icon"
                    *ngIf="isSubmitted && formControls.retailerName.errors"></em>
            <div *ngIf="isSubmitted && formControls.retailerName.errors" class="help-block">
              <div id="emailErrMsg" class="formfield_err_msg" *ngIf="creatAccForm.controls.retailerName.errors.required"
                [innerHTML]="mandatoryfield"></div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="storeName" [innerHTML]="mandatoryStoreName"></label>
            <input type="text" class="form-control" formControlName="storeName" id="storeName"
              [placeholder]="placeholderStoreName" maxlength="50" />
          </div>
        </div>
      </div>

      <div class="form_section">
        <div class="form-group">
          <label for="comments" [innerHTML]="mandatoryComments"> </label>
          <textarea class="form-control" id="comments" formControlName="comments" rows="3"
            [placeholder]="placeholderComment" maxlength="100"></textarea>
        </div>
      </div>
      <div *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div>

      <div class="create_acnt_btn">
        <button type="submit" class="btn btn-primary gtmCreateAcnt" [innerHTML]="mandatoryCreateAccount"
          [disabled]="!disableButton"></button>
      </div>
      </form>
    </div>
  </div>
</div>
</div>
