
export class GlobalConstants {
    static readonly HOME_PAGE = 'home';
    static readonly STORE_PAGE = 'stores';
    static readonly ASSET_LIBRARY = 'asset-library';
    static readonly PROFILE_PAGE = 'profile';
    static readonly LANDING_HOME = 'landingHome';
    static readonly CREATE_PROFILE = 'create';
    static readonly NEVER = 'Never';
    static readonly NOW = 'Now';
    static readonly ME = 'Me';
    static readonly LOGO_CONTENT_GROUP = 'LogoContentGroup';
    static readonly PROMO_CONTENT_GROUP = 'PromoContentGroup';
    static readonly BANNER_CONTENT_GROUP = 'BannerContentGroup';
    static readonly LOGO = 'Logo';
    static readonly BANNER = 'banner';
    static readonly CONTENT_GROUP = 'Content Group';
    static readonly PRICE_CONTENT_GROUP = 'PriceContentGroup';
    static readonly EXISTING_PROFILES = 'Existing Profiles';
    static readonly PREVIEW = 'Preview';
    static readonly DOWNLOAD = 'Download';
    static readonly NO_RECORDS_FOUND = 'No Records Found';
    static readonly DELETE = 'Delete';
    static readonly DUPLICATE = 'Duplicate';
    static readonly EDIT = 'Edit';
    static readonly DELETE_LAPTOP = 'Delete gaming laptops?';
    static readonly DELETE_DESCRIPTION = ' This change may apply to the devices using this information.';
    static readonly CANCEL = 'Cancel';
    static readonly DELETE_PROFILE = 'Delete Profile';
    static readonly DUPLICATE_GAMING_LAPTOPS = 'Duplicate Gaming Laptops';
    static readonly DUPLICATE_PROFILE = 'Duplicate Profile';
    static readonly PROFILE_NAME = 'Profile Name';
    static readonly SHOWING = 'showing';
    static readonly OF = 'of';
    static readonly PROFILES_HEADING = 'profiles';
    static readonly CREAT_PROFILES = 'Create Profile';
    static readonly CREATED_DATE = 'Created Date';
    static readonly GROUP_NAME = 'Group Name';
    static readonly UPLOAD_DATE = 'Upload Date';
    static readonly FILE_NAME = 'File Name';
    static readonly DOWNLOAD_LIBRARY_IPOS = 'Download Standard IPOS';
    static readonly USER_ID = 'userId'; // Key for User Id in session storage
    static readonly SMALL = 'Small';
    static readonly MEDIUM = 'Medium';
    static readonly LARGE = 'Large';
    static readonly FONTSIZE_12PX = '12px';
    static readonly FONTSIZE_14PX = '14px';
    static readonly FONTSIZE_16PX = '16px';
    static readonly TWO_COL_RIGHT_ASSET = '2_col_right_asset';
    static readonly TWO_COL_LEFT_ASSET = '2_col_left_asset';
    static readonly ONE_COL_MID_ASSET = '1_col_mid_asset';
    static readonly NUMBER_0 = 0;
    static readonly NUMBER_12 = 12;
    static readonly NUMBER_14 = 14;
    static readonly NUMBER_16 = 16;
    static readonly SLASH = '/';
    static readonly USERS = 'users';
    static readonly USERS_RETAILHQS = 'retailhqs';
    static readonly USERS_STORES = 'stores';
    static readonly USERS_ACTIVEUSERS = 'activeusers';
    static readonly USERS_OEMS = 'oems';
    static readonly USERS_PENDINGUSERS = 'pendingusers';
    static readonly USERS_DOMAINWHITELIST = 'domainwhiltelist';

    static readonly SEGMENTS = 'Segments';

    // Asset File Types that can be uploaded - can be Image or Video
    static readonly FILE_TYPE_IMAGE = 'image';
    static readonly FILE_TYPE_VIDEO = 'video';

    // Content-Type alowed for upload
    static readonly CONTENT_TYPE_JPG = 'jpg';
    static readonly CONTENT_TYPE_JPEG = 'jpeg';
    static readonly CONTENT_TYPE_JFIF = 'jfif';
    static readonly CONTENT_TYPE_PNG = 'png';
    static readonly CONTENT_TYPE_GIF = 'gif';
    static readonly CONTENT_TYPE_MP4 = 'mp4';
    //User Management Stores
  
    static readonly BANNER_CAROUSEL = 'bannerCarousel';
    static readonly CONFIGURATIONS = 'configurations';
    static readonly CONTENT_PAGES = 'Content Pages';
    static readonly URL_EXPERIENCE = 'URL';

    static readonly ASC_SORT = 'asc';
    static readonly DESC_SORT = 'desc';
    static readonly UPLOAD_LOGO_IMAGE_SIZE_3MB = 3145728;

    // create profile page constants
    static readonly SKIP = 'skip';
    static readonly PROMO = 'promo';
    static readonly STORE_LOGO = 'store_logo';
    static readonly PROFILE_INFO = 'profile_info';
    static readonly SHOW_ON_DEVICE ='checkbox';
    static readonly ADD_PACKAGES ='check';
    static readonly RADIO = 'radio';
    static readonly CHECK = 'check';
    static readonly SEND = 'send';
    static readonly PROFILE = 'profile';
    static readonly STORELOGO_BGCOLOR = '#000000';
}
