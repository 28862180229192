<div>
  <ul *ngIf="(tabIndex === 1 || tabIndex === 2)" class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 1}">New Attract Loop Video</a>
    </li>
    <li class="nav-item exit_preview">
      <a class="nav-link" (click)="cancel()">
        <img src="assets/images/exit_icon.svg" alt="exit without changes" />
      </a>
    </li>
  </ul>
  <!-- Main section -->
  <section *ngIf="tabIndex === 0" class="main_section al_storepromo">
    <!-- Attractor Loop Group Tab -->
    <div *ngIf="tabIndex === 0" id="attractorLoopContentGroups">
      <div class="main_section_headers">
        <svg class="header_icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.0005 5.25C3.033 5.25 2.25 6.033 2.25 7.0005V16.9995C2.25 17.967 3.033 18.75 4.0005 18.75H13.9995C14.967 18.75 15.75 17.967 15.75 16.9995V7.0005C15.75 6.033 14.967 5.25 13.9995 5.25H4.0005ZM0.75 7.0005C0.75 5.205 2.205 3.75 4.0005 3.75H13.9995C15.795 3.75 17.25 5.205 17.25 7.0005V16.9995C17.25 18.795 15.795 20.25 13.9995 20.25H4.0005C3.13841 20.25 2.31164 19.9075 1.70205 19.298C1.09246 18.6884 0.75 17.8616 0.75 16.9995V7.0005Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.8765 8.47567L21.0285 6.06817C21.0998 6.02518 21.1812 6.00162 21.2645 5.99983C21.3478 5.99805 21.4301 6.0181 21.5032 6.058C21.5763 6.0979 21.6377 6.15625 21.6813 6.22724C21.7248 6.29824 21.749 6.37941 21.7515 6.46267V17.5387C21.7515 17.8807 21.3615 18.1282 21.0285 17.9347L16.878 15.5272L16.125 16.8247L20.2755 19.2322C21.564 19.9792 23.2515 19.0897 23.2515 17.5387V6.46267C23.2515 4.91317 21.564 4.02367 20.2755 4.77067L16.1265 7.17817L16.8795 8.47567H16.8765Z" />
        </svg> ATTRACT LOOP VIDEOS
      </div>

      <!-- Data header with info like no. of content groups, action buttons and filters -->
      <div class="data_header" [ngClass]="(displayAttractorLoop?.length > 0)?'':'display-block'">
        <span *ngIf="displayAttractorLoop?.length > 0">
          <span *ngIf="displayAttractorLoop" id="dataTitle">{{displayAttractorLoop?.length}} Attract
            Loop Videos</span>
          <span *ngIf="displayAttractorLoop?.length > 1">
            Sort by :&nbsp;
            <div ngbDropdown class="btn-group">
              <button ngbDropdownToggle class="btn btn-primary btn-sm dropdown-toggle" id="sortDropDown">{{sortValue}}
              </button>
              <div ngbDropdownMenu aria-labelledby="sortDropDown">
                <button ngbDropdownItem (click)="sortByDate()">Create Date</button>
                <button ngbDropdownItem (click)="sortByName()">Group Name</button>
              </div>
            </div>
          </span>
        </span>
        <span *ngIf="displayAttractorLoop?.length === 0">Upload a short video to play during the attract loop that
          triggers when a device is idle.</span>
        <span class="data_action">
          <button type="button" id="uploadpromo" class="btn btn-primary btn-sm left_icon_btn" (click)="createNewAttractLoop()">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 3.5C8.63261 3.5 8.75979 3.55268 8.85355 3.64645C8.94732 3.74021 9 3.86739 9 4V8C9 8.13261 8.94732 8.25979 8.85355 8.35355C8.75979 8.44732 8.63261 8.5 8.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H8V4C8 3.86739 8.05268 3.74021 8.14645 3.64645C8.24021 3.55268 8.36739 3.5 8.5 3.5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 8C8 7.86739 8.05268 7.74021 8.14645 7.64645C8.24021 7.55268 8.36739 7.5 8.5 7.5H12.5C12.6326 7.5 12.7598 7.55268 12.8536 7.64645C12.9473 7.74021 13 7.86739 13 8C13 8.13261 12.9473 8.25979 12.8536 8.35355C12.7598 8.44732 12.6326 8.5 12.5 8.5H9V12C9 12.1326 8.94732 12.2598 8.85355 12.3536C8.75979 12.4473 8.63261 12.5 8.5 12.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12V8Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 15C10.3565 15 12.137 14.2625 13.4497 12.9497C14.7625 11.637 15.5 9.85652 15.5 8C15.5 6.14348 14.7625 4.36301 13.4497 3.05025C12.137 1.7375 10.3565 1 8.5 1C6.64348 1 4.86301 1.7375 3.55025 3.05025C2.2375 4.36301 1.5 6.14348 1.5 8C1.5 9.85652 2.2375 11.637 3.55025 12.9497C4.86301 14.2625 6.64348 15 8.5 15ZM8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16Z" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="16" height="16" transform="translate(0.5)" />
                </clipPath>
              </defs>
            </svg>
            New Attract Loop Video
          </button>
        </span>
      </div>

      <!-- Data Content -->
      <div class="data_content">
        <div class="accordion" id="promoContentAccordian">

          <!-- promo CG 3 panel -->
          <div
            *ngFor="let attractorLoop of displayAttractorLoop | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
            class="card">
            <div class="card-header small_text" id="promoCGThree">
              <div class="collapse_link collapsed" type="button" [attr.aria-expanded]="attractorLoop?.isExpanded"
                aria-controls="collapseThree">
                <div class="title_left">
                  <div class="title">{{attractorLoop?.configurations?.publishedName}}</div>
                  <iposcen-assetid-copy [assetID]="attractorLoop?.id"></iposcen-assetid-copy>
                  <div class="subtitle_group">
                    <span class="subtitle">Created By: {{attractorLoop?.createdByUser}}</span>
                    <span class="subtitle">Last Edited By {{attractorLoop?.createdByUser}}:
                      {{attractorLoop?.updateTime | formatDate: "12hr"}}</span>
                    <span class="subtitle">Pushed to all devices:
                      {{attractorLoop?.ispushedToDevice ? 'Y' : 'N'}}</span>
                  </div>
                </div>
              </div>
              <div class="button_wrapper collapse_link collapsed">
                <div class="display-flex">
                <button type="button" class="btn btn-outline-primary btn-sm left_icon_btn delete_btn gtmDeleteLoopVdo"
                  (click)="openDeleteModal(attractorLoop?.id)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                      fill="#0068B5" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                      fill="#0068B5" />
                  </svg>
                  Delete
                </button>
                <iposcen-delete-popup #deleteModal [contentName]="attractorLoopToDeleteName"
                  [buttonName]="'Attract Loop Video'" (delete)="deleteAttractorLoop(attractorLoopToDelete)">
                </iposcen-delete-popup>

                <button type="button" class="btn btn-primary btn-sm left_icon_btn gtmEditLoopVdo"
                  (click)="editAttractorLoop(attractorLoop?.id)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.293 1.29279C11.4805 1.10532 11.7348 1 12 1C12.2652 1 12.5195 1.10532 12.707 1.29279L14.707 3.29279C14.8945 3.48031 14.9998 3.73462 14.9998 3.99979C14.9998 4.26495 14.8945 4.51926 14.707 4.70679L5.707 13.7068C5.59752 13.8166 5.464 13.8994 5.317 13.9488L2.317 14.9488C2.14074 15.0078 1.95153 15.0165 1.7706 14.974C1.58966 14.9314 1.42417 14.8393 1.29268 14.7079C1.1612 14.5765 1.06894 14.4111 1.02625 14.2302C0.983555 14.0493 0.992126 13.8601 1.051 13.6838L2.051 10.6838C2.1002 10.5364 2.18305 10.4026 2.293 10.2928L11.293 1.29279ZM12 1.99979L14 3.99979L5 12.9998L2 13.9998L3 10.9998L12 1.99979Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.146 6.35448L9.646 3.85448L10.354 3.14648L12.854 5.64648L12.147 6.35448H12.146ZM3 10.0005V10.5005C3 10.6331 3.05268 10.7603 3.14645 10.854C3.24021 10.9478 3.36739 11.0005 3.5 11.0005H4V11.5005C4 11.6331 4.05268 11.7603 4.14645 11.854C4.24021 11.9478 4.36739 12.0005 4.5 12.0005H5V12.5005C5 12.6331 5.05268 12.7603 5.14645 12.854C5.24021 12.9478 5.36739 13.0005 5.5 13.0005H6V11.5005C6 11.3679 5.94732 11.2407 5.85355 11.1469C5.75979 11.0532 5.63261 11.0005 5.5 11.0005H5V10.5005C5 10.3679 4.94732 10.2407 4.85355 10.1469C4.75979 10.0532 4.63261 10.0005 4.5 10.0005H3Z"
                      fill="white" />
                  </svg>
                  Edit
                </button>
              </div>
                <img [ngClass] = "attractorLoop.isExpanded ? 'transform-180' : 'cursor-pointer'" src="/assets/images/chevron_down.svg"
                alt="click to expand" (click)="getDetailsOfAttractorLoop(attractorLoop?.id)"/>

              </div>
            </div>
            <div id="collapseThree" #collapse="ngbCollapse" [(ngbCollapse)]="!attractorLoop.isExpanded"
              aria-labelledby="promoCGThree" data-parent="#promoContentAccordian">
              <div class="card-body">
                <table class="table-borderless" aria-describedby="attactor loop table">
                  <tr>
                    <th scope="col">Uploaded Video</th>
                  </tr>
                  <tr>
                    <td>{{attractorLoop?.configurations?.uploadedVideoName}}</td>
                  </tr>
                </table>
                <div class="totalDiv">
                  <ng-container *ngFor="let page of attractorLoop?.configurations?.contentGroupPages">
                    <div class="eachPage">
                      <iposcen-live-preview [data]="loadMiniPreview(attractorLoop.id,page)">
                      </iposcen-live-preview>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Data Footer - Pagination -->
      <div class="data_footer">
        <nav aria-label="attractor loop content pagination"
          *ngIf="displayAttractorLoop?.length > 0">
          <ngb-pagination [collectionSize]="displayAttractorLoop?.length" [(page)]="page" [pageSize]="pageSize"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="false"></ngb-pagination>
        </nav>
      </div>

    </div>
  </section>
  <section *ngIf="tabIndex === 1 || tabIndex === 2" class="main_section al_newpromo">
    <ng-container>
      <div *ngIf="tabIndex === 1">
        <iposcen-create-attractor-loop (goBacktoAttractorLoop)="setCreatedGrpID($event)"
          (refreshAttractorLoopdata)="refreshAttractorLoopdata()"></iposcen-create-attractor-loop>
      </div>
    </ng-container>

  </section>
</div>
