import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { StoresComponent } from './stores/stores.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RetailHqComponent } from './retail-hq/retail-hq.component';
import { ColorPickerModule } from 'ngx-color-picker';




@NgModule({
  declarations: [UsersComponent,StoresComponent,
    RetailHqComponent],
  imports: [
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule, 
    ColorPickerModule,
    NgbModule
  ]
 
})
export class UsersModule { }
