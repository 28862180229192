<ng-template #deleteModal>
<div class="card deletePopup">
  <div class="modal-header">
    <div (click)="dismiss()" class="close_icon">
      <img src="assets/images/popup_close_button.svg" alt="close icon"/>
    </div>
  </div>
  <div class="modal-body">
     <h2>Delete {{contentName}}?</h2>
     <div class="card_desc">
      This change may apply to all profiles and devices using this information.
     </div>
  </div>
  <div class="modal-footer card-footer">
          <button type="button" (click)="dismiss()" class="btn btn-outline-primary gtmCancelDltContGrp">
            Cancel
          </button>
          <button  type="button" class="btn btn-primary gtmDeleteContGrp" (click)="deleteContent()">
            Delete {{buttonName}}
          </button>
  </div>
</div>
</ng-template>