import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from '../../../../app/common/constants/global.constant';
@Component({
  selector: 'iposcen-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  deleteLaptop: string;
  deletedDesc: string;
  cancel: string;
  deleteProfile: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.deleteLaptop = GlobalConstants.DELETE_LAPTOP;
    this.deletedDesc = GlobalConstants.DELETE_DESCRIPTION;
    this.cancel = GlobalConstants.CANCEL;
    this.deleteProfile = GlobalConstants.DELETE_PROFILE;
  }
  close() {
   this.activeModal.close();
  }

}
