import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { CommonService } from '../../../common/services/common.service';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { RouterConstants } from '../../../../app/core/routing/constants/app-paths.constant';
import { BannerCarouselService } from '../../../../app/core/routing/services/asset-library/banner-carousel.service';
import { BannerContent, BannerContentConfiguration, BannerContentGroupPage, CreateBannerContentResponse } from './interface/banner-carousel.i';
import { type } from 'os';
import { AssetLibraryConstants } from '../../../core/routing/services/asset-library/constants/asset-library.constant';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'iposcen-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss']
})
export class BannerCarouselComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal') deleteModal: ElementRef;
  userId = sessionStorage.getItem('userId');
  configurations: BannerContentConfiguration[];
  tabIndex = 0;
  allBannerContentGroups: BannerContent[];
  displayBannerContentGroups = [];
  selectedFiles: FileList;
  sideNav = 'banner';
  sortValue = GlobalConstants.CREATED_DATE;
  mediaObjects: any[] = [];
  page = 1;
  pageSize = 5;
  lCGlayout = 1;
  bannerCGToDelete: string;
  bannerCGToDeleteName: string;
  contentGrpData: object;
  pageType = GlobalConstants.BANNER_CONTENT_GROUP;
  clickable = false;
  bannerFunction: string;
  pages: any;
  customURL: string;
  pageGrpForm: any;
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
  + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private router: Router,
    private cmnService: CommonService,
    private bannerContentService: BannerCarouselService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getAllBannerContentsByUser();
    this.customURL = '';
    this.pages = {};
    this.bannerFunction = GlobalConstants.CONTENT_PAGES;
    this.cmnService.uploadedImageURL = '';
    //listens image data from banner preview
    this.cmnService.pageDataTobannerObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (this.contentGrpData) {
          const bannerContent = this.contentGrpData[GlobalConstants.BANNER_CAROUSEL];
          if (typeof bannerContent.configurations === 'string') {
            bannerContent.configurations =
              JSON.parse(bannerContent.configurations) as BannerContentConfiguration;
          }
          this.customURL = res.data;
          bannerContent.configurations.customURL = res.data;
          this.contentGrpData[GlobalConstants.BANNER_CAROUSEL] = bannerContent;
        }
      });

    //listens page data from live preview
    this.cmnService.pageDataTologoObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (this.contentGrpData) {
          const bannerContent = this.contentGrpData[GlobalConstants.BANNER_CAROUSEL];
          let configurations = bannerContent.configurations;
          if (res.data.media.url !== '') {
            this.prepareMediaSet(res, bannerContent.id);
          }
          const checkJson = this.checkExistance(configurations.contentGroupPages, 'pageNum', res.number);
          // console.log(checkJson);
          if (checkJson.hasMatch) {// If there is configuration object already for the page number
            // Update the pages
            configurations.contentGroupPages[checkJson.matchedIndex] = {
              pageNum: res.number,
              title: res.data.heading,
              titleFontColor: res.data.hcolor,
              bgColor: res.data.bgcolor,
              bodyCopy: res.data.body,
              bodyCopyFontSize: this.fontSizeNumber(res.data.size),
              bodyCopyFontColor: res.data.bcolor,
              UiLayout: this.getUILayoutNameByID(res.data.layout),
              linkedAsset: res.data.media.url !== '' ? true : false,
              linkedAssetFileType: res.data.media.format
            };
          } else {
            const bannerContentGroupPage: BannerContentGroupPage = {
              pageNum: res.number,
              title: res.data.heading,
              titleFontColor: res.data.hcolor,
              bgColor: res.data.bgcolor,
              bodyCopy: res.data.body,
              bodyCopyFontSize: this.fontSizeNumber(res.data.size),
              bodyCopyFontColor: res.data.bcolor,
              UiLayout: this.getUILayoutNameByID(res.data.layout),
              linkedAsset: res.data.media.url !== '' ? true : false,
              linkedAssetFileType: res.data.media.format
            };
            configurations.contentGroupPages.push(bannerContentGroupPage);
          }
          this.contentGrpData[GlobalConstants.BANNER_CAROUSEL] = bannerContent;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // To hold all pages media objects and its metada.
  prepareMediaSet(res: any, promoContentId: string) {
    const checkJson = this.checkExistance(this.mediaObjects, 'pageNum', res.number);
    if (checkJson.hasMatch) {
      // Update the pages
      this.mediaObjects[checkJson.matchedIndex] = {
        id: promoContentId,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        this.mediaObjects[checkJson.matchedIndex].uploaded = true;
      }
    } else {
      const dataObj: any = {
        id: promoContentId,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        dataObj.uploaded = true;
      }
      this.mediaObjects.push(dataObj);
    }
    // console.log(this.mediaObjects);
  }
//mini preview
loadMiniPreview(gId: string, pageContent: any) {
  this.pageGrpForm = {
    bgcolor: pageContent ? pageContent.bgColor : '#0068B5',
    heading:  pageContent ? pageContent.title : '',
    hcolor: pageContent ? pageContent.titleFontColor : '#ffffff',
    body: pageContent ? pageContent.bodyCopy : '',
    bcolor: pageContent ? pageContent.bodyCopyFontColor : '#ffffff',
    size: pageContent ? this.getFontSize(pageContent.bodyCopyFontSize) : GlobalConstants.MEDIUM,
    media: {
      url: (pageContent && pageContent.linkedAsset) ?
      environment.apiBaseUrlAsset + this.url + AssetLibraryConstants.BANNER_CONTENTS + AssetLibraryConstants.SLASH +
  gId + AssetLibraryConstants.SLASH + pageContent.pageNum.toString() : '',
      name: '',
      format: (pageContent && pageContent.linkedAsset) ? (pageContent.linkedAssetFileType) ? pageContent.linkedAssetFileType : '' : '',
      fileObj: ''
    },
    id: gId,    // adding created group id,
    layout: pageContent ? this.getUILayoutIDByName(pageContent.UiLayout) : 1
  };

  return this.pageGrpForm;
}
getFontSize(size: number ) {
  if (size === GlobalConstants.NUMBER_12) { return GlobalConstants.SMALL; }
  if (size === GlobalConstants.NUMBER_14) { return GlobalConstants.MEDIUM;}
  if (size === GlobalConstants.NUMBER_16) { return GlobalConstants.LARGE; }
}
getUILayoutIDByName(name: string) {
  if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
    return 1;
  } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
    return 2;
  } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
    return 3;
  }
}
  checkExistance(data, key, compValue) {
    const checkJSON = { hasMatch: false, matchedIndex: -1 };
    for (let index = 0; index < data.length; index += 1) {
      if (data[index][key] === compValue) {
        checkJSON.hasMatch = true;
        checkJSON.matchedIndex = index;
        break;
      }
    }
    return checkJSON;
  }

  setSideNav(value: string) {
    this.sideNav = value;
  }

  updateBCG() {
    const updateBannerContent: CreateBannerContentResponse = this.contentGrpData as CreateBannerContentResponse;
    //Once page is updated.
    const bannerContent = updateBannerContent.bannerCarousel;
    if (typeof bannerContent.configurations !== 'string') {
      bannerContent.configurations = JSON.stringify(bannerContent.configurations);
    }
    this.bannerContentService.putBannerContentById(JSON.stringify(bannerContent), bannerContent.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putBannerContentResponse => {
        this.setIndex(0);
        this.getAllBannerContentsByUser();
        this.clickable = false;
        this.cmnService.uploadedImageURL = '';
      });
  }

  getAllBannerContentsByUser() {
    this.bannerContentService.getAllBannerContentByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllBannerContentResponse => {
        this.allBannerContentGroups = (getAllBannerContentResponse.bannerCarousel as BannerContent[]);
        this.configurations = [];
        this.allBannerContentGroups.forEach(bannerContentGroup => {
          this.configurations.push(JSON.parse(bannerContentGroup.configurations) as BannerContentConfiguration);
        });
        // display Banner content groups using displayBannercontentgroups var as configurations is coming as string
        this.displayBannerContentGroups = [];
        this.allBannerContentGroups.forEach(ele => {
          ele.configurations = JSON.parse(ele.configurations);
          ele.createdByUser = (ele.createdByUser === this.userId) ? GlobalConstants.ME : ele.createdByUser;
          this.displayBannerContentGroups.push(ele);
        });
        this.displayBannerContentGroups.forEach(element => {
          // adding this custom property to know which Banner content is expanded
          // console.log('display banner content group:: ' + JSON.stringify(element));
          element.isExpanded = false;
          if (element.updateTime) {
            element.updateTime = new Date(element.updateTime)
          }
          // adding " " for form factor elements for view
          if (element && element.configurations && element.configurations.formFactor && element.configurations.formFactor.manufacturer) {
            element.configurations.formFactor.manufacturer = element.configurations.formFactor.manufacturer.join(", ");
          }
          if (element && element.configurations && element.configurations.formFactor && element.configurations.formFactor.devices) {
            element.configurations.formFactor.devices = element.configurations.formFactor.devices.join(", ");
          }
          if (element && element.configurations && element.configurations.formFactor && element.configurations.formFactor.processors) {
            element.configurations.formFactor.processors = element.configurations.formFactor.processors.join(", ");
          }
          if (element && element.configurations && element.configurations.formFactor && element.configurations.formFactor.generations) {
            element.configurations.formFactor.generations = element.configurations.formFactor.generations.join(", ");
          }
          // fetch images from s3
          // console.log('fetch image: '+ element.id);
          this.fetchImagesForBannerCGPages(element.id);
        });

        this.sortByDate();
      },
        error => {
          // do something
        });
  }

  setTabNavigation(option) {
    this.bannerFunction = option;
  }

  cancel() {
    this.getAllBannerContentsByUser();
    this.setIndex(0);
    this.dataService.setBannerContent(undefined);
    this.mediaObjects = [];
    this.cmnService.uploadedImageURL = '';
    this.bannerFunction = GlobalConstants.CONTENT_PAGES;
    if (this.lCGlayout !== 1) {
      this.lCGlayout = 1;
    }
  }

  setCreatedGrpID(data) {
    // console.log('type of data:: '+ typeof data + ', value is: ' + JSON.stringify(data));
    if (typeof data === 'object' && data.cancel) {
      this.setIndex(0);
    } else {
      this.clickable = true;
      this.contentGrpData = data;
      if (typeof this.contentGrpData[GlobalConstants.BANNER_CAROUSEL][GlobalConstants.CONFIGURATIONS] === 'string') {
        this.contentGrpData[GlobalConstants.BANNER_CAROUSEL][GlobalConstants.CONFIGURATIONS] =
          JSON.parse(this.contentGrpData[GlobalConstants.BANNER_CAROUSEL][GlobalConstants.CONFIGURATIONS]) as BannerContentConfiguration;
      }
      if (this.bannerFunction === GlobalConstants.URL_EXPERIENCE) {
        this.setIndex(2);
        this.bannerFunction = GlobalConstants.CONTENT_PAGES;
      } else {
        this.pages = {};
        this.setIndex(3);
      }
    }
    this.mediaObjects = [];
    if (this.lCGlayout !== 1) {
      this.lCGlayout = 1;
    }
  }

  setTab(bannerFunction) {
    if (this.lCGlayout !== 1) {
      this.lCGlayout = 1;
    }
    if (bannerFunction === GlobalConstants.URL_EXPERIENCE) {
      this.customURL = '';
      this.contentGrpData[GlobalConstants.BANNER_CAROUSEL].configurations.contentGroupPages = [];
      this.setIndex(2);
      this.cmnService.emitMetadataCheck(true, bannerFunction);
    } else {
      this.mediaObjects = [];
      this.pages = {};
      this.contentGrpData[GlobalConstants.BANNER_CAROUSEL].configurations.customURL = '';
      this.setIndex(3);
      this.cmnService.emitMetadataCheck(true, bannerFunction);
    }
  }

  /*when user opens pages*/
  setPreviewLayout(data) {
    this.lCGlayout = data;
  }

  setIndex(value: number) {
    this.tabIndex = value;
  }

  setLayout(selected) {
    this.lCGlayout = selected;
    this.cmnService.emitLayout(selected);
  }

  refreshBannerContentdata() {
    this.getAllBannerContentsByUser();
    this.setIndex(0);
    this.cmnService.uploadedImageURL = '';
    this.mediaObjects = [];
  }

  redirectToLogin() {
    this.router.navigateByUrl(RouterConstants.LOGIN_PAGE_LINK);
  }

  trackByConfiguration(index: number, el: string): number {
    return index;
  }

  fetchImagesForBannerCGPages(bannerCGId: string) {
    this.displayBannerContentGroups.forEach(element => {
      if (element.id === bannerCGId && element.configurations && element.configurations.contentGroupPages) {
        element.configurations.contentGroupPages.forEach(page => {
          if (page.linkedAsset) {
            const reader = new FileReader();

            this.bannerContentService.getBannerContentByPageId(bannerCGId, page.pageNum)
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(imageData => {
                reader.addEventListener('load', () => {
                  page.image = reader.result;
                }, false);

                if (imageData) {
                  reader.readAsDataURL(imageData);
                }
              },
                error => {
                  // do something
                });
          }
        });
      }
    });
  }


  getDetailsOfBannerCG(id: string) {
    this.displayBannerContentGroups.forEach(element => {
      if (element.id === id) {
        element.isExpanded = !element.isExpanded;
      }
    });
  }

  sortByDate() {
    this.sortValue = GlobalConstants.CREATED_DATE;
    this.displayBannerContentGroups.sort((banner1, banner2) => banner2.updateTime - banner1.updateTime);
  }

  sortByName() {
    this.sortValue = GlobalConstants.GROUP_NAME;
    this.displayBannerContentGroups.sort((banner1, banner2) =>
      banner1.configurations.publishedName.localeCompare(banner2.configurations.publishedName));
  }

  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openDeleteModal(bannerCGID: string) {
    this.bannerCGToDelete = this.displayBannerContentGroups.find(ele => ele.id === bannerCGID).id;
    this.bannerCGToDeleteName = this.displayBannerContentGroups.find(ele => ele.id === bannerCGID).configurations.publishedName;
    this.openModal(this.deleteModal);
  }

  editBanner(bannerCGID: string) {
    const bannerCGToEdit = this.displayBannerContentGroups.find(ele => ele.id === bannerCGID);
    this.dataService.setBannerContent(bannerCGToEdit);
    this.setIndex(1);
  }

  deleteBannerCG(bannerCGID: string) {
    // delete Banner API call
    this.bannerContentService.deleteBannerContentById(bannerCGID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {
        const index = this.displayBannerContentGroups.findIndex(d => d.id === bannerCGID); // find index in your array
        if (index >= 0) {
          this.displayBannerContentGroups.splice(index, 1); // remove element from array
        }
        this.closeModal(this.deleteModal);
      },
        error => {
          // do something
          this.closeModal(this.deleteModal);
        });
  }

  fontSizeNumber(size) {
    if (size === GlobalConstants.SMALL) { return GlobalConstants.NUMBER_12; }
    if (size === GlobalConstants.MEDIUM) { return GlobalConstants.NUMBER_14; }
    if (size === GlobalConstants.LARGE) { return GlobalConstants.NUMBER_16; }
  }

  getUILayoutNameByID(id) {// TODO - Confirm with Sujay if layout name is correct
    if (id === 1) {
      return GlobalConstants.TWO_COL_RIGHT_ASSET;
    } else if (id === 2) {
      return GlobalConstants.TWO_COL_LEFT_ASSET;
    } else if (id === 3) {
      return GlobalConstants.ONE_COL_MID_ASSET;
    }
  }

  addPage() {
    const keyLen = Object.keys(this.pages).length;
    if (keyLen === 0) {
      this.pages.page2 = true;
      this.setIndex(4);
    } else {
      this.pages.page3 = true;
      this.setIndex(5);
    }
  }

  checkPages() {
    if (this.pages.page2 && this.pages.page3) {
      return true;
    }
    return false;
  }

  addNewBannerCarousel() {
    this.dataService.setBannerContent(undefined);
    this.tabIndex = 1;
  }

}
