import { Component, ElementRef, EventEmitter, OnInit,OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { first } from 'rxjs/internal/operators/first';
import { CreateProfileService } from '../../../core/routing/services/profile/create-profile.service';
import { ProfileResponse, ProfileRequest } from '../interface/profile.i';
import { DataService } from 'src/app/core/data/data-service';
import { GlobalConstants } from '../../../common/constants/global.constant';

@Component({
  selector: 'iposcen-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  @Output() readonly gotoBackCreateProfile = new EventEmitter<object>();
  @ViewChild('profileLanguageModal') profileLanguageModal: ElementRef;
  operatingSystem: string;
  isAttractorLoopRadionBtn: boolean;
  selectedLangauge = []  as any;
  profileInfoFrm: FormGroup;
  isSubmitted: boolean;
  isGamingMsg: boolean;
  QrCodeStatus: string;
  rxtStatus: string;
  idleTimeValue: string;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder,
    private CreateProfileService: CreateProfileService,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.operatingSystem = 'Windows';
    this.idleTimeValue = '2 minutes';
    this.isAttractorLoopRadionBtn = false;
    this.isGamingMsg = false;
    this.createProfileInfoForm();
    this.isSubmitted = false;
    this.selectedLangauge.push('English - United States');
    this.QrCodeStatus = 'Enabled';
    this.rxtStatus = 'Disabled';
  }

  getSelectedOperatingSystem(selectedOS){
    this.operatingSystem = selectedOS;
  }

  getSelectedIdleTime(selectedIdleTime){
    this.idleTimeValue = selectedIdleTime;
  }

  getIdleDisplay(selectedIdleDisplayValue){
    if(selectedIdleDisplayValue === 'fact-tag-rotation'){
      this.isAttractorLoopRadionBtn = false;
    }else if (selectedIdleDisplayValue === 'attractorLoop'){
      this.isAttractorLoopRadionBtn = true;
    }
  }

  createProfileInfoForm() {
     this.profileInfoFrm = this.formBuilder.group({
       profileName: new FormControl(''),
       OperatingSystemVal: new FormControl(''),
       selectedLangauge: new FormControl(''),
       idleTime: new FormControl('')
     });
  }

  isGaming(data){
    this.isGamingMsg = data.currentTarget.checked;
  }

  onAdd(){
    this.isSubmitted = true;
    if(this.profileInfoFrm.controls.profileName.value === ''){
      return;
    }
    const profileInfoContent: ProfileRequest = {
      profileInfo: '',
       profileName: this.profileInfoFrm.controls.profileName.value,
       operatingSystem: this.operatingSystem,
       languages: this.selectedLangauge,
       idleTimer : this.idleTimeValue,
       isIposPackaged: true,
       attractLoop: {
        attractLoopId:'',
        showOnDevice: true
      }

    };
    this.CreateProfileService.createProfileInfo(JSON.stringify(profileInfoContent))
    .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createProfileResponse => {
        const createProfileInfo: ProfileResponse = createProfileResponse as ProfileResponse;
        this.gotoBackCreateProfile.emit(createProfileInfo);        
        
        this.CreateProfileService.setProfileId(createProfileResponse.profile.profileId);       
        
      },
        error => {
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openModal(modal: any) { modal.open(); }

  closeModal(modal: any) { modal.close(); }

  openMultiSelectLanguageModal(){
    this.openModal(this.profileLanguageModal);
  }

  updateLanguageDetails(data){
    this.selectedLangauge = data;
    this.closeModal(this.profileLanguageModal);
  }

  displayQRCode(data){
    this.QrCodeStatus = this.checkStatus(data);
  }

  includeRxtSalesTool(data){
   this.rxtStatus =this.checkStatus(data); 
  }

  checkStatus(param){
    if ( param.currentTarget.checked ) {
      return('Enabled');
    } else {
      return('Disabled');
    }
  }
  
  
}
