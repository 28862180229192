<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
      <a class="central_logo" [href]="loginhref"> <img class="img-fluid" alt="IPOS Central LOGO" /> </a>
      <div class="card new_pass_card">
        <form class="form-signin" [formGroup]="newPasswordForm" (change)="resetButton()" (keyup)="resetButton()" (ngSubmit)="onNext()">
          <div class="form-group">
            <div class="form_header" [innerHTML]="heading"></div>
          </div>
          <div class="firstspan">
          <span  [innerHTML]="firstDescription"></span></div>
          <div class="form-group">
            <label class="control-label" for="verificationCode" [innerHTML]="verificationCodeLabel"></label>
            <input [ngClass]="{
              'has-error':
                isSubmitted && newPasswordForm.controls.newPassword.errors
            }"  id="verificationCode" type="verificationCode" class="form-control"
              formControlName="verificationCode" [placeholder]="placeholderConfCode">
              <em class="err_icon"
              *ngIf="isSubmitted && newPasswordForm.controls.verificationCode.errors"></em>
              <span *ngIf="isSubmitted && newPasswordForm.controls.verificationCode.errors" class="help-block">
                <span id="pwdErrMsg" class="formfield_err_msg" *ngIf="newPasswordForm.controls.verificationCode.errors.required"
                  [innerHTML]="requiredField"></span>
                  <div id="emailErrMsg" class="formfield_err_msg"
                  *ngIf="newPasswordForm.controls.verificationCode.errors.pattern" [innerHTML]="cnfCodeLength"></div>
              </span>
          </div>
          <div class="form-group">
            <label for="password" [innerHTML]="newPwdLabel"></label>
           
              <input [ngClass]="{
                'has-error':
                  isSubmitted && newPasswordForm.controls.newPassword.errors
              }"  class="form-control" [type]="fieldTextType ? 'text' : 'password'" maxlength="30" [placeholder]="newPwdLabel" formControlName="newPassword" id="newPassword" required>
              <em class="err_icon"
              *ngIf="isSubmitted && newPasswordForm.controls.newPassword.errors"></em>
              <fa-icon *ngIf="newPasswordForm.controls.newPassword.value" class="input-icon" [icon]="displayEye"  [ngClass]="{
                'eye-slash': fieldTextType,
                'eye': !fieldTextType
              }"
              (click)="toggleFieldTextType()"></fa-icon>
           
            <span *ngIf="isSubmitted && newPasswordForm.controls.newPassword.errors" class="help-block">
              <span id="pwdErrMsg" class="formfield_err_msg" *ngIf="newPasswordForm.controls.newPassword.errors.required"
                [innerHTML]="requiredField"></span>
                <div id="emailErrMsg" class="formfield_err_msg"
                *ngIf="newPasswordForm.controls.newPassword.errors.pattern" [innerHTML]="pwdLength"></div>
            </span>
            </div>
            <div class="form-group">
              <label for="cnfpassword" [innerHTML]="cnfnewPwdLabel"></label>
             
                <input [ngClass]="{
                  'has-error':
                    isSubmitted && newPasswordForm.controls.cnfpassword.errors
                }"  class="form-control" [type]="fieldTextTypeCP ? 'text' : 'password'" maxlength="30" [placeholder]="cnfnewPwdLabel" formControlName="cnfpassword" id="cnfpassword" required>
                <em class="err_icon"
                *ngIf="isSubmitted && newPasswordForm.controls.cnfpassword.errors"></em>
                <fa-icon *ngIf="newPasswordForm.controls.cnfpassword.value" class="input-icon" [icon]="displayEye"  [ngClass]="{
                  'eye-slash': fieldTextTypeCP,
                  'eye': !fieldTextTypeCP
                }"
                (click)="toggleCPFieldTextType()"></fa-icon>
             
              <span *ngIf="isSubmitted && newPasswordForm.controls.cnfpassword.errors" class="help-block">
                <span id="pwdErrMsg" class="formfield_err_msg" *ngIf="newPasswordForm.controls.cnfpassword.errors.required"
                  [innerHTML]="requiredField"></span>
                  <div class="formfield_err_msg" *ngIf="newPasswordForm.controls.cnfpassword.errors.confirmedValidator"
                  [innerHTML]="mandatoryPasswordMatch"></div>
              </span>
             
              </div>
          <div *ngIf="errorMessage" [class]="alertFormat">
            {{ errorMessage }}
          </div>
          <div class="newpwd_btn">
            
            <button id="signupSubmit"  type="submit" class="btn btn-primary gtmResetPswd" [innerHTML]="heading" [disabled]="!resetBtn">
            </button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
