import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LANGUAGE_MAPPINGS } from '../../constants/language-mappings.const';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'iposcen-lang-multi-select',
  templateUrl: './lang-multi-select.component.html',
  styleUrls: ['./lang-multi-select.component.scss']
})
export class LangMultiSelectComponent implements OnInit {

  @Input() selectedItem: string;
  @Output() updateLanguage = new EventEmitter<string>();
  @ViewChild('profileLanguageModal') private modalContent: TemplateRef<LangMultiSelectComponent>;
  private modalRef: NgbModalRef;
  private modalOption: NgbModalOptions = {};
  languageJSON = [] as any;
  userSelectedLanguage = [] as any;
  newSelectedLanguage = [] as any;
  browserLanguage:string;
  langForm: FormGroup;

  constructor(private modalService: NgbModal,
    private formbuilder: FormBuilder) { }
  
  ngOnInit(): void {
    this.languageGeneration();
    this.browserLanguage = '';
    this.userSelectedLanguage.push('English - United States');
    this.langForm = this.formbuilder.group({
      lang: new FormControl('', [Validators.required]),
    });
  }

  languageGeneration() {
    LANGUAGE_MAPPINGS.forEach(element => {
      let display = false;
      if(element.langName === 'English - United States'){
        display = true;
      }
      const language = {
        langCode: element.langCode,
        langName: element.langName,
        checked: display
      };
      this.languageJSON.push(language);
    });
  }

  

  updateLanguageList(){
    this.newSelectedLanguage = [];
    this.updateLanguage.emit(this.userSelectedLanguage);
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.open(this.modalContent, this.modalOption);
    this.modalRef.result.then();
  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.languageJSON.forEach(element => {
      for (let count = 0 ; count < this.newSelectedLanguage.length; count++) {
        if(this.newSelectedLanguage[count] === element.langName){
          element.checked = false;
          this.userSelectedLanguage = this.userSelectedLanguage.filter(item => item !== this.newSelectedLanguage[count]);
        }
      }
    });
    this.updateLanguage.emit(this.userSelectedLanguage);
    this.newSelectedLanguage = [];
    this.modalRef.dismiss();
  }

  updateList(data,status){
    this.languageJSON.forEach(element => {
      if(element.langName === data){
        element.checked = status;
      }
    });
  }

  getLanguageChkBx(data){
    if (data.currentTarget.checked) {
      this.userSelectedLanguage.push(data.currentTarget.id);
      this.newSelectedLanguage.push(data.currentTarget.id);
    } else {
      this.newSelectedLanguage = this.newSelectedLanguage.filter(item => item !== data.currentTarget.id);
      this.userSelectedLanguage = this.userSelectedLanguage.filter(item => item !== data.currentTarget.id);
    }
    this.updateList(data.currentTarget.id,data.currentTarget.checked);
  }

  checkCount(){
    return(this.userSelectedLanguage.length > 4);
  }
}
