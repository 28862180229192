import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectLanguageConstants } from '../../common/components/select-lang/constants/select-lang.consts';
import { RouterConstants } from '../../core/routing/constants/app-paths.constant';
import { LANGUAGE_MAPPINGS } from './../../common/constants/language-mappings.const';
import { LanguageConstants } from './constants/lang.consts';

@Component({
  selector: 'iposcen-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  languages: string[] = [];
  headerForm: FormGroup;
  help: string;
  isSelected: boolean;
  languageText: string;
  language: string;
  cancel: string;
  selectLanguage: string;
  buttonText: string;
  loginhref: string;
  selectedLanguage: string;
  languageJSON = [] as any;
  browserLanguage: string;
  count = 0;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.help = LanguageConstants.HELP;
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.languageText = LanguageConstants.LANGUAGE;
    this.language = LanguageConstants.LANGUAGE;
    this.cancel = LanguageConstants.CANCEL;
    this.selectLanguage = LanguageConstants.SELECT_LANGUAGE;
    this.buttonText = LanguageConstants.LANGUAGE;
    this.languages = LANGUAGE_MAPPINGS.map(lang => lang.langName);
    this.browserLanguage = navigator.language;

    if (localStorage.getItem('preferredLanguage') !== undefined && localStorage.getItem('preferredLanguage') !== null) {
      this.browserLanguage = localStorage.getItem('preferredLanguage');
    }
    this.loadJSONObject(this.browserLanguage);
    this.headerForm = this.formBuilder.group({
      lang: new FormControl('', [Validators.required])
    });
  }

  trackByLanguage(index: number, el: string): number {
    return index;
  }

  getButtonText() {
    this.buttonText = this.selectedLanguage;
  }

  setButtonText(selectedName: string, selectedCode: string) {
    this.selectedLanguage = selectedName;
    localStorage.setItem('preferredLanguage', selectedCode);
  }

  loadJSONObject(defaultLanguage: string): void {
    LANGUAGE_MAPPINGS.forEach(element => {
      let display = false;
      if (defaultLanguage === element.langCode) {
        display = true;
        this.count = 1;
      }
      const language = {
        langCode: element.langCode,
        langName: element.langName,
        checked: display
      };
      this.languageJSON.push(language);
    });
      if (this.count === 0) {
        this.router.navigate([RouterConstants.SELECT_LANGUAGE_LINK, SelectLanguageConstants.LOGIN_DEFAULT]);
      }
  }
}
