import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
// import { map } from 'rxjs/operators';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { AssetLibraryConstants } from './constants/asset-library.constant';

@Injectable({
  providedIn: 'root'
})
export class StoreLogoService {

  userId = sessionStorage.getItem('userId');
  STORE_LOGO_URL = AssetLibraryConstants.ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId + AssetLibraryConstants.STORE_LOGO;
  GET_STORE_LOGO_BY_ID_URL = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId
    + AssetLibraryConstants.STORE_LOGO + AssetLibraryConstants.SLASH;

  constructor(private httpMethodsservice: HttpMethodsService) {

  }

  createStoreLogo(configurationsJson: string) {
    const url = this.STORE_LOGO_URL;
    const addObject = {
      configurations: configurationsJson,
      isdefault: false
    };

    return this.httpMethodsservice.post(url, addObject)
      .pipe(map((res: any) =>
        res));
  }

  putStoreLogo(isDefault: boolean, id: string) {
    const url = this.STORE_LOGO_URL + AssetLibraryConstants.SLASH + id;
    const addObject = {
      isdefault: isDefault
    };

    return this.httpMethodsservice.put(url, addObject)
      .pipe(map((res: any) =>
        res));
  }

  getAllStoreLogoByUser() {
    const url = this.STORE_LOGO_URL;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) =>
        res));
  }

  getStoreLogoById(id: string) {
    const url = this.GET_STORE_LOGO_BY_ID_URL + id;

    return this.httpMethodsservice.getImage(url, true)
      .pipe(map((res: any) =>
        res));
  }

  uploadImage(image: File, id: string, imageType: string) {
    const url = this.GET_STORE_LOGO_BY_ID_URL + id;

    // const formData = new FormData();
    // formData.append('image', image);

    return this.httpMethodsservice.putImage(url, image, imageType, true)
      .pipe(map((res: any) =>
        res));
  }

  deleteS3StoreLogoById(id: string) {
    const url = this.GET_STORE_LOGO_BY_ID_URL + id;

    return this.httpMethodsservice.delete(url, true)
      .pipe(map((res: any) =>
        res));
  }

  deleteStoreLogoById(id: string) {
    const url = this.STORE_LOGO_URL + AssetLibraryConstants.SLASH + id;

    return this.httpMethodsservice.delete(url)
      .pipe(map((res: any) =>
        res));
  }
}
