 <!-- Duplicate Laptop Card with Input Field (without text)-->
 <div class="card duplicate_laptop">
    <a (click)="close()" class="popup_close">
      <img
        alt="go to profile page"
      />
    </a>

    <div class="form_header" [innerHTML]="duplicateGamingLaptops"></div>
    <div [innerHTML]="profName">
    </div>
    <input class="text_input" type="text" value="Profile Name"/>
    <div class="card-footer">
        <button type="button" class="btn btn-outline-primary gtmDuplicateCancel" [innerHTML]="cancel">
          
        </button>
        <button type="button" class="btn btn-primary inactive gtmDuplicateProfile" [innerHTML]="duplicateProfile">
        </button>
    </div>
  </div>

