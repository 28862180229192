<div class="quick_links">
  <a href="#" class="help_link gtmHelp">
    <img alt="click here for help" />
    &nbsp; <span [innerHTML]="help"></span>
  </a>
  <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block" display="dynamic" placement="bottom-right">
    <a id="language" class="gtmLanguage" ngbDropdownToggle [innerHTML]="buttonText">

      &nbsp; <span [innerHTML]="language"></span>
    </a>
    <div ngbDropdownMenu aria-labelledby="language">
      <div class="dropdown-menu_header">
        <h5 [innerHTML]="languageText"></h5>
      </div>
      <form [formGroup]="headerForm" class="dropdownscroll" ngbRadioGroup>
        <div class="form-check">
          <label ngbButtonLabel for="language" class="lang_radiobtn"
            *ngFor="let language of languageJSON; trackBy: trackByLanguage">
            {{ language.langName }}
            <input type="radio" name="lang" value="language.langName"
              (click)="setButtonText(language.langName, language.langCode)" [checked]="language.checked" />
          </label>
        </div>

      </form>
      <div class="dropdown-menu_footer">
        <button type="submit" class="btn btn-outline-primary" [innerHTML]="cancel"
          (click)="$event.stopPropagation(); myDrop.close();"></button>
        <button type="submit" class="btn btn-primary" [innerHTML]="selectLanguage"
          (click)="$event.stopPropagation();getButtonText(); myDrop.close();"></button>
      </div>
    </div>
  </div>

</div>
