<div class="title_group_info">
  <span><strong>Content ID: </strong>{{assetID}}</span>
  <span>
    <button type="button" class="btn btn-link small_text left_icon_btn" (click)="copyAssetId(assetID)">
      <img src="assets/images/clipboard_icon.svg" alt="copy" />Copy to Clipboard
      <span class="right_icon_btn" *ngIf="isCopy">
        <img src="assets/images/editcircle_icon.svg" alt="copied" class="" />
      </span>
    </button>
  </span>
  <span #addButton></span>
</div>
