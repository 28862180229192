export class ConfirmRegistrationConstants {
     static readonly CONF_REG_HEADING = 'Confirm Registration';
     static readonly CONF_CODE = 'Confirmation Code';
     static readonly CONFIRM_BUTTON = 'Confirm';
     static readonly RESEND_CODE = 'Resend Code';
     static readonly CODE_RESENT = 'Code Resent';
     static readonly PLACEHOLDER_CONFIRMATION_CODE = 'e.g. 123897';
     static readonly LOGIN = 'Login';
     static readonly ACCOUNT_CREATED = 'Account Created';
     static readonly ACCOUNT_REQUESTED = 'Account Requested';
     static readonly CONF_MESSAGE_APPROVED = 'Thank you, <firstName>. Your account has been successfully created.';
     static readonly CONF_MESSAGE_UNAPPROVED = 'Thank you, <firstName>. Your request for an account has been made and will be reviewed by an admin within 24 hours. For urgent need, please contact -&nbsp; <a href="#">[support_email]@[domain].com </a>';
     static readonly MESSAGE_NOTIFY_TO_USER = 'Thank you, <firstName>. Please enter the confirmation code sent to <[email]@[domain].com> to activate your IPOS account.';
     static readonly DIMISS_REASON_ESC = 'by pressing ESC';
     static readonly DISMISS_REASON_DROP = 'by clicking on a backdrop';
     static readonly APPROVED_DOMAIN_LIST = ['yahoo.com', 'yahoo.co.in'];
}
