export class RouterConstants {
    static readonly SELECT_LANGUAGE_LINK = '/select-language';
    static readonly CREAT_ACCOUNT_LINK = '/create-Account';
    static readonly RESET_PASSWORD_LINK = '/reset-Password';
    static readonly HOME_PAGE_LINK = '/home';
    static readonly LOGIN_PAGE_LINK = '/login';
    static readonly CONFIRM_REGISTRATION_LINK = '/confirm-registration';
    static readonly FORGOT_PWD_LINK = '/forgot-Password';
    static readonly NEW_PWD_LINK = '/new-password';
    static readonly ASSET_LIBRARY_LINK = '/asset-library';
    static readonly EMAIL_SENT_LINK = '/email-sent';
    static readonly PROFILES_LINK = '/profile';
    static readonly STORES_LINK = '/stores';
    static readonly CREATE_PROFILE_LINK = '/profile/create';
    static readonly LANDING_HOME = '/home/landingHome';
    static readonly ACCOUNT_CREATED_LINK = '/account-created';
    static readonly USERS_RETAIL_LINK = '/users/retailhqs';
}
