<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
<a class="central_logo" [href]="href"> <img class="img-fluid" alt="IPOS Central LOGO" /> </a>
<div class= "card rest_pwd_message">
  <a [href]="href" class="popup_close">
    <img class="exit_image"
      alt="go to login page"
    />
  </a>

<div class="form_header" [innerHTML]="modalTitle">
</div>
<span [innerHTML]="modalBody"></span>
</div>
</div>
</div>
</div>
