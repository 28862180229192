import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { AssetLibraryConstants } from '../../../core/routing/services/asset-library/constants/asset-library.constant';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../common/services/common.service';
import { DataService } from '../../../core/data/data-service';
import { PriceContentService } from '../../../core/routing/services/asset-library/price-content.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { PriceContent, PriceContentConfiguration, PriceContentGroupPage, CreatePriceContentResponse, PriceContentViewModel } from './interfaces/price-content.i';
@Component({
  selector: 'iposcen-price-content',
  templateUrl: './price-content.component.html',
  styleUrls: ['./price-content.component.scss']
})

export class PriceContentComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal') deleteModal: ElementRef;
  userId = sessionStorage.getItem('userId');
  configurations: PriceContentConfiguration[];
  tabIndex = 0;
  allPriceContentGroups: PriceContent[];
  displayPriceContentGroups = [];
  selectedFiles: FileList;
  sideNav = 'store';
  sortValue = GlobalConstants.CREATED_DATE;
  page = 1;
  pageSize = 5;
  maxSize = 5;
  contentGrpData: any;
  pageType = GlobalConstants.PRICE_CONTENT_GROUP;
  priceCGToDelete: string;
  priceCGToDeleteName: string;
  LCGlayout = 1;
  pages: any; // update the no of pages for edit.
  mediaObjects: any[] = [];
  editCheck: boolean;
  clickable = false; // This is to enable tabs click only after clicking on Save & Proceed.TODO: will remove this later.
  showPriceContentName: string;
  createdDateLabel: string;
  groupNameLabel: string;
  pageGrpForm: any;
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
  + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private router: Router,
    private cmnService: CommonService,
    private priceContentService: PriceContentService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.createdDateLabel = GlobalConstants.CREATED_DATE;
    this.groupNameLabel = GlobalConstants.GROUP_NAME;
    this.getAllPriceContentsByUser();
    this.pages = {};
    this.editCheck = false;
    this.cmnService.pageDataTologoObser
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {
    // pages data. To-Do: update to common object ;
    // const updatePromoContent: CreatePromoContentResponse = this.contentGrpData as CreatePromoContentResponse;
    // Once page is updated.
    const priceContent = this.contentGrpData.priceContent;
    // console.log(logoContent.configurations,"...ss")
    const configurations = priceContent.configurations;
    if (res.data.media.url !== '') {
      this.prepareMediaSet(res, priceContent.id);
    }
    const checkJson = this.checkExistance(configurations.contentGroupPages, 'pageNum', res.number);
    if (checkJson.hasMatch) {// If there is configuration object already for the page number
      // Update the pages
      configurations.contentGroupPages[checkJson.matchedIndex] = {
        pageNum: res.number,
        title: res.data.heading,
        titleFontColor: res.data.hcolor,
        bgColor: res.data.bgcolor,
        bodyCopy: res.data.body,
        bodyCopyFontSize: this.fontSizeNumber(res.data.size),
        bodyCopyFontColor: res.data.bcolor,
        UiLayout: this.getUILayoutNameByID(res.data.layout),
        linkedAsset: res.data.media.url !== '' ? true : false,
        linkedAssetFileType: res.data.media.format
      };
    } else {
      const promoContentGroupPage: PriceContentGroupPage = {
        pageNum: res.number,
        title: res.data.heading,
        titleFontColor: res.data.hcolor,
        bgColor: res.data.bgcolor,
        bodyCopy: res.data.body,
        bodyCopyFontSize: this.fontSizeNumber(res.data.size),
        bodyCopyFontColor: res.data.bcolor,
        UiLayout: this.getUILayoutNameByID(res.data.layout),
        linkedAsset: res.data.media.url !== '' ? true : false,
        linkedAssetFileType: res.data.media.format
      };
      configurations.contentGroupPages.push(promoContentGroupPage);
    }
    this.contentGrpData.priceContent = priceContent;
  });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setCreatedGrpID(data) {
    if (typeof data === 'object' && data.cancel) {
      this.pages = {};
      this.editCheck = false;
      this.setIndex(0);
    } else {
      this.contentGrpData = data;
      if (typeof this.contentGrpData.priceContent.configurations === 'string') {
        this.contentGrpData.priceContent.configurations =
          JSON.parse(this.contentGrpData.priceContent.configurations) as PriceContentConfiguration;
      }
      this.showPriceContentName = this.contentGrpData.priceContent.configurations.publishedName;
      this.clickable = true;
      this.setIndex(2);
    }
    this.mediaObjects = [];
  }
  fontSizeNumber(size) {
    if (size === GlobalConstants.SMALL) {
      return GlobalConstants.NUMBER_12;
    }
    if (size === GlobalConstants.MEDIUM) {
      return GlobalConstants.NUMBER_14;
    }
    if (size === GlobalConstants.LARGE) {
      return GlobalConstants.NUMBER_16;
    }
  }

  getUILayoutNameByID(id) {// TODO - Confirm with Sujay if layout name is correct
    if (id === 1) {
      return GlobalConstants.TWO_COL_RIGHT_ASSET;
    } else if (id === 2) {
      return GlobalConstants.TWO_COL_LEFT_ASSET;
    } else if (id === 3) {
      return GlobalConstants.ONE_COL_MID_ASSET;
    }
  }
  refreshPriceContentdata() {
    this.getAllPriceContentsByUser();
    this.setIndex(0);
  }

  setPreviewLayout(data) {
    this.LCGlayout = data;
  }
  loadMiniPreview(gId: string, pageContent: any) {
    this.pageGrpForm = {
      bgcolor: pageContent ? pageContent.bgColor : '#0068B5',
      heading:  pageContent ? pageContent.title : '',
      hcolor: pageContent ? pageContent.titleFontColor : '#ffffff',
      body: pageContent ? pageContent.bodyCopy : '',
      bcolor: pageContent ? pageContent.bodyCopyFontColor : '#ffffff',
      size: pageContent ? this.fontSizeNumber(pageContent.bodyCopyFontSize) : GlobalConstants.MEDIUM,
      media: {
        url: (pageContent && pageContent.linkedAsset) ?
        environment.apiBaseUrlAsset + this.url + AssetLibraryConstants.PRICE_CONTENTS + AssetLibraryConstants.SLASH +
		gId + AssetLibraryConstants.SLASH + pageContent.pageNum.toString() : '',
        name: '',
        format: (pageContent && pageContent.linkedAsset) ? (pageContent.linkedAssetFileType) ? pageContent.linkedAssetFileType : '' : '',
        fileObj: ''
      },
      id: gId,    // adding created group id,
      layout: pageContent ? this.getUILayoutIDByName(pageContent.UiLayout) : 1
    };

    return this.pageGrpForm;
  }
  getUILayoutIDByName(name: string) {
    if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
      return 1;
    } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
      return 2;
    } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
      return 3;
    }
  }
  checkExistance(data, key, compValue) {
    const checkJSON = { hasMatch: false, matchedIndex: -1 };
    for (let index = 0; index < data.length; index += 1) {
      if (data[index][key] === compValue) {
        checkJSON.hasMatch = true;
        checkJSON.matchedIndex = index;
        break;
      }
    }

    return checkJSON;
  }

  // To hold all pages media objects and its metada.
  prepareMediaSet(res: any, promoContentId: string) {
    const checkJson = this.checkExistance(this.mediaObjects, 'pageNum', res.number);
    if (checkJson.hasMatch) {
      // Update the pages
      this.mediaObjects[checkJson.matchedIndex] = {
        id: promoContentId,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        this.mediaObjects[checkJson.matchedIndex].uploaded = true;
      }
    } else {
      const dataObj: any = {
        id: promoContentId,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        dataObj.uploaded = true;
      }
      this.mediaObjects.push(dataObj);
    }
  }

  setSideNav(value: string) {
    this.sideNav = value;
  }

  addPage() {
    const keyLen = Object.keys(this.pages).length;
    if (keyLen === 0) {
      this.pages.page2 = true;
      this.setIndex(3);
    } else {
      this.pages.page3 = true;
      this.setIndex(4);
    }
  }

  checkPages() {
    if (this.pages.page2 && this.pages.page3) {
      return true;
    }

    return false;
  }

  getAllPriceContentsByUser() {
    this.priceContentService.getAllPriceContentByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllPriceContentResponse => {
        this.allPriceContentGroups = (getAllPriceContentResponse.priceContent as PriceContent[]);
        this.configurations = [];
        this.allPriceContentGroups.forEach(priceContentGroup => {
          this.configurations.push(JSON.parse(priceContentGroup.configurations) as PriceContentConfiguration);
        });
        // display Price content groups using displayPricecontentgroups var as configurations is coming as string
        this.displayPriceContentGroups = [];

        this.allPriceContentGroups.forEach(ele => {
          ele.configurations = JSON.parse(ele.configurations);
          ele.createdByUser = (ele.createdByUser === this.userId) ? GlobalConstants.ME : ele.createdByUser;
          this.displayPriceContentGroups.push(ele);
        });

        this.displayPriceContentGroups.forEach(element => {
          // adding this custom property to know which Price content is expanded
          element.isExpanded = false;
          if (element.updateTime) {
            element.updateTime = new Date(element.updateTime);
          }
        });

        this.sortByDate();
      },
        error => {
          // do something
        });
  }

  editPrice(priceCGID: string) {
    this.pages = {};
    this.mediaObjects = [];
    const priceCGToEdit = this.displayPriceContentGroups.find(ele => ele.id === priceCGID);
    this.dataService.setPriceContent(priceCGToEdit);
    this.editCheck = true;
    for (let each of priceCGToEdit.configurations.contentGroupPages) {
      if (each.pageNum === 2) {
        this.pages.page2 = true;
      }
      if (each.pageNum === 3) {
        this.pages.page3 = true;
      }
    }
    const tempPromoCont = priceCGToEdit as PriceContent;
    this.contentGrpData = {
      priceContent: tempPromoCont,
      responseContext: {}
    }; // set data to cntGrpData to show in pages
    this.showPriceContentName = priceCGToEdit.configurations.publishedName;
    this.setIndex(1);
  }

  sortBy(sortValue: string) {
    switch (sortValue) {
      case 'createDate': {
        this.sortValue = GlobalConstants.CREATED_DATE;
        this.displayPriceContentGroups.sort((price1, price2) => price2.updateTime - price1.updateTime);
        break;
      }
      case 'groupName': {
        this.sortValue = GlobalConstants.GROUP_NAME;
        this.displayPriceContentGroups.sort((price1, price2) =>
        price1.configurations.publishedName.localeCompare(price2.configurations.publishedName));
        break;
      }
      default:
        break;
    }
  }

  duplicateAndEditPriceContent(priceContentId: string) {
    const priceContentToEdit = this.displayPriceContentGroups.find(ele => ele.id === priceContentId);
    const priceContentConfig = priceContentToEdit.configurations;
    if (priceContentConfig.contentGroupPages) {
      priceContentConfig.contentGroupPages.forEach(page => {
        page.image = undefined;
      });
    }
    const priceContent: PriceContentConfiguration = {
      publishedName: `Copy of ${priceContentConfig.publishedName}`,
      internalName: priceContentConfig.internalName,
      publishTime: priceContentConfig.publishTime ? priceContentConfig.publishTime : new Date().toString(),
      expireTime: priceContentConfig.expireTime ? priceContentConfig.expireTime : GlobalConstants.NEVER,
      contentScheduleTimezoneCode: priceContentConfig.contentScheduleTimezoneCode,
      type: GlobalConstants.PRICE_CONTENT_GROUP,
      contentGroupPages: priceContentConfig.contentGroupPages,
      formFactor: priceContentConfig.formFactor
    };
    this.priceContentService.createPriceContent(JSON.stringify(priceContent))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createPriceContentResponse => {
        this.getDetailsOfPriceCG(priceContentId);
        const createPriceContentResponseObj: CreatePriceContentResponse = createPriceContentResponse as CreatePriceContentResponse;
        const priceContentViewModel: PriceContentViewModel = createPriceContentResponseObj.priceContent as unknown as PriceContentViewModel;
        priceContentViewModel.configurations =
                    JSON.parse(createPriceContentResponseObj.priceContent.configurations) as PriceContentConfiguration;

        // Loop and duplicate images
        this.duplicatePriceContentImage(priceContentToEdit, priceContentViewModel);

        this.dataService.setPriceContent(priceContentViewModel);
        this.editCheck = true;
        for (let each of priceContentViewModel.configurations.contentGroupPages) {
          if (each.pageNum === 2) {
            this.pages.page2 = true;
          }
          if (each.pageNum === 3) {
            this.pages.page3 = true;
          }
        }
        this.contentGrpData = {
          priceContent: priceContentViewModel,
          responseContext: {}
        }; // set data to cntGrpData to show in pages
        const tempViewModel: any = priceContentViewModel; // dummy instance to add all required  flags for expand,delete,sort by date&time format
        tempViewModel.isExpanded = false;
        tempViewModel.createdByUser = (tempViewModel.createdByUser === this.userId) ? GlobalConstants.ME : tempViewModel.createdByUser;
        if (tempViewModel.updateTime) {
          tempViewModel.updateTime = new Date(tempViewModel.updateTime);
          tempViewModel.updateTimeDisplay = new Date(tempViewModel.updateTime).toLocaleString()
            .replace(',', '');
        }
        tempViewModel.isDeleted = false;
        if (tempViewModel && tempViewModel.configurations) {
          if (tempViewModel.configurations.publishTime) {
            tempViewModel.configurations.publishTime = new Date(tempViewModel.configurations.publishTime).toLocaleString()
              .replace(',', '');
          }
          if (tempViewModel.configurations.expireTime && tempViewModel.configurations.expireTime !== GlobalConstants.NEVER) {
            tempViewModel.configurations.expireTime = new Date(tempViewModel.configurations.expireTime).toLocaleString()
              .replace(',', '');
          }
        }
        // this.displayPriceContentGroups.push(tempViewModel);
        this.displayPriceContentGroups.push(priceContentViewModel);
        if (this.sortValue === 'Created Date') {
        this.sortBy('createDate');
        } else {
          this.sortBy('groupName');
        }
        this.showPriceContentName = priceContent.publishedName;
        this.page = 1;
        this.setIndex(1);
      },
      error => {
      }
    );
  }

  duplicatePriceContentImage(priceContent, newPriceContent) {
    if (priceContent.configurations && priceContent.configurations.contentGroupPages) {
      priceContent.configurations.contentGroupPages.forEach(page => {
        if (page.linkedAsset) {
          this.priceContentService.getPriceContentByPageId(priceContent.id, page.pageNum)
            .pipe(takeUntil(this.ngUnsubscribe))
            .pipe(first())
            .subscribe(imageData => {
              this.priceContentService.putS3PriceContentByPageId(imageData, 'png', newPriceContent.id, page.pageNum) // Check how to get image type
                .pipe(takeUntil(this.ngUnsubscribe))
                .pipe(first())
                .subscribe(() => {
                },
                  error => {
                    // do something
                  });
            },
              error => {
                // do something
              });
        }

      });
    }
  }

  setIndex(value: number) {
    this.tabIndex = value;
  }

  setLayout(selected) {
    this.LCGlayout = selected;
    this.cmnService.emitLayout(selected);
  }

  getDetailsOfPriceCG(id: string) {
    this.displayPriceContentGroups.forEach(element => {
      if (element.id === id) {
        element.isExpanded = !element.isExpanded;
      }
    });
  }

  sortByDate() {
    this.sortValue = GlobalConstants.CREATED_DATE;
    this.displayPriceContentGroups.sort((price1, price2) => price2.updateTime - price1.updateTime);
  }

  sortByName() {
    this.sortValue = GlobalConstants.GROUP_NAME;
    this.displayPriceContentGroups.sort((price1, price2) =>
      price1.configurations.publishedName.localeCompare(price2.configurations.publishedName));
  }

  cancel() {
    this.dataService.setPriceContent(undefined);
    if (this.contentGrpData) {
      for (let each of this.displayPriceContentGroups) {
        if (each.id === this.contentGrpData.priceContent.id) {
          if (typeof each.configurations === 'string') {
            each.configurations = JSON.parse(each.configurations);
          }
          break;
        }
      }
    }
    this.setIndex(0);
    this.mediaObjects = [];
    this.pages = {};
    this.clickable = false;
    this.editCheck = false;
  }

  /* Delete price content */
  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openDeleteModal(priceCGID: string) {
    this.priceCGToDelete = this.displayPriceContentGroups.find(ele => ele.id === priceCGID).id;
    this.priceCGToDeleteName = this.displayPriceContentGroups.find(ele => ele.id === priceCGID).configurations.publishedName;
    this.openModal(this.deleteModal);
  }

  /* Delete price content */
  deletePriceCG(priceCGID: string) {
    // delete Price API call
    this.priceContentService.deletePriceContentById(priceCGID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {
        const index = this.displayPriceContentGroups.findIndex(d => d.id === priceCGID); // find index in your array
        if (index >= 0) {
          this.displayPriceContentGroups.splice(index, 1); // remove element from array
        }
        this.closeModal(this.deleteModal);
      },
      error => {
        this.closeModal(this.deleteModal);
      });
  }

  updatePriceContent() {
    const updatePriceContent: CreatePriceContentResponse = this.contentGrpData as CreatePriceContentResponse;
    // Once page is updated.
    const priceContent = updatePriceContent.priceContent;
    if (typeof priceContent.configurations !== 'string') {
      priceContent.configurations = JSON.stringify(priceContent.configurations);
    }
    this.priceContentService.putPriceContentById(JSON.stringify(priceContent), updatePriceContent.priceContent.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putPromoContentResponse => {
        // Once the update call is successful, make a PUT call for the logo
        for (const each of this.mediaObjects) {
          if (each.uploaded) {
            this.updateMedia(each);
          }
        }
        this.pages = {};
        this.setIndex(0);
        this.getAllPriceContentsByUser();
        this.clickable = false;
      },
      error => {
      }
    );
  }

  updateMedia(each) {
    // console.log(each);
    this.priceContentService.putS3PriceContentByPageId(each.media.fileObj,
      each.media.name.split('.')
        .pop(), each.id, each.pageNum.toString())
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(() => {
        // TODO: what should be done when API response is received?
      },
      error => { // do something
      }
    );
  }

  addNewPriceContent() {
    this.dataService.setPriceContent(undefined);
    this.tabIndex = 1;
  }

}
