import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProfileStorelogoComponent } from './profile-storelogo/profile-storelogo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [ProfileStorelogoComponent],
  imports: [
    CommonModule,
    ColorPickerModule,
    NgbModule
  ],
  exports: [ProfileStorelogoComponent]
})
export class ProfileStorelogoModule { }
