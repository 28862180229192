import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../core/routing/services/login/login.service.ts.service';
import { ConfirmRegistrationConstants } from '../confirm-registration/constants/confirm-registration.constant';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LoginConstants } from './constants/login.constant';
import { User } from './_models/user.model';
import { AppGlobal } from '../../../core/state/app-global';
import { faEye, faEyeSlash, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SelectLanguageConstants } from '../../../common/components/select-lang/constants/select-lang.consts';
import { IPOSValidator } from '../../../common/validators/validators';
import { RouterConstants } from '../../../core/routing/constants/app-paths.constant';
import { CognitoCallback } from '../../../core/routing/interfaces/cognito.i';
import { CreateAccountService } from '../../../core/routing/services/createAccount/create-account.service';
import { DetailService } from '../../../core/routing/services/details/detail.service';
import { ForgotPwdComponent } from '../forgot-pwd/forgot-pwd.component';
import { AuthService } from '../../../core/routing/services/auth/auth.service';

@Component({
  selector: 'iposcen-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements CognitoCallback, OnInit {

  loginForm: FormGroup;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faExclamationCircle = faExclamationCircle;
  alertFormat: string;
  logIn: string;
  messageString: string;
  emailAddress: string;
  requiredField: string;
  validEmailId: string;
  passwordField: string;
  forgotPassword: string;
  createAccount: string;
  createAccButton: string;
  placeholderEmail: string;
  placeholderPassword: string;
  maxlengthField: string;
  forgotPasswordHref: string;
  loginhref: string;
  pwdLength: string;
  isSubmitted = false;
  fieldTextType: boolean;
  exclamationFieldType: boolean;
  displayEye: IconDefinition;
  displayExclamation: IconDefinition;
  loginButton: boolean;
  description: string;
  closeResult: string;
  returnUrl: string;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private service: CreateAccountService,
    private userService: LoginService,
    private formBuilder: FormBuilder,
    private _appGlobal: AppGlobal,
    private modalService: NgbModal,
    private detailService: DetailService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.displayEye = faEye;
    this.loginButton = false;
    this.description = LoginConstants.DESCRIPTION_CREATEACCOUNT;
    this.displayExclamation = faExclamationCircle;
    this.forgotPasswordHref = RouterConstants.FORGOT_PWD_LINK;
    this.maxlengthField = LoginConstants.MAXIMUM_CHAR;
    this.logIn = LoginConstants.LOGIN;
    this.pwdLength = LoginConstants.PASSWORD_MIN_LENGTH;
    this.emailAddress = LoginConstants.EMAIL_ADDRESS;
    this.placeholderEmail = LoginConstants.PLACEHOLDER_EMAIL_ADDRESS;
    this.placeholderPassword = LoginConstants.PLACEHOLDER_PASSWORD;
    this.requiredField = LoginConstants.REQUIRED_FIELD;
    this.validEmailId = LoginConstants.VALID_EMAIL_ID;
    this.passwordField = LoginConstants.PASSWORD;
    this.forgotPassword = LoginConstants.FORGOT_PASSWORD;
    this.createAccButton = LoginConstants.CREATE_ACCOUNT_BUTTON;
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.createAccount = LoginConstants.CREATE_ACCOUNT;
    const PAT_EMAIL = IPOSValidator.EMAIL;
    const PAT_PASSWORD = IPOSValidator.PASSWORD;
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern(PAT_EMAIL)]),
      password: new FormControl('', [Validators.required])
    });
    this.messageString = undefined;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || RouterConstants.HOME_PAGE_LINK;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loginBtn() {
    this.loginButton = false;
    if (this.loginForm.get('email').value
      && this.loginForm.get('password').value) {
      this.loginButton = true;
    }
  }
  get formControls() { return this.loginForm.controls; }

  login() {
    this.isSubmitted = true;
    if (this.loginForm.invalid || this.loginForm.get('email').value === null || this.loginForm.get('password').value === null) {

      return;
    }
    this.messageString = undefined;
    this.userService.authenticate(this.loginForm.get('email').value, this.loginForm.get('password').value, this);
    this.detailService.setLayoutToMain(false);

  }

  open(): void {
    this.modalService.open(ForgotPwdComponent);
  }
  getDismissReason(reason: any): string {
    let message: string;
    switch (reason) {
      case ModalDismissReasons.ESC: {
        message = ConfirmRegistrationConstants.DIMISS_REASON_ESC;
        break;
      }
      case ModalDismissReasons.BACKDROP_CLICK: {
        message = ConfirmRegistrationConstants.DISMISS_REASON_DROP;
        break;
      }
      default: {
        message = `with: ${reason}`;
        break;
      }
    }

    return message;
  }

  redirectToLanguage() {
    this.router.navigate([RouterConstants.SELECT_LANGUAGE_LINK, SelectLanguageConstants.CREATE_ACCOUNT]);
  }
  cognitoCallback(message: string, result: any) {

    if (message !== undefined) { // error
      this.messageString = message;
      this.alertFormat = 'alert alert-danger';
      if (this.messageString === LoginConstants.INCORRECT_CREDDENTIALS) {
        this.messageString = LoginConstants.INCORRECT_EMAIL_PASSWORD;
      } else if (this.messageString === LoginConstants.USER_NOT_CONFIRMED) {
        this.resendCode();
        this.router.navigate([RouterConstants.CONFIRM_REGISTRATION_LINK, this.loginForm.get('email').value]);

      } else if (this.messageString === LoginConstants.USER_SET_PASSWORD) {
        this.router.navigate([RouterConstants.RESET_PASSWORD_LINK]);
      } else {
        this.messageString = message;
      }

    } else { // success
      this.messageString = LoginConstants.LOGGED_IN_SUCCESSFULLY;
      this.alertFormat = 'alert alert-success';
      this.handleLoginSuccess(result);
    }

  }

  resendCode(): void {
    sessionStorage.setItem('email', this.loginForm.get('email').value);
    this.service.resendCode(this.loginForm.get('email').value, this);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.displayEye = faEyeSlash;
    } else if (!this.fieldTextType) {
      this.displayEye = faEye;
    }
  }

  toggleExclamation() {
    this.exclamationFieldType = !this.exclamationFieldType;
  }

  handleLoginSuccess(result: any) {
    const user = new User(
      result.idToken.payload.sub,
      result.idToken.payload.email,
      result.idToken.payload.given_name,
      result.idToken.payload.family_name
    );
    this._appGlobal.setCurrentUser(user);
    sessionStorage.setItem('userId', user.userId);
    sessionStorage.setItem('firstname', user.firstName);
    sessionStorage.setItem('lastname', user.lastName);

    // Changes to call GetID and GetCredentialsByID APIs to get STS, which will be used for API authorization
    this.getSTS(result);
  }

  getSTS(result: any) {
    this.authService.getId(result.idToken.jwtToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getIdResponse => {
        console.log('getIdResponse:: ' + JSON.stringify(getIdResponse));

        this.authService.getCredentialsForIdentity(result.idToken.jwtToken, getIdResponse.IdentityId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(getCredsForIdResponse => {
            console.log('getCredsForIdResponse:: ' + JSON.stringify(getCredsForIdResponse));
            sessionStorage.setItem('idToken', result.idToken.jwtToken);
            sessionStorage.setItem('accessToken', getCredsForIdResponse.Credentials.SessionToken);
            sessionStorage.setItem('SecretKey', getCredsForIdResponse.Credentials.SecretKey);
            sessionStorage.setItem('AccessKeyId', getCredsForIdResponse.Credentials.AccessKeyId);
            // sessionStorage.setItem('accessToken', result.accessToken.jwtToken);
            // If there is a redirect uri present, navigate to that uri instead of home page.
            this.router.navigate([this.returnUrl]);
          },
            error => {
              // do something
            });
      },
        error => {
          // do something
        });
  }
}
