import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/services/common.service';
import { CreateProfileService } from '../../core/routing/services/profile/create-profile.service';
import { LogoContent, StoreLogo, PromotionalContent } from '../create-profile/interface/profile.i';
import { GlobalConstants } from '../../common/constants/global.constant';

@Component({
  selector: 'iposcen-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {
  isTabEnabled: boolean;
  sideNav: string;
  isTabVisited: boolean;
  isStoreLogoTabVisited: boolean;
  isPromoTabVisited: boolean;
  inActive: string;
  profileID: string;
  promo: string = GlobalConstants.PROMO;
  storelogo: string = GlobalConstants.STORE_LOGO;

  constructor(private cmnService: CommonService,
    private createProfileService: CreateProfileService) { }

  ngOnInit(): void {
    this.isTabEnabled = false;
    this.isTabVisited = false;
    this.isStoreLogoTabVisited = false;
    this.isPromoTabVisited = false;
    this.inActive = 'inActive';
    this.sideNav = GlobalConstants.PROFILE_INFO;
    this.cmnService.emitPromoDataToProfileObser.subscribe(data => {
      if (data === GlobalConstants.SKIP) {
        this.sideNav = GlobalConstants.SEGMENTS;
        this.isPromoTabVisited = true;
        this.inActive = '';
      } else {
        let promoContentData: PromotionalContent = data as PromotionalContent;
        this.createProfileService.updateProfileforPromoContent(this.profileID, { promotionalContents: promoContentData }).subscribe(data => {
          //set the tab to segment on success
          this.sideNav = GlobalConstants.SEGMENTS;
          this.isPromoTabVisited = true;
          this.inActive = '';
        });
      }
    });

    this.cmnService.emitDataToProfileObser.subscribe(data => {
      if (data === GlobalConstants.SKIP) {
        this.sideNav = GlobalConstants.PROMO;
        this.isStoreLogoTabVisited = true;
        this.inActive = '';
      } else {
        let logoData: LogoContent = data as LogoContent;
        this.createProfileService.updateProfile(this.profileID, { logoContents: logoData }).subscribe(data => {
          // set the tab to promo on success
          this.isStoreLogoTabVisited = true;
          this.inActive = '';
          this.sideNav = GlobalConstants.PROMO;
        });
      }
    });

    this.cmnService.emitDataSlToProfileObser.subscribe(data => {
      if (data === GlobalConstants.SKIP) {
        this.sideNav = GlobalConstants.PROMO;
        this.isStoreLogoTabVisited = true;
        this.inActive = '';
      } else {
        const storeLogos: StoreLogo = data as StoreLogo;
        this.createProfileService.updateProfileStoreLogo(this.profileID, { storeLogos: storeLogos })
          .subscribe(data => {
          });
      }
    });
    this.profileID = '';
  }
  setSideNav(value: string) {
    this.sideNav = value;
  }

  setCreateProfileView(data) {
    if (data.responseContext.success === true) {
      this.sideNav = this.storelogo;
      this.isTabVisited = true;
      this.profileID = data.profile.profileId;
    }

  }


}
