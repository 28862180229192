import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from './../../../../../environments/environment';
import { APIConstants } from './constants/api.constant';

@Injectable()
export class HttpMethodsService {

  stringSubject = new Subject<string>();

  private _BASE_URL = environment.apiBaseUrl;
  private _BASE_URL_ASSET = environment.apiBaseUrlAsset;

  constructor(private http: HttpClient) { }

  auth_post(url: string, postObject: any, apiTye: string, isAssetApi = false) {
    const headers = new HttpHeaders()
      .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_X_AMZ_JSON)
      .set(APIConstants.HEADER_NM_X_AMZ_TARGET, (apiTye === 'Id' ? APIConstants.HEADER_VAL_GET_ID : APIConstants.HEADER_VAL_GET_CRED_FOR_ID));

    return this.http.post(url, postObject, { headers });
  }

  post(url: string, postObject: any, isAssetApi = false) {
    const headers = new HttpHeaders()
      .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.post(requestUrl, postObject, { headers });
  }

  patch(url: string, postObject: any, isAssetApi = false) {
    const headers = new HttpHeaders()
            .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.patch(requestUrl, postObject, { headers });
  }

  put(url: string, postObject: any, isAssetApi = false) {
    const headers = new HttpHeaders()
      .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.put(requestUrl, postObject, { headers });
  }

  putImage(url: string, putObject: File, imageType: string, isAssetApi = false) {
    const headers = new HttpHeaders()
      .set(APIConstants.HEADER_NM_X_AMZ_ACL, APIConstants.HEADER_VAL_BUCKET_OWNER_FULL_CONTROL);
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.put(requestUrl, putObject, { headers });
  }

  delete(url: string, isAssetApi = false) {
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.delete(requestUrl);
  }

  get(url: string, isAssetApi = false) {
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.get(requestUrl);
  }

  getImage(url: string, isAssetApi = false) {
    const requestUrl = this.prepareRequestUrl(url, isAssetApi);

    return this.http.get(requestUrl, { responseType: 'blob' });
  }

  prepareRequestUrl(requestUri: string, isAssetApi: boolean): string {
    const apiRoot = isAssetApi ? this._BASE_URL_ASSET : this._BASE_URL;

    return apiRoot + requestUri;
  }

}


