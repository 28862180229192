<div id="newLogoContent" class="tab-pane active">
  <div class="tab-content" id="newPromoTabs-content">
    <form [formGroup]="attractorLoopgrpForm">
      <div class="row justify-content-start">
        <div class="form-group col-md-6">
          <label for="publishedName">Published Name<span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="publishedName" placeholder="Published Name"
            formControlName="publishedName" name="publishedName" required />
          <div *ngIf="isSubmitted && attractorLoopgrpForm?.controls?.publishedName?.value.trim() === ''"
            class="formfield_err_msg"> This is required field
          </div>
          <div *ngIf="isSubmitted && attractorLoopgrpForm?.controls?.publishedName?.errors?.maxlength"
            class="formfield_err_msg">Published name may be up to 50 characters long</div>
        </div>
        <div class="form-group col-md-6">
          <ng-template #popContent class="popover">Videos must be:
            <ul class="view-attractor-list">
              <li>In MP4 format,</li>
              <li>less than 60 seconds in length,</li>
              <li>under 10MB.</li>
            </ul>
          </ng-template>
          <label>Attract Loop Video<span class="text-danger">*</span>
            <img class="info_icon" src="../../../../assets/images/info_circle_icon.svg" alt="hover for help"
              placement="right" [ngbPopover]="popContent" triggers="mouseenter:mouseleave" />
          </label>
          <div id="uploadButton">
            <label for="uploadedVideoName">
              <input #videoInput class="form-control" formControlName="uploadedVideoName" name="uploadedVideoName"
                id="uploadedVideoName" type="file" accept="video/mp4" (change)="readVideoUrl($event)" hidden>
              <video #video style="display: none;" *ngIf="videoUrl" controls [attr.src]="videoUrl"
                (loadedmetadata)="getDuration($event)">
              </video>
              <span class="btn btn-primary btn-sm left_icon_btn">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.5024 2.5H2.50244C2.17092 2.5 1.85298 2.6317 1.61856 2.86612C1.38414 3.10054 1.25244 3.41848 1.25244 3.75V16.25C1.25244 16.5815 1.38414 16.8995 1.61856 17.1339C1.85298 17.3683 2.17092 17.5 2.50244 17.5H17.5024C17.834 17.5 18.1519 17.3683 18.3863 17.1339C18.6207 16.8995 18.7524 16.5815 18.7524 16.25V3.75C18.7524 3.41848 18.6207 3.10054 18.3863 2.86612C18.1519 2.6317 17.834 2.5 17.5024 2.5ZM2.50244 1.25C1.8394 1.25 1.20352 1.51339 0.734674 1.98223C0.265834 2.45107 0.00244141 3.08696 0.00244141 3.75V16.25C0.00244141 16.913 0.265834 17.5489 0.734674 18.0178C1.20352 18.4866 1.8394 18.75 2.50244 18.75H17.5024C17.8307 18.75 18.1558 18.6853 18.4592 18.5597C18.7625 18.4341 19.0381 18.2499 19.2702 18.0178C19.5024 17.7856 19.6865 17.51 19.8121 17.2067C19.9378 16.9034 20.0024 16.5783 20.0024 16.25V3.75C20.0024 3.08696 19.739 2.45107 19.2702 1.98223C18.8014 1.51339 18.1655 1.25 17.5024 1.25H2.50244Z"
                      fill="white" />
                    <path
                      d="M13.3099 9.55744C13.4028 9.46487 13.5224 9.40383 13.6518 9.38296C13.7812 9.3621 13.914 9.38247 14.0312 9.44119L18.7524 11.8749V17.4999H1.25244V14.9999L4.55994 12.0574C4.66211 11.9556 4.79634 11.8923 4.93986 11.8782C5.08339 11.8641 5.22739 11.9 5.34744 11.9799L8.67244 14.1962L13.3099 9.55869V9.55744Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.62744 8.75C5.87367 8.75 6.11749 8.7015 6.34497 8.60727C6.57246 8.51305 6.77916 8.37494 6.95327 8.20083C7.12738 8.02672 7.26549 7.82002 7.35972 7.59253C7.45394 7.36505 7.50244 7.12123 7.50244 6.875C7.50244 6.62877 7.45394 6.38495 7.35972 6.15747C7.26549 5.92998 7.12738 5.72328 6.95327 5.54917C6.77916 5.37506 6.57246 5.23695 6.34497 5.14273C6.11749 5.0485 5.87367 5 5.62744 5C5.13016 5 4.65325 5.19754 4.30162 5.54917C3.94999 5.90081 3.75244 6.37772 3.75244 6.875C3.75244 7.37228 3.94999 7.84919 4.30162 8.20083C4.65325 8.55246 5.13016 8.75 5.62744 8.75Z"
                      fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Upload Video</span>
            </label>
            <div *ngIf="isVideoAvailable && isVideoFormat">{{videoFileName}}</div>
            <div *ngIf="videoTypeError" class="uploadfile_err_msg">Incorrect file type received. <div> Video must be <strong>MP4</strong> format.</div></div>
            <div *ngIf="videoLengthError  && isVideoFormat" class="uploadfile_err_msg">Video must be <strong> less than 60 </strong> <div><strong> seconds long.</strong></div></div>
            <div *ngIf="videoSizeError  && isVideoFormat" class="uploadfile_err_msg">Video exceeds maximum size <div><strong>(10 MB).</strong></div></div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start">
        <div class="form-group col-md-6">
          <label for="internalName">Internal Name</label>
          <input type="text" class="form-control" id="internalName" placeholder="Internal Name"
            formControlName="internalName" />
        </div>
      </div>

    </form>
  </div>
  <div class="button_wrapper" style="justify-content: flex-end">
    <button type="button" (click)="cancel()" class="btn btn-outline-primary gtmCancelUpldVdo">
      Cancel
    </button>
    <button type="button" (click)="onAdd()" class="btn btn-primary gtmSaveUpldVdo"
      [disabled]="!attractorLoopgrpForm?.controls?.publishedName?.value || !isVideoAvailable || isSubmitted || !isVideoFormat">
      Save & Proceed
    </button>
  </div>
</div>
