export class Sort {
    private sortOrder = 1;
    private minusOne = -1;
    private one = 1;
    private zero = 0;
    private colllator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
    });

    constructor() {}

    startSort(property, order, type = '') {
         if (order === 'desc') {
            this.sortOrder = -1;
         }

         return(a, b) => {
             if (type === 'date') {
                 return this.sortData(new Date(a[property]), new Date(b[property]));
             } else if (type !== 'date') {
                 return this.colllator.compare(a[property], b[property]) * this.sortOrder;
             }
         };

    }

    private sortData(a, b) {
       if (a < b) {
           return this.minusOne * this.sortOrder;
       } else if (a > b) {
           return this.one * this.sortOrder;
       } else {
           return this.zero * this.sortOrder;
       }
    }
}
