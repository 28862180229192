import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { timeZone } from '../../../../../core/routing/constants/timeZone.constant';

@Component({
  selector: 'iposcen-datetimepicker-popover',
  templateUrl: './datetimepicker-popover.component.html',
  styleUrls: ['./datetimepicker-popover.component.scss']
})
export class DatetimepickerPopoverComponent implements OnInit {
  @Input() popoverTitle: string;
  @Input() buttonText: string;
  @Output() currentDateTime = new EventEmitter<string>();
  @Output() selectedDateTime = new EventEmitter<any>();
  datetimePickerFrm: FormGroup;
  timeZone = [] as any;
  timeHoursConstant = [] as any;
  timeMinuteConstant = [] as any;
  dateRes = [] as any;
  hourVal: string;
  minuteVal: string;
  timeZoneVal: string;
  expiryDateTime: string;
  isExpiredDateIncorrect: boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getTimeZone();
    this.hourVal = '00';
    this.minuteVal = '00';
    this.timeZoneVal = 'Time Zone';
    this.expiryDateTime = '';
    this.createDateTimePickerFrm();
    this.createConstantData();
  }

  createConstantData() {
    for (let hour = 0 ; hour < 24; hour++) {
      const timeHour = hour.toString().length < 2 ? '0' + hour : hour.toString();
      this.timeHoursConstant.push(timeHour);
    }
    for (let min = 0 ; min < 60; min++) {
      const timeMin = min.toString().length < 2 ? '0' + min : min.toString();
      this.timeMinuteConstant.push(timeMin);
    }

  }

  createDateTimePickerFrm(){
    this.datetimePickerFrm = this.formBuilder.group({
      publishedTimeStamp: new FormControl(''),
      hourBtn: new FormControl(''),
      minuteBtn: new FormControl(''),
      timeZoneBtn: new FormControl('')
    });
  }

  getTimeZone() {
    timeZone.forEach(element => {
      const timeZoneList = {
        timeZoneValue: element.value,
        offset : element.offset
      };
      this.timeZone.push(timeZoneList);

    });
    this.timeZone = this.timeZone.slice()
                        .sort((sort1, sort2) => sort2.offset - sort1.offset);
  }

  getSelectedHour(selectedHour) {
    this.hourVal = selectedHour;
  }

  getSelectedTimeZone(selectedTimeZone) {
    this.timeZoneVal = selectedTimeZone;
  }

  getSelectedMinute(selectedMinute) {
    this.minuteVal = selectedMinute;
  }

  getCurrentTime(){
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const hour = today.getHours();
    const minute = today.getMinutes();
    const dateInfo =  `${mm}/${dd}/${yyyy}`;
    const time = `${hour}:${minute}`;
    let timeZone = today.toTimeString().slice(9);

    if (timeZone.length > 30) {
      timeZone = timeZone.substring(0, 30);
      timeZone = `${timeZone}...`;
    }
    const currentDateResult = `${dateInfo} ${time} ${timeZone}`;
    this.currentDateTime.emit(currentDateResult);
  }

  getSelectedTime() {
    const dateVal = this.datetimePickerFrm.controls.publishedTimeStamp.value;
    const hourData = this.hourVal;
    const minuteData = this.minuteVal;
    let timeZone = this.timeZoneVal;

    if ( timeZone.length > 30 ) {
      timeZone = timeZone.substring(0, 30);
      timeZone = `${timeZone}...`;
    }
    if ( dateVal !== '' && hourData !== '00' && timeZone !== '00' ) {
      const formattedDate = moment(dateVal).format('MM/DD/YYYY');
      const selectedDateResult = `${formattedDate} ${hourData}:${minuteData}`;
      const selectedTimeZoneResult = timeZone;
      this.dateRes.push(selectedDateResult);
      this.dateRes.push(selectedTimeZoneResult);
      this.selectedDateTime.emit(this.dateRes);
    }
  }
}
