import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateAccountService } from '../../../../core/routing/services/createAccount/create-account.service';
import { RouterConstants } from '../../../../core/routing/constants/app-paths.constant';
import { CognitoCallback } from '../../../../core/routing/interfaces/cognito.i';
import { LoginService } from '../../../../core/routing/services/login/login.service.ts.service';
import { ForgotPasswordConstants } from '../constants/forgot-password.constant';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { faEye, faEyeSlash, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IPOSValidator } from '../../../../common/validators/validators';

@Component({
  selector: 'iposcen-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy, CognitoCallback {

  errorMessage: string;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faExclamationCircle = faExclamationCircle;
  newPasswordForm: FormGroup;
  verificationCode: string;
  email: string;
  password: string;
  heading: string;
  newPwdLabel: string;
  cnfnewPwdLabel: string;
  pwdLength: string;
  placeholderConfCode: string;
  isSubmitted = false;
  fieldTextType: boolean;
  fieldTextTypeCP: boolean;
  exclamationFieldType: boolean;
  displayEye: IconDefinition;
  displayExclamation: IconDefinition;
  goback: string;
  verificationCodeLabel: string;
  loginhref: string;
  requiredField: string;
  cnfCodeLength: string;
  resetBtn: boolean;
  firstDescription: string;
  alertFormat: string;
  mandatoryPasswordMatch: string;
  private sub: any;

  constructor(private router: Router,
    public service: CreateAccountService,
    public route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private loginService: LoginService,
    public modalService: NgbModal) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.email = params.email;

    });
    this.errorMessage = undefined;
    this.resetBtn = false;
    this.displayEye = faEye;
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.heading = ForgotPasswordConstants.RESET_PWD_HEADING;
    this.newPwdLabel = ForgotPasswordConstants.NEW_PWD_LABEL;
    this.cnfnewPwdLabel = ForgotPasswordConstants.CNF_NEW_PWD_LABEL;
    this.placeholderConfCode = ForgotPasswordConstants.CONFIRMATION_CODE;
    this.verificationCodeLabel = ForgotPasswordConstants.VERFICATION_CODE;
    this.goback = ForgotPasswordConstants.GO_BACK;
    const PAT_PASSWORD = IPOSValidator.PASSWORD_REGEX;
    const PAT_VERIFICATION_CODE = IPOSValidator.VERIFICATION_CODE;
    this.requiredField = ForgotPasswordConstants.REQUIRED_FIELD;
    this.pwdLength = ForgotPasswordConstants.PASSWORD_MIN_LENGTH;
    this.cnfCodeLength = ForgotPasswordConstants.CONFIRMATION_CODE_LENGTH;
    this.firstDescription = ForgotPasswordConstants.NEW_PASSWORD_SPAN_MSG;
    this.mandatoryPasswordMatch = ForgotPasswordConstants.MANDATORY_PASSWORD_MATCH;
    this.firstDescription = this.firstDescription.replace('<[email]@[domain].com>', this.email);
    this.newPasswordForm = this.formbuilder.group({
      verificationCode: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(PAT_PASSWORD), Validators.minLength(8)]),
      cnfpassword: new FormControl('', [Validators.required])

    },
    {
      validator: IPOSValidator.confirmedValidator('newPassword', 'cnfpassword')
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  resendCode(): void {
    this.newPasswordForm.reset();
      this.service.resendCode(this.email, this);
  }

  onNext() {
    this.isSubmitted = true;
    this.errorMessage = undefined;
    if (this.newPasswordForm.invalid || this.newPasswordForm.get('verificationCode').value === null ||
      this.newPasswordForm.get('newPassword').value === null
       || this.newPasswordForm.get('cnfpassword').value === null
       ) {
      return;
    }
    this.verificationCode = this.newPasswordForm.get('verificationCode').value;
    this.password = this.newPasswordForm.get('newPassword').value;
    if (this.password === this.newPasswordForm.get('cnfpassword').value ) {
    this.loginService.confirmNewPassword(this.email, this.verificationCode, this.password, this);
  }
  }

  cognitoCallback(message: string) {
    if (message !== undefined && message !== null) { // error
      this.alertFormat = 'alert alert-danger';
      if (message === ForgotPasswordConstants.CODE_RESENT) {
        this.alertFormat = 'alert alert-secondary';
      }
      this.errorMessage = message;
    } else { // success
      this.router.navigate([RouterConstants.RESET_PASSWORD_LINK]);
    }

  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.displayEye = faEyeSlash;
    } else if (!this.fieldTextType) {
      this.displayEye = faEye;
    }
  }

  toggleCPFieldTextType() {
    this.fieldTextTypeCP = !this.fieldTextTypeCP;
    if (this.fieldTextTypeCP) {
      this.displayEye = faEyeSlash;
    } else if (!this.fieldTextTypeCP) {
      this.displayEye = faEye;
    }
  }

  toggleExclamation() {
    this.exclamationFieldType = !this.exclamationFieldType;
  }

  resetButton() {
    this.resetBtn = false;
    if (this.newPasswordForm.get('verificationCode').value
      && this.newPasswordForm.get('newPassword').value
      && this.newPasswordForm.get('cnfpassword').value) {
      this.resetBtn = true;
    }
  }

}
