import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RetailHq, RetailHqConfiguration, createRetail, updateRetail } from '../../../modules/users/interface/retail.i';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RetailService } from '../../../core/routing/services/retail_api/retail.service';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from '../../../../app/common/constants/global.constant';

@Component({
  selector: 'iposcen-retail-hq',
  templateUrl: './retail-hq.component.html',
  styleUrls: ['./retail-hq.component.scss']
})

export class RetailHqComponent implements OnInit, OnDestroy {
  @ViewChild('colorpicker') colPicModal: ElementRef;
  @ViewChild('searchValue') animateThis: ElementRef;
  @ViewChild('retailerId') retailerIdSort: ElementRef;
  @ViewChild('retailerName') retailerNameSort: ElementRef;
  @ViewChild('createdTime') createdTimeSort: ElementRef;
  @ViewChild('updatedTime') updatedTimeSort: ElementRef;
  retailTheme;
  brandCheck = false;
  brandEdit = false;
  editCheck = false;
  retailHqForm: FormGroup;
  createResponse: createRetail[] = [];
  updateResponse: updateRetail[] = [];
  firstname = sessionStorage.getItem('firstname');
  lastName = sessionStorage.getItem('lastname');
  openModal = false;
  retailData: any = {};
  private modalRef: NgbModalRef;
  configurations: RetailHqConfiguration[];
  displayRetailHqList = [];
  allRetailHqData: RetailHq[] = [];
  detailsResp = {};
  page: number;
  tableSize: number;
  showFrom: number;
  showTo: number;
  retailerId: string;
  retailerName: string;
  createdTime: string;
  updatedTime: string;
  retailUpdateValue: string;
  retailName: string;
  private ngUnsubscribe = new Subject<boolean>();


  constructor(private retailHqService: RetailService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.getAllRetailerInfo();
    this.createRetailHqForm();
    this.prepareRetailThemeForm();
    this.page = 1;
    this.tableSize = 10;
    this.showFrom = ((this.page - 1) * this.tableSize) + 1;
    this.showTo = Math.min(this.page * this.tableSize);
    this.configurations = [];
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  prepareRetailThemeForm() {
    this.retailTheme = {
      bgcolor: '#0068B5',
      heading: '',
      hcolor: '#ffffff'
    };
  }

  dismiss() {
    this.modalRef.close();
  }

  isSelected(bcolor, bgcolor) {
    if (this.retailTheme.bcolor === bcolor && this.retailTheme.bgcolor === bgcolor) {
      return true;
    }

    return false;
  }

  chooseTheme(fontColor: string, bgColor: string) {
    [
      this.retailTheme.bgcolor,
      this.retailTheme.hcolor
    ] =
      [
        bgColor,
        fontColor
      ];
    this.dismiss();
  }

  brandChecks() {
    this.brandCheck = !this.brandCheck;
  }

  brandEditCheck(retailData) {
    this.brandEdit = !this.brandEdit;
    retailData.configurations.bgcolor = this.retailTheme.bgcolor
  }

  createRetailHqForm() {
    this.retailHqForm = this.formBuilder.group({
      retailerName: new FormControl(''),
      expirationDays: new FormControl('30'),
      blockWindows: new FormControl('true'),
      srtEnabled: new FormControl(false),
      bandColor: new FormControl(''),
      bgcolor: new FormControl('')
    });
  }

  createRetailHq() {
    const retailHq: createRetail = {
      retailerName: this.retailHqForm.controls.retailerName.value,
      srtEnabled: this.retailHqForm.controls.srtEnabled.value,
      createdByName: this.firstname + this.lastName,
      configurations: JSON.stringify({
        deviceExpirationDays: this.retailHqForm.controls.expirationDays.value,
        blockWindowsUpdate: this.retailHqForm.controls.blockWindows.value,
        bandColor: this.retailHqForm.controls.bandColor.value,
        bgcolor: this.brandCheck ? this.retailTheme.bgcolor : ""
      }),
    };
    this.retailHqService.createRetailHq(JSON.stringify(retailHq)).pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first()).subscribe(createRetailResponse => {
        this.createResponse = (createRetailResponse.responseContext as createRetail[]);
        if (createRetailResponse.responseContext.success == true) {
          this.openModal = false;
          this.resetForm();
          this.getAllRetailerInfo();
        }
      })
  }

  updateRetail(retailData) {
    const updateHq: updateRetail = {
      configurations: JSON.stringify(retailData.configurations),
      retailerName: this.retailName,
      srtEnabled: retailData.srtEnabled
    }
    let id = retailData.retailerId
    if (!retailData.retailerName) {
      delete retailData.retailerName;
    }
    this.retailHqService.putRetailHqById(JSON.stringify(updateHq), id).pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first()).subscribe(updateRetailResponse => {
        this.updateResponse = (updateRetailResponse.responseContext as updateRetail[]);
        if (updateRetailResponse.responseContext.success == true) {
          this.retailUpdateValue = this.retailName;
          this.getAllRetailerInfo();
        }
      })
  }

  openColPicModal() {
    this.modalRef = this.modalService.open(this.colPicModal);
    this.modalRef.result.then();
  }

  openCreateModal() {
    this.openModal = !this.openModal
    this.createRetailHqForm();
  }

  resetForm() {
    this.retailHqForm.reset();
    this.brandCheck = false;
    this.retailTheme.bgcolor = '#0068B5';
    this.retailHqForm = this.formBuilder.group({
      expirationDays: new FormControl('30'),
      blockWindows: new FormControl('true'),
    });
  }

  CloseRetailModal() {
    this.openModal = false;
    this.resetForm();
  }

  // getting all retail hq data
  getAllRetailerInfo() {
    this.retailHqService.getAllRetailerInfo()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(getAllRetailHqResponse => {
        this.allRetailHqData = (getAllRetailHqResponse.retailer as RetailHq[]);
        if (this.allRetailHqData.length < 10) {
          this.showTo = this.allRetailHqData.length;
        } else {
          this.showTo = Math.min(this.page * this.tableSize);
        }
        this.displayRetailHqList = [];
        this.allRetailHqData.forEach(ele => {
          this.displayRetailHqList.push(ele);
        });
        this.displayRetailHqList.forEach(element => {
          element.configurations = (JSON.parse(element.configurations) as RetailHqConfiguration[]);
          // adding this custom property to know which Banner content is expanded
          element.isExpanded = false;
          element.isEdit = false;
        });
      });
  }

  getAllRetailerInfoSearchByName() {
    this.retailHqService.getAllRetailerInfoSearchByName(this.animateThis.nativeElement.value)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(getAllRetailHqResponse => {
        this.allRetailHqData = (getAllRetailHqResponse.retailer as RetailHq[]);
        if (this.allRetailHqData.length < 10) {
          this.showTo = this.allRetailHqData.length;
        } else {
          this.showTo = Math.min(this.page * this.tableSize);
        }
        this.displayRetailHqList = [];
        this.allRetailHqData.forEach(ele => {
          this.displayRetailHqList.push(ele);
        });
        this.displayRetailHqList.forEach(element => {
          element.configurations = (JSON.parse(element.configurations) as RetailHqConfiguration[]);
          element.isExpanded = false;
        });
      });
  }
  getAllRetailerInfoSortById(sortby) {
    this.getAllRetaiHqsort(sortby, this.retailerIdSort.nativeElement.getAttribute('data-order'))
  }
  getAllRetailerInfoSortByName(sortby) {
    this.getAllRetaiHqsort(sortby, this.retailerNameSort.nativeElement.getAttribute('data-order'))
  }
  getAllRetailerInfoSortByCreateTime(sortby) {
    this.getAllRetaiHqsort(sortby, this.createdTimeSort.nativeElement.getAttribute('data-order'))
  }
  getAllRetailerInfoSortByUpdateTime(sortby) {
    this.getAllRetaiHqsort(sortby, this.updatedTimeSort.nativeElement.getAttribute('data-order'))
  }

  getAllRetaiHqsort(sortby: string, sortedElement) {
    this.retailHqService.getAllRetailerInfoSortBy(sortby, sortedElement)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(getAllRetailHqResponse => {
        this.allRetailHqData = (getAllRetailHqResponse.retailer as RetailHq[]);
        this.displayRetailHqList = [];
        this.allRetailHqData.forEach(ele => {
          this.displayRetailHqList.push(ele);
        });
        this.displayRetailHqList.forEach(element => {
          element.configurations = (JSON.parse(element.configurations) as RetailHqConfiguration[]);
          element.isExpanded = false;
        });
      });
    if (sortedElement === GlobalConstants.DESC_SORT) {
      this.retailerNameSort.nativeElement.setAttribute('data-order', GlobalConstants.ASC_SORT);
    } else {
      this.retailerNameSort.nativeElement.setAttribute('data-order', GlobalConstants.DESC_SORT);
    }
  }

  getDetailsOfRetailHq(id: string) {
    this.displayRetailHqList.forEach(element => {
      if (element.retailerId === id) {
        element.isExpanded = !element.isExpanded;
        if (!element.isExpanded) {
          element.isEdit = false;
        }
      }
    });
    //Add TODO when we expand other item first data is refreshing
    this.configurations = [];
    this.retailHqService.getAllRetailerInfoById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(getAllRetailHqResponse => {
        this.detailsResp = getAllRetailHqResponse.retailer;
        this.retailName = getAllRetailHqResponse.retailer.retailerName;
        this.configurations = (JSON.parse(getAllRetailHqResponse.retailer.configurations) as RetailHqConfiguration[]);
        this.retailData.configurations = JSON.parse(this.retailData.configurations);
      });
  }

  onTableDataChange(event) {
    this.page = event;
    this.showFrom = ((this.page - 1) * this.tableSize) + 1;
    this.showTo = Math.min(this.page * this.tableSize, this.allRetailHqData.length);
  }

  editRetailDetails(id: string) {
    this.displayRetailHqList.forEach(element => {
      if (element.retailerId === id) {
        element.isEdit = true;
      }
    });
    this.getDetailsOfRetailHq(id);
  }

  cancelEdit(id: string) {
    let index = this.displayRetailHqList.findIndex(ele => ele.retailerId === id);
    if (index > -1) {
      this.displayRetailHqList[index].isEdit = false;
      this.displayRetailHqList[index].isExpanded = false;
    }
    this.getAllRetailerInfo();
  }
}
