<!-- Login page content -->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <a class="central_logo" [href]="loginhref"> <img class="img-fluid" alt="IPOS Central LOGO" /> </a>
      <!-- Login Section -->
      <div class="card language_card">
        <div class="card-header">
         <span class="language_heading"> <img class="lang_image" alt="select language">&nbsp;
          <h5 [innerHTML]="languageHeading"></h5> </span>
          <p class="ipos_central_p" [innerHTML]="subHeading"></p>
        </div>
        <div class="card-body">
          <form [formGroup]="langForm" class="dropdownscroll" ngbRadioGroup>
            <div class="card-body">
              <label for="{{ language.langName }}" class="lang_radiobtn"
               *ngFor="let language of languageJSON; trackBy: trackByLanguage" >
                {{ language.langName }}
                <input type="radio" name="langRadios" id="{{ language.langName }}" [value]='language.langName'
                  (click)="setButtonText(language.langName, language.langCode)" [checked]="language.checked" />
              </label>

            </div>
          </form>
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary gtmSelectLang" (click)="languageSelected()" [innerHTML]="selectLanguage">

          </button>
        </div>
      </div>
    </div>
  </div>
</div>
