<div class="time_date_popover">
    <form [formGroup]="datetimePickerFrm">
        <div style="padding: 13px 0px;">
            <div class="picker_row">
                <!--Date Picker-->
                <div>
                    <div class="form-group">
                        <label for="publishedTimeStamp">Date</label>
                        <input type="date" class="form-control" id="publishedTimeStamp"
                        formControlName="publishedTimeStamp" />
                    </div>
                </div>
                <!--Time Picker-->
                <div class="time_field">
                    <div class="field_labels">Hour</div>
                    <div class="btn-group" ngbDropdown>
                        <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                             id="hourBtn">
                            {{hourVal}}</button>
                        <div ngbDropdownMenu class="dropdown-menu hour-dropDwn">
                            <a class="dropdown-item" ngbDropdownItem *ngFor="let Hour of timeHoursConstant; "
                                (click)="getSelectedHour(Hour)">
                                {{ Hour}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="time_separator">:</div>
                <div class="time_field">
                    <div class="field_labels">Minute</div>
                    <div class="btn-group" ngbDropdown>
                        <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                        id="minuteBtn" >
                            {{minuteVal}}</button>
                        <div ngbDropdownMenu aria-labelledby="storeDropDown" class="minute-dropDwn">
                            <a ngbDropdownItem *ngFor="let Minute of timeMinuteConstant;"
                                (click)="getSelectedMinute(Minute)">
                                {{ Minute }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="time_separator">:</div>

                <!--TimeZonePicker-->
                <div class="time_zone_field">
                    <div class="field_labels">Time Zone</div>
                    <div ngbDropdown class="btn-group" display="dynamic" placement="bottom-right">
                        <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                             id="timeZoneBtn" >
                            {{timeZoneVal}}</button>
                        <div ngbDropdownMenu>
                            <a ngbDropdownItem *ngFor="let timeZoneValue of timeZone;"
                                (click)="getSelectedTimeZone(timeZoneValue.timeZoneValue)" class="timeZone-list">
                                {{ timeZoneValue.timeZoneValue }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popover-footer-action">
            <button type="button" class="btn btn-sm btn-outline-primary left-btn" (click)="getCurrentTime()">
                {{buttonText}}
            </button>
            <button type="button" class="btn btn-sm btn-primary save-btn" (click)="getSelectedTime()">
                Save Selection
            </button>
            </div>
        </div>
    </form>
</div>
