import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { AssetLibraryConstants } from './constants/asset-library.constant';

@Injectable({
  providedIn: 'root'
})
export class AttractorLoopService {

  userId = sessionStorage.getItem('userId');
  ATTRACTOR_LOOP_URL = AssetLibraryConstants.ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId + AssetLibraryConstants.ATTRACTOR_LOOP;
  ATTRACTOR_LOOP_BY_ID_URL = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId
    + AssetLibraryConstants.ATTRACTOR_LOOP + AssetLibraryConstants.SLASH;
  constructor(private httpMethodsservice: HttpMethodsService) {
  }

  getAllAttractorLoopByUser() {
    const url = this.ATTRACTOR_LOOP_URL;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) =>
        res));
  }

  createAttractorLoop(configurationsJson: string) {
    const url = this.ATTRACTOR_LOOP_URL;
    const addObject = {
      configurations: configurationsJson
    };

    return this.httpMethodsservice.post(url, addObject)
      .pipe(map((res: any) =>
        res));
  }

  putAttractorLoopById(inputJson: string, id: string) {
    const url = this.ATTRACTOR_LOOP_URL + "/" + id;

    return this.httpMethodsservice.put(url, inputJson)
      .pipe(map((res: any) =>
        res));
  }

  uploadVideo(video: File, id: string, videoType: string) {
    const url = this.ATTRACTOR_LOOP_BY_ID_URL + id;

    //Note: Using the putImage http method for Video as well, it is treated generic.
    return this.httpMethodsservice.putImage(url, video, videoType, true)
      .pipe(map((res: any) =>
        res));
  }

  deleteAttractorLoopById(attractorLoopId: string) {
    const url = this.ATTRACTOR_LOOP_URL
                + AssetLibraryConstants.SLASH + attractorLoopId;

    return this.httpMethodsservice.delete(url)
      .pipe(map((res: any) =>
        res));
  }
}
