import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetLibraryRoutingModule } from './asset-library-routing.module';
import { LogoContentComponent } from './logo-content/logo-content.component';
import { CreateLogoContentComponent } from './create-logo-content/create-logo-content.component';
import { PromoContentComponent } from './promo-content/promo-content.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageComponent } from './page/page.component';
import { LivePreviewModule } from '../../common/components/live-preview/live-preview.module';
import { DeleteModule } from '../../common/components/delete/delete.module';
import { ProfileStorelogoModule } from '../../common/components/profile-storelogo/profile-storelogo.module';
import { AssetidCopyModule } from '../../common/components/assetid-copy/assetid-copy.module';
import { PipeUtilModule } from '../../modules/pipes/pipe-util/pipe-util.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { CreatePromoContentComponent } from './create-promo-content/create-promo-content.component';
import { FormFactorsModule } from './form-factors/form-factors.module';
import { DataService } from '../../../app/core/data/data-service';
import { AssetLibraryComponent } from './asset-library.component';
import { DateTimePickerModule } from '../../common/components/datetimepicker/datetimepicker-popover/datetimepicker.module'
import { SegmentsComponent } from './segments/segments.component';
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';
import { CreateBannerCarouselComponent } from './create-banner-carousel/create-banner-carousel.component';
import { CreatePriceContentComponent } from './create-price-content/create-price-content.component';
import { PriceContentComponent } from './price-content/price-content.component';
import { AttractorLoopComponent } from './attractor-loop/attractor-loop.component';
import { CreateAttractorLoopComponent } from './create-attractor-loop/create-attractor-loop.component';
import { SpinnerOverlayComponent } from './../../common/components/spinner-overlay/spinner-overlay.component';
import { CreateSegmentsComponent } from './create-segments/create-segments.component';
import { BannerFunctionComponent } from './create-banner-carousel/banner-function/banner-function.component';
import { BannerUrlComponent } from './create-banner-carousel/banner-url/banner-url.component';
import { ConfirmDialogModule } from '../../common/components/confirm-dialog/confirm-dailog.module';
import { ContentLivePreviewModule } from '../../common/components/content-live-preview/content-live-preview.module';
@NgModule({
  declarations: [
    AssetLibraryComponent,
    LogoContentComponent,
    CreateLogoContentComponent,
    CreatePromoContentComponent,
    PromoContentComponent,
    PageComponent,
    SegmentsComponent,
    BannerCarouselComponent,
    CreateBannerCarouselComponent,
    CreatePriceContentComponent,
    PriceContentComponent,
    AttractorLoopComponent,
    CreateAttractorLoopComponent,
    SpinnerOverlayComponent,
    CreateSegmentsComponent,
    BannerFunctionComponent,
    BannerUrlComponent],
  imports: [
    CommonModule,
    NgbModule,
    AssetLibraryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LivePreviewModule,
    DeleteModule,
    ProfileStorelogoModule,
    AssetidCopyModule,
    PipeUtilModule,
    ColorPickerModule,
    FormFactorsModule,
    DateTimePickerModule,
    ConfirmDialogModule,
    ContentLivePreviewModule
  ],
  providers: [
    DataService
  ],
  exports: [
    LogoContentComponent,
    CreateLogoContentComponent,
    CreatePromoContentComponent,
    PromoContentComponent,
    PageComponent,
    SegmentsComponent,
    BannerCarouselComponent,
    CreateBannerCarouselComponent,
    CreatePriceContentComponent,
    PriceContentComponent,
    AttractorLoopComponent,
    CreateAttractorLoopComponent,
    SpinnerOverlayComponent,
    CreateSegmentsComponent
  ]
})
export class AssetLibraryModule { } // TODO: Change this name  to a more common name so other modules can import this
