import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'iposcen-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {

  @Input() contentName: string;
  @Input() buttonName: string;
  @Output() readonly delete = new EventEmitter();

  @ViewChild('deleteModal') private modalContent: TemplateRef<DeletePopupComponent>;
  private modalRef: NgbModalRef;
  private modalOption : NgbModalOptions = {};

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.open(this.modalContent, this.modalOption);
    this.modalRef.result.then();
  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  deleteContent() {
    this.delete.emit();
  }
}
