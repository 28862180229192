import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
// import { BehaviorSubject } from 'rxjs';
// import 'rxjs/add/operator/share';
// import 'rxjs/add/operator/startWith';
import { User } from './../../modules/account/login/_models/user.model';

@Injectable()
export class AppGlobal {

    currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

    setCurrentUser(currentUser) {
        this.currentUser.next(currentUser);
    }

}
