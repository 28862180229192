import { Component, OnInit } from '@angular/core';
import { StoresService } from 'src/app/core/routing/services/stores_api/stores.service';
import { Stores } from './interface/store.i';
import { StoresConstant } from './interface/store.i';
import { Enumvalues } from './interface/store.i';
import { storesdetailconstant } from './interface/store.i';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { StoreSearch } from './../../../core/routing/services/stores_api/constants/stores.constant'

@Component({
  selector: 'iposcen-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})

export class StoresComponent implements OnInit {
  storeForm: FormGroup;
  storesData: Stores[] = [];
  showCreatePopup: boolean;
  particularStoreData = [];
  tempData: any;
  country: string;
  storeName: string;
  storeId: string;
  retailerID: string;
  retailerName: string;
  createdBy: string;
  storeNumber: string;
  customStoreId: string;
  stateregionProvince: string;
  address: string;
  postalCode: string;
  storeCode: string;
  tabIndex: number;
  isDesc: boolean = false;
  showstoresContainer: boolean;
  page: number;
  tableSize: number;
  searchTag: string;
  searchData: string;
  showFrom: number;
  showTo: number;
  globalSortValue: string;
  isAscendingSort: boolean = false;
  loadFlag: string;
  expanded: boolean[] = []

  constructor(private storesService: StoresService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.getAllStoresByUserID();
    this.showCreatePopup = false;
    this.showstoresContainer = true;
    this.page = 1;
    this.tableSize = 10;
    this.showFrom = ((this.page - 1) * this.tableSize) + 1;
    this.showTo = Math.min(this.page * this.tableSize);
    this.loadFlag = StoresConstant.LOAD_FLAG;
    this.createFormGroupvaluesforStore();
  }

  createFormGroupvaluesforStore() {
    this.storeForm = this.formBuilder.group({
      store_name: new FormControl(''),
      retailer_name: new FormControl(''),
      store_number: new FormControl(''),
      custom_storeId: new FormControl(''),
      country_name: new FormControl(''),
      region_name: new FormControl(''),
      address: new FormControl(''),
      postalcode: new FormControl('')
    });
  }

  getAllStoresByUserID() {
      this.storesService.getAllStoresbyUserID().subscribe(getAllStoresResponse => {
      this.storesData = (getAllStoresResponse.store as Stores[]);
      this.showFrom = ((this.page - 1) * this.tableSize) + 1;
      this.showTo = Math.min(this.page * this.tableSize, this.storesData.length);
    })
  }
  getStoresbyID(storedata, index) {
    this.expanded[index] = !this.expanded[index];
    storesdetailconstant.STOREID_DATA = storedata.storeId;
    storesdetailconstant.REATILERID_DATA = storedata.retailerId;
    storesdetailconstant.USERID_DATA = storedata.createdByUserID;
    let tempResult = {};
    this.storesService.getAStoreData(storesdetailconstant).subscribe(getStoreData => {
      tempResult = (getStoreData.store as Stores[]);
      if (tempResult) {
        this.particularStoreData.push(tempResult)
      }
    })
  }

  createnewStores() {
    this.showCreatePopup = true;
  }

  onCreateStore() {
    let StoresContent = {
      storeName: this.storeForm.controls.store_name.value,
      retailerName: this.storeForm.controls.retailer_name.value,
      storeNumber: this.storeForm.controls.store_number.value,
      customStoreID: this.storeForm.controls.custom_storeId.value,
      country: this.storeForm.controls.country_name.value,
      stateRegionProvince: this.storeForm.controls.region_name.value,
      address: this.storeForm.controls.address.value,
      postalCode: this.storeForm.controls.postalcode.value
    }
    this.storesService.createNewStore(StoresContent, this.retailerID).subscribe(data => {
    })
  }

  searchtheStores(searchdata) {
    if (this.loadFlag == StoresConstant.LOAD_FLAG && !this.searchTag) {
      this.searchTag = StoreSearch.STORE_NAME_SEARCH;
      this.loadFlag = StoresConstant.UNLOAD_FLAG;
    }
    if (searchdata && this.searchTag) {
      this.storesService.getStoresbySearch(this.searchTag, searchdata).subscribe(getAllStoresResponse => {
        this.storesData = (getAllStoresResponse.store as Stores[]);
        this.showFrom = ((this.page - 1) * this.tableSize) + 1;
        this.showTo = Math.min(this.page * this.tableSize, this.storesData.length);
        if (this.storesData.length === 0) {
          this.showFrom = this.storesData.length;
          this.showTo = this.storesData.length;
        }
      })
    }
    else {
      this.getAllStoresByUserID();
    }
  }

  onSelectChange(value) {
    if (value == Enumvalues.VALUE_1) {
      this.searchTag = StoreSearch.STORE_NAME_SEARCH;
    }
    else if (value == Enumvalues.VALUE_2) {
      this.searchTag = StoreSearch.RETAILER_NAME_SEARCH;
    }
    else if (value == Enumvalues.VALUE_3) {
      this.searchTag = StoreSearch.COUNTRY_SEARCH;
    }
  }
  onTableDataChange(event) {
    this.page = event;
    this.getAllStoresByUserID();
    this.showFrom = ((this.page - 1) * this.tableSize) + 1;
    this.showTo = Math.min(this.page * this.tableSize, this.storesData.length);
  }


  //TODO --> Need to Change into Switch case
  sort(sortKey: string) {
    if (sortKey == StoresConstant.STORE_CODE) {
      let tempval = StoresConstant.STORE_CODE_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
    else if (sortKey == StoresConstant.STORE_NAME) {
      let tempval = StoresConstant.STORE_NAME_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
    else if (sortKey == StoresConstant.CUST_STORE_ID) {
      let tempval = StoresConstant.CUST_STORE_ID_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
    else if (sortKey == StoresConstant.STORE_NUMBER) {
      let tempval = StoresConstant.STORE_NUMBER_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
    else if (sortKey == StoresConstant.COUNTRY) {
      let tempval = StoresConstant.COUNTRY_SORT;
      this.sortingTableWithAscandDesc(tempval);
    }
    else if (sortKey == StoresConstant.REGION) {
      let tempval = StoresConstant.REGION_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
    else if (sortKey == StoresConstant.ADDRESS) {
      let tempval = StoresConstant.ADDRESS_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
    else if (sortKey == StoresConstant.POSTAL_CODE) {
      let tempval = StoresConstant.POSTAL_CODE_SORT;
      this.sortingTableWithAscandDesc(tempval);

    }
  }

  sortingTheStoresTable() {
    this.storesService.sort(this.globalSortValue).subscribe(getAllStoresResponse => {
      this.storesData = (getAllStoresResponse.store as Stores[]);
      this.showFrom = ((this.page - 1) * this.tableSize) + 1;
      this.showTo = Math.min(this.page * this.tableSize, this.storesData.length);
    })
  }

  sortingTableWithAscandDesc(tempval) {
    this.isAscendingSort = !this.isAscendingSort;
    if (this.isAscendingSort == true) {
      this.globalSortValue = tempval + StoresConstant.ASCENDING_ORDER;
    }
    else {
      this.globalSortValue = tempval + StoresConstant.DESCENDING_ORDER;
    }
    this.sortingTheStoresTable();
  }
}
