import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { LogoContentService } from '../../../core/routing/services/asset-library/logo-content.service';
import { CreateLogoContentResponse, LogoContent, LogoContentConfiguration, LogoContentViewModel } from '../logo-content/interfaces/logo-content.i';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { CommonService } from '../../../../app/common/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'iposcen-create-logo-content',
  templateUrl: './create-logo-content.component.html',
  styleUrls: ['./create-logo-content.component.scss']
})
export class CreateLogoContentComponent implements OnInit, OnDestroy {
  @Input() logoContentDetails: LogoContentViewModel;
  @Output() readonly goBacktoLogoContent = new EventEmitter<object>();  
  
  logoContentgrpForm: FormGroup;
  isPublishedDateInCorrect: boolean;
  isExpiredDateIncorrect: boolean;
  isSubmitted: boolean;
  isFormValid: boolean;
  selectedLogoContentId: string;
  dateTimeValue : string;
  expiryDateTime: string;
  selectedLogoContent: LogoContentViewModel;
  private ngUnsubscribe = new Subject<boolean>();
  currentDetailPage: string;
  createdLogoContentId: string;

  constructor(
    private formBuilder: FormBuilder,
    private logoContentService: LogoContentService,
    private dataService: DataService,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.dateTimeValue='';
    this.expiryDateTime ='';
    this.dataService.getLogoContent()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedLogoContent: any) => {
        if (selectedLogoContent) {
          this.selectedLogoContent = selectedLogoContent;
          this.selectedLogoContentId = selectedLogoContent.id;
        }
      });

    this.isSubmitted = false;
    this.createLogoContentGroupForm();
    // console.log('logoContentetails', this.logoContentDetails);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createLogoContentGroupForm() {
    // TODO -- need to add validations for form fields
    this.logoContentgrpForm = this.formBuilder.group({
      publishedName: new FormControl((this.selectedLogoContent) ? this.selectedLogoContent.configurations.publishedName : '',
        [Validators.required, Validators.maxLength(50)]),
      publishedTimeStamp: new FormControl((this.selectedLogoContent) ? this.selectedLogoContent.configurations.publishTime : GlobalConstants.NOW),
      internalName: new FormControl((this.selectedLogoContent) ? this.selectedLogoContent.configurations.internalName : ''),
      expiryTimeStamp: new FormControl((this.selectedLogoContent) ? this.selectedLogoContent.configurations.expireTime:GlobalConstants.NEVER)
    });
  }
  getPublishDate(selectedDate){
    this.dateTimeValue = `${selectedDate[0]}, ${selectedDate[1]}`;
  }
  getExpiryNever(time){
    this.expiryDateTime = GlobalConstants.NEVER;
  }
  getSelectedTime(time){
    this.dateTimeValue = time;
  }
  getExpirySelectedTime(selectedDate){
    let expiryDateSmaller = false;
    let expiryDateVal = selectedDate[0];
    if (this.dateTimeValue.toString() === '') {
      // check if expiry date is smaller than current date
      const today = this.getCurrentDate();
      expiryDateSmaller = Date.parse(expiryDateVal) <
        Date.parse(today);
    } else {
      const publishDateValue = this.dateTimeValue[0];
      expiryDateSmaller = Date.parse(expiryDateVal) <
        Date.parse(publishDateValue);
    }
    this.expiryDateTime = `${selectedDate[0]}, ${selectedDate[1]}`;
    this.isExpiredDateIncorrect = expiryDateSmaller;
  }

  getCurrentDate(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minute = today.getMinutes();
    var dateInfo =  `${mm}/${dd}/${yyyy}`;
    var time = `${hour}:${minute}`;
    var timeZone = today.toTimeString().slice(9);
    if(timeZone.length > 30){
      timeZone = timeZone.substring(0, 30);
      timeZone = `${timeZone}...`;
    };
    var currentDateResult = `${dateInfo} ${time} ${timeZone}`;
    currentDateResult = currentDateResult.toString();
    return currentDateResult;
  }

  createLogoContent() {
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.getCurrentDate();
    }
    const logoContent: LogoContentConfiguration = {
      publishedName: this.logoContentgrpForm.controls.publishedName.value,
      internalName: this.logoContentgrpForm.controls.internalName.value,
      publishTime: (this.dateTimeValue.toString())?this.dateTimeValue.toString():this.logoContentgrpForm.controls.publishedTimeStamp.value,
      expireTime: (this.expiryDateTime.toString())?this.expiryDateTime.toString():this.logoContentgrpForm.controls.expiryTimeStamp.value,
      contentScheduleTimezoneCode: 'CST',
      type: GlobalConstants.LOGO_CONTENT_GROUP,
      contentGroupPages: []
    };
    this.logoContentService.createLogoContent(JSON.stringify(logoContent))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createLogoContentResponse => {      
        const createLogoContentResponseObj: CreateLogoContentResponse = createLogoContentResponse as CreateLogoContentResponse;
        this.goBacktoLogoContent.emit(createLogoContentResponseObj);

        //Coding for create profile logo content       
        this.createdLogoContentId = createLogoContentResponse.logoContent.id;        
        this.currentDetailPage = this.router.url;
        const pageName = this.currentDetailPage.lastIndexOf('/');        
        //checking if Current URL is for create profile
        if (this.currentDetailPage.substring(pageName + 1) === GlobalConstants.CREATE_PROFILE) {
           //using commonservice to pass Logocontet id to create profile page
           this.commonService.emitLogoContentId(this.createdLogoContentId);         
        }
      },
        error => {
        }
      );

    // this.goBacktoLogoContent.emit({"logoContent":{"id":"logo-content-d62f99b1-c3c0-476c-84f0-26817bbbffb8","configurations":'{"publishedName":"Newyork sales","internalName":"","publishTime":"2020-12-04","expireTime":"2020-12-04","contentScheduleTimezoneCode":"CST","type":"LogoContentGroup","contentGroupPages":[]}',"islocked":false,"updateTime":"December 03, 2020 13:06:03","createdByUser":"224f3e3a-7d13-4b43-bf0f-d1b39628ed5a","isdefault":false,"ispushedToDevice":false},"responseContext":{"sessionId":"7681F289C961CD10E2D2225963BE13E1","status":"OK","statusCode":200,"success":true,"currentTimeStamp":"2020-12-03T13:06:03.644+00:00"}}); /*sample response */
  }

  updateLogoContent() {
    
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.getCurrentDate();
    }
    const logoContent = this.selectedLogoContent as unknown as LogoContent;
    const logoContentConfig: LogoContentConfiguration = {
      publishedName: this.logoContentgrpForm.controls.publishedName.value,
      internalName: this.logoContentgrpForm.controls.internalName.value,
      //publishTime: this.logoContentgrpForm.controls.publishedTimeStamp && this.logoContentgrpForm.controls.publishedTimeStamp.value !== '' ?
        //this.logoContentgrpForm.controls.publishedTimeStamp.value : new Date().toString(),
      publishTime: (this.dateTimeValue.toString())?this.dateTimeValue.toString():this.logoContentgrpForm.controls.publishedTimeStamp.value,
      //expireTime: this.logoContentgrpForm.controls.expiryTimeStamp && this.logoContentgrpForm.controls.expiryTimeStamp.value !== '' ?
       // this.logoContentgrpForm.controls.expiryTimeStamp.value : GlobalConstants.NEVER,
      expireTime: (this.expiryDateTime.toString())?this.expiryDateTime.toString():this.logoContentgrpForm.controls.expiryTimeStamp.value,
      contentScheduleTimezoneCode: 'CST', // TODO - What timezone to set here
      type: GlobalConstants.LOGO_CONTENT_GROUP,
      contentGroupPages: this.selectedLogoContent.configurations.contentGroupPages
    };
    logoContent.configurations = JSON.stringify(logoContentConfig);
    this.logoContentService.putLogoContentById(JSON.stringify(logoContent), this.selectedLogoContentId)
     .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putLogoContentResponse => {
        // console.log('putLogoContentResponse:: ' + JSON.stringify(putLogoContentResponse));
        const createLogoContentResponseObj: CreateLogoContentResponse = putLogoContentResponse as CreateLogoContentResponse;
        this.goBacktoLogoContent.emit(createLogoContentResponseObj);
      },
        error => {
        });
  }

  cancel() {
    this.isSubmitted = false;
    this.goBacktoLogoContent.emit({cancel: true});
    this.dataService.setLogoContent(undefined);
  }

  // check if published date is smaller than today's date
  checkPublishedDateTime() {
    this.isSubmitted = false;
    const today = new Date();
    let isPublishedDateSmaller;
    if (this.logoContentgrpForm.controls.publishedTimeStamp.toString() !== '') {
      isPublishedDateSmaller = new Date(this.logoContentgrpForm.controls.publishedTimeStamp.value).getTime() < new Date().getTime();
    }

    this.isPublishedDateInCorrect = isPublishedDateSmaller;
  }

  // check if expiry date is smaller than published date
  checkExpiryDateTime() {
    this.isSubmitted = false;
    let expiryDateSmaller = false;
    if (this.logoContentgrpForm.controls.expiryTimeStamp.value !== '') {
      expiryDateSmaller = Date.parse(this.logoContentgrpForm.controls.expiryTimeStamp.value) <
        Date.parse(this.logoContentgrpForm.controls.publishedTimeStamp.value);
    }

    this.isExpiredDateIncorrect = expiryDateSmaller;
  }

  onAdd() {
    this.isSubmitted = true;
    this.isFormValid = !this.isPublishedDateInCorrect &&
                        !this.isExpiredDateIncorrect &&
                        this.logoContentgrpForm?.controls?.publishedName?.value.trim() !== ''
                        && !this.logoContentgrpForm?.controls?.publishedName?.errors?.maxlength;
    if (this.isFormValid) {
      // if (this.logoContentDetails && this.logoContentDetails.id) {
      if (this.selectedLogoContentId) {
        // edit api
        this.updateLogoContent();
      } else {
        this.createLogoContent();
      }
    }
  }
  
}
