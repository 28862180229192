import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import { CognitoConstants } from './constants/cognito.constant';
import { Callback } from '../../interfaces/cognito.i';

@Injectable()
export class CognitoUtil {
    url: string;
    serviceConfigs: awsservice.ServiceConfigurationOptions;
    region = environment.region;
    userPoolId = environment.userPoolId;
    identityPoolId = environment.identityPoolId;
    poolData: any = {
        UserPoolId: environment.userPoolId,
        ClientId: environment.clientId
    };

    cognitoCreds: AWS.CognitoIdentityCredentials;

    getUserPool() {
        if (environment.cognito_idp_endpoint) {
            this.poolData.endpoint = environment.cognito_idp_endpoint;
        }

        return new CognitoUserPool(this.poolData);
    }

    getCurrentUser() {
        return this.getUserPool()
            .getCurrentUser();
    }

    // AWS Stores Credentials in many ways, and with TypeScript this means that
    // getting the base credentials we authenticated with from the AWS globals gets really murky,
    // having to get around both class extension and unions. Therefore, we're going to give
    // developers direct access to the raw, unadulterated CognitoIdentityCredentials
    // object at all times.
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    getCognitoCreds() {
        return this.cognitoCreds;
    }

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.

    buildCognitoCreds(idTokenJwt: string) {
        this.url = CognitoConstants.COGNITO_IDP + this.region.toLowerCase() +
            CognitoConstants.COGNTIO_AMAZON_AWS + this.userPoolId;
        if (environment.cognito_idp_endpoint) {
            this.url = environment.cognito_idp_endpoint + CognitoConstants.SLASH + this.userPoolId;
        }
        const logins: CognitoIdentity.LoginsMap = {};
        logins[this.url] = idTokenJwt;
        const params = {
            IdentityPoolId: this.identityPoolId, /* required */
            Logins: logins
        };
        if (environment.cognito_identity_endpoint) {
            this.serviceConfigs.endpoint = environment.cognito_identity_endpoint;
        }
        this.cognitoCreds = new AWS.CognitoIdentityCredentials(params, this.serviceConfigs);
        this.cognitoCreds.clearCachedId();
        this.cognitoCreds = new AWS.CognitoIdentityCredentials(params, this.serviceConfigs);

        // this.setCognitoCreds(this.cognitoCreds);

        return this.cognitoCreds;
    }

    getCognitoIdentity(): string {
        return this.cognitoCreds.identityId;
    }

    getAccessToken(callback: Callback): void {
        if (callback === undefined) {
            throw new Error(CognitoConstants.GET_ACCESS_TOKEN_ERROR);
        }
        if (this.getCurrentUser() !== undefined) {
            this.getCurrentUser()
                .getSession((err, session) => {
                    if (err) {
                        callback.callbackWithParam(undefined);
                    } else {
                        if (session.isValid()) {
                            callback.callbackWithParam(session.getAccessToken()
                                .getJwtToken());
                        }
                    }
                });
        } else {
            callback.callbackWithParam(undefined);
        }
    }

    getIdToken(callback: Callback): void {
        if (callback === undefined) {
            throw new Error(CognitoConstants.GET_ID_TOKEN_ERROR);
        }
        if (this.getCurrentUser() !== undefined) {
            this.getCurrentUser()
                .getSession((err, session) => {
                    if (err) {
                        callback.callbackWithParam(undefined);
                    } else {
                        if (session.isValid()) {
                            callback.callbackWithParam(session.getIdToken()
                                .getJwtToken());
                        }
                    }
                });
        } else {
            callback.callbackWithParam(undefined);
        }
    }

    getRefreshToken(callback: Callback): void {
        if (callback === undefined) {
            throw new Error(CognitoConstants.GET_REFRESH_TOKEN_ERROR);
        }
        if (this.getCurrentUser() !== undefined) {
            this.getCurrentUser()
                .getSession((err, session) => {
                    if (err) {
                        callback.callbackWithParam(undefined);
                    } else {
                        if (session.isValid()) {
                            callback.callbackWithParam(session.getRefreshToken());
                        }
                    }
                });
        } else {
            callback.callbackWithParam(undefined);
        }
    }

    refresh(): void {
        this.getCurrentUser()
            .getSession((err, session) => {
                //   if (session.isValid()) {
                //     console.log('CognitoUtil: refreshed successfully');
                // } else {
                //     console.log('CognitoUtil: refreshed but session is still not valid');
                // }
                if (err) {
                    throw new Error(CognitoConstants.GET_REFRESH_CREDENTIALS.concat(err?.toString()));
                }
            });
    }
}
