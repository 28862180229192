import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoCallback } from '../../../core/routing/interfaces/cognito.i';
import { CreateAccount } from '../../../core/routing/interfaces/create-account.i';
import { CreateAccountService } from '../../../core/routing/services/createAccount/create-account.service';
import { IPOSValidator } from '../../../common/validators/validators';
import { countryConstants } from '../../../core/routing/constants/country.constant';
import { CreateAccountConstants } from './constants/create-account.const';
import { RouterConstants } from '../../../core/routing/constants/app-paths.constant';
import {
  faEye,
  faEyeSlash,
  IconDefinition
} from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfirmRegistrationConstants } from '../confirm-registration/constants/confirm-registration.constant';

@Component({
  selector: 'iposcen-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements CognitoCallback, OnInit {
  creatAccForm: FormGroup;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faExclamationCircle = faExclamationCircle;
  createAccount: CreateAccount = new CreateAccount();
  countries = [] as any;
  errorMessage: string;
  isSubmitted = false;
  PAT_EMAIL = IPOSValidator.EMAIL;
  PAT_PASSWORD = IPOSValidator.CREAT_ACC_PASSWORD;
  mandatoryEmail: string;
  mandatoryFirstName: string;
  mandatoryLastName: string;
  mandatoryCountrySelect: string;
  mandatoryPassword: string;
  mandatoryConfPassword: string;
  mandatoryRetailerName: string;
  mandatoryStoreName: string;
  mandatoryfield: string;
  mandatoryEmailValid: string;
  mandatoryIndicates: string;
  mandatoryCreateAccount: string;
  mandatoryComments: string;
  mandatoryPasswordMatch: string;
  placeholderEmail: string;
  placeholderComment: string;
  placeholderFirstName: string;
  placehoiderLastName: string;
  placeholderRetailerName: string;
  placeholderStoreName; string;
  placeholderPassword: string;
  placeholderCnfPassword: string;
  defaultText: string;
  pwdLength: string;
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  displayEyePassword: IconDefinition;
  displayEyeCnfPassword: IconDefinition;
  isApprovedDomain: boolean;
  loginhref: string;
  disableButton: boolean;

  constructor(
    private createUserService: CreateAccountService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.errorMessage = undefined;
    this.disableButton = false;
    this.getcreatAccForm();
    this.getCountries();
    this.disableBtn();
    this.displayEyePassword = faEye;
    this.displayEyeCnfPassword = faEye;
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.pwdLength = CreateAccountConstants.PASSWORD_MIN_LENGTH;
    this.defaultText = CreateAccountConstants.MANDADTORY_COUNTRY_DEFAULT;
    this.mandatoryFirstName = CreateAccountConstants.MANDADTORY_FIRSTNAME;
    this.mandatoryLastName = CreateAccountConstants.MANDADTORY_LASTNAME;
    this.mandatoryEmail = CreateAccountConstants.MANDADTORY_EMAIL;
    this.mandatoryEmailValid = CreateAccountConstants.MANDADTORY_VALID_EMAIL;
    this.placeholderEmail = CreateAccountConstants.MANDADTORY_WORK_EMAIL;
    this.placeholderComment = CreateAccountConstants.PLACEHOLDER_COMMENTS;
    this.placeholderFirstName = CreateAccountConstants.PLACEHOLDER_FIRSTNAME;
    this.placehoiderLastName = CreateAccountConstants.PLACEHOLDER_LASTNAME;
    this.placeholderPassword = CreateAccountConstants.PLACEHOLDER_PASSWORD;
    this.placeholderCnfPassword = CreateAccountConstants.PLACEHOLDER_CNFPASSWORD;
    this.placeholderRetailerName = CreateAccountConstants.PLACEHOLDER_RETAILER_NAME;
    this.placeholderStoreName = CreateAccountConstants.PLACEHOLDER_STORENAME;
    this.mandatoryPassword = CreateAccountConstants.MANDADTORY_PASSWORD;
    this.mandatoryConfPassword = CreateAccountConstants.MANDADTORY_CNFPASSWORD;
    this.mandatoryCountrySelect = CreateAccountConstants.MANDADTORY_COUNTRY;
    this.mandatoryRetailerName =
      CreateAccountConstants.MANDADTORY_RETAILER_NAME;
    this.mandatoryfield = CreateAccountConstants.MANDADTORY_REQUIRED_FIELD;
    this.mandatoryStoreName = CreateAccountConstants.MANDADTORY_STORENAME;
    this.mandatoryIndicates =
      CreateAccountConstants.MANDADTORY_INDICATES_HEADING;
    this.mandatoryCreateAccount =
      CreateAccountConstants.MANDADTORY_CREATE_ACCOUNT;
    this.mandatoryComments = CreateAccountConstants.MANDADTORY_COMMENTS;
    this.mandatoryPasswordMatch =
      CreateAccountConstants.MANDATORY_PASSWORD_MATCH;
    sessionStorage.setItem('email', this.creatAccForm.get('email').value);
    this.isApprovedDomain = ConfirmRegistrationConstants.APPROVED_DOMAIN_LIST.includes(
      sessionStorage.getItem('email')
        .toLowerCase()
        .split('@')[1]
    );
  }

  getcreatAccForm() {
    this.creatAccForm = this.formBuilder.group(
      {
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(this.PAT_EMAIL)
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(this.PAT_PASSWORD),
          Validators.minLength(8)
        ]),
        cnfPassword: new FormControl('', [
          Validators.required
        ]),
        countrySelect: new FormControl('', [Validators.required]),
        retailerName: new FormControl('', [Validators.required]),
        storeName: new FormControl(''),
        comments: new FormControl('')
      },
      {
        validator: IPOSValidator.confirmedValidator('password', 'cnfPassword')
      }
    );
  }

  get formControls() {
    return this.creatAccForm.controls;
  }

  onRegister() {
    this.isSubmitted = true;
    if (this.creatAccForm.invalid) {
      return;
    }
    this.errorMessage = undefined;
    this.createAccount.firstName = this.creatAccForm.get('firstName').value;
    this.createAccount.lastName = this.creatAccForm.get('lastName').value;
    this.createAccount.email = this.creatAccForm.get('email').value;
    this.createAccount.password = this.creatAccForm.get('password').value;
    this.createAccount.cnfPassword = this.creatAccForm.get('cnfPassword').value;
    this.createAccount.countrySelect = this.creatAccForm.get(
      'countrySelect'
    ).value;
    this.createAccount.retailerName = this.creatAccForm.get(
      'retailerName'
    ).value;
    this.createAccount.storeName = this.creatAccForm.get('storeName').value;
    this.createAccount.comments = this.creatAccForm.get('comments').value;
    this.createUserService.register(this.createAccount, this);
    sessionStorage.setItem('firstName', this.createAccount.firstName);
    sessionStorage.setItem('email', this.createAccount.email);
  }

  getCountries() {
    countryConstants.forEach(element => {
      const country = {
        CountryName: element.CountryName
      };
      this.countries.push(country);
    });
  }

  disableBtn() {
    this.disableButton = false;
    if (this.creatAccForm.get('firstName').value && this.creatAccForm.get('lastName').value && this.creatAccForm.get('email').value
      && this.creatAccForm.get('password').value && this.creatAccForm.get('cnfPassword').value &&
      this.creatAccForm.get('countrySelect').value && this.creatAccForm.get('retailerName').value) {
      this.disableButton = true;
    }

  }

  trackByCountry(index: number, el: string): number {
    return index;
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.displayEyePassword = faEyeSlash;
    } else if (!this.fieldTextType) {
      this.displayEyePassword = faEye;
    }
  }
  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
    if (this.repeatFieldTextType) {
      this.displayEyeCnfPassword = faEyeSlash;
    } else if (!this.repeatFieldTextType) {
      this.displayEyeCnfPassword = faEye;
    }
  }

  cognitoCallback(message: string, result: any) {
    if (message !== undefined) {
      // error
      if (message === CreateAccountConstants.USER_ALREADY_EXISTS) {
        this.errorMessage = CreateAccountConstants.USER_ALREADY_EXISTS_ID;
      } else {
        this.errorMessage = message;
      }
    } else {
      // success
      // move to the next step
      this.router.navigate([
        RouterConstants.CONFIRM_REGISTRATION_LINK,
        this.creatAccForm.get('email').value
      ]);
    }
  }
}
