<ng-template #formFactorsModal>
  <div class="card form_factors">
    <div class="modal-header">
      <div (click)="close()" class="close_icon cursor-pointer">
        <img src="assets/images/popup_close_button.svg" alt="close icon"/>
      </div>
    </div>
    <div class="modal-body">
      <!-- Select Form Factors Modal -->
      <div class="card ">

        <div class="form_header">Apply content group to</div>
        <form [formGroup]="allDevicesForm" >
          <div class="factor_check" formArrayName="allDevices">
            <input type="checkbox" [formControlName]="0" checked /> &nbsp;All Devices
          </div>
          </form>
        <div style="display: flex; justify-content: space-between;">
          <form [formGroup]="manufacturersForm" >
            <div class="factor_head"> Manufacturers</div>
            <div class="factor_check" formArrayName="manufacturers" *ngFor="let order of manufacturersFormArray.controls; let i = index">
              <input type="checkbox" [formControlName]="i"/> {{formFactorsConfigData.manufacturers[i].name}}
            </div>
            <!-- <div *ngIf="!manufacturersForm.valid">At least one manufacturer must be selected</div> -->
          </form>
          <form [formGroup]="deviceTypesForm">
            <div class="factor_head"> Device Types</div>
            <div class="factor_check" formArrayName="deviceTypes" *ngFor="let order of deviceTypesFormArray.controls; let i = index">
              <input type="checkbox" [formControlName]="i"/> {{formFactorsConfigData.deviceTypes[i].name}}
            </div>
            <!-- <div *ngIf="!deviceTypesForm.valid">At least one device type must be selected</div> -->
          </form>
          <form [formGroup]="processorsForm">
            <div class="factor_head"> Processors</div>
            <div class="factor_check" formArrayName="processors" *ngFor="let order of processorsFormArray.controls; let i = index">
              <input type="checkbox" [formControlName]="i"/> {{formFactorsConfigData.processors[i].name}}
            </div>
            <!-- <div *ngIf="!processorsForm.valid">At least one processor must be selected</div> -->
          </form>
          <form [formGroup]="generationsForm">
            <div class="factor_head"> Generation</div>
            <div class="factor_check" formArrayName="generations" *ngFor="let order of generationsFormArray.controls; let i = index">
              <input type="checkbox" [formControlName]="i"/> {{formFactorsConfigData.generation[i].name}}
            </div>
            <!-- <div *ngIf="!generationsForm.valid">At least one generation must be selected</div> -->
          </form>
        </div>

        <div class="card-footer">
          <button type="button" class="btn btn-outline-primary gtmCancelFrmFctr" (click)="close()">
            Cancel
          </button>
          <button type="button" class="btn btn-primary gtmApplyFrmFctr" (click)="formSubmit()"> 
            Apply to devices
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>