

    <!-- Profiles Section starts here -->
    <div class="content__wrapper">
      <div class="card existing_profiles">
        <div class="card-header">
          <span>
            <h2 [innerHTML]="exisitingProfiles"></h2>
            <span *ngIf="profileData.length"><span class="showing" [innerHTML]="showing"></span>&nbsp;<strong>{{ showFrom }} - {{ showTo }}</strong>&nbsp;<span class="of" [innerHTML]="of"></span>&nbsp;<strong>{{ profileData.length }}&nbsp;<span [innerHTML]="profilesHeading"></span></strong></span>
          </span>
          <span>
            <button
              type="button"
              id="createProfile"
              class="btn btn-primary left_icon_btn gtmProfileCreateProfile"
              (click) = "showCreateProfile()"
            >
            <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V3C22.5 2.60218 22.342 2.22064 22.0607 1.93934C21.7794 1.65804 21.3978 1.5 21 1.5ZM3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0Z"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
              <span [innerHTML]="createProfile"></span>

            </button>
          </span>
        </div>
        <div class="card-body table-responsive-md">
          <table id="existingProfilesTable" class="table table-borderless" aria-describedby="profile">
            <colgroup>
              <col span="4" />
              <col span="3" id="last_3_cols" />
            </colgroup>
            <thead>
              <tr *ngIf="profileData?.length">
                <th scope="col" [iposcenSort]="profileData" data-order="desc" data-name="profileName">
                  Profile &nbsp; <em class="sort_filter"></em>
                </th>
                <th scope="col" [iposcenSort]="profileData" data-order="desc" data-name="updatedDate" >
                  Created &nbsp; <em class="sort_filter"></em>
                </th>
                <th scope="col" [iposcenSort]="profileData" data-order="desc" data-name="operatingSystem">
                  Operating System &nbsp; <em class="sort_filter"></em>
                </th>
                <th scope="col" [iposcenSort]="profileData" data-order="desc" data-name="languages" >
                  Languages &nbsp; <em class="sort_filter"></em>
                </th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let new of profileData | slice: (page-1) * tableSize : page * tableSize">
                <td>{{ new.profileName }}</td>
                <td > {{ new.updatedDate | date: 'MM/dd/yy' }}</td>
                <td><em [ngClass]= "{ 'windows': new.operatingSystem === 'Windows' || new.operatingSystem === 'Windows 10', 'linux': new.operatingSystem === 'Linux', 'chromeOS': new.operatingSystem === 'Chrome OS' }"></em>{{ new.operatingSystem }}</td>
                <td>{{ new.languages }}</td>
                <td><em class="preview_eye_icon"></em><span [innerHTML]="preview"></span></td>
                <td><em class="download_icon gtmProfileDownload"></em><span [innerHTML]="download"></span></td>
                <td><em placement="left"
                  [ngbPopover]="popOver" class="vertical_more_icon"></em></td>
              </tr>
              <tr *ngIf="!profileData.length">
                <td class="profile_Record" [innerHTML]="noRecordFound"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer"  *ngIf="profileData.length">
          <ngb-pagination
       [collectionSize]="profileData.length" [(page)]="page" [pageSize]="tableSize"
                [rotate]="false" [ellipses]="false" [boundaryLinks]="false">
          </ngb-pagination>
        </div>
      </div>
    </div>
    <ng-template class="popover-body" #popOver>
              <!--Delete, Duplicate and Edit Options-->
              <div class="options_popup">
                <div class="option_type delete left_icon_btn gtmProfileDelete" (click)="openDelete()">
                  <img  alt="delete"/>
                  Delete
                </div>
                <div class="option_type duplicate left_icon_btn gtmProfileDuplicate" (click)="openDuplicate()">
                  <img alt="duplicate"/>
                  Duplicate
                </div>
                <div class="option_type vector left_icon_btn gtmProfileEdit">
                  <img alt="edit"/>
                  Edit
                </div>
              </div>
    </ng-template>

