
      <div class="card for_pwd_card">
        <form [formGroup]="forgotPasswordForm" (change)="forgotBtn()" (keyup)="forgotBtn()" (ngSubmit)="goToVerificationCodeToReset()">
          <div class="form_header" [innerHTML]="forgotPwdHeading"></div>
          <p [innerHTML]="description">
          </p>

          <div class="form-group">
            <label for="email" [innerHTML]="emailLabel"></label>
            <input [ngClass]="{
              'has-error':
                isSubmitted && forgotPasswordForm.controls.email.errors
            }" class="form-control" type="email" maxlength="50" [placeholder]="placeholderEmail" formControlName="email" required>
            <em class="err_icon" *ngIf="isSubmitted && forgotPasswordForm.controls.email.errors"></em>
          <div *ngIf="isSubmitted && forgotPasswordForm.controls.email.errors" class="help-block">
            <div class="formfield_err_msg" *ngIf="forgotPasswordForm.controls.email.errors.required" [innerHTML]="requiredField">
            </div>
            <div class="formfield_err_msg" *ngIf="forgotPasswordForm.controls.email.errors.pattern" [innerHTML]="validEmailId">
            </div>
          </div>
          </div>

          <div class="for_pwd_btn">
            <button type="button" class="resend_code btn btn-outline-primary gtmCancel" (click)= "redirectToLogin()" [innerHTML]="cancel">
             
            </button>
            <button type="submit" class="btn btn-primary gtmSndVerifyCd" [innerHTML]="next" [disabled]="!forgotButton">
           
            </button>
          </div>
         
        </form>
      </div>

