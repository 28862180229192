export const LANGUAGE_MAPPINGS = [
    {
      langCode: 'en-US',
      langName: 'English - United States'
    },
    {
      langCode: 'ar-sa',
      langName: 'اللغة العربية'
    },
    {
      langCode: 'da',
      langName: 'Dansk'
    },
    {
      langCode: 'fr',
      langName: 'Français'
    },
    {
      langCode: 'hu',
      langName: 'Magyar'
    },
    {
      langCode: 'ko',
      langName: '한국어‏'
    },
    {
      langCode: 'pt-br',
      langName: 'Português (Brasil)‏'
    },
    {
      langCode: 'es',
      langName: 'Español'
    },
    {
      langCode: 'tr',
      langName: 'Türkçe‏'
    },
    {
      langCode: 'zh-Hans',
      langName: '简体中文‏'
    },
    {
      langCode: 'de',
      langName: 'Deutsch‏'
    },
    {
      langCode: 'nl',
      langName: 'Nederlands‏'
    },
    {
      langCode: 'id',
      langName: 'Bahasa Indonesia‏'
    },
    {
      langCode: 'no',
      langName: 'Norsk'
    },
    {
      langCode: 'ro',
      langName: 'Română‏'
    },
    {
      langCode: 'es-mx',
      langName: 'Español (España)‏'
    },
    {
      langCode: 'uk',
      langName: 'Українська‏'
    },
    {
      langCode: 'zh-Hant',
      langName: '繁體中文‏'
    },
    {
      langCode: 'el',
      langName: 'Ελληνικά‏'
    },
    {
      langCode: 'it',
      langName: 'Italiano‏'
    },
    {
      langCode: 'pl',
      langName: 'Polski‏'
    },
    {
      langCode: 'ru',
      langName: 'Русский‏'
    },
    {
      langCode: 'sv',
      langName: 'Svenska‏'
    },
    {
      langCode: 'vi',
      langName: 'Tiếng Việt‏'
    },
    {
      langCode: 'cs',
      langName: 'Čeština‏'
    },
    {
      langCode: 'fi',
      langName: 'Suomi‏'
    },
    {
      langCode: 'he',
      langName: 'עברית‏'
    },
    {
      langCode: 'ja',
      langName: '日本語'
    },
    {
      langCode: 'pt',
      langName: 'Português (Portugal)‏'
    },
    {
      langCode: 'sk',
      langName: 'Slovenčina‏'
    },
    {
      langCode: 'th',
      langName: 'ไทย‏'
    }
  ];
  