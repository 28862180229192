<div [className]="isListPreview ? 'list-preview live_preview' : 'banner-live-preview'">
  <div class="banner-function-layout">
    <div class="heading-text">
      Banner Function
    </div>
    <div class="form-check line-break-div cont-pages-data-section">
      <input class="form-check-input" type="radio" name="banner_function" value="Content Pages" id="cont_pages"
        [checked]="isContentPagesChecked" (click)="selectBannerFunction('Content Pages')" />
      <label class="form-check-label banner-options-label" for="cont_pages">
        Content Pages
        <span class="carbonhue d-block">
          Display up to 3 customizable
          pages.
        </span>
      </label>
    </div>
    <div class="form-check line-break-div url-exp-data-section">
      <input class="form-check-input" type="radio" name="banner_function" value="URL Experience" id="url_exp"
        [checked]="isURLChecked" (click)="selectBannerFunction('URL')" />
      <label class="form-check-label banner-options-label" for="url_exp">
        URL Experience
        <span class="carbonhue d-block">
          Launch a URL.
        </span>
      </label>
    </div>
    <iposcen-confirm-dialog #confirmModal [popupTitle]="title" [popupDesc]="description" [popupAction]="action"
      (cancelConfirm)="cancelModal()" (confirm)="confirmBannerOption()"></iposcen-confirm-dialog>
  </div>
  <iposcen-live-preview *ngIf="bannerFunction === 'Content Pages'"></iposcen-live-preview>
  <iposcen-content-live-preview [sidenav]="sidenav" *ngIf="bannerFunction === 'Default' || bannerFunction === 'URL'">
  </iposcen-content-live-preview>
</div>
