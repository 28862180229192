import { Injectable } from '@angular/core';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { RetailStoreConstants } from '../retail_api/constants/retailHq.constants';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class RetailService {

  userId = sessionStorage.getItem('userId');
  RETAILEHQ_URL = RetailStoreConstants.SLASH_RETAILER_STORE + RetailStoreConstants.SLASH_RETAILER + RetailStoreConstants.SLASH_USER_ID + RetailStoreConstants.SLASH + this.userId + RetailStoreConstants.RETAILER_URL;
  UPDATE_URL = RetailStoreConstants.SLASH_RETAILER_STORE + RetailStoreConstants.SLASH_RETAILER + RetailStoreConstants.SLASH_USER_ID + RetailStoreConstants.SLASH + this.userId + RetailStoreConstants.RETAILER_URL + RetailStoreConstants.SLASH;
  constructor(
    private httpMethod: HttpMethodsService) { }

  getAllRetailerInfo() {
    const url = this.RETAILEHQ_URL;

    return this.httpMethod.get(url)
      .pipe(map((res: any) =>
        res));
  }
  getAllRetailerInfoById(id) {
    const url = this.RETAILEHQ_URL + RetailStoreConstants.SLASH + id;
    return this.httpMethod.get(url)
      .pipe(map((res: any) =>
        res));
  }

  getAllRetailerInfoSearchByName(name: string) {
    const url = this.RETAILEHQ_URL + RetailStoreConstants.SEARCH_URL + name;

    return this.httpMethod.get(url)
      .pipe(map((res: any) =>
        res));
  }
  getAllRetailerInfoSortBy(sortby, sorttype) {
    const url = this.RETAILEHQ_URL + RetailStoreConstants.SORTBY + sortby + RetailStoreConstants.SORT_TYPE + sorttype;

    return this.httpMethod.get(url)
      .pipe(map((res: any) =>
        res));
  }

  createRetailHq(configurationsJson: string) {
    const url = this.RETAILEHQ_URL;

    return this.httpMethod.post(url, configurationsJson)
      .pipe(map((res: any) =>
        res));
  }

  putRetailHqById(configurationsJson: string, id: string) {
    const url = this.RETAILEHQ_URL + RetailStoreConstants.SLASH + id;

    return this.httpMethod.patch(url, configurationsJson)
      .pipe(map((res: any) =>
        res));
  }
}
