import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { CommonService } from '../../../../common/services/common.service';

@Component({
  selector: 'iposcen-content-live-preview',
  templateUrl: './content-live-preview.component.html',
  styleUrls: ['./content-live-preview.component.scss']
})
export class ContentLivePreviewComponent implements OnInit, OnDestroy {
  imageURL: string;
  @Input() sidenav;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private cmnSer: CommonService) { }

  ngOnInit(): void {
    this.imageURL = this.cmnSer.uploadedImageURL || '';
    this.cmnSer.imageDataToBannerObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          this.imageURL = data.image.url;
          this.cmnSer.uploadedImageURL = this.imageURL;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
