import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../../common/services/common.service';
import { StoreLogoViewModel } from '../../asset-library/logo-content/interfaces/store-logo.i';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { first } from 'rxjs/internal/operators/first';
import { CreateProfileService } from '../../../core/routing/services/profile/create-profile.service';
import { ProfileRequest, LogoContent } from '../interface/profile.i';

@Component({
  selector: 'iposcen-profile-storelogo-container',
  templateUrl: './profile-storelogo-container.component.html',
  styleUrls: ['./profile-storelogo-container.component.scss']
})
export class ProfileStorelogoContainerComponent implements OnInit, OnDestroy {

  tabIndex = 0;
  logoCId: string;
  profileData: string; 
  defaultStoreLogos: StoreLogoViewModel;
  defaultLogoImage: any;
  bgColor: string; 
  showOrHidePreview: boolean;
  private ngUnsubscribe = new Subject<boolean>();
  constructor(private commonService: CommonService,
              private createProfileService: CreateProfileService) { }

  ngOnInit(): void {
    this.showOrHidePreview = true;
    this.defaultLogoImage = '/assets/images/preview_footer_logo.svg';
    this.bgColor = GlobalConstants.STORELOGO_BGCOLOR;
    this.commonService.emitLivePreviewDataToProfileObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data[0].imageData !== undefined) {
          this.defaultLogoImage = data[0]?.imageData;
        }
        this.bgColor = data[0]?.bgColor;
      });

    this.commonService.logoContentIdObser
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res) => {
      this.logoCId = res;    
      this.profileData = this.createProfileService.getProfileId();    
      const LogoContentInfo: LogoContent={
      logoContentId: this.logoCId,
      showOnDevice: true
    }

    const profileRequest: ProfileRequest={
      logoContents : [LogoContentInfo]
    }  
    
    this.createProfileService.updateLogoContentId(this.profileData, JSON.stringify(profileRequest))
    .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(Response => {
         //TODO
      },
        error => {
        });
   });

   this.commonService.tabIndexObser
   .pipe(takeUntil(this.ngUnsubscribe))
   .subscribe(data => {    
     if(data === 4 || data === 5 || data === 6){
      this.showOrHidePreview = false;
     }else{
      this.showOrHidePreview = true;
     }
   });

  }

    setIndex(value: number) {
    this.tabIndex = value;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
