import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class DetailService {
  $isMainLayout: Observable<boolean>;
  $currentIndex: Observable<string>;
  $currentPage: Observable<string>;
  private messageSource = new BehaviorSubject<boolean>(true);
  private headerIndex = new BehaviorSubject<string>('homepage');
  private detailsPage = new BehaviorSubject<string>('');

  constructor() {
    this.$isMainLayout = this.messageSource.asObservable();
    this.$currentIndex = this.headerIndex.asObservable();
    this.$currentPage = this.detailsPage.asObservable();
  }

  setHeaderIndex(headerIndex: any) {
    this.headerIndex.next(headerIndex);
  }

  setLayoutToMain(message: boolean) {
    this.messageSource.next(message);
  }

  setDetailsPage(detailsPage: any) {
    this.detailsPage.next(detailsPage);
  }

}
