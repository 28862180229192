// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  region: 'us-east-1',
  identityPoolId: 'us-east-1:2e607d76-6a00-4553-96eb-554a5fe3eeca',
  userPoolId: 'us-east-1_K46Geogjk',
  clientId: '4g9bdem1bm1r25lpg4g9lvrod0',
  rekognitionBucket: 'rekognition-pics',
  mandatorySignIn: true,
  albumName: 'usercontent',
  bucketRegion: 'us-east-1',
  accountId: '153888508172',

  ddbTableName: 'LoginTrail',

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',
  gtm: 'GTM-W582BQZ',

  apiBaseUrl: 'https://www.iposcentral-test.com',
  apiBaseUrlAsset: 'https://www.iposcentral-test.com/asset-library-test-original'

  // For later reference
  // apiBaseUrl: 'https://www.iposcentral-qa.com',
  // apiBaseUrlAsset: 'https://www.iposcentral-qa.com/asset-library-qa-original'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
