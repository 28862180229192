import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeleteModule } from './common/components/delete/delete.module';
import { AssetidCopyModule } from './common/components/assetid-copy/assetid-copy.module';
import { SelectLangComponent } from './common/components/select-lang/select-lang.component';
import { ErrorInterceptor, TokenInterceptor } from './core/routing/interceptors';
import { CognitoUtil } from './core/routing/services/cognito/cognito.service';
import { DetailService } from './core/routing/services/details/detail.service';
import { ProfileComponent } from './modules/profile/profile.component';

import { HttpMethodsService } from './core/routing/services/_helpers/http-methods.service';
import { AppGlobal } from './core/state/app-global';
import { AccountComponent } from './modules/account/account.component';
import { AccountModule } from './modules/account/account.module';
import { AssetLibraryModule } from './modules/asset-library/asset-library.module';
import { FooterComponent } from './modules/footer/footer.component';
import { HeaderComponent } from './modules/header/header.component';
import { DateTimePickerModule } from './common/components/datetimepicker/datetimepicker-popover/datetimepicker.module'
import { HomeComponent } from './modules/home/home.component';
import { DeleteComponent } from './modules/profile/delete/delete.component';
import { DuplicateComponent } from './modules/profile/duplicate/duplicate.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SortDirective } from './directive/sort.directive';
import { SpinnerInterceptor } from './core/routing/interceptors/spinner.interceptor';
import { AuthService } from './core/routing/services/auth/auth.service';
import { CreateProfileModule } from './modules/create-profile/create-profile.module';
import { ConfirmDialogModule } from './common/components/confirm-dialog/confirm-dailog.module';
import { UsersModule} from './modules/users/users.module';
import { ContentLivePreviewModule } from './common/components/content-live-preview/content-live-preview.module';

@NgModule({
  declarations: [
    AppComponent,
    SelectLangComponent,
    HeaderComponent,
    FooterComponent,
    AccountComponent,
    HomeComponent,
    ProfileComponent,
    DeleteComponent,
    DuplicateComponent,
    SortDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    AccountModule,
    FontAwesomeModule,
    AssetLibraryModule,
    DateTimePickerModule,
    DeleteModule,
    CreateProfileModule,
    UsersModule,
    AssetidCopyModule,
    ConfirmDialogModule,
    UsersModule,
    ContentLivePreviewModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    AppGlobal,
    HttpMethodsService,
    CognitoUtil,
    DetailService,
    AuthService,
    { provide: 'googleTagManagerId', useValue: environment.gtm }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
