export class CreateAccount {
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
    cnfPassword: string;
    countrySelect: string;
    retailerName: string;
    storeName: string;
    comments: string;
    role: string;
    userSettings: string;
    retailerLocationNo: string;
    region: string;
    locale: string;
}
