import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { PromoContentService } from '../../../core/routing/services/asset-library/promo-content.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { CreatePromoContentResponse, PromoContent, PromoContentConfiguration, PromoContentViewModel, PromoFormFactor } from '../promo-content/interfaces/promo-content.i';

@Component({
  selector: 'iposcen-create-promo-content',
  templateUrl: './create-promo-content.component.html',
  styleUrls: ['./create-promo-content.component.scss']
})
export class CreatePromoContentComponent implements OnInit, OnDestroy {
  @Output() readonly goBacktoPromoContent = new EventEmitter<object>();
  @ViewChild('formFactorsModal') formFactorsModal: ElementRef;

  promoContentgrpForm: FormGroup;
  isPublishedDateInCorrect: boolean;
  isExpiredDateIncorrect: boolean;
  isSubmitted: boolean;
  isFormValid: boolean;
  promoCGToDeleteName: string;
  dateTimeValue : string;
  expiryDateTime: string;
  selectedFormFactors: PromoFormFactor;
  selectedPromoContentId: string;
  selectedPromoContent: PromoContentViewModel;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private logoContentService: PromoContentService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dateTimeValue='';
    this.expiryDateTime ='';
    this.dataService.getPromoContent()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((promoContentToEdit: any) => {
        if (promoContentToEdit) {
          this.selectedPromoContent = promoContentToEdit;
          this.selectedPromoContentId = promoContentToEdit.id;
          this.selectedFormFactors = {
            allDevices: this.selectedPromoContent.configurations.formFactor.allDevices,
            manufacturer: this.selectedPromoContent.configurations.formFactor.manufacturer,
            devices: this.selectedPromoContent.configurations.formFactor.devices,
            processors: this.selectedPromoContent.configurations.formFactor.processors,
            generations: this.selectedPromoContent.configurations.formFactor.generations
          };
        } else {
          this.selectedFormFactors = {
            allDevices: true,
            manufacturer: '',
            devices: '',
            processors: '',
            generations: ''
          };
        }
      });

    this.isSubmitted = false;
    this.createLogoContentGroupForm();
  }

  getCurrentDate(time){
    this.dateTimeValue = time;
  }
  
  getExpiryNever(time){
    this.expiryDateTime = GlobalConstants.NEVER;
  }

  getSelectedTime(time){
    this.dateTimeValue = time;
    
  }

  getExpirySelectedTime(time){
    this.expiryDateTime = time;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openFormFactorsModal() {
    this.openModal(this.formFactorsModal);
  }

  applyFormFactor(updatedFormFactors) {
    // console.log('received value:: ' + JSON.stringify(updatedFormFactors));
    this.selectedFormFactors = updatedFormFactors;
    this.closeModal(this.formFactorsModal);
  }

  createLogoContentGroupForm() {
    // TODO -- need to add validations for form fields
    this.promoContentgrpForm = this.formBuilder.group({
      publishedName: new FormControl((this.selectedPromoContent) ? this.selectedPromoContent.configurations.publishedName : '',
        [Validators.required, Validators.maxLength(50)]),
      publishedTimeStamp: new FormControl((this.selectedPromoContent) ? this.selectedPromoContent.configurations.publishTime : GlobalConstants.NOW),
      internalName: new FormControl((this.selectedPromoContent) ? this.selectedPromoContent.configurations.internalName : ''),
      expiryTimeStamp: new FormControl((this.selectedPromoContent) ? this.selectedPromoContent.configurations.expireTime : GlobalConstants.NEVER)
    });
  }

  checkCurrentDate (){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minute = today.getMinutes();
    var dateInfo =  `${mm}/${dd}/${yyyy}`;
    var time = `${hour}:${minute}`;
    var timeZone = today.toTimeString().slice(9);
    if(timeZone.length > 30){
      timeZone = timeZone.substring(0, 30);
      timeZone = `${timeZone}...`;
    };
    var currentDateResult = `${dateInfo} ${time} ${timeZone}`;
    currentDateResult = currentDateResult.toString();
    return currentDateResult;
  }
  
  createNewPromoContent() {
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.checkCurrentDate();
    }
    const promoFactor: PromoFormFactor = {
      allDevices: this.selectedFormFactors.allDevices,
      manufacturer: this.selectedFormFactors.manufacturer,
      devices: this.selectedFormFactors.devices,
      processors: this.selectedFormFactors.processors,
      generations: this.selectedFormFactors.generations
    };
    
    const promoContent: PromoContentConfiguration = {
      publishedName: this.promoContentgrpForm.controls.publishedName.value,
      internalName: this.promoContentgrpForm.controls.internalName.value,
      publishTime: (this.dateTimeValue.toString())?this.dateTimeValue.toString():this.promoContentgrpForm.controls.publishedTimeStamp.value,
      expireTime: (this.expiryDateTime.toString())?this.expiryDateTime.toString():this.promoContentgrpForm.controls.expiryTimeStamp.value,
      contentScheduleTimezoneCode: 'CST',
      formFactor: promoFactor,
      type: GlobalConstants.PROMO_CONTENT_GROUP,
      contentGroupPages: []
    };
    this.logoContentService.createPromoContent(JSON.stringify(promoContent))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createPromoContentResponse => {
        const createPromoContentResponseObj: CreatePromoContentResponse = createPromoContentResponse as CreatePromoContentResponse;
        this.goBacktoPromoContent.emit(createPromoContentResponseObj);
      },
        error => {
        });
  }

  updatePromoContent() {
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.checkCurrentDate();
    }
    const promoContent = this.selectedPromoContent as unknown as PromoContent;
    const promoFactor: PromoFormFactor = {
      allDevices: this.selectedFormFactors.allDevices,
      manufacturer: this.selectedFormFactors.manufacturer,
      devices: this.selectedFormFactors.devices,
      processors: this.selectedFormFactors.processors,
      generations: this.selectedFormFactors.generations
    };
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.checkCurrentDate();
    }
    const promoContentConfig: PromoContentConfiguration = {
      publishedName: this.promoContentgrpForm.controls.publishedName.value,
      internalName: this.promoContentgrpForm.controls.internalName.value,
      publishTime: (this.dateTimeValue.toString())?this.dateTimeValue.toString():this.promoContentgrpForm.controls.publishedTimeStamp.value,
      expireTime: (this.expiryDateTime.toString())?this.expiryDateTime.toString():this.promoContentgrpForm.controls.expiryTimeStamp.value,
      contentScheduleTimezoneCode: 'CST',
      formFactor: promoFactor,
      type: GlobalConstants.PROMO_CONTENT_GROUP,
      contentGroupPages: this.selectedPromoContent.configurations.contentGroupPages
    };
    promoContent.configurations = JSON.stringify(promoContentConfig);
    this.logoContentService.putPromoContentById(JSON.stringify(promoContent), this.selectedPromoContentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putPromoContentResponse => {
        const createPromoContentResponseObj: CreatePromoContentResponse = putPromoContentResponse as CreatePromoContentResponse;
        this.goBacktoPromoContent.emit(createPromoContentResponseObj);
      },
        error => {
        });
  }

  cancel() {
    this.isSubmitted = false;
    this.goBacktoPromoContent.emit({cancel: true});
    this.dataService.setPromoContent(undefined);
  }

  // check if published date is smaller than today's date
  checkPublishedDateTime() {
    const today = new Date();
    let isPublishedDateSmaller;
    if (this.promoContentgrpForm.controls.publishedTimeStamp.toString() !== '') {
      isPublishedDateSmaller = new Date(this.promoContentgrpForm.controls.publishedTimeStamp.value).getTime() < new Date().getTime();
    }

    this.isPublishedDateInCorrect = isPublishedDateSmaller;
  }

  // check if expiry date is smaller than published date
  checkExpiryDateTime() {
    let expiryDateSmaller = false;
    if (this.promoContentgrpForm.controls.expiryTimeStamp.value !== '') {
      expiryDateSmaller = Date.parse(this.promoContentgrpForm.controls.expiryTimeStamp.value) <
        Date.parse(this.promoContentgrpForm.controls.publishedTimeStamp.value);
    }

    this.isExpiredDateIncorrect = expiryDateSmaller;
  }

  onAdd() {
    this.isSubmitted = true;
    this.isFormValid = !this.isPublishedDateInCorrect && !this.isExpiredDateIncorrect
      && this.promoContentgrpForm?.controls?.publishedName?.value.trim() !== '';
    if (this.isFormValid) {
      if (this.selectedPromoContentId) { // Since promo content id exists, it is an update process
        this.updatePromoContent();
      } else {
        this.createNewPromoContent();
      }
    }
  }
}
