import { Component, Input, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'iposcen-assetid-copy',
  templateUrl: './assetid-copy.component.html',
  styleUrls: ['./assetid-copy.component.scss']
})
export class AssetidCopyComponent implements OnInit {
  @Input() assetID: string;
  @ViewChild('addButton') animateThis: ElementRef;
  isCopy = false;
  private textarea: HTMLTextAreaElement;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  /* asset ID copy to clip board function*/
  copyAssetId(val: string) {
    this.textarea = this.renderer.createElement('textarea');
    this.renderer.setAttribute(this.textarea, 'readonly', null);
    this.renderer.setStyle(this.textarea, 'position', 'fixed');
    this.renderer.setStyle(this.textarea, 'bottom', '100%');
    this.renderer.setStyle(this.textarea, 'right', '100%');
    this.textarea.value = val;
    this.renderer.appendChild(this.animateThis.nativeElement, this.textarea);
    this.textarea.select();
    document.execCommand('copy');
    this.renderer.removeChild(this.animateThis.nativeElement, this.textarea);
    this.isCopy = true;
  }

}
