export interface Stores {
    retailerId: string;
    retailerName: string;
    storeId: string;
    storeName: string;
    storeNumber: string;
    stateRegionProvince: string;
    address: string;
    country: string;
    postalCode: string;

}

export class StoresConstant {
    static readonly ASCENDING_ORDER = '=asc';
    static readonly DESCENDING_ORDER = '=desc';
    static readonly STORE_CODE = 'storeCode';
    static readonly STORE_NAME = 'storeName';
    static readonly CUST_STORE_ID = 'custStoreId';
    static readonly STORE_NUMBER = 'storenumber';
    static readonly COUNTRY = 'country';
    static readonly REGION = 'region';
    static readonly ADDRESS = 'address';
    static readonly POSTAL_CODE = 'postalcode';
    static readonly STORE_CODE_SORT = "storeIdSort";
    static readonly STORE_NAME_SORT = "storeNameSort";
    static readonly CUST_STORE_ID_SORT = "customStoreIDSort";
    static readonly STORE_NUMBER_SORT = "storeNumberSort";
    static readonly COUNTRY_SORT = 'countrySort';
    static readonly REGION_SORT = 'stateRegionProvinceSort';
    static readonly ADDRESS_SORT = 'addressSort';
    static readonly POSTAL_CODE_SORT = 'postalCodeSort';
    static readonly LOAD_FLAG = "Y";
    static readonly UNLOAD_FLAG = "N";

}

export const storesdetailconstant = {
    STOREID_DATA: "store_ID",
    REATILERID_DATA: 'retailer_ID',
    USERID_DATA: "user_ID",
};
export enum Enumvalues {
    VALUE_1 = "1",
    VALUE_2 = "2",
    VALUE_3 = "3"
}