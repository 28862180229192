import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iposcen-account',
  templateUrl: './asset-library.component.html',
  styleUrls: ['./asset-library.component.scss']
})
export class AssetLibraryComponent implements OnInit {

  sideNav = 'store';
  constructor() { }

  ngOnInit(): void {
  }

  setSideNav(value: string) {
    this.sideNav = value;
  }
}
