export class ForgotPasswordConstants {
    static readonly FORGOT_PWD_HEADING = 'Forgot Password';
    static readonly PARAGRAPH_DESC = 'Enter your email address to receive a link where you can change your password.';
    static readonly EMAIL_LABEL = 'Email Address';
    static readonly NEXT_BTN = 'Send Verification Code';
    static readonly PLACEHOLDER_EMAIL = 'jane.doe@example.com';
    static readonly CODE_RESENT = 'Code Resent';
    static readonly CANCEL_BTN = 'Cancel';
    static readonly RESET_PWD_HEADING = 'Reset Password';
    static readonly EMAIL_SENT = 'Email Sent !!';
    static readonly VERFICATION_CODE = 'Verification Code';
    static readonly GO_BACK = 'Resend Code';
    static readonly REQUIRED_FIELD = 'This field is required';
    static readonly VALID_EMAIL_ID = 'Email address not found. Please enter correct email address';
    static readonly NEW_PWD_LABEL = 'New Password';
    static readonly CNF_NEW_PWD_LABEL = 'Confirm Password';
    static readonly DIMISS_REASON_ESC = 'by pressing ESC';
    static readonly EMAIL_SENT_DESC_ONE = ' An email containing your password verification code has been sent to <span class="email_sent_emailId"><[email]@[domain].com></span>:';
    static readonly EMAIL_SENT_DESC_TWO = '   It may take a few minutes to receive it based on your email system and the verification code sent to your email will expire within an hour. If you do not receive it, be sure to check your Spam folder before trying again.';
    static readonly DISMISS_REASON_DROP = 'by clicking on a backdrop';
    static readonly CONFIRMATION_CODE = 'e.g. 123987';
    static readonly MANDATORY_PASSWORD_MATCH = 'Passwords do not match';
    static readonly CLOSE = 'Close';
    static readonly NEW_PASSWORD_SPAN_MSG = 'Please enter a new password for <strong><[email]@[domain].com></strong>:';
    static readonly CONFIRMATION_CODE_LENGTH = 'Confirmation code must contain 6 digits';
    static readonly PASSWORD_ERROR_MESSAGE = 'Password length should br greater than or equal to 8';
    static readonly PASSWORD_MIN_LENGTH =
        'Password must contain at least 8 characters (with minimum one uppercase letter, one lowercase letter, one number and one special character)';
    static readonly RESET_PWD_MODAL_TITLE = 'Reset Password';
    static readonly MODAL_BODY = 'Your new passord has been created successfully.';
    static readonly RESET_MODAL_BODY = 'Your password has been successfully reset.<br> Login with your new password';
    static readonly AUTO_CLOSING_SECONDS = 6000;
    static readonly ERROR_MESSAGE_CONSTRAINT = '2 validation errors detected: Value at "password" failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\S]+.*[\S]+$; Value at "password" failed to satisfy constraint: Member must have length greater than or equal to 6';
}
