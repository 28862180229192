import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { first, takeUntil } from 'rxjs/operators/index';
import { CommonService } from '../../../../app/common/services/common.service';
import { GlobalConstants } from '../../../../app/common/constants/global.constant';
import { DataService } from '../../../../app/core/data/data-service';
import { BannerCarouselService } from '../../../../app/core/routing/services/asset-library/banner-carousel.service';
import { BannerContent, BannerContentConfiguration, BannerContentGroupPage, BannerContentViewModel, BannerFormFactor, CreateBannerContentResponse } from '../banner-carousel/interface/banner-carousel.i';
import { CreateBannerConfiguration, CreateBannerViewModel, BannerPreview } from './interface/create-banner-carousel.i';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iposcen-create-banner-carousel',
  templateUrl: './create-banner-carousel.component.html',
  styleUrls: ['./create-banner-carousel.component.scss']
})
export class CreateBannerCarouselComponent implements OnInit, OnDestroy {

  @Input() bannerContentDetails: BannerContentViewModel;
  @Output() readonly goBacktoBannerContent = new EventEmitter<object>();
  @Output("refreshBannerContentdata") refreshBannerContentdata = new EventEmitter();
  @ViewChild('imageInput', { static: false }) imageInput: ElementRef;
  @ViewChild('formFactorsModal') formFactorsModal: ElementRef;
  @ViewChild('deleteModal') deleteModal: ElementRef;
  @ViewChild('deleteStoreLogoModal') deleteStoreLogoModal: ElementRef;
  @Input() pageNumber: number;
  @Input() contentGroupType: string;
  @Input() bannerFunction: string;
  bannerContentgrpForm: FormGroup;
  isPublishedDateInCorrect: boolean;
  bannerDeleteButton: string;
  isExpiredDateIncorrect: boolean;
  defaultImages: CreateBannerViewModel[];
  otherImages: CreateBannerViewModel[];
  bannerCarousel: CreateBannerViewModel[];
  bannerToDelete: string;
  bannerToDeleteName: string;
  isSubmitted: boolean;
  isFormValid: boolean;
  isImage: boolean;
  file: any;
  imageDoc: any;
  imageUrl: any = '';
  imageSizeError: any;
  imageLengthError: any;
  imageTypeError: any;
  imageFormat: string;
  imageFileType: string;
  imageFileName: string;
  imageFileSize: number;
  isImageAvailable = false;
  isImageFormat: boolean;
  bannerContentConfiguration: BannerContentConfiguration;
  bannerCGToDeleteName: string;
  isPublished: boolean;
  selectedBannerContentId: string;
  selectedContentGroupID: string;
  selectedFormFactors: BannerFormFactor;
  selectedBannerContent: BannerContentViewModel;
  otherbannerContent: BannerContentViewModel[];
  bannerCarouselImages: CreateBannerViewModel[];
  bannerContent: BannerContent;
  bannnerContentGroupPages: BannerContentGroupPage;
  media = {} as any;
  userId = sessionStorage.getItem('userId');
  url: string;
  private ngUnsubscribe = new Subject<boolean>();
  bannerPreviewData: BannerPreview;

  constructor(
    private formBuilder: FormBuilder,
    private bannerContentService: BannerCarouselService,
    private cmnSer: CommonService,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    
    this.dataService.getBannerContent()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedBannerContentToEdit: any) => {
        if (selectedBannerContentToEdit) {
          this.isImageFormat = true;
          this.selectedBannerContent = selectedBannerContentToEdit;
          this.selectedBannerContentId = selectedBannerContentToEdit.id;
          this.isImageAvailable = (this.selectedBannerContent.configurations.imageFile !== '');
          this.imageFileName = this.isImageAvailable ? this.selectedBannerContent.configurations.imageFile : '';
          this.selectedFormFactors = {
            allDevices: this.selectedBannerContent.configurations.formFactor.allDevices,
            manufacturer: this.selectedBannerContent.configurations.formFactor.manufacturer,
            devices: this.selectedBannerContent.configurations.formFactor.devices,
            processors: this.selectedBannerContent.configurations.formFactor.processors,
            generations: this.selectedBannerContent.configurations.formFactor.generations
          };
        } else {
          this.selectedFormFactors = {
            allDevices: true,
            manufacturer: '',
            devices: '',
            processors: '',
            generations: ''
          };
          this.bannerPreviewData = {
            image: {
              name: '',
              url: '',
              format: ''
            }
          }
        }
      });

    this.isSubmitted = false;
    this.isPublished = this.getPublishName();
    this.createBannerContentGroupForm();
    this.media = {
      url: '',
      name: '',
      format: '',
      uploadedDate: ''
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createBannerContentGroupForm() {
    // TODO -- need to add validations for form fields
    this.bannerDeleteButton = GlobalConstants.BANNER;
    this.bannerContentgrpForm = this.formBuilder.group({
      publishedName: new FormControl((this.selectedBannerContent) ? this.selectedBannerContent.configurations.publishedName : '',
        [Validators.required, Validators.maxLength(50)]),
      internalName: new FormControl((this.selectedBannerContent) ? this.selectedBannerContent.configurations.internalName : ''),
      imageFile: new FormControl('')
    });
  }


  trackByConfiguration(index: number, el: string): number {
    return index;
  }

  trackByCreateBanner(index: number, el: string): number {
    return index;
  }

  getPublishName(): boolean {
    return this.isSubmitted && this.bannerContentgrpForm?.controls?.publishedName?.value.trim() === '';

  }

  openDeleteModalBC() {
    this.imageUrl = '';
    this.media.uploadedDate = '';
    this.imageFileName = '';
    this.imageDoc = [];
    this.isImageAvailable = false;
    this.emitImageDataToPreview(this.imageDoc)
  }
  deleteStoreLogo(storeLogoId: string) {
    // TO DO
  }


  createBannerContent() {
    const bannerFactor: BannerFormFactor = {
      allDevices: this.selectedFormFactors.allDevices,
      manufacturer: this.selectedFormFactors.manufacturer,
      devices: this.selectedFormFactors.devices,
      processors: this.selectedFormFactors.processors,
      generations: this.selectedFormFactors.generations
    };
    const bannerContentConfig: BannerContentConfiguration = {
      publishedName: this.bannerContentgrpForm.controls.publishedName.value,
      internalName: this.bannerContentgrpForm.controls.internalName.value,
      imageFile: (this.imageDoc) ? this.imageDoc[0].name : this.imageFileName,
      type: GlobalConstants.BANNER_CONTENT_GROUP,
      contentGroupPages: [],
      customURL: '',
      formFactor: bannerFactor
    };
    this.bannerContentService.createBannerContent(JSON.stringify(bannerContentConfig))

      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createBannerCarouselResponse => {
        const createBannerCarouselResponseObj: CreateBannerContentResponse = createBannerCarouselResponse as CreateBannerContentResponse;
        this.bannerContentService.uploadImage(this.imageDoc, createBannerCarouselResponse.bannerCarousel.id,
          (this.imageDoc === !this.imageDoc) ? '' : this.imageDoc[0].name.split('.')
            .pop())
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(() => {
            this.goBacktoBannerContent.emit(createBannerCarouselResponseObj);
            this.cmnSer.emitMetadataCheck(true, this.bannerFunction);
          },
            error => {
              // do something
            });
      },
        error => {

        });

  }

  updateBannerContent() {
    const bannerCarousel = this.selectedBannerContent as unknown as BannerContent;
    const bannerFactor: BannerFormFactor = {
      allDevices: this.selectedFormFactors.allDevices,
      manufacturer: this.selectedFormFactors.manufacturer,
      devices: this.selectedFormFactors.devices,
      processors: this.selectedFormFactors.processors,
      generations: this.selectedFormFactors.generations
    };
    const bannerContentConfig: BannerContentConfiguration = {
      publishedName: this.bannerContentgrpForm.controls.publishedName.value,
      internalName: this.bannerContentgrpForm.controls.internalName.value,
      imageFile: (this.imageDoc) ? this.imageDoc[0].name : this.imageFileName,
      type: GlobalConstants.BANNER_CONTENT_GROUP,
      contentGroupPages: this.selectedBannerContent.configurations.contentGroupPages,
      customURL: '',
      formFactor: bannerFactor
    };
    bannerCarousel.configurations = JSON.stringify(bannerContentConfig);
    this.bannerContentService.putBannerContentById(JSON.stringify(bannerCarousel), this.selectedBannerContentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(updateBannerCarouselResponse => {
        const createBannerCarouselResponseObj: CreateBannerContentResponse = updateBannerCarouselResponse as CreateBannerContentResponse;
        const bannerCarouselImageViewModel: CreateBannerViewModel = updateBannerCarouselResponse.bannerCarousel as CreateBannerViewModel;
        this.bannerContentService.uploadImage(this.imageDoc, updateBannerCarouselResponse.bannerCarousel.id,
          (this.imageDoc) ? this.imageDoc[0].name.split('.') : this.imageFileName.split('.')
            .pop())
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(() => {
            this.bannerCarouselImages = [];
            bannerCarouselImageViewModel.imageData = this.imageDoc;
            bannerCarouselImageViewModel.fileName = (this.imageDoc) ? this.imageDoc[0].name.split('.') : this.imageFileName.split('.');
            this.bannerCarouselImages.push(bannerCarouselImageViewModel);
            this.refreshBannerContentdata.emit();
          },
            error => {
              // do something
            });
      },
        error => {

        });

  }


  cancel() {
    this.isSubmitted = false;
    this.goBacktoBannerContent.emit({ cancel: true });
    this.dataService.setBannerContent(undefined);
    this.cmnSer.uploadedImageURL = '';
    this.bannerFunction = "Content Pages";
  }
  onAdd() {
    this.isSubmitted = true;
    this.isFormValid = this.bannerContentgrpForm?.controls?.publishedName?.value.trim() !== '';
    if (this.isFormValid) {
      if (this.selectedBannerContentId) {
        this.updateBannerContent();
      } else {
        this.createBannerContent();
      }
    }
  }


  getDuration(e) {
    this.imageDoc = e.target.files;
    const max_size = 10048576; // TODO: Move to constants
    this.imageFileType = this.imageDoc[0].type;
    this.imageFileName = this.imageDoc[0].name;
    this.imageFileSize = this.imageDoc[0].size;
    this.imageSizeError = this.imageFileSize > max_size;
    this.imageTypeError = (this.imageFileType !== GlobalConstants.FILE_TYPE_IMAGE);
    this.isImageAvailable = !(this.imageSizeError || this.imageTypeError || (this.imageSizeError && this.imageTypeError));

  }

  processFile(event) {
    this.imageDoc = event.target.files;
    if (this.imageDoc) {
      const reader = new FileReader();
      reader.readAsDataURL(this.imageDoc[0]);
      this.imageFileName = this.imageDoc[0].name;
      this.imageFileType = this.imageDoc[0].type;
      this.imageFileSize = this.imageDoc[0].size;
      this.media.uploadedDate = new Date();
      const fileType = this.imageDoc[0].type.split('/')[1];
      if (this.imageFileType.indexOf(GlobalConstants.FILE_TYPE_IMAGE) > -1) {
        this.imageSizeError = this.imageFileSize > 3145728;
        if (fileType === GlobalConstants.CONTENT_TYPE_PNG ||
          fileType === GlobalConstants.CONTENT_TYPE_GIF ||
          fileType === GlobalConstants.CONTENT_TYPE_JPEG) {
          this.imageTypeError = false;
        } else { this.imageTypeError = true; }
        this.isImageAvailable = !(this.imageSizeError || this.imageTypeError || (this.imageSizeError && this.imageTypeError));
        this.isImageFormat = true;
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imageDoc[0]));
      } else {
        this.imageTypeError = true;
        this.isImageFormat = false;
        //this.imageUrl = '';
      }
      if (this.imageSizeError || this.imageTypeError) {
        this.imageUrl = '';
      } else {
        reader.onload = _event => {
          this.imageUrl = reader.result;
          this.emitImageDataToPreview(this.imageDoc[0]);
        };
      }
    }
  }

  emitImageDataToPreview(imageDoc) {
    this.bannerPreviewData.image.name = imageDoc ? imageDoc.name : '';
    this.bannerPreviewData.image.url = imageDoc ? this.imageUrl : '';
    this.bannerPreviewData.image.format = imageDoc ? imageDoc.type : '';
    this.cmnSer.emitImageData(this.bannerPreviewData);
  }

  openModal(modal: any) {
    modal.open();
  }

  closeModal(modal: any) {
    modal.close();
  }

  openFormFactorsModal() {
    this.openModal(this.formFactorsModal);
  }
  applyFormFactor(updatedFormFactors) {
    this.selectedFormFactors = updatedFormFactors;
    this.closeModal(this.formFactorsModal);
  }
}
