import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalConstants } from '../../common/constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private layoutSub = new Subject<any>();
  private pageSub = new Subject<any>();
  private pageDataTologoSub = new Subject<any>();
  private imageDataTobannerSub = new Subject<any>();
  private pageDataTobannerSub = new Subject<any>();
  private metaDataInfoCheckSub = new Subject<any>();
  private emitDataToProfileSub = new Subject<any>();
  private logoContentIdSub = new Subject<string>();
  private emitPromoDataToProfileSub = new Subject<any>();
  public uploadedImageURL: string;
  private emitDataSlToProfileSub = new Subject<any>();
  private emitLivePreviewDataToProfileSub = new Subject<any>();
  private tabIndexSub = new Subject<any>();

  layoutObser = this.layoutSub.asObservable();
  pageObser = this.pageSub.asObservable();
  pageDataTologoObser = this.pageDataTologoSub.asObservable();
  imageDataToBannerObser = this.imageDataTobannerSub.asObservable();
  pageDataTobannerObser = this.pageDataTobannerSub.asObservable();
  metaDataInfoObser = this.metaDataInfoCheckSub.asObservable();
  emitDataToProfileObser = this.emitDataToProfileSub.asObservable();  
  logoContentIdObser = this.logoContentIdSub.asObservable();  
  emitPromoDataToProfileObser = this.emitPromoDataToProfileSub.asObservable();
  emitDataSlToProfileObser = this.emitDataSlToProfileSub.asObservable();
  emitLivePreviewDataToProfileObser = this.emitLivePreviewDataToProfileSub.asObservable();
  tabIndexObser = this.tabIndexSub.asObservable();

  constructor() { }
  // Function to emit data from layout(log-content componet) to PAGE(page component) tab
  emitLayout(selected) {
    this.layoutSub.next(selected);
  }
  // Function to emit data from PAGE(page componet) to live-preview component
  emitPageData(contentGrpType, pageNum, data, onlytopreview?: string) {
    this.pageSub.next(data);
    if ((contentGrpType === GlobalConstants.LOGO_CONTENT_GROUP || contentGrpType === GlobalConstants.PROMO_CONTENT_GROUP ||
      contentGrpType === GlobalConstants.PRICE_CONTENT_GROUP || contentGrpType === GlobalConstants.SEGMENTS) && !onlytopreview) {
      // emit logocontent/promo/pricegroup
      this.pageDataTologoSub.next({ page: contentGrpType, number: pageNum, data });
    }
    if (contentGrpType === GlobalConstants.BANNER_CONTENT_GROUP) {
      // emit page data to banner content
      this.pageDataTologoSub.next({ page: contentGrpType, number: pageNum, data });
    }
  }

  //Function to emit URL data from PAGE(page component) to BC component
  emitURLData(contentGrpType, data) {
    //this.pageSub.next(data);
    if (contentGrpType === GlobalConstants.BANNER_CONTENT_GROUP) {
      this.pageDataTobannerSub.next({ page: contentGrpType, data });
    }
  }

  //Fuction to emit created LogoContentId to profile for patch
  emitLogoContentId(id : string) {
    this.logoContentIdSub.next(id);
  }

  //Function to emit image data from PAGE(page component) to banner-preview component
  emitImageData(data) {
    //this.pageSub.next(data);
    this.imageDataTobannerSub.next(data);
  }

  emitMetadataCheck(isMetadataAdded, bannerFunction) {
    this.metaDataInfoCheckSub.next({ flag: isMetadataAdded, type: bannerFunction });
  }

  // to send data from profil-> logo content groups to 'create profile'
  emitDataToProfile(data: any) {
    this.emitDataToProfileSub.next(data);
  }

  emitPromoDataToProfile(data: any) {
    this.emitPromoDataToProfileSub.next(data);
  }
  // to send data from -> profile store logo (common folder) to 'create profile'
  emitSlDataToProfile(data: any) {
    this.emitDataSlToProfileSub.next(data);
  }

  emitLivePreviewDataToProfile(data: any) {
    this.emitLivePreviewDataToProfileSub.next(data);
  }

  emitTabIndex(data: any) {
    this.tabIndexSub.next(data);
  }
}
