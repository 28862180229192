import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterConstants } from '../../routing/constants/app-paths.constant';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (sessionStorage.getItem('userId')) {
            // user is logged in, so return true
            return true;
        }

        // user is not logged in, so redirect to login page with the return url
        this.router.navigate([RouterConstants.LOGIN_PAGE_LINK], { queryParams: { returnUrl: state.url }});

        return false;
    }
}
