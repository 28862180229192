<!-- Login page content -->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <a class="central_logo" [href]="loginhref"> <img class="img-fluid" alt="IPOS Central LOGO" /> </a>
      <!-- Login Section -->
      <div class="card login_card">
        <form [formGroup]="loginForm" class="login-container" (change)="loginBtn()" (keyup)="loginBtn()"
          (ngSubmit)="login()">
          <div class="form_header" [innerHTML]="logIn"></div>
          <div class="form-group">
            <label for="email" [innerHTML]="emailAddress"></label>
            <input [ngClass]="{
              'has-error':
                isSubmitted && formControls.email.errors
            }" class="form-control" type="email" maxlength="50" [placeholder]="placeholderEmail"
              formControlName="email" required>
            <em class="err_icon" *ngIf="isSubmitted && loginForm.controls.email.errors"></em>
            <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
              <div id="emailErrMsg" class="formfield_err_msg" *ngIf="loginForm.controls.email.errors.required"
                [innerHTML]="requiredField"></div>
              <div class="formfield_err_msg" *ngIf="loginForm.controls.email.errors.pattern" [innerHTML]="validEmailId">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="password" [innerHTML]="passwordField"></label>

            <input [ngClass]="{
              'has-error':
                isSubmitted && formControls.password.errors
            }" class="form-control" [type]="fieldTextType ? 'text' : 'password'" [placeholder]="placeholderPassword"
              maxlength="30" formControlName="password" id="password" required>
            <em class="err_icon" *ngIf="isSubmitted && formControls.password.errors"></em>
            <fa-icon *ngIf="formControls.password.value" class="input-icon" [icon]="displayEye" [ngClass]="{
              'eye-slash': fieldTextType,
              'eye': !fieldTextType
            }" (click)="toggleFieldTextType()"></fa-icon>

            <span *ngIf="isSubmitted && formControls.password.errors" class="help-block">
              <span id="pwdErrMsg" class="formfield_err_msg" *ngIf="formControls.password.errors.required"
                [innerHTML]="requiredField"></span>
            </span>
          </div>
          <a class="forgot_password_href gtmForgotPassword" (click)="open()" [innerHTML]="forgotPassword">
          </a>
          <div *ngIf="messageString" [class]="alertFormat">
            {{ messageString }}
          </div>


          <div class="login_btn">
            <button type="submit" class="btn btn-primary gtmLogin" [disabled]="!loginButton">Log In</button>

          </div>
        </form>
      </div>

      <div class="card create_account_card">
        <div class="form_header" [innerHTML]="createAccount"></div>
        <p class="ipos_central_p" [innerHTML]="description">
        </p>
        <div class="create_account_btn">
          <button type="button" class="btn btn-outline-primary gtmCrtAcntLoginPg" (click)="redirectToLanguage()"
            [innerHTML]="createAccButton">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>