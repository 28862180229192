import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectLangComponent } from './common/components/select-lang/select-lang.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { AccountCreatedComponent } from './modules/account/confirm-registration/account-created/account-created.component';
import { ConfirmRegistrationComponent } from './modules/account/confirm-registration/confirm-registration.component';
import { CreateAccountComponent } from './modules/account/create-account/create-account.component';
import { EmailSentComponent } from './modules/account/forgot-pwd/email-sent/email-sent.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ForgotPwdComponent } from './modules/account/forgot-pwd/forgot-pwd.component';
import { NewPasswordComponent } from './modules/account/forgot-pwd/new-password/new-password.component';
import { ResetPwdComponent } from './modules/account/forgot-pwd/new-password/reset-pwd/reset-pwd.component';
import { LoginComponent } from './modules/account/login/login.component';
import { LogoContentComponent } from './modules/asset-library/logo-content/logo-content.component';
import { HomeComponent } from './modules/home/home.component';
import { AssetLibraryComponent } from './modules/asset-library/asset-library.component';
import { CreateProfileComponent } from './modules/create-profile/create-profile.component';
import { UsersComponent } from './modules/users/users/users.component';


const routes: Routes = [{
  path: '',
  component: LoginComponent
},

{
  path: 'login',
  redirectTo: 'login'
},
{
  path: 'create-Account',
  component: CreateAccountComponent
},
{
  path: 'reset-Password',
  component: ResetPwdComponent
},
{
  path: 'select-language/:pageName',
  component: SelectLangComponent
},
{
  path: 'new-password/:email',
  component: NewPasswordComponent
},
{
  path: 'asset-library',
  component: AssetLibraryComponent,
  canActivate: [AuthGuard]
},
{
  path: 'profile',
  component: ProfileComponent,
  canActivate: [AuthGuard]
},

{
  path: 'home',
  component: HomeComponent,
  canActivate: [AuthGuard]
},
{
  path: 'confirm-registration/:email',
  component: ConfirmRegistrationComponent
},
{
  path: 'account-created',
  component: AccountCreatedComponent
},
{
  path: 'profile/create',
  component: CreateProfileComponent,
  canActivate: [AuthGuard]
},
{
  path: 'users/retailhqs',
  component: UsersComponent,
  canActivate: [AuthGuard]
},
{
  path: 'users/stores',
  component: UsersComponent,
  canActivate: [AuthGuard]
},

{
  path: 'users/activeusers',
  component: UsersComponent,
  canActivate: [AuthGuard]
},



{
  path: '**',
  redirectTo: ''
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
