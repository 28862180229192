import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalConstants } from 'src/app/common/constants/global.constant';
import { CommonService } from '../../../../common/services/common.service';
import { BannerPreview } from '../interface/create-banner-carousel.i';

@Component({
  selector: 'iposcen-banner-function',
  templateUrl: './banner-function.component.html',
  styleUrls: ['./banner-function.component.scss']
})
export class BannerFunctionComponent implements OnInit, OnDestroy {
  @ViewChild('confirmModal') confirmModal: ElementRef;
  @Input() sidenav;
  @Output() readonly selectedOption = new EventEmitter<string>();
  @Output() readonly goBacktoBannerContent = new EventEmitter<string>();
  bannerPreviewData: BannerPreview;
  isListPreview: boolean;
  isContentPagesChecked = true;
  isURLChecked = false;
  isMetaData = false;
  bgColor: string;
  previewBgColor: string;
  imageURL: string;
  currentOption: string;
  title: string;
  description: string;
  action: string;
  bannerFunction = 'Default';
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private cmnSer: CommonService) { }

  ngOnInit(): void {
    this.currentOption = GlobalConstants.CONTENT_PAGES;
    this.imageURL = '';
    this.isListPreview = false;
    this.previewBgColor = '#0095CA';
    this.cmnSer.imageDataToBannerObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.bannerPreviewData = data;
        this.imageURL = this.bannerPreviewData.image.url;
      });

    this.cmnSer.metaDataInfoObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isMetaData = res.flag;
        this.bannerFunction = res.type;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  selectBannerFunction(option) {
    this.currentOption = option;
    if (this.isMetaData) {
      if (this.currentOption === GlobalConstants.CONTENT_PAGES && this.bannerFunction ===  GlobalConstants.URL_EXPERIENCE) {
        this.isContentPagesChecked = true;
        this.isURLChecked = false;
        this.title = 'Add Content Pages';
        this.description = 'This will remove the URL experience from this group';
        this.action = 'Add Content pages';
        this.openModal(this.confirmModal);
      } else if (this.currentOption === GlobalConstants.URL_EXPERIENCE && this.bannerFunction === GlobalConstants.CONTENT_PAGES) {
        this.isContentPagesChecked = false;
        this.isURLChecked = true;
        this.title = 'Add URL Experience';
        this.description = 'This will remove the content pages from this group';
        this.action = 'Add URL Experience';
        this.openModal(this.confirmModal);
      }
    } else {
      this.selectedOption.emit(this.currentOption);
    }
  }

  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  confirmBannerOption() {
    if (this.isContentPagesChecked && this.bannerFunction === GlobalConstants.URL_EXPERIENCE) {
      this.isContentPagesChecked = true;
      this.isURLChecked = false;
    } else if (this.isURLChecked && this.bannerFunction === GlobalConstants.CONTENT_PAGES) {
      this.isContentPagesChecked = false;
      this.isURLChecked = true;
    }
    this.goBacktoBannerContent.emit(this.currentOption);
    this.closeModal(this.confirmModal);
  }

  cancelModal() {
    if (this.isContentPagesChecked && this.bannerFunction === GlobalConstants.URL_EXPERIENCE) {
      this.isContentPagesChecked = false;
      this.isURLChecked = true;
    } else if (this.isURLChecked && this.bannerFunction === GlobalConstants.CONTENT_PAGES) {
      this.isContentPagesChecked = true;
      this.isURLChecked = false;
    }
  }
}
