import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from '../../../../app/common/constants/global.constant';
import { SegmentsConfiguration, CreateSegmentsResponse, SegmentViewModel, Segment } from '../segments/interfaces/segments.i';
import { SegmentsService } from '../../../core/routing/services/asset-library/segments.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { first } from 'rxjs/internal/operators/first';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';

@Component({
  selector: 'iposcen-create-segments',
  templateUrl: './create-segments.component.html',
  styleUrls: ['./create-segments.component.scss']
})
export class CreateSegmentsComponent implements OnInit, OnDestroy {
  @Output() readonly goBacktoSegmentContent = new EventEmitter<object>();
  @ViewChild('colorpicker') colPicModal: ElementRef;
  @Input() pageNumber: number;
  @Input() contentGroupType: string;
  segmentThemeForm;
  isPublishedDateInCorrect: boolean;
  isExpiredDateIncorrect: boolean;
  isSubmitted: boolean;
  isFormValid: boolean;
  segmentsForm: FormGroup;
  isEdit: boolean;
  selectedSegmentId: string;
  selectedSegment: SegmentViewModel;
  dateTimeValue: string;
  private modalRef: NgbModalRef;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private segmentsService: SegmentsService,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.dateTimeValue = '';
    this.dataService.getSegment()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((segmentToEdit: any) => {
        if (segmentToEdit) {
          this.isEdit = true;
          this.selectedSegment = segmentToEdit;
          this.selectedSegmentId = segmentToEdit.id;
        }
      });
    this.prepareSegmentThemeForm();
    this.isSubmitted = false;
    this.createSegmentGroupForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createSegmentGroupForm() {
    // TODO -- need to add validations for form fields
    this.segmentsForm = this.formBuilder.group({
      headline: new FormControl((this.selectedSegment) ? this.selectedSegment?.configurations?.publishedName : '',
        [Validators.required, Validators.maxLength(50)]),
      subHeadline: new FormControl((this.selectedSegment) ? this.selectedSegment?.configurations?.internalName : '',
        [Validators.maxLength(50)]),
      retailerName: new FormControl((this.selectedSegment) ? this.selectedSegment?.configurations?.retailerName : '',
        [Validators.maxLength(50)]),
      publishedTimeStamp: new FormControl((this.selectedSegment) ? this.selectedSegment?.configurations?.publishTime : ''),
      expiryTimeStamp: new FormControl((this.selectedSegment) ? this.selectedSegment?.configurations?.expireTime : ''),
      isApplyToIpos: new FormControl((this.selectedSegment) ? this.selectedSegment?.configurations?.isApplyToIpos : false)
    });
  }

  prepareSegmentThemeForm() {
    this.segmentThemeForm = {
      bgcolor: this.selectedSegment ? this.selectedSegment?.configurations?.segmentBgColor : '#0068B5',
      hcolor: this.selectedSegment ? this.selectedSegment?.configurations?.segmentFontColor : '#ffffff'
    };
  }

  chooseTheme(fontColor: string, bgColor: string) {
    [
      this.segmentThemeForm.bgcolor,
      this.segmentThemeForm.hcolor
    ] =
      [
        bgColor,
        fontColor
      ];
    this.dismiss();
  }

  fontSize(size) {
    if (size === GlobalConstants.SMALL) { return GlobalConstants.FONTSIZE_12PX; }
    if (size === GlobalConstants.MEDIUM) { return GlobalConstants.FONTSIZE_14PX; }
    if (size === GlobalConstants.LARGE) { return GlobalConstants.FONTSIZE_16PX; }
  }

  isSelected(hcolor, bgcolor) {
    if (this.segmentThemeForm.hcolor === hcolor && this.segmentThemeForm.bgcolor === bgcolor) {
      return true;
    }

    return false;
  }

  openColPicModal() {
    this.modalRef = this.modalService.open(this.colPicModal);
    this.modalRef.result.then();
  }

  dismiss() {
    this.modalRef.close();
  }

  checkPublishedDateTime() {
    this.isSubmitted = false;
    const today = new Date();
    let isPublishedDateSmaller;
    if (this.segmentsForm.controls.publishedTimeStamp.toString() !== '') {
      isPublishedDateSmaller = new Date(this.segmentsForm.controls.publishedTimeStamp.value).getTime() < new Date().getTime();
    }

    this.isPublishedDateInCorrect = isPublishedDateSmaller;
  }

  // check if expiry date is smaller than published date
  checkExpiryDateTime() {
    this.isSubmitted = false;
    let expiryDateSmaller = false;
    if (this.segmentsForm.controls.expiryTimeStamp.value !== '') {
      expiryDateSmaller = Date.parse(this.segmentsForm.controls.expiryTimeStamp.value) <
        Date.parse(this.segmentsForm.controls.publishedTimeStamp.value);
    }

    this.isExpiredDateIncorrect = expiryDateSmaller;
  }

  onAdd() {
    this.isSubmitted = true;
    this.isFormValid = !this.isPublishedDateInCorrect &&
                        !this.isExpiredDateIncorrect &&
                        this.segmentsForm?.controls?.headline?.value.trim() !== ''
                        && !this.segmentsForm?.controls?.headline?.errors?.maxlength;
    if (this.isFormValid) {
      if (this.selectedSegmentId) {
        this.updateSegment();
      } else {
        this.createSegment();
      }
    }
  }

  createSegment() {
    const segment: SegmentsConfiguration = {
      publishedName: this.segmentsForm.controls.headline.value,
      internalName: this.segmentsForm.controls.subHeadline.value,
      retailerName: this.segmentsForm.controls.retailerName.value,
      segmentBgColor: this.segmentThemeForm.bgcolor,
      segmentFontColor: this.segmentThemeForm.hcolor,
      publishTime: this.segmentsForm.controls.publishedTimeStamp && this.segmentsForm.controls.publishedTimeStamp.value !== '' ?
        this.segmentsForm.controls.publishedTimeStamp.value : new Date().toString(),
      expireTime: this.segmentsForm.controls.expiryTimeStamp && this.segmentsForm.controls.expiryTimeStamp.value !== '' ?
        this.segmentsForm.controls.expiryTimeStamp.value : GlobalConstants.NEVER,
      contentScheduleTimezoneCode: 'CST',
      type: GlobalConstants.SEGMENTS,
      contentGroupPages: [],
      formFactor: undefined,
      isApplyToIpos: this.segmentsForm.controls.isApplyToIpos.value
    };
    this.segmentsService.createSegment(JSON.stringify(segment))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createSegmentsResponse => {
        const createSegmentsResponseObj: CreateSegmentsResponse = createSegmentsResponse as CreateSegmentsResponse;
        this.goBacktoSegmentContent.emit(createSegmentsResponseObj);
      },
        error => {
        }
      );
  }

  cancel() {
    this.isSubmitted = false;
    this.goBacktoSegmentContent.emit({ cancel: true });
    this.dataService.setSegment(undefined);
  }

  updateSegment() {
    const segment = this.selectedSegment as unknown as Segment;
    const segmentConfig: SegmentsConfiguration = {
      publishedName: this.segmentsForm.controls.headline.value,
      internalName: this.segmentsForm.controls.subHeadline.value,
      retailerName: this.segmentsForm.controls.retailerName.value,
      segmentBgColor: this.segmentThemeForm.bgcolor,
      segmentFontColor: this.segmentThemeForm.hcolor,
      publishTime: this.segmentsForm.controls.publishedTimeStamp?.value,
      expireTime: this.segmentsForm.controls.expiryTimeStamp?.value,
      contentScheduleTimezoneCode: 'CST',
      formFactor: undefined,
      type: GlobalConstants.SEGMENTS,
      contentGroupPages: this.selectedSegment.configurations.contentGroupPages,
      isApplyToIpos: this.segmentsForm.controls.isApplyToIpos.value
    };
    segment.configurations = JSON.stringify(segmentConfig);
    this.segmentsService.editSegmentById(JSON.stringify(segment), this.selectedSegmentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putSegmentResponse => {
        const putSegmentResponseObj: CreateSegmentsResponse = putSegmentResponse as CreateSegmentsResponse;
        this.goBacktoSegmentContent.emit(putSegmentResponseObj);
      },
        error => {
        });
  }

}
