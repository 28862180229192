import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { ProfileAPI } from '../profile_api/constants/profile.constant';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  userId = sessionStorage.getItem('userId');
  userIds = 'userid4@ipos.com';
  PROFILE_URL = ProfileAPI.SLASH_PROFILE + ProfileAPI.SLASH_USER_ID + ProfileAPI.SLASH + this.userIds + ProfileAPI.PROFILES_URL;
  constructor(private httpClient: HttpClient,
    private httpMethod: HttpMethodsService) { }

  getAllProfilesByUser() {
    const url = this.PROFILE_URL;

    return this.httpMethod.get(url)
    .pipe(map((res: any) =>
    res));
  }
}
