import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { CommonService } from '../../../common/services/common.service';
import { AttractorLoopService } from '../../../core/routing/services/asset-library/attractor-loop.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { RouterConstants } from './../../../core/routing/constants/app-paths.constant';
import { AttractorLoop, AttractorLoopConfiguration } from './interfaces/attractor-loop.i';
import { AssetLibraryConstants } from '../../../core/routing/services/asset-library/constants/asset-library.constant';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'iposcen-attractor-loop',
  templateUrl: './attractor-loop.component.html',
  styleUrls: ['./attractor-loop.component.scss']
})
export class AttractorLoopComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal') deleteModal: ElementRef;
  userId = sessionStorage.getItem('userId');
  configurations: AttractorLoopConfiguration[];
  displayAttractorLoop = [];
  allAttractorLoop: AttractorLoop[];
  tabIndex = 0;
  // sideNav = 'store';
  sortValue = 'Create Date';
  page = 1;
  pageSize = 5;
  attractorLoopToDelete: string;
  attractorLoopToDeleteName: string;
  attractorLoopData: object;
  pageGrpForm: any;
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
  + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private router: Router,
    private cmnService: CommonService,
    private dataService: DataService,
    private attractorLoopService: AttractorLoopService
  ) { }

  ngOnInit(): void {
    this.getAllAttractorLoopByUser();
  }

  // setSideNav(value: string) {
  //   this.sideNav = value;
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAllAttractorLoopByUser() {
    this.attractorLoopService.getAllAttractorLoopByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllAttractorLoopResponse => {
        this.allAttractorLoop = (getAllAttractorLoopResponse.attractLoop as AttractorLoop[]);
        this.configurations = [];
        this.allAttractorLoop.forEach(attractorLoopGroup => {
          this.configurations.push(JSON.parse(attractorLoopGroup.configurations) as AttractorLoopConfiguration);
        });

        // display Attractor loop using displayAttractorLoop var as configurations is coming as string
        this.displayAttractorLoop = [];
        this.allAttractorLoop.forEach(ele => {
          ele.configurations = JSON.parse(ele.configurations);
          ele.createdByUser = (ele.createdByUser === this.userId) ? GlobalConstants.ME : ele.createdByUser;
          this.displayAttractorLoop.push(ele);
        });
        this.displayAttractorLoop.forEach(element => {
          element.isExpanded = false;
          if (element.updateTime) {
            element.updateTime = new Date(element.updateTime);
          }
        });
        this.sortByDate();
      },
        error => {
          // do something
        });
  }

  cancel() {
    this.dataService.setAttractorLoop(undefined);
    this.setIndex(0);
  }

  editAttractorLoop(attractorLoopID: string) {
    const attractorLoopToEdit = this.displayAttractorLoop.find(ele => ele.id === attractorLoopID);
    this.dataService.setAttractorLoop(attractorLoopToEdit);
    this.setIndex(1);
  }

  setIndex(value: number) {
    this.tabIndex = value;
  }

  /* Delete attrat loop content */
  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openDeleteModal(attractorLoopID: string) {
    this.attractorLoopToDelete = this.displayAttractorLoop.find(ele => ele.id === attractorLoopID).id;
    this.attractorLoopToDeleteName = this.displayAttractorLoop.find(ele => ele.id === attractorLoopID).configurations.publishedName;
    this.openModal(this.deleteModal);
  }

  deleteAttractorLoop(attractorLoopID: string) {
    this.attractorLoopService.deleteAttractorLoopById(attractorLoopID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {
        const index = this.displayAttractorLoop.findIndex(d => d.id === attractorLoopID); // find index in your array
        if(index >= 0) {
          this.displayAttractorLoop.splice(index, 1); // remove element from array
        }
        this.closeModal(this.deleteModal);
      },
        error => {
          // do something
          this.closeModal(this.deleteModal);
        });
  }
  loadMiniPreview(gId: string, pageContent: any) {
    this.pageGrpForm = {
      bgcolor: pageContent ? pageContent.bgColor : '#0068B5',
      heading:  pageContent ? pageContent.title : '',
      hcolor: pageContent ? pageContent.titleFontColor : '#ffffff',
      body: pageContent ? pageContent.bodyCopy : '',
      bcolor: pageContent ? pageContent.bodyCopyFontColor : '#ffffff',
      size: pageContent ? this.getFontSize(pageContent.bodyCopyFontSize) : GlobalConstants.MEDIUM,
      media: {
        url: (pageContent && pageContent.linkedAsset) ?
        environment.apiBaseUrlAsset + this.url + AssetLibraryConstants.ATTRACTOR_LOOP + AssetLibraryConstants.SLASH +
		gId + AssetLibraryConstants.SLASH + pageContent.pageNum.toString() : '',
        name: '',
        format: (pageContent && pageContent.linkedAsset) ? (pageContent.linkedAssetFileType) ? pageContent.linkedAssetFileType : '' : '',
        fileObj: ''
      },
      id: gId,    // adding created group id,
      layout: pageContent ? this.getUILayoutIDByName(pageContent.UiLayout) : 1
    };

    return this.pageGrpForm;
  }
  getFontSize(size: number ) {
    if (size === GlobalConstants.NUMBER_12) { return GlobalConstants.SMALL; }
    if (size === GlobalConstants.NUMBER_14) { return GlobalConstants.MEDIUM;}
    if (size === GlobalConstants.NUMBER_16) { return GlobalConstants.LARGE; }
  }
  getUILayoutIDByName(name: string) {
    if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
      return 1;
    } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
      return 2;
    } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
      return 3;
    }
  }
  setCreatedGrpID(data) {
    if (typeof data === 'object' && data.cancel) {
      this.setIndex(0);
    } else {
      this.attractorLoopData = data;
      this.setIndex(2);
    }
  }

  refreshAttractorLoopdata() {
    this.getAllAttractorLoopByUser();
    this.setIndex(0);
  }

  getDetailsOfAttractorLoop(id: string) {
    this.displayAttractorLoop.forEach(element => {
      if (element.id === id) {
        element.isExpanded = !element.isExpanded;
      }
    });
  }

  sortByDate() {
    this.sortValue = 'Created Date';
    this.displayAttractorLoop.sort((loop1, loop2) => loop2.updateTime - loop1.updateTime);

  }

  sortByName() {
    this.sortValue = 'Group Name';
    this.displayAttractorLoop.sort((loop1, loop2) =>
      loop1.configurations.publishedName.localeCompare(loop2.configurations.publishedName));
  }

  createNewAttractLoop() {
    this.dataService.setAttractorLoop(undefined);
    this.tabIndex = 1;
  }

}
