import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { CognitoUtil } from '../cognito/cognito.service';
import { CognitoCallback, LoggedInCallback } from '../../interfaces/cognito.i';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import STS from 'aws-sdk/clients/sts';
import { LoginServiceConstants } from '../../../routing/services/login/constants/login-service.constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(public cognitoUtil: CognitoUtil) {
  }

  onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

    AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken()
      .getJwtToken());

    // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
    // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
    // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
    // security credentials. The identity is then injected directly into the credentials object.
    // If the first SDK call we make wants to use our IdentityID, we have a
    // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
    // very innocuous API call that forces this behavior.
    const clientParams: any = {};
    if (environment.sts_endpoint) {
      clientParams.endpoint = environment.sts_endpoint;
    }
    const sts = new STS(clientParams);
    sts.getCallerIdentity((err, data) => {
      callback.cognitoCallback(undefined, session);
    });
  };

  onLoginError = (callback: CognitoCallback, err) => {
    callback.cognitoCallback(err.message, undefined);
  };

  authenticate(username: string,
    password: string,
    callback: CognitoCallback) {

    const authenticationData = {
      Username: username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        callback.cognitoCallback(LoginServiceConstants.USER_SET_PASSWORD, undefined);
      },
      onSuccess: result => {
        this.onLoginSuccess(callback, result);
      },
      onFailure: err => {
        this.onLoginError(callback, err);
      },
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => {
              this.onLoginSuccess(callback, result);
            },
            onFailure: err => {
              this.onLoginError(callback, err);
            }
          });
        });
      }
    });
  }
  forgotPassword(username: string, callback: CognitoCallback) {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (): void => {

      },
      onFailure: err => {
        callback.cognitoCallback(err.message, undefined);
      },
      inputVerificationCode() {
        callback.cognitoCallback(undefined, undefined);
      }
    });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => {
        callback.cognitoCallback(undefined, undefined);
      },
      onFailure: err => {
        callback.cognitoCallback(err.message, undefined);
      }
    });
  }

  logout() {
    this.cognitoUtil.getCurrentUser()
      .signOut();

  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback === undefined) {
      throw new Error(LoginServiceConstants.AUTHENTICATION_ERROR);
    }

    const cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser !== undefined) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          callback.isLoggedIn(err, false);
        } else {
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      callback.isLoggedIn(LoginServiceConstants.LOGGED_IN_ERROR, false);
    }
  }
}
