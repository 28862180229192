<div *ngIf="showOrHidePreview">
  <div class="live_preview">
  <!-- Preview container specifies size of preview window -->
  <div class="preview__container">
    <!-- Preview Resizer maintains aspect ratio of preview to 16:9 -->
    <div class="preview__resizer">
      <!-- This wrapper encases all preview content -->
      <div class="preview__wrapper">
        <!-- Preview has 2 sections: Main Layout and Footer -->

        <!-- Main Layout: Contains Sidebar and Navbar, Tiles -->
        <div class="main_layout">
          <!-- Sidebar with fact-tags -->
          <div class="sidebar">
            <div class="fact_tags">
              <img src="/assets/images/ftag_proc.png" alt="proc ftag" />
              <img src="/assets/images/ftags.png" alt="ftags" />
              <img src="/assets/images/ftags.png" alt="ftags" />
              <img src="/assets/images/ftags.png" alt="ftags" />
              <img src="/assets/images/ftags.png" alt="ftags" />
              <img src="/assets/images/ftags.png" alt="ftags" />
            </div>
            <img
              class="ftag_scroll"
              src="/assets/images/ftag_scroll_btn.svg"
              alt="scroll"
            />
          </div>

          <!-- Main section with top navbar and tiles -->
          <div class="tiles_section">
            <div class="top_nav">
              <img src="/assets/images/preview_top_nav.svg" alt="navbar" />
            </div>
            <div class="tiles">
              <!-- Tile 1 -->
              <div class="herotile">
                <img
                  style="max-width: 16.3%"
                  src="/assets/images/preview_herotile_vector.svg"
                  alt="tile 1"
                />
              </div>
              <div class="small_tiles">
                <!-- Tile 2 -->
                <div class="card">
                  <div class="card-body">
                    <img
                      src="/assets/images/preview_tiles_vector.svg"
                      alt="tile 2"
                    />
                  </div>
                  <div class="card-footer">
                    <img
                      src="/assets/images/preview_tiles_caption.svg"
                      alt="tile 2 caption"
                    />
                  </div>
                </div>

                <!-- Tile 3 -->
                <div class="card banner_tile_preview">
                  <div class="card-body">
                    <svg
                      width="30.72%"
                      height="23"
                      viewBox="0 0 47 23"
                      fill="#e9e9e9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4.20082" cy="4.20082" r="4.20082" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.747 19.4657H46.9998L30.6585 0.191406L18.5161 14.5132L21.2443 18.841L18.0421 15.0723L18.0359 15.0796L11.1747 6.98685L0.594727 19.4657H1.747Z"
                      />
                      <rect
                        x="0.594727"
                        y="20.8223"
                        width="46.4051"
                        height="1.18987"
                      />
                    </svg>
                  </div>
                  <div class="card-footer">
                    <svg
                      width="84"
                      height="8"
                      viewBox="0 0 84 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="3.5"
                        width="70"
                        height="2"
                        rx="1"
                        fill="#AEAEAE"
                        stroke="#AEAEAE"
                      />
                      <path d="M80 1L83 4.5L80 7.5" stroke="#AEAEAE" />
                    </svg>
                  </div>
                </div>

                <!-- Tile 4 -->
                <div class="card">
                  <div class="card-body">
                    <img
                      src="/assets/images/preview_tiles_vector.svg"
                      alt="tile 2"
                    />
                  </div>
                  <div class="card-footer">
                    <img
                      src="/assets/images/preview_tiles_caption.svg"
                      alt="tile 2 caption"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Main Layout ends here -->

        <!-- Footer -->
        <footer class="footer">
          <div class="retailer_logo" [ngStyle]="{'background-color':bgColor}">
            <img [src]="defaultLogoImage" alt="logo" />
          </div>
          <div class="inline_separator"></div>
          <div class="price">
            <span class="deleted_text"><del>$2099.00</del></span> &nbsp;
            <span class="new_price">$1899.00</span>
          </div>
          <div class="inline_separator"></div>
          <div class="processor">
            <img src="/assets/images/preview_footer_processor.svg" alt="proc" />
          </div>
          <div class="explore_pc">
            <!-- Segment text and icon -->
            <svg
              width="41"
              height="13"
              viewBox="0 0 41 13"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.58895 4.7172L5.41427 8.89189L4.21875 7.69637L4.98478 6.93034L5.41427 7.35982L8.82292 3.95117L9.58895 4.7172Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.4987 2.16732C4.10546 2.16732 2.16536 4.10742 2.16536 6.50065C2.16536 8.89389 4.10546 10.834 6.4987 10.834C8.89193 10.834 10.832 8.89389 10.832 6.50065C10.832 4.10742 8.89193 2.16732 6.4987 2.16732ZM1.08203 6.50065C1.08203 3.50911 3.50716 1.08398 6.4987 1.08398C9.49024 1.08398 11.9154 3.50911 11.9154 6.50065C11.9154 9.49219 9.49024 11.9173 6.4987 11.9173C3.50716 11.9173 1.08203 9.49219 1.08203 6.50065Z"
              />
              <rect
                x="3.25"
                y="6.72461"
                width="1.08333"
                height="1.08333"
                transform="rotate(-45 3.25 6.72461)"
                fill="#00C7FD"
              />
              <line
                x1="18"
                y1="7"
                x2="40"
                y2="7"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <!-- Explore PC text and icon -->
            <svg
              width="41"
              height="15"
              viewBox="0 0 41 15"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.1683 9.98658C37.1273 9.94573 37.0948 9.89719 37.0726 9.84376C37.0505 9.79033 37.0391 9.73305 37.0391 9.67519C37.0391 9.61734 37.0505 9.56006 37.0726 9.50663C37.0948 9.45319 37.1273 9.40466 37.1683 9.3638L39.4967 7.0363L37.1683 4.70879C37.0857 4.62621 37.0393 4.5142 37.0393 4.3974C37.0393 4.28061 37.0857 4.1686 37.1683 4.08601C37.2509 4.00343 37.3629 3.95703 37.4797 3.95703C37.5965 3.95703 37.7085 4.00343 37.7911 4.08601L40.43 6.72491C40.4709 6.76576 40.5034 6.8143 40.5256 6.86773C40.5477 6.92116 40.5592 6.97845 40.5592 7.0363C40.5592 7.09415 40.5477 7.15143 40.5256 7.20487C40.5034 7.2583 40.4709 7.30683 40.43 7.34769L37.7911 9.98658C37.7502 10.0275 37.7017 10.06 37.6482 10.0822C37.5948 10.1044 37.5375 10.1158 37.4797 10.1158C37.4218 10.1158 37.3645 10.1044 37.3111 10.0822C37.2577 10.06 37.2091 10.0275 37.1683 9.98658Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.8828 7.03747C30.8828 6.92083 30.9292 6.80896 31.0116 6.72648C31.0941 6.64399 31.206 6.59766 31.3226 6.59766H39.2393C39.356 6.59766 39.4678 6.64399 39.5503 6.72648C39.6328 6.80896 39.6791 6.92083 39.6791 7.03747C39.6791 7.15412 39.6328 7.26599 39.5503 7.34847C39.4678 7.43095 39.356 7.47729 39.2393 7.47729H31.3226C31.206 7.47729 31.0941 7.43095 31.0116 7.34847C30.9292 7.26599 30.8828 7.15412 30.8828 7.03747Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M28.6866 11.8752C28.3367 11.8752 28.0011 11.7362 27.7536 11.4887C27.5062 11.2413 27.3672 10.9057 27.3672 10.5557V3.51867C27.3672 3.16873 27.5062 2.83312 27.7536 2.58568C28.0011 2.33823 28.3367 2.19922 28.6866 2.19922H34.8441C35.194 2.19922 35.5296 2.33823 35.777 2.58568C36.0245 2.83312 36.1635 3.16873 36.1635 3.51867V4.83811C36.1635 4.95476 36.1172 5.06663 36.0347 5.14911C35.9522 5.23159 35.8403 5.27793 35.7237 5.27793C35.607 5.27793 35.4952 5.23159 35.4127 5.14911C35.3302 5.06663 35.2839 4.95476 35.2839 4.83811V3.51867C35.2839 3.40202 35.2375 3.29015 35.1551 3.20767C35.0726 3.12519 34.9607 3.07885 34.8441 3.07885H28.6866C28.57 3.07885 28.4581 3.12519 28.3756 3.20767C28.2932 3.29015 28.2468 3.40202 28.2468 3.51867V10.5557C28.2468 10.6724 28.2932 10.7842 28.3756 10.8667C28.4581 10.9492 28.57 10.9955 28.6866 10.9955H34.8441C34.9607 10.9955 35.0726 10.9492 35.1551 10.8667C35.2375 10.7842 35.2839 10.6724 35.2839 10.5557V9.23627C35.2839 9.11963 35.3302 9.00776 35.4127 8.92528C35.4952 8.8428 35.607 8.79646 35.7237 8.79646C35.8403 8.79646 35.9522 8.8428 36.0347 8.92528C36.1172 9.00776 36.1635 9.11963 36.1635 9.23627V10.5557C36.1635 10.9057 36.0245 11.2413 35.777 11.4887C35.5296 11.7362 35.194 11.8752 34.8441 11.8752H28.6866Z"
                />
              </g>
              <line
                x1="1"
                y1="3"
                x2="22"
                y2="3"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="4"
                y1="11"
                x2="22"
                y2="11"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="14.0741"
                    height="14.0741"
                    transform="translate(26.9258)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </footer>
      </div>
    </div>
  </div>
</div>
</div>

<div>
   <iposcen-logo-content></iposcen-logo-content>
</div>
