export class CreateAccountConstants {
  static readonly MANDADTORY_FIRSTNAME = 'First Name *';
  static readonly MANDADTORY_LASTNAME = 'Last Name *';
  static readonly MANDADTORY_EMAIL = 'Email Address *';
  static readonly MANDADTORY_PASSWORD = 'Password *';
  static readonly MANDADTORY_CNFPASSWORD = 'Confirm Password *';
  static readonly MANDADTORY_COUNTRY = 'Country *';
  static readonly MANDADTORY_COUNTRY_DEFAULT = 'Select Country';
  static readonly MANDADTORY_RETAILER_NAME = 'Retailer Name *';
  static readonly MANDADTORY_STORENAME = 'Store Name';
  static readonly PLACEHOLDER_FIRSTNAME = 'Jane';
  static readonly PLACEHOLDER_LASTNAME = 'Doe';
  static readonly PLACEHOLDER_PASSWORD = 'Enter Password';
  static readonly PLACEHOLDER_CNFPASSWORD = 'Enter Confirm Password';
  static readonly PLACEHOLDER_COUNTRY = 'Country';
  static readonly PLACEHOLDER_RETAILER_NAME = 'Example Ltd.';
  static readonly PLACEHOLDER_STORENAME = 'Store #21';
  static readonly MANDADTORY_COMMENTS =
    'Comments &nbsp;  <small class="text-muted"> (max 100 characters)</small>';
  static readonly MANDADTORY_CREATE_ACCOUNT = 'Create Account';
  static readonly MANDADTORY_INDICATES_HEADING = '* indicates a required field';
  static readonly MANDADTORY_REQUIRED_FIELD = 'This field is required';
  static readonly MANDADTORY_VALID_EMAIL =
    'Please provide a valid email address';
    static readonly MANDADTORY_WORK_EMAIL =
    'jane.doe@example.com';
  static readonly MANDATORY_PASSWORD_MATCH = 'Passwords do not match';
  static readonly USER_ALREADY_EXISTS =
    'An account with the given email already exists.';
  static readonly USER_ALREADY_EXISTS_ID =
    'Email address already exists, please provide another email address.';
  static readonly PASSWORD_MIN_LENGTH =
    'Password must contain at least 8 characters (with minimum one uppercase letter, one lowercase letter, one number and one special character)';
    static readonly PLACEHOLDER_COMMENTS = 'Please comment on requirement in case of personal emails for quick approval.';
}
