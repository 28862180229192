<div class="content__wrapper">
  <!-- Sidenav with relevant tabs -->

  <section class="sidenav">
    <div class="sidenav_header">Asset library</div>
    <div class="sidenav_item">
      <div (click)="setSideNav('store')" [ngClass]="{'active': sideNav === 'store'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.4213 0.6525C11.2913 -0.2175 12.7013 -0.2175 13.5713 0.6525L23.3438 10.4265C24.2138 11.2965 24.2138 12.705 23.3438 13.5735L13.5713 23.3475C12.7013 24.2175 11.2928 24.2175 10.4243 23.3475L0.648799 13.575C0.441894 13.3685 0.277743 13.1233 0.165743 12.8533C0.0537436 12.5832 -0.00390625 12.2938 -0.00390625 12.0015C-0.00390625 11.7092 0.0537436 11.4198 0.165743 11.1497C0.277743 10.8797 0.441894 10.6345 0.648799 10.428L10.4213 0.6525ZM12.5213 1.7025C12.3821 1.56327 12.1932 1.48505 11.9963 1.48505C11.7994 1.48505 11.6105 1.56327 11.4713 1.7025L1.6973 11.475C1.55807 11.6142 1.47985 11.8031 1.47985 12C1.47985 12.1969 1.55807 12.3858 1.6973 12.525L11.4713 22.299C11.6105 22.4382 11.7994 22.5164 11.9963 22.5164C12.1932 22.5164 12.3821 22.4382 12.5213 22.299L22.2953 12.525C22.4345 12.3858 22.5127 12.1969 22.5127 12C22.5127 11.8031 22.4345 11.6142 22.2953 11.475L12.5213 1.701V1.7025Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M17.7819 6.219C17.8518 6.28867 17.9072 6.37143 17.945 6.46255C17.9828 6.55367 18.0023 6.65135 18.0023 6.75C18.0023 6.84865 17.9828 6.94633 17.945 7.03745C17.9072 7.12857 17.8518 7.21133 17.7819 7.281L7.28195 17.781C7.14112 17.9218 6.95011 18.0009 6.75095 18.0009C6.55178 18.0009 6.36078 17.9218 6.21995 17.781C6.07912 17.6402 6 17.4492 6 17.25C6 17.0508 6.07912 16.8598 6.21995 16.719L16.7199 6.219C16.7896 6.14915 16.8724 6.09374 16.9635 6.05593C17.0546 6.01812 17.1523 5.99866 17.2509 5.99866C17.3496 5.99866 17.4473 6.01812 17.5384 6.05593C17.6295 6.09374 17.7123 6.14915 17.7819 6.219Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.21839 6.219C6.14854 6.28867 6.09313 6.37143 6.05532 6.46255C6.01751 6.55367 5.99805 6.65135 5.99805 6.75C5.99805 6.84865 6.01751 6.94633 6.05532 7.03745C6.09313 7.12857 6.14854 7.21133 6.21839 7.281L16.7184 17.781C16.8592 17.9218 17.0502 18.0009 17.2494 18.0009C17.4486 18.0009 17.6396 17.9218 17.7804 17.781C17.9212 17.6402 18.0003 17.4492 18.0003 17.25C18.0003 17.0508 17.9212 16.8598 17.7804 16.719L7.28039 6.219C7.21072 6.14915 7.12796 6.09374 7.03684 6.05593C6.94572 6.01812 6.84804 5.99866 6.74939 5.99866C6.65074 5.99866 6.55305 6.01812 6.46194 6.05593C6.37082 6.09374 6.28806 6.14915 6.21839 6.219Z" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Store Logo
      </div>
      <div (click)="setSideNav('promo')" [ngClass]="{'active': sideNav === 'promo'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21 7.5H3V21C3 21.3978 3.15804 21.7794 3.43934 22.0607C3.72064 22.342 4.10218 22.5 4.5 22.5H19.5C19.8978 22.5 20.2794 22.342 20.5607 22.0607C20.842 21.7794 21 21.3978 21 21V7.5ZM1.5 6V21C1.5 21.7956 1.81607 22.5587 2.37868 23.1213C2.94129 23.6839 3.70435 24 4.5 24H19.5C20.2956 24 21.0587 23.6839 21.6213 23.1213C22.1839 22.5587 22.5 21.7956 22.5 21V6H1.5Z" />
          <path
            d="M12 2.25C11.0054 2.25 10.0516 2.64509 9.34835 3.34835C8.64509 4.05161 8.25 5.00544 8.25 6H6.75C6.75 4.60761 7.30312 3.27226 8.28769 2.28769C9.27226 1.30312 10.6076 0.75 12 0.75C13.3924 0.75 14.7277 1.30312 15.7123 2.28769C16.6969 3.27226 17.25 4.60761 17.25 6H15.75C15.75 5.00544 15.3549 4.05161 14.6517 3.34835C13.9484 2.64509 12.9946 2.25 12 2.25Z" />
        </svg>
        Promotional Content
      </div>
      <div (click)="setSideNav('segments')" [ngClass]="{'active': sideNav === 'segments'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M23.0319 3.969C23.1018 4.03867 23.1572 4.12143 23.195 4.21255C23.2328 4.30367 23.2523 4.40135 23.2523 4.5C23.2523 4.59865 23.2328 4.69633 23.195 4.78745C23.1572 4.87857 23.1018 4.96133 23.0319 5.031L12.5319 15.531C12.4623 15.6008 12.3795 15.6563 12.2884 15.6941C12.1973 15.7319 12.0996 15.7513 12.0009 15.7513C11.9023 15.7513 11.8046 15.7319 11.7135 15.6941C11.6224 15.6563 11.5396 15.6008 11.4699 15.531L6.96995 11.031C6.90022 10.9613 6.8449 10.8785 6.80716 10.7874C6.76942 10.6963 6.75 10.5986 6.75 10.5C6.75 10.4014 6.76942 10.3037 6.80716 10.2126C6.8449 10.1215 6.90022 10.0387 6.96995 9.969C7.03968 9.89927 7.12246 9.84395 7.21357 9.80621C7.30468 9.76847 7.40233 9.74905 7.50095 9.74905C7.59956 9.74905 7.69721 9.76847 7.78832 9.80621C7.87943 9.84395 7.96222 9.89927 8.03195 9.969L12.0009 13.9395L21.9699 3.969C22.0396 3.89915 22.1224 3.84374 22.2135 3.80593C22.3046 3.76812 22.4023 3.74866 22.5009 3.74866C22.5996 3.74866 22.6973 3.76812 22.7884 3.80593C22.8795 3.84374 22.9623 3.89915 23.0319 3.969Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 3.75005C10.3683 3.75005 8.77326 4.2339 7.41656 5.14042C6.05985 6.04694 5.00243 7.33542 4.37801 8.84291C3.75358 10.3504 3.59021 12.0092 3.90854 13.6095C4.22686 15.2099 5.0126 16.6799 6.16638 17.8337C7.32017 18.9875 8.79018 19.7732 10.3905 20.0915C11.9909 20.4099 13.6497 20.2465 15.1572 19.6221C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7662 15.2268 20.25 13.6317 20.25 12C20.25 11.8011 20.329 11.6104 20.4697 11.4697C20.6103 11.3291 20.8011 11.25 21 11.25C21.1989 11.25 21.3897 11.3291 21.5303 11.4697C21.671 11.6104 21.75 11.8011 21.75 12C21.7497 14.1463 21.0413 16.2325 19.7345 17.9351C18.4278 19.6377 16.5958 20.8615 14.5226 21.4168C12.4494 21.9721 10.2509 21.8278 8.26813 21.0063C6.28531 20.1848 4.62896 18.732 3.55595 16.8733C2.48294 15.0145 2.05324 12.8536 2.33349 10.7257C2.61373 8.59782 3.58826 6.62186 5.10594 5.10428C6.62362 3.5867 8.59964 2.61229 10.7275 2.33219C12.8554 2.05208 15.0163 2.48192 16.875 3.55505C16.9646 3.60193 17.0439 3.66646 17.1079 3.74476C17.1719 3.82306 17.2195 3.9135 17.2477 4.01064C17.2759 4.10779 17.2841 4.20963 17.2719 4.31005C17.2597 4.41046 17.2273 4.50737 17.1767 4.59494C17.1261 4.68252 17.0583 4.75894 16.9773 4.81963C16.8964 4.88032 16.8041 4.92401 16.7058 4.94807C16.6076 4.97214 16.5055 4.97608 16.4056 4.95966C16.3058 4.94325 16.2104 4.90681 16.125 4.85255C14.8714 4.12758 13.4482 3.74721 12 3.75005Z" />
        </svg>
        Segments
      </div>
        <div (click)="setSideNav('banner')" [ngClass]="{'active': sideNav === 'banner'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9 1.5H1.5V22.5H9V1.5ZM22.5 1.5H15V9H22.5V1.5ZM22.5 15H15V22.5H22.5V15ZM0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0L9 0C9.39782 0 9.77936 0.158035 10.0607 0.43934C10.342 0.720644 10.5 1.10218 10.5 1.5V22.5C10.5 22.8978 10.342 23.2794 10.0607 23.5607C9.77936 23.842 9.39782 24 9 24H1.5C1.10218 24 0.720644 23.842 0.43934 23.5607C0.158035 23.2794 0 22.8978 0 22.5V1.5ZM13.5 1.5C13.5 1.10218 13.658 0.720644 13.9393 0.43934C14.2206 0.158035 14.6022 0 15 0L22.5 0C22.8978 0 23.2794 0.158035 23.5607 0.43934C23.842 0.720644 24 1.10218 24 1.5V9C24 9.39782 23.842 9.77936 23.5607 10.0607C23.2794 10.342 22.8978 10.5 22.5 10.5H15C14.6022 10.5 14.2206 10.342 13.9393 10.0607C13.658 9.77936 13.5 9.39782 13.5 9V1.5ZM15 13.5C14.6022 13.5 14.2206 13.658 13.9393 13.9393C13.658 14.2206 13.5 14.6022 13.5 15V22.5C13.5 22.8978 13.658 23.2794 13.9393 23.5607C14.2206 23.842 14.6022 24 15 24H22.5C22.8978 24 23.2794 23.842 23.5607 23.5607C23.842 23.2794 24 22.8978 24 22.5V15C24 14.6022 23.842 14.2206 23.5607 13.9393C23.2794 13.658 22.8978 13.5 22.5 13.5H15Z" />
        </svg>
        Banner Carousel
      </div>
      <div (click)="setSideNav('price')" [ngClass]="{'active': sideNav === 'price'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.75 3C0.75 2.40326 0.987053 1.83097 1.40901 1.40901C1.83097 0.987053 2.40326 0.75 3 0.75H9.879C10.4755 0.750525 11.0474 0.987923 11.469 1.41L21.969 11.91C22.3904 12.3319 22.627 12.9037 22.627 13.5C22.627 14.0963 22.3904 14.6681 21.969 15.09L15.0915 21.969C14.8825 22.1781 14.6344 22.3439 14.3614 22.4571C14.0883 22.5703 13.7956 22.6285 13.5 22.6285C13.2044 22.6285 12.9117 22.5703 12.6386 22.4571C12.3656 22.3439 12.1175 22.1781 11.9085 21.969L1.4085 11.469C0.986973 11.0472 0.750127 10.4753 0.75 9.879V3ZM3 2.25C2.80109 2.25 2.61032 2.32902 2.46967 2.46967C2.32902 2.61032 2.25 2.80109 2.25 3V9.879C2.25004 10.0775 2.3288 10.2679 2.469 10.4085L12.969 20.9085C13.0387 20.9783 13.1214 21.0338 13.2126 21.0716C13.3037 21.1094 13.4013 21.1288 13.5 21.1288C13.5987 21.1288 13.6963 21.1094 13.7874 21.0716C13.8786 21.0338 13.9613 20.9783 14.031 20.9085L20.9085 14.031C20.9783 13.9613 21.0338 13.8786 21.0716 13.7875C21.1094 13.6963 21.1288 13.5987 21.1288 13.5C21.1288 13.4014 21.1094 13.3037 21.0716 13.2126C21.0338 13.1214 20.9783 13.0387 20.9085 12.969L10.4085 2.469C10.2679 2.3288 10.0775 2.25004 9.879 2.25H3Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.75 6.75C3.75 5.95435 4.06607 5.19129 4.62868 4.62868C5.19129 4.06607 5.95435 3.75 6.75 3.75C7.54565 3.75 8.30871 4.06607 8.87132 4.62868C9.43393 5.19129 9.75 5.95435 9.75 6.75C9.75 7.54565 9.43393 8.30871 8.87132 8.87132C8.30871 9.43393 7.54565 9.75 6.75 9.75C5.95435 9.75 5.19129 9.43393 4.62868 8.87132C4.06607 8.30871 3.75 7.54565 3.75 6.75ZM6.75 5.25C6.35218 5.25 5.97064 5.40804 5.68934 5.68934C5.40804 5.97064 5.25 6.35218 5.25 6.75C5.25 7.14782 5.40804 7.52936 5.68934 7.81066C5.97064 8.09196 6.35218 8.25 6.75 8.25C7.14782 8.25 7.52936 8.09196 7.81066 7.81066C8.09196 7.52936 8.25 7.14782 8.25 6.75C8.25 6.35218 8.09196 5.97064 7.81066 5.68934C7.52936 5.40804 7.14782 5.25 6.75 5.25Z" />
        </svg>
        Price Content
      </div>
      <div (click)="setSideNav('attractor')" [ngClass]="{'active': sideNav === 'attractor'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.0005 5.25C3.033 5.25 2.25 6.033 2.25 7.0005V16.9995C2.25 17.967 3.033 18.75 4.0005 18.75H13.9995C14.967 18.75 15.75 17.967 15.75 16.9995V7.0005C15.75 6.033 14.967 5.25 13.9995 5.25H4.0005ZM0.75 7.0005C0.75 5.205 2.205 3.75 4.0005 3.75H13.9995C15.795 3.75 17.25 5.205 17.25 7.0005V16.9995C17.25 18.795 15.795 20.25 13.9995 20.25H4.0005C3.13841 20.25 2.31164 19.9075 1.70205 19.298C1.09246 18.6884 0.75 17.8616 0.75 16.9995V7.0005Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.8765 8.47567L21.0285 6.06817C21.0998 6.02518 21.1812 6.00162 21.2645 5.99983C21.3478 5.99805 21.4301 6.0181 21.5032 6.058C21.5763 6.0979 21.6377 6.15625 21.6813 6.22724C21.7248 6.29824 21.749 6.37941 21.7515 6.46267V17.5387C21.7515 17.8807 21.3615 18.1282 21.0285 17.9347L16.878 15.5272L16.125 16.8247L20.2755 19.2322C21.564 19.9792 23.2515 19.0897 23.2515 17.5387V6.46267C23.2515 4.91317 21.564 4.02367 20.2755 4.77067L16.1265 7.17817L16.8795 8.47567H16.8765Z" />
        </svg>
        Attract Loop Videos
      </div>
    </div>
  </section>

  <section class="main_section">
    <!-- Main section -->
    <ng-container *ngIf="sideNav === 'store'">
      <iposcen-logo-content class="logo_content_width gtmStore"></iposcen-logo-content>
    </ng-container>
    <ng-container *ngIf="sideNav === 'promo'">
      <iposcen-promo-content class="promo_content_width gtmPromo"></iposcen-promo-content>
    </ng-container>

    <ng-container *ngIf="sideNav === 'segments'">
      <iposcen-segments class="segements_width gtmSegments"></iposcen-segments>
    </ng-container>
    <ng-container *ngIf="sideNav === 'banner'">
      <iposcen-banner-carousel class="banner_content_width gtmBanner"></iposcen-banner-carousel>
    </ng-container>
    
    <ng-container *ngIf="sideNav === 'price'">
      <iposcen-price-content class="logo_content_width gtmPrice"></iposcen-price-content>
    </ng-container>
    <ng-container *ngIf="sideNav === 'attractor'">
      <iposcen-attractor-loop class="promo_content_width gtmAttractor"></iposcen-attractor-loop>
    </ng-container>
  </section>
  <iposcen-spinner-overlay></iposcen-spinner-overlay>
</div>