<div id="newLogoContent" class="tab-pane active">
  <form [formGroup]="bannerContentgrpForm">
    <div class="row">
      <div class="col-lg-3">
        <div class="form-group">
          <label for="publishedName">Published Name<span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="publishedName" placeholder="Published Name"
            formControlName="publishedName" name="publishedName" maxlength="50" required />
          <div *ngIf="isPublished" class="formfield_err_msg">This is a required field</div>
          <div *ngIf="isSubmitted && bannerContentgrpForm?.controls?.publishedName?.errors?.maxlength"
            class="formfield_err_msg">Published name may be up to 50 characters long</div>
        </div>
        <div class="form-group ">
          <label for="internalName">Internal Name</label>
          <input type="text" class="form-control" id="internalName" placeholder="Internal Name"
            formControlName="internalName" />
        </div>
      </div>
      <div class="col-lg-6 banner_image_column">
        <div>
          <div class="form-group">
            <label for="">
              Banner Thumbnail <span class="text-danger">*</span>
            </label>
            <ng-template #popContent>Image must be JPG, JPEG, PNG, JFIF or GIF format</ng-template>
            <img class="info_icon" alt="hover for help" placement="bottom-right" [ngbPopover]="popContent"
              triggers="mouseenter:mouseleave" />
            <div id="uploadButton">
              <label>
                <input #imageInput id="uploadLogo" type="file"
                  accept="image/jpg,image/jfif,image/x-png,image/jpeg,image/gif" formControlName="imageFile"
                  (change)="processFile($event)">
                <span class="btn btn-primary btn-sm left_icon_btn"><svg width="16" height="17" viewBox="0 0 16 17"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.5 8.25822C0.632608 8.25822 0.759785 8.3109 0.853553 8.40467C0.947322 8.49844 1 8.62561 1 8.75822V12.2582C1 12.5234 1.10536 12.7778 1.29289 12.9653C1.48043 13.1529 1.73478 13.2582 2 13.2582H14C14.2652 13.2582 14.5196 13.1529 14.7071 12.9653C14.8946 12.7778 15 12.5234 15 12.2582V8.75822C15 8.62561 15.0527 8.49844 15.1464 8.40467C15.2402 8.3109 15.3674 8.25822 15.5 8.25822C15.6326 8.25822 15.7598 8.3109 15.8536 8.40467C15.9473 8.49844 16 8.62561 16 8.75822V12.2582C16 12.7887 15.7893 13.2974 15.4142 13.6724C15.0391 14.0475 14.5304 14.2582 14 14.2582H2C1.46957 14.2582 0.960859 14.0475 0.585786 13.6724C0.210714 13.2974 0 12.7887 0 12.2582V8.75822C0 8.62561 0.0526784 8.49844 0.146447 8.40467C0.240215 8.3109 0.367392 8.25822 0.5 8.25822ZM5 5.11222C5.09376 5.20596 5.22092 5.25862 5.3535 5.25862C5.48608 5.25862 5.61324 5.20596 5.707 5.11222L8 2.81822L10.293 5.11122C10.3391 5.15898 10.3943 5.19707 10.4553 5.22327C10.5163 5.24948 10.5819 5.26327 10.6483 5.26385C10.7147 5.26442 10.7805 5.25177 10.842 5.22663C10.9034 5.20149 10.9593 5.16437 11.0062 5.11742C11.0531 5.07047 11.0903 5.01465 11.1154 4.9532C11.1406 4.89175 11.1532 4.82591 11.1526 4.75952C11.152 4.69313 11.1383 4.62752 11.1121 4.56652C11.0858 4.50552 11.0478 4.45035 11 4.40422L8.354 1.75822C8.30755 1.71166 8.25238 1.67472 8.19163 1.64951C8.13089 1.6243 8.06577 1.61133 8 1.61133C7.93423 1.61133 7.86911 1.6243 7.80837 1.64951C7.74762 1.67472 7.69245 1.71166 7.646 1.75822L5 4.40422C4.95344 4.45067 4.91649 4.50584 4.89129 4.56659C4.86608 4.62733 4.85311 4.69246 4.85311 4.75822C4.85311 4.82399 4.86608 4.88911 4.89129 4.94986C4.91649 5.0106 4.95344 5.06578 5 5.11222Z" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8 2.25781C8.13261 2.25781 8.25979 2.31049 8.35355 2.40426C8.44732 2.49803 8.5 2.6252 8.5 2.75781V10.7578C8.5 10.8904 8.44732 11.0176 8.35355 11.1114C8.25979 11.2051 8.13261 11.2578 8 11.2578C7.86739 11.2578 7.74021 11.2051 7.64645 11.1114C7.55268 11.0176 7.5 10.8904 7.5 10.7578V2.75781C7.5 2.6252 7.55268 2.49803 7.64645 2.40426C7.74021 2.31049 7.86739 2.25781 8 2.25781Z" />
                  </svg> Upload Image</span>
              </label>
              <div *ngIf="imageTypeError" class="uploadfile_err_msg">Incorrect file type received. Image <div> must be <strong>JPG, PNG </strong> or <strong>GIF </strong> format.</div></div>
              <div *ngIf="imageSizeError && isImageFormat" class="uploadfile_err_msg"> File exceeds maximum size <div><strong>(3MB).</strong></div></div>
            </div>
          </div>
        </div>
        <div class="banner_uploaded">
          <div class="banner_img_thumbnail" [ngClass]="!imageUrl ? 'border-dashed': 'border-solid'">
            <img src="{{imageUrl === '' ? '../../../../assets/images/thumbnail_placeholder_banner.png': imageUrl}}"
              alt="image_thumbnail" />
          </div>
          <div class="banner_uploaded_info">
            <div class="banner_uploaded_caption">
              <div *ngIf="isImageAvailable && isImageFormat">{{imageFileName}}</div>
              <div *ngIf="isImageAvailable && isImageFormat">{{media.uploadedDate | date: 'MM/dd/yy' }}</div>

            </div>
            <span>
              <button *ngIf="imageUrl !== '' && isImageAvailable && isImageFormat" type="button"
                (click)="openDeleteModalBC()" class="btn btn-outline-primary btn-sm left_icon_btn gtmDltBannerImg">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                    fill="#0068B5" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                    fill="#0068B5" />
                </svg>
                Delete
              </button>
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <label for="">Apply content group to</label>
        <button type="button" class="btn btn-primary btn-sm left_icon_btn gtmFormFctrBanner"
          (click)="openFormFactorsModal()">
          <img class="laptop_icon_formFactor" alt="select form factors" />
          <span>Select Form Factors</span>
        </button>
        <iposcen-form-factors #formFactorsModal [contentName]="bannerCGToDeleteName"
          (applyFormFactor)="applyFormFactor($event)"></iposcen-form-factors>
        <div *ngIf="this.selectedFormFactors" class="selected_factors">
          <img src="assets/images/laptop_icon_blue.svg" alt="selected form factors" />
          <div *ngIf="this.selectedFormFactors.allDevices">
            <div class="display_flex_common">
              <div class="font_weight_common">All Devices</div>
            </div>
          </div>
          <div *ngIf="!this.selectedFormFactors.allDevices">
            <div class="display_flex_common">
              <div class="font_weight_common">Manufacturers:</div>&nbsp;{{this.selectedFormFactors.manufacturer}}
            </div>
            <div class="display_flex_common">
              <div class="font_weight_common">Device Types:</div>&nbsp;{{this.selectedFormFactors.devices}}
            </div>
            <div class="display_flex_common">
              <div class="font_weight_common">Processors:</div>&nbsp;{{this.selectedFormFactors.processors}}
            </div>
            <div class="display_flex_common">
              <div class="font_weight_common">Generation:</div>&nbsp;{{this.selectedFormFactors.generations}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="button_wrapper">
      <button type="button" (click)="cancel()" class="btn btn-outline-primary gtmCancelBanner">
        Cancel
      </button>
      <button type="button" (click)="onAdd()" class="btn btn-primary gtmSaveBanner"
        [disabled]="!bannerContentgrpForm?.controls?.publishedName?.value || !isImageAvailable || isSubmitted || !isImageFormat">
        Save & Proceed
      </button>
    </div>

  </form>

</div>