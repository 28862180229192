import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { CommonService } from '../../../common/services/common.service';
import { LogoContentService } from '../../../core/routing/services/asset-library/logo-content.service';
import { StoreLogoService } from '../../../core/routing/services/asset-library/store-logo.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { RouterConstants } from './../../../core/routing/constants/app-paths.constant';
import { LogoContent, LogoContentConfiguration, CreateLogoContentResponse, LogoContentGroupPage, LogoContentViewModel } from './interfaces/logo-content.i';
import { CreateStoreLogoResponse, GetStoreLogoByUserResponse, StoreLogoConfiguration, StoreLogoViewModel } from './interfaces/store-logo.i';
import { AssetLibraryConstants } from '../../../core/routing/services/asset-library/constants/asset-library.constant';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'iposcen-logo-content',
  templateUrl: './logo-content.component.html',
  styleUrls: ['./logo-content.component.scss']
})
export class LogoContentComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal') deleteModal: ElementRef;
  @ViewChild('deleteStoreLogoModal') deleteStoreLogoModal: ElementRef;
  userId = sessionStorage.getItem('userId');
  configurations: LogoContentConfiguration[];
  // storeLogos: StoreLogoViewModel[];
  defaultStoreLogos: StoreLogoViewModel[]=[];
  otherStoreLogos: StoreLogoViewModel[]=[];
  storelogos: StoreLogoViewModel[];
  tabIndex = 0;
  allLogoContentGroups: LogoContent[];
  displayLogoContentGroups = [];
  selectedFiles: FileList;
  sortValue = GlobalConstants.CREATED_DATE;
  storeLogoSortValue = GlobalConstants.UPLOAD_DATE;
  logoContentData: LogoContent;
  // TODO create object
  logoCGToDelete: string;
  logoCGToDeleteName: string;
  logoToDelete: string;
  logoToDeleteName: string;
  page = 1;
  pageSize = 5;
  color = '#ffffff';
  contentGrpData: any;
  pageType = GlobalConstants.LOGO_CONTENT_GROUP;
  LCGlayout = 1;
  logoDeleteButton = GlobalConstants.LOGO;
  logoContentDeleteButton = GlobalConstants.CONTENT_GROUP;
  pages: any; // update the no of pages for edit.
  mediaObjects: any[] = [];
  editCheck: boolean;
  isImageSizeError: boolean;
  isImageTypeError: boolean;
  isImageUploadError: boolean;
  isimageFormat: boolean;
  isDisabled: boolean;
  clickable = false; // This is to enable tabs click only after clicking on Save & Proceed.TODO: will remove this later.
  // TODO - Sandeep to check with neeraj for page n pagesize
  showLogContentName: string;// To Show logocontentname as tab title.
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
  + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;
  pageGrpForm: any;
  presentPage: string;
  profile_page: string = GlobalConstants.PROFILE;
  assets_page: string = GlobalConstants.ASSET_LIBRARY
  private ngUnsubscribe = new Subject<boolean>();
  selctedContGrpsForProfile:any = {
    contentGrps :[]
  };
  constructor(private router: Router,
    private logoContentService: LogoContentService,
    private storeLogoService: StoreLogoService,
    private cmnService: CommonService, private dataService: DataService) {
  }
  
  ngOnInit(): void {
    this.getAllLogoContentsByUser();
    this.getAllStoreLogosByUser();
    this.pages = {};
    this.isDisabled = false;
    this.editCheck = false;
    // dummy data to preview page
    this.cmnService.pageDataTologoObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
      // Once page is updated.
      const logoContent = this.contentGrpData.logoContent;
      let configurations = logoContent.configurations;
      if (res.data.media.url !== '') {
        this.prepareMediaSet(res, logoContent.id);
      }
      const checkJson = this.checkExistance(configurations.contentGroupPages, 'pageNum', res.number);
      if (checkJson.hasMatch) {// If there is configuration object already for the page number
        // Update the pages
        configurations.contentGroupPages[checkJson.matchedIndex] = {
          pageNum: res.number,
          title: res.data.heading,
          titleFontColor: res.data.hcolor,
          bgColor: res.data.bgcolor,
          bodyCopy: res.data.body,
          bodyCopyFontSize: this.fontSizeNumber(res.data.size),
          bodyCopyFontColor: res.data.bcolor,
          UiLayout: this.getUILayoutNameByID(res.data.layout),
          linkedAsset: res.data.media.url !== '' ? true : false,
          linkedAssetFileType: res.data.media.format
        };
      } else {
        const logoContentGroupPage: LogoContentGroupPage = {
          pageNum: res.number,
          title: res.data.heading,
          titleFontColor: res.data.hcolor,
          bgColor: res.data.bgcolor,
          bodyCopy: res.data.body,
          bodyCopyFontSize: this.fontSizeNumber(res.data.size),
          bodyCopyFontColor: res.data.bcolor,
          UiLayout: this.getUILayoutNameByID(res.data.layout),
          linkedAsset: res.data.media.url !== '' ? true : false,
          linkedAssetFileType: res.data.media.format
        };
        configurations.contentGroupPages.push(logoContentGroupPage);
      }
      this.contentGrpData.logoContent = logoContent;
    });

    //set presentpage 
    let currentdetailPage = this.router.url;
    const pageName = currentdetailPage.lastIndexOf('/');
    if (currentdetailPage.substring(pageName + 1) === GlobalConstants.ASSET_LIBRARY) {
      this.presentPage = GlobalConstants.ASSET_LIBRARY;
    }
    else if(currentdetailPage.substring(pageName + 1) === GlobalConstants.CREATE_PROFILE) {
      this.presentPage = GlobalConstants.PROFILE;
    }
  
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkExistance(data, key, compValue) {
    const checkJSON = {
      hasMatch: false,
      matchedIndex: -1
    };
    for (let index = 0; index < data.length; index += 1) {
      if (data[index][key] === compValue) {
        checkJSON.hasMatch = true;
        checkJSON.matchedIndex = index;
        break;
      }
    }

    return checkJSON;
  }

  // To hold all pages media objects and its metada.
  prepareMediaSet(res:any, id:string) {
    const checkJson = this.checkExistance(this.mediaObjects, 'pageNum', res.number);
    if (checkJson.hasMatch) {
      // Update the pages
      this.mediaObjects[checkJson.matchedIndex] = {
        id: id,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        this.mediaObjects[checkJson.matchedIndex].uploaded = true;
      }
    } else {
      const dataObj:any = {
        id: id,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        dataObj.uploaded = true;
      }
      this.mediaObjects.push(dataObj);
    }
    // console.log(this.mediaObjects);
  }

  updateLCG() {
    const updateLogoContent: CreateLogoContentResponse = this.contentGrpData as CreateLogoContentResponse;
    // Once page is updated.
    const logoContent = updateLogoContent.logoContent;
    if (typeof logoContent.configurations !== 'string') {
      logoContent.configurations = JSON.stringify(logoContent.configurations);
    }
    this.logoContentService.putLogoContentById(JSON.stringify(logoContent), updateLogoContent.logoContent.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putLogoContentResponse => {
        // Once the update call is successful, call a PUT call for the logo
        for (const each of this.mediaObjects) {
          if (each.uploaded){
            this.updateMedia(each);
          }
        }
        this.pages = {};
        this.setIndex(1);
        this.getAllLogoContentsByUser();
        this.clickable = false;
      },
      error => {
      }
    );
  }

  updateMedia(each) {
    this.logoContentService.putS3LogoContentByPageId(each.media.fileObj,
      each.media.name.split('.')
        .pop(), each.id, each.pageNum.toString())
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(() => {

      },
        error => { // do something
        }
      );
  }

  fontSizeNumber(size) {
    if (size === 'Small') { return 12; }
    if (size === 'Medium') { return 14; }
    if (size === 'Large') { return 16; }
  }

  getUILayoutNameByID(id) {// TODO - Confirm with Sujay if layout name is correct
    if (id === 1) {
      return '2_col_right_asset';
    } else if (id === 2) {
      return '2_col_left_asset';
    } else if (id === 3) {
      return '1_col_mid_asset';
    }
  }

  getAllStoreLogosByUser() {
    // console.log('Component, all store logo, My userid:: ' + sessionStorage.getItem('userId'));
    this.storeLogoService.getAllStoreLogoByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllStoreLogoResponse => {
        const storeLogos = [];
        const getStoreLogoByUserResponse: GetStoreLogoByUserResponse = getAllStoreLogoResponse as GetStoreLogoByUserResponse;

        getStoreLogoByUserResponse.storeLogo.forEach(storeLogo => {
          const reader = new FileReader();
          const storeLogoViewModel: StoreLogoViewModel = storeLogo as StoreLogoViewModel;
          this.storeLogoService.getStoreLogoById(storeLogo.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .pipe(first())
            .subscribe(imageData => {
              reader.addEventListener('load', () => {
                storeLogoViewModel.imageData = reader.result;
                const storeLogoConfiguration = JSON.parse(storeLogo.configurations) as StoreLogoConfiguration;
                storeLogoViewModel.fileName = storeLogoConfiguration.fileName;
                storeLogos.push(storeLogoViewModel);
                storeLogos.forEach(ele => {
                  ele.isdefault = ele.isdefault ? ele.isdefault : false;
                  ele.updateTime = new Date(ele.updateTime);
                  ele.updateTimeDisplay = new Date(ele.updateTime).toLocaleString()
                    .replace(',', '');
                });
                this.defaultStoreLogos = storeLogos.filter(ele => ele.isdefault);
                this.otherStoreLogos = storeLogos.filter(ele => !ele.isdefault);
                this.sortBy('uploadDate');
                // this.storeLogos = defaultStoreLogos.concat(nonDefaultStoreLogos);
              }, false);

              if (imageData) {
                reader.readAsDataURL(imageData);
              }
            },
              error => {
                // do something
              });
          // this.configurations.push(JSON.parse(storeLogo.configurations) as LogoContentConfiguration);
        });
      },
        error => {
          // do something
        });
  }

  getAllLogoContentsByUser() {
    // console.log('In component, My userid:: ' + sessionStorage.getItem('userId'));
    this.logoContentService.getAllLogoContentByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllLogoContentResponse => {
        this.allLogoContentGroups = (getAllLogoContentResponse.logoContent as LogoContent[]);
        this.configurations = [];
        this.allLogoContentGroups.forEach(logoContentGroup => {
          try {
            this.configurations.push(JSON.parse(logoContentGroup.configurations) as LogoContentConfiguration);
          } catch (err) {
            // console.log(err, 'in logo content group');
          }
        });
        // display logo content groups using displaylogocontentgroups var as configurations is coming as string
        this.displayLogoContentGroups = [];
        this.allLogoContentGroups.forEach(ele => {
          ele.configurations = JSON.parse(ele.configurations);
          ele.createdByUser = (ele.createdByUser === this.userId) ? GlobalConstants.ME : ele.createdByUser;
          this.displayLogoContentGroups.push(ele);
        });

        this.displayLogoContentGroups.forEach(element => {
          // adding this custom property to know which logo content is expanded
          element.isExpanded = false;
          if (element.updateTime) {
            element.updateTime = new Date(element.updateTime);
            element.updateTimeDisplay = new Date(element.updateTime).toLocaleString()
              .replace(',', '');
          }
          // if deleting make it true
          element.isDeleted = false;
          // converting date for display purpose:
          if (element && element.configurations) {
            if (element.configurations.publishTime) {
              // element.configurations.publishTime = new Date(element.configurations.publishTime).toLocaleString()
              //   .replace(',', '');
            }
            if (element.configurations.expireTime && element.configurations.expireTime !== GlobalConstants.NEVER) {
              // element.configurations.expireTime = new Date(element.configurations.expireTime).toLocaleString()
              //   .replace(',', '');
            }
          }
          if((this.presentPage === GlobalConstants.PROFILE)){
            element.showOnDevice = false;
            element.addToPackage = false;
          }
        });
        this.sortByDate();
      },
        error => {
          // do something
        }
      );
  }

  loadMiniPreview(gId: string, pageContent: any) {
    this.pageGrpForm = {
      bgcolor: pageContent ? pageContent.bgColor : '#0068B5',
      heading:  pageContent ? pageContent.title : '',
      hcolor: pageContent ? pageContent.titleFontColor : '#ffffff',
      body: pageContent ? pageContent.bodyCopy : '',
      bcolor: pageContent ? pageContent.bodyCopyFontColor : '#ffffff',
      size: pageContent ? this.getFontSize(pageContent.bodyCopyFontSize) : GlobalConstants.MEDIUM,
      media: {
        url: (pageContent && pageContent.linkedAsset) ?
        environment.apiBaseUrlAsset + this.url + AssetLibraryConstants.LOGO_CONTENTS + AssetLibraryConstants.SLASH + gId + AssetLibraryConstants.SLASH + pageContent.pageNum.toString() : '',
        name: '',
        format: (pageContent && pageContent.linkedAsset) ? (pageContent.linkedAssetFileType) ? pageContent.linkedAssetFileType : '' : '',
        fileObj: ''
      },
      id: gId,    // adding created group id,
      layout: pageContent ? this.getUILayoutIDByName(pageContent.UiLayout) : 3
    };

    return this.pageGrpForm;
  }
  getFontSize(size: number ) {
    if (size === GlobalConstants.NUMBER_12) { return GlobalConstants.SMALL; }
    if (size === GlobalConstants.NUMBER_14) { return GlobalConstants.MEDIUM;}
    if (size === GlobalConstants.NUMBER_16) { return GlobalConstants.LARGE; }
  }
  getUILayoutIDByName(name: string) {
    if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
      return 1;
    } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
      return 2;
    } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
      return 3;
    }
  }
  reloadLogoContent(value: number) {
    // Call the API to get all logo content by user
    this.getAllLogoContentsByUser();
    this.setIndex(value);
  }
  close() {
    this.dataService.setLogoContent(undefined);
    // TO-DO: temp fix for showing published name as balnk after edit.
    if(this.contentGrpData){
      for (let each of this.displayLogoContentGroups) {
        if (each.id === this.contentGrpData.logoContent.id) {
          if (typeof each.configurations === 'string') {
            each.configurations = JSON.parse(each.configurations);
          }
          break;
        }
      }
    }
    this.setIndex(1);
    this.clickable = false;
    this.mediaObjects = [];
    this.pages = {};
    this.editCheck = false;
  }

  setIndex(value: number) {
    this.tabIndex = value;
    this.cmnService.emitTabIndex(this.tabIndex);
  }

  refreshLGCdata() {
    this.getAllLogoContentsByUser();
    this.setIndex(1);
    this.mediaObjects = [];
  }

  setCreatedGrpID(data) {
    if (typeof data === 'object' && data.cancel) {
      this.setIndex(1);
      this.pages = {};
      this.editCheck = false;
    } else {
      this.contentGrpData = data;
      if(typeof this.contentGrpData.logoContent.configurations === 'string'){
        this.contentGrpData.logoContent.configurations =
          JSON.parse(this.contentGrpData.logoContent.configurations) as LogoContentConfiguration;
      }
      this.showLogContentName = this.contentGrpData.logoContent.configurations.publishedName;
      this.clickable = true;
      this.setIndex(4);
    }
    this.mediaObjects = [];
  }
  /*when user opens pages*/
  setPreviewLayout(data) {
    this.LCGlayout = data;
  }
  /*when users slects layout*/
  setLayout(selected) {
    this.LCGlayout = selected;
    this.cmnService.emitLayout(selected);
  }

  redirectToLogin() {
    this.router.navigateByUrl(RouterConstants.LOGIN_PAGE_LINK);
  }

  trackByConfiguration(index: number, el: string): number {
    return index;
  }

  trackByStoreLogo(index: number, el: string): number {
    return index;
  }

  fetchImagesForLogoCGPages(logoCGId: string) {
    this.displayLogoContentGroups.forEach(element => {
      // console.log('element.id:: ' + element.id);
      // console.log('element.configurations: '+ JSON.stringify (element.configurations));
      if (element.id === logoCGId && element.configurations && element.configurations.contentGroupPages) {
        element.configurations.contentGroupPages.forEach(page => {
          if (page.linkedAsset) {
            const reader = new FileReader();

            this.logoContentService.getLogoContentByPageId(logoCGId, page.pageNum)
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(imageData => {
                reader.addEventListener('load', () => {
                  page.image = reader.result;
                }, false);

                if (imageData) {
                  reader.readAsDataURL(imageData);
                }
              },
                error => {
                  // do something
                });
          }

        });
      }
    });
  }

  // get details of logo content by id when click on arrow besides particular logo content group
  getLogoContentDetails(id: string) {
    // TODO check with Sujay or Magesh to fetch the id of existing logo  - first ngfor if i can change
    this.logoContentService.getLogoContentById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getLogoContentById => {
        const logoContentGrpdetails = (getLogoContentById.logoContent as LogoContent);
        this.logoContentData = logoContentGrpdetails;
        // console.log('logoContentGrpDetails--', logoContentGrpdetails);
      },
        error => {
          // do something
        });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const fileType = file.type.split('/')[1];
    if (file.type.split('/')[0] === GlobalConstants.FILE_TYPE_IMAGE ) {
    this.isImageSizeError = file.size > 3145728;
    this.isImageTypeError = fileType !== GlobalConstants.CONTENT_TYPE_JPEG &&
      fileType !== GlobalConstants.CONTENT_TYPE_PNG &&
      fileType !== GlobalConstants.CONTENT_TYPE_GIF;
    this.isImageUploadError = (this.isImageSizeError || this.isImageTypeError || (this.isImageSizeError && this.isImageTypeError));
    this.isimageFormat = true;
  } else {
    this.isimageFormat = false;
    this.isImageTypeError = true;
    this.isImageUploadError = true;
  }
    if (!this.isImageUploadError) {
      reader.addEventListener('load', (event: any) => {
        const storeLogo = {
          fileName: file.name
        };
        this.storeLogoService.createStoreLogo(JSON.stringify(storeLogo))
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(createStoreLogo => {
            const createStoreLogoResponse: CreateStoreLogoResponse = createStoreLogo as CreateStoreLogoResponse;
            const storeLogoViewModel: StoreLogoViewModel = createStoreLogoResponse.storeLogo as StoreLogoViewModel;
            this.storeLogoService.uploadImage(file, createStoreLogoResponse.storeLogo.id, file.name.split('.')
              .pop())
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(() => {
                if (!this.otherStoreLogos || (this.otherStoreLogos && this.otherStoreLogos.length === 0)) {
                  this.otherStoreLogos = [];
                }
                storeLogoViewModel.imageData = event.target.result;
                storeLogoViewModel.fileName = file.name;
                storeLogoViewModel.updateTime = new Date(storeLogoViewModel.updateTime);
                  storeLogoViewModel.updateTimeDisplay = new Date(storeLogoViewModel.updateTime).toLocaleString()
                  .replace(',', '');
                this.otherStoreLogos.push(storeLogoViewModel);
                this.sortBy('uploadDate');
              },
              error => {
                // do something
              });
          },
          error => {
            // do something
          });
    });
  }

    reader.readAsDataURL(file);
  }

  getDetailsOfLogoCG(id: string) {
    this.displayLogoContentGroups.forEach(element => {
      if (element.id === id) {
        element.isExpanded = !element.isExpanded;
      }
    });
  }

  sortBy(sortValue: string) {
    switch (sortValue) {
      case 'createDate': {
        this.sortValue = GlobalConstants.CREATED_DATE;
        this.displayLogoContentGroups.sort((logo1, logo2) => logo2.updateTime - logo1.updateTime);
        break;
      }
      case 'groupName': {
        this.sortValue = GlobalConstants.GROUP_NAME;
        this.displayLogoContentGroups.sort((logo1, logo2) =>
          logo1.configurations.publishedName.localeCompare(logo2.configurations.publishedName));
        break;
      }
      case 'uploadDate': {
        this.storeLogoSortValue = GlobalConstants.UPLOAD_DATE;
        this.otherStoreLogos.sort((logo1, logo2) => (logo2.updateTime  as any) - (logo1.updateTime  as any));
        // this.otherStoreLogos.sort((logo1, logo2) => logo2.updateTime.localeCompare(logo1.updateTime));
        break;
      }
      case 'fileName': {
        this.storeLogoSortValue = GlobalConstants.FILE_NAME;
        this.otherStoreLogos.sort((logo1, logo2) =>
          logo1.fileName.localeCompare(logo2.fileName));
        break;
      }
      default:
        break;
    }
  }

  sortByDate() {
    this.sortValue = GlobalConstants.CREATED_DATE;
    // this.displayLogoContentGroups.sort((logo1, logo2) => logo2.updateTime.localeCompare(logo1.updateTime));
    this.displayLogoContentGroups.sort((logo1, logo2) => logo2.updateTime - logo1.updateTime);
  }

  sortByName() {
    this.sortValue = GlobalConstants.GROUP_NAME;
    this.displayLogoContentGroups.sort((logo1, logo2) =>
      logo1.configurations.publishedName.localeCompare(logo2.configurations.publishedName));
  }

  deleteLogoCG(logoCGID: string) {
    const index = this.displayLogoContentGroups.findIndex(d => d.id === logoCGID); // find index in your array
    // make that isdeleted tru for this id
    this.displayLogoContentGroups[index].isDeleted = true;
    // delete logo API call
    this.logoContentService.deleteLogoContentById(logoCGID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {
        if(index >= 0) {
          this.displayLogoContentGroups.splice(index, 1); // remove element from array
        }
        this.closeModal(this.deleteModal);
      },
        error => {
          // do something
          this.closeModal(this.deleteModal);
        });
  }

  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openDeleteModalSL(logoID) {
    this.storelogos = this.defaultStoreLogos.concat(this.otherStoreLogos);
    // console.log(this.storelogos);
    this.logoToDelete = this.storelogos.find(ele => ele.id === logoID).id;
    this.logoToDeleteName = this.storelogos.find(ele => ele.id === logoID).fileName;
    // console.log('Store Logo will be Deleted-', this.storelogos.find(ele => ele.id === logoID).fileName);
    this.openModal(this.deleteStoreLogoModal);
  }

  openDeleteModal(logoCGID: string) {
    this.logoCGToDelete = this.displayLogoContentGroups.find(ele => ele.id === logoCGID).id;
    this.logoCGToDeleteName = this.displayLogoContentGroups.find(ele => ele.id === logoCGID).configurations.publishedName;
    this.openModal(this.deleteModal);
  }

  setAsDefaultStoreLogo(storeLogoId: string, isDefault: boolean) {

    this.storeLogoService.putStoreLogo(isDefault, storeLogoId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putStoreLogoResponse => {
        // console.log('putStoreLogoResponse:: ' + JSON.stringify(putStoreLogoResponse));

        if (!isDefault) {
          // Here we are doing Remove from default. So remove from default store logo and add to other store logo catg
          this.defaultStoreLogos.forEach(element => {
            // Set is Default as false for all
            element.isdefault = false;
          });
          this.otherStoreLogos = this.otherStoreLogos.concat(this.defaultStoreLogos);
          this.defaultStoreLogos.pop();
        } else {
          // Here we are adding as default. So remove from other store logo and add to default store logo catg
          const storeLogos = this.defaultStoreLogos.concat(this.otherStoreLogos);
          storeLogos.forEach(element => {
            // Set is Default as false for all
            element.isdefault = false;
            if (element.id === storeLogoId) {
              element.isdefault = true;
              this.defaultStoreLogos.push(element);
            }
          });
          this.defaultStoreLogos = storeLogos.filter(ele => ele.isdefault);
          this.otherStoreLogos = storeLogos.filter(ele => !ele.isdefault);
        }
      },
        error => {
          // do something
        });
  }

  deleteStoreLogo(storeLogoId: string) {
    // delete store logo api call if different other deletelogocg()
    // Call delete logo for S3 server first
    // Then call delete logo

    this.storeLogoService.deleteS3StoreLogoById(storeLogoId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {

        this.storeLogoService.deleteStoreLogoById(storeLogoId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(deleteResponse => {
            // console.log('Delete Store logo success --', deleteResponse);
            const logoToDel = this.storelogos.find(d => d.id === storeLogoId);
            if (logoToDel && logoToDel.isdefault) { this.defaultStoreLogos.splice(0, 1); } else {
              const index = this.otherStoreLogos.findIndex(logo => logo.id === storeLogoId);
            if(index >= 0) {
              this.otherStoreLogos.splice(index, 1);
            }
            }
            this.closeModal(this.deleteStoreLogoModal);
          },
            error => {
              // do something
              // console.log('Delete Store logo error --', error);
              this.closeModal(this.deleteStoreLogoModal);
            });
      },
        error => {
          // do something
          // console.log('Delete S3 logo error --', error);
        });
  }

  editLogoContent(logoContentId: string) {
    this.mediaObjects = [];
    this.pages = {};
    const logoContentToEdit = this.displayLogoContentGroups.find(ele => ele.id === logoContentId);
    this.dataService.setLogoContent(logoContentToEdit);
    this.editCheck = true;
    for (let each of logoContentToEdit.configurations.contentGroupPages) {
      if (each.pageNum === 2){
        this.pages.page2 = true;
      }
      if (each.pageNum === 3){
        this.pages.page3 = true;
      }
    }
    this.contentGrpData = {logoContent:logoContentToEdit,responseContext:{}}; // set data to cntGrpData to show in pages.
    this.showLogContentName = logoContentToEdit.configurations.publishedName;
    this.setIndex(3);
    // this.contentGrpData.logoContent.configurations = JSON.stringify(this.contentGrpData.logoContent.configurations);
  }

  duplicateAndEditLogoContent(logoContentId: string) {
    const logoContentToEdit = this.displayLogoContentGroups.find(ele => ele.id === logoContentId);
    const logoContentConfig = logoContentToEdit.configurations;
    const logoContent: LogoContentConfiguration = {
      publishedName: `Copy of ${logoContentConfig.publishedName}`,
      internalName: logoContentConfig.internalName,
      publishTime: logoContentConfig.publishTime ? logoContentConfig.publishTime : new Date().toString(),
      expireTime: logoContentConfig.expireTime ? logoContentConfig.expireTime : GlobalConstants.NEVER,
      contentScheduleTimezoneCode: logoContentConfig.contentScheduleTimezoneCode,
      type: GlobalConstants.LOGO_CONTENT_GROUP,
      contentGroupPages: logoContentConfig.contentGroupPages
    };
    this.logoContentService.createLogoContent(JSON.stringify(logoContent))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createLogoContentResponse => {
        this.getDetailsOfLogoCG(logoContentId);
        const createLogoContentResponseObj: CreateLogoContentResponse = createLogoContentResponse as CreateLogoContentResponse;
        const logoContentViewModel: LogoContentViewModel = createLogoContentResponseObj.logoContent as unknown as LogoContentViewModel;
        logoContentViewModel.configurations =
                    JSON.parse(createLogoContentResponseObj.logoContent.configurations) as LogoContentConfiguration;

        // Loop and duplicate images
        this.duplicateLogoContentImage(logoContentToEdit, logoContentViewModel);

        this.dataService.setLogoContent(logoContentViewModel);
        this.editCheck = true;
        for (let each of logoContentViewModel.configurations.contentGroupPages) {
          if (each.pageNum === 2){
            this.pages.page2 = true;
          }
          if (each.pageNum === 3){
            this.pages.page3 = true;
          }
        }
        this.contentGrpData = {
          logoContent: logoContentViewModel,
          responseContext: {}
        }; // set data to cntGrpData to show in pages.
        const tempViewModel: any = logoContentViewModel; // dummy instance to add all required  flags for expand,delete,sort by date&time format
        tempViewModel.isExpanded = false;
        tempViewModel.createdByUser = (tempViewModel.createdByUser === this.userId) ? GlobalConstants.ME : tempViewModel.createdByUser;
        if (tempViewModel.updateTime) {
          tempViewModel.updateTime = new Date(tempViewModel.updateTime);
          tempViewModel.updateTimeDisplay = new Date(tempViewModel.updateTime).toLocaleString()
            .replace(',', '');
        }
        tempViewModel.isDeleted = false;
        this.displayLogoContentGroups.push(tempViewModel);
        if (this.sortValue === 'Created Date') {
          this.sortBy('createDate');
        } else {
          this.sortBy('groupName');
        }
        this.showLogContentName = logoContent.publishedName;
        this.page = 1;
        this.setIndex(3);
      },
        error => {
        }
      );
  }

  duplicateLogoContentImage(logoContent, newLogoContent) {
    if (logoContent.configurations && logoContent.configurations.contentGroupPages) {
      logoContent.configurations.contentGroupPages.forEach(page => {
        if (page.linkedAsset) {
          this.logoContentService.getLogoContentByPageId(logoContent.id, page.pageNum)
            .pipe(takeUntil(this.ngUnsubscribe))
            .pipe(first())
            .subscribe(imageData => {
              this.logoContentService.putS3LogoContentByPageId(imageData, 'png', newLogoContent.id, page.pageNum) // Check how to get image type
                .pipe(takeUntil(this.ngUnsubscribe))
                .pipe(first())
                .subscribe(() => {
                },
                  error => {
                    // do something
                  });
            },
              error => {
                // do something
              });
        }

      });
    }
  }

  addPage() {
    const keyLen = Object.keys(this.pages).length;
    if (keyLen === 0) {
      this.pages.page2 = true;
      this.setIndex(5);
    } else {
      this.pages.page3 = true;
      this.setIndex(6);
    }
  }

  checkPages() {
    if (this.pages.page2 && this.pages.page3) {
      return true;
    }
    return false;
  }

  createNewLogoContent() {
    this.dataService.setLogoContent(undefined);
    this.tabIndex = 3;
  }

  //Adding content group objects for profile
  pushToSelectedGrps(ind: number) {
    this.selctedContGrpsForProfile.contentGrps.push ({
      "logoContentId" : this.displayLogoContentGroups[ind].id,
      "showOnDevice" : this.displayLogoContentGroups[ind].showOnDevice
    });
  }

  setPackageNshowOnDeviceVal(index: number, value: boolean) {
    this.displayLogoContentGroups[index].showOnDevice = this.displayLogoContentGroups[index].addToPackage = value;
  }

  addToProfile(eventObj: any, index: number, eventSource: string) {
    //for radio button selection
    if(eventSource === GlobalConstants.RADIO) {
      if (this.selctedContGrpsForProfile.contentGrps?.length === 3) {
          if(this.displayLogoContentGroups[index].addToPackage) {
            this.displayLogoContentGroups[index].showOnDevice = true;
            this.selctedContGrpsForProfile.showOnDevice = this.displayLogoContentGroups[index].id;
          }
          else if(this.selctedContGrpsForProfile.showOnDevice) {
            let checkInd = this.displayLogoContentGroups.findIndex (d => d.id === this.selctedContGrpsForProfile.showOnDevice);
            this.setPackageNshowOnDeviceVal(checkInd, false);
            this.selctedContGrpsForProfile.showOnDevice = undefined; 
            this.selctedContGrpsForProfile.contentGrps.splice (checkInd,1); 
            this.setPackageNshowOnDeviceVal(index, true);
            this.selctedContGrpsForProfile.showOnDevice = this.displayLogoContentGroups[index].id;
            this.pushToSelectedGrps(index);
          }else{
            eventObj.target.checked = false;
          }
      } else {
        if(this.selctedContGrpsForProfile.showOnDevice) {
          let checkInd = this.selctedContGrpsForProfile.contentGrps.findIndex (d => d.logoContentId === this.selctedContGrpsForProfile.showOnDevice);
          this.selctedContGrpsForProfile.contentGrps.splice (checkInd,1);
          let checkInd2 = this.displayLogoContentGroups.findIndex (d => d.id === this.selctedContGrpsForProfile.showOnDevice);
          this.setPackageNshowOnDeviceVal (checkInd2, false);
        }
        this.displayLogoContentGroups[index].addToPackage = this.displayLogoContentGroups[index].showOnDevice = true;
        let checkPrevIDInd = this.selctedContGrpsForProfile.contentGrps.findIndex (d => d.logoContentId === this.displayLogoContentGroups[index].id);
        if(checkPrevIDInd === -1) {
          this.pushToSelectedGrps (index);
        }
        this.selctedContGrpsForProfile.showOnDevice = this.displayLogoContentGroups[index].id;
      }
    } 
    //for checkbox  selection
    else if (eventSource === GlobalConstants.CHECK) {
      if (eventObj.target.checked) {
        if (this.selctedContGrpsForProfile.contentGrps?.length <= 2) {
          this.displayLogoContentGroups[index].addToPackage = eventObj.target.checked;
          this.pushToSelectedGrps (index);
        } else {
          eventObj.target.checked = false;
        }
      } else {
        this.setPackageNshowOnDeviceVal(index, false);
        this.selctedContGrpsForProfile.showOnDevice = undefined;
        let checkInd = this.selctedContGrpsForProfile.contentGrps.findIndex (d => d.logoContentId === this.displayLogoContentGroups[index].id);
        if(checkInd >= 0) {          
          this.selctedContGrpsForProfile.contentGrps.splice (checkInd,1);
        }
      }
    }
  }

  sendToProfile(action: string) {
    if(action === GlobalConstants.SEND) {
      this.cmnService.emitDataToProfile(this.selctedContGrpsForProfile.contentGrps);
    }else {
      this.cmnService.emitDataToProfile(GlobalConstants.SKIP);
    }
  }

  disabledLogoContent(isDisable: boolean) {
    this.isDisabled = true;
  }
}
