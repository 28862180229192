import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { AssetLibraryConstants } from './constants/asset-library.constant';

@Injectable({
  providedIn: 'root'
})
export class PriceContentService {
  userId = sessionStorage.getItem('userId');
  PRICE_CONTENTS_URL =
    AssetLibraryConstants.ASSET_LIBRARY_USERS + this.userId +
    AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId +
    AssetLibraryConstants.PRICE_CONTENTS;
  GET_PRICE_CONTENTS_BY_ID_URL = this.PRICE_CONTENTS_URL + AssetLibraryConstants.SLASH;
  GET_PRICE_CONTENT_BY_PAGE_ID_URL =
    AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId +
    AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId +
    AssetLibraryConstants.PRICE_CONTENTS + AssetLibraryConstants.SLASH;

  constructor(private httpMethodsservice: HttpMethodsService) {}

  createPriceContent(configurationsJson: string) {
    const url = this.PRICE_CONTENTS_URL;
    const addObject = {
      configurations: configurationsJson
    };

    return this.httpMethodsservice
      .post(url, addObject)
      .pipe(map((res: any) => res));
  }
  putPriceContentById(inputJson: string, id: string) {
    const url = this.GET_PRICE_CONTENTS_BY_ID_URL + id;

    return this.httpMethodsservice.put(url, inputJson)
      .pipe(map((res: any) =>
        res));
  }
  getAllPriceContentByUser() {
    const url = this.PRICE_CONTENTS_URL;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) => res));
  }
  getPriceContentById(id: string) {
    const url = this.GET_PRICE_CONTENTS_BY_ID_URL + id;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) => res));
  }

  getPriceContentByPageId(priceContentId: string, pageId: string) {
    const url = this.GET_PRICE_CONTENT_BY_PAGE_ID_URL + priceContentId +
      AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.getImage(url, true)
      .pipe(map((res: any) => res));
  }

  putS3PriceContentByPageId(image: File, imageType: string, priceContentId: string, pageId: string) {
    const url = this.GET_PRICE_CONTENT_BY_PAGE_ID_URL
      + priceContentId + AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.putImage(url, image, imageType, true)
      .pipe(map((res: any) =>
        res));
  }

  deleteS3PriceContentByPageId(priceContentId: string, pageId: string) {
    const url = this.GET_PRICE_CONTENT_BY_PAGE_ID_URL
                + priceContentId + AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.delete(url, true)
      .pipe(map((res: any) =>
        res));
  }

  deletePriceContentById(priceContentId: string) {
    const url = this.PRICE_CONTENTS_URL
                + AssetLibraryConstants.SLASH + priceContentId;

    return this.httpMethodsservice.delete(url)
      .pipe(map((res: any) =>
        res));
  }
}
