import { Injectable } from '@angular/core';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { map } from 'rxjs/internal/operators/map';
import { createProfileConstants } from './constants/create-profile.constant';
import { GlobalConstants } from '../../../../../app/common/constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class CreateProfileService {
  userId = sessionStorage.getItem('userId');
  profileId: string;
  CREATE_PROFILE_URL = createProfileConstants.CREATE_PROFILE_USER + this.userId + createProfileConstants.PROFILE_ACTION;
  UPDATE_PROFILE_URL = createProfileConstants.PROFILE + createProfileConstants.USERS + this.userId + createProfileConstants.PROFILE_ACTION + createProfileConstants.SLASH;
  UPDATE_lOGOCONTENT_ID = this.CREATE_PROFILE_URL + createProfileConstants.SLASH;
  currentProfile: any = {} //TO-DO: will be removed when data store will be created
  constructor(private httpMethodsservice: HttpMethodsService) { }

  createProfileInfo(configurationsJson: string) {
    const url = this.CREATE_PROFILE_URL;
    const addObject = {
      configurations: configurationsJson
    };

    return this.httpMethodsservice.post(url, addObject)
      .pipe(map((res: any) => {
        this.currentProfile = res;
        return res;
      }
      ));
  }
  getProfile() {
    return this.currentProfile();
  }
  setProfile(data: any) {
    this.currentProfile = data;
  }

  updateProfile(id: string, profileData: Object) {
    const url = this.UPDATE_PROFILE_URL + id;
    return this.httpMethodsservice.patch(url, profileData)
      .pipe(map((res: any) => {
        return res;
      }
      ));
  }

  updateLogoContentId(profileId: string, data){
    const url = this.UPDATE_lOGOCONTENT_ID + profileId;
    return this.httpMethodsservice.patch(url, data)
      .pipe(map((res: any) =>
        res));
  }

  setProfileId(id: string) {
    this.profileId = id;
  }
 
  getProfileId() {
    return this.profileId;
  }
  
  updateProfileforPromoContent(id: string, profileData: Object) {
    const url = this.CREATE_PROFILE_URL + GlobalConstants.SLASH + id;
    return this.httpMethodsservice.patch(url, profileData)
      .pipe(map((res: any) => {
        return res;
      }
      ));
  }
  updateProfileStoreLogo(id: string, profileData: Object) {
    const url = this.UPDATE_PROFILE_URL + id;

    return this.httpMethodsservice.patch(url, profileData)
      .pipe(map((res: any) => {

        return res;
      }
      ));
  }

}
