<div [className]="isListPreview ? 'list-preview live_preview' : 'live_preview'" *ngIf="previewData.layout === 1">

  <div class="preview_content" [style.background-color]="previewData.bgcolor">
    <div class="live_preview_text">
      <div class="live_heading" [ngStyle]="{'color':previewData.hcolor}">{{previewData.heading || 'HEADING'}}</div>
      <p class="live_body" [ngStyle]="{'color':previewData.bcolor,'font-size':fontSize(previewData.size)}">
        {{previewData.body || 'Body'}}
      </p>
    </div>
    <img class="live_preview_img"
      src="{{previewData.media.url || '/assets/images/preview_thumbnail.png'}}"
      alt="image_thumbnail" *ngIf="previewData.media.format === 'image' || previewData.media.format === ''" />
    <video src="{{previewData.media.url}}" *ngIf="previewData.media.format === 'video'" controls class="video1"></video>
  </div>
</div>
<div [className]="isListPreview ? 'list-preview live_preview' : 'live_preview'" *ngIf="previewData.layout === 2">
  <div class="preview_content" [style.background-color]="previewData.bgcolor">
    <img class="live_preview_img"
      src="{{previewData.media.url || '/assets/images/preview_thumbnail.png'}}"
      alt="image_thumbnail" *ngIf="previewData.media.format === 'image' || previewData.media.format === ''" />
    <video src="{{previewData.media.url}}" *ngIf="previewData.media.format === 'video'" controls class="video2"></video>
    <div class="live_preview_text">
      <div class="live_heading" [ngStyle]="{'color':previewData.hcolor}">{{previewData.heading || 'HEADING'}}</div>
      <p class="live_body" [ngStyle]="{'color':previewData.bcolor,'font-size':fontSize(previewData.size)}">
        {{previewData.body || 'Body'}}
      </p>
    </div>
  </div>
</div>
<div [className]="isListPreview ? 'list-preview live_preview' : 'live_preview'" *ngIf="previewData.layout === 3">
  <div class="column live_preview_layout" [style.background-color]="previewData.bgcolor">
    <div class="jusCen">
      <div class="live_heading" [ngStyle]="{'color':previewData.hcolor}">
        {{previewData.heading || 'HEADING'}}
      </div>
    </div>
    <img class="img3"
      src="{{previewData.media.url || '/assets/images/preview_thumbnail.png'}}"
      alt="image_thumbnail" *ngIf="previewData.media.format === 'image' || previewData.media.format === ''" />
    <video src="{{previewData.media.url}}" *ngIf="previewData.media.format === 'video'" controls class="video3"></video>
    <div class="jusCen">
      <div class="live_body mt-2" [ngStyle]="{'color':previewData.bcolor,'font-size':fontSize(previewData.size)}">
        {{previewData.body || 'Body'}}
      </div>
    </div>
  </div>
</div>