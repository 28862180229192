import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';

@NgModule({
  declarations: [DeletePopupComponent],
  imports: [
    CommonModule
  ],
  exports: [DeletePopupComponent]
})
export class DeleteModule { }
