import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { ConfirmRegistrationConstants } from '../../../../modules/account/confirm-registration/constants/confirm-registration.constant';
import { CognitoCallback } from '../../interfaces/cognito.i';
import { CreateAccount } from '../../interfaces/create-account.i';
import { CognitoUtil } from '../cognito/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class CreateAccountService {

  constructor(public cognitoUtil: CognitoUtil) {

  }

  register(user: CreateAccount, callback: CognitoCallback): void {

    const attributeList = [];

    const firstName = {
      Name: 'given_name',
      Value: user.firstName
    };
    const lastName = {
      Name: 'family_name',
      Value: user.lastName
    };
    const email = {
      Name: 'email',
      Value: user.email
    };
    const password = {
      Name: 'password',
      Value: user.password
    };
    const cnfPassword = {
      Name: 'cnfPassword',
      Value: user.cnfPassword
    };
    const retailerName = {
      Name: 'custom:custom:retailer_name',
      Value: user.retailerName
    };
    const storeName = {
      Name: 'custom:custom:store_name',
      Value: user.storeName
    };
    const comments = {
      Name: 'comments',
      Value: user.comments
    };
    const countrySelect = {
      Name: 'custom:custom:country',
      Value: user.countrySelect
    };

     const phoneNo = {
       Name: 'phoneNumber',
       Value: user.phoneNumber

     };
     const locale = {
      Name: 'locale',
      Value: user.locale

    };
    const region = {
      Name: 'custom:custom:region',
      Value: user.region

    };
    const role = {
      Name: 'custom:custom:role',
      Value: user.role

    };
    const userSettings = {
      Name: 'custom:custom:user_settings',
      Value: user.userSettings

    };
    const retailerLocationNo = {
      Name: 'custom:retailer_location_no',
      Value: user.retailerLocationNo

    };
    attributeList.push(new CognitoUserAttribute(firstName));
    attributeList.push(new CognitoUserAttribute(lastName));
    attributeList.push(new CognitoUserAttribute(email));
    attributeList.push(new CognitoUserAttribute(locale));
    attributeList.push(new CognitoUserAttribute(retailerName));
    attributeList.push(new CognitoUserAttribute(storeName));
    attributeList.push(new CognitoUserAttribute(countrySelect));
    this.cognitoUtil.getUserPool()
    .signUp(user.email, user.password, attributeList, undefined,  (err, result) => {
      if (err) {
        callback.cognitoCallback(err.message, undefined);
      } else {
        callback.cognitoCallback(undefined, result);
      }
    });
  }

  confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true,  (err, result) => {
      if (err) {
        callback.cognitoCallback(err.message, undefined);
      } else {
        callback.cognitoCallback(undefined, result);
      }
    });
  }

  resendCode(username: string, callback: CognitoCallback): void {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode( (err, result) => {
      if (err) {
        callback.cognitoCallback(err.message, undefined);
      } else {
        callback.cognitoCallback(ConfirmRegistrationConstants.CODE_RESENT, result);
      }
    });
  }

  // newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
  //   console.log(newPasswordUser);
  //   // Get these details and call
  //   //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
  //   let authenticationData = {
  //     Username: newPasswordUser.username,
  //     Password: newPasswordUser.existingPassword,
  //   };
  //   let authenticationDetails = new AuthenticationDetails(authenticationData);

  //   let userData = {
  //     Username: newPasswordUser.username,
  //     Pool: this.cognitoUtil.getUserPool()
  //   };

  //   console.log("UserLoginService: Params set...Authenticating the user");
  //   let cognitoUser = new CognitoUser(userData);
  //   console.log("UserLoginService: config is " + AWS.config);
  //   cognitoUser.authenticateUser(authenticationDetails, {
  //     newPasswordRequired: function (userAttributes, requiredAttributes) {
  //       // User was signed up by an admin and must provide new
  //       // password and required attributes, if any, to complete
  //       // authentication.

  //       // the api doesn't accept this field back
  //       delete userAttributes.email_verified;
  //       cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
  //         onSuccess: function (result) {
  //           callback.cognitoCallback(undefined, userAttributes);
  //         },
  //         onFailure: function (err) {
  //           callback.cognitoCallback(err, undefined);
  //         }
  //       });
  //     },
  //     onSuccess: function (result) {
  //       callback.cognitoCallback(undefined, result);
  //     },
  //     onFailure: function (err) {
  //       callback.cognitoCallback(err, undefined);
  //     }
  //   });
  // }
}
