import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetidCopyComponent } from './assetid-copy/assetid-copy.component';



@NgModule({
  declarations: [AssetidCopyComponent],
  imports: [
    CommonModule
  ],
  exports: [AssetidCopyComponent]
})
export class AssetidCopyModule { }
