<div class="main_section mt-5 mb-2 pl-8 pr-8">
  <!-- Navtabs -->
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="setIndex(0)" [ngClass]="{'active': tabIndex === 0}">Retail HQs</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="setIndex(1)" [ngClass]="{'active': tabIndex === 1}">stores</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="setIndex(2)" [ngClass]="{'active': tabIndex === 2}">Active
        Users</a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link" data-toggle="tab" (click)="setIndex(3)" [ngClass]="{'active': tabIndex === 3}">OEM's</a>
      </li>      
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" (click)="setIndex(4)" [ngClass]="{'active': tabIndex === 4}">Pending Users</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" (click)="setIndex(5)" [ngClass]="{'active': tabIndex === 5}">Domain whitelist</a>
      </li> -->
    </ul>
    <div class="tab-content">
        <div *ngIf="tabIndex === 0" class="tab-pane" [ngClass]="{'active': tabIndex === 0}">
           <iposcen-retail-hq></iposcen-retail-hq>
        </div>
        <div *ngIf="tabIndex === 1" class="tab-pane" [ngClass]="{'active': tabIndex === 1}">
           <iposcen-stores></iposcen-stores><!-- include your component here-->
        </div>
        <div *ngIf="tabIndex === 2" class="tab-pane" [ngClass]="{'active': tabIndex === 2}">
            Active users (replace with component)<!-- include your component here-->
         </div>        
         <div *ngIf="tabIndex === 3" class="tab-pane" [ngClass]="{'active': tabIndex === 3}">
            OEM's <!-- include your component here-->
         </div>        
         <div *ngIf="tabIndex === 4" class="tab-pane" [ngClass]="{'active': tabIndex === 4}">
            Pending users <!-- include your component here-->
         </div>        
         <div *ngIf="tabIndex === 5" class="tab-pane" [ngClass]="{'active': tabIndex === 5}">
            Domain whitelist <!-- include your component here-->
         </div>        
    </div>
  </div>