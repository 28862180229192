import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RouterConstants } from './core/routing/constants/app-paths.constant';
import { DetailService } from './core/routing/services/details/detail.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { GlobalConstants } from './common/constants/global.constant';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'iposcen-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'ipos-ui-central';
  assetLibraryHref: string;
  homePageHref: string;
  mainLayout: boolean;
  currentdetailPage: string;
  profileHref: string;
  storesHref: string;
  homePageTabIndex: number;
  layOut: boolean;
  downloadIPOS: string;
  usersRetailHref: string;
  private ngUnsubscribe = new Subject<boolean>();
  private routerdetailPage: Subscription;


  constructor(
    private router: Router,
    private detailService: DetailService,
    private ref: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    this.gtmService.addGtmToDom();
    this.assetLibraryHref = RouterConstants.ASSET_LIBRARY_LINK;
    this.homePageHref = RouterConstants.HOME_PAGE_LINK;
    this.profileHref = RouterConstants.PROFILES_LINK;
    this.storesHref = RouterConstants.STORES_LINK;
    this.downloadIPOS = GlobalConstants.DOWNLOAD_LIBRARY_IPOS;
    this.usersRetailHref = RouterConstants.USERS_RETAIL_LINK;
    this.homePageTabIndex = 0;

    this.detailService.$isMainLayout
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(message => (this.mainLayout = message));
    // if (this.mainLayout) {
    //   console.log(this.mainLayout);
    //   this.router.navigateByUrl(RouterConstants.HOME_PAGE_LINK);
    // } else {
    //   this.router.navigateByUrl('');
    // }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  setIndex(value: number) {
    this.homePageTabIndex = value;
  }
  ngAfterViewInit() {
    // return to home page from details page and click on view all specs, header should not be selected
    this.routerdetailPage = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentdetailPage = event.urlAfterRedirects;
        const pageName = this.currentdetailPage.lastIndexOf('/');

        if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.HOME_PAGE ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.STORE_PAGE ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.ASSET_LIBRARY ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.PROFILE_PAGE ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.CREATE_PROFILE ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_RETAILHQS ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_STORES ||
          this.currentdetailPage.substring(pageName + 1) === GlobalConstants.USERS_ACTIVEUSERS) {
          this.layOut = false;
          if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.ASSET_LIBRARY) {
            this.homePageTabIndex = 3;
          }
          if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.PROFILE_PAGE) {
            this.homePageTabIndex = 2;
          }
          if (this.currentdetailPage.substring(pageName + 1) === (GlobalConstants.USERS_RETAILHQS || GlobalConstants.USERS_STORES || GlobalConstants.USERS_ACTIVEUSERS)) {
            this.homePageTabIndex = 1;
          }
        } else {
          this.layOut = true;
        }
      }
    });
    this.ref.detectChanges();
  }
}
