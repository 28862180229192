import { Component, OnInit } from '@angular/core';
import { SpinnerOverlayService } from '../../services/spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'iposcen-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit {

  loading: boolean;

  constructor(private spinnerOverlayService: SpinnerOverlayService) {

    this.spinnerOverlayService.$isLoading.subscribe(status => {
      this.loading = status;
    });
  }

  ngOnInit(): void {
  }
}
