import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class DataService {

    private promoContent = new BehaviorSubject('');

    private logoContent = new BehaviorSubject('');

    private bannerContent = new BehaviorSubject('');
    private priceContent = new BehaviorSubject('');

    private attractorLoop = new BehaviorSubject('');

    private segment = new BehaviorSubject('');

    getPromoContent(): Observable<any> {
        return this.promoContent.asObservable();
    }

    setPromoContent(message: any) {
        this.promoContent.next(message);
    }

    getLogoContent(): Observable<any> {
        return this.logoContent.asObservable();
    }

    setLogoContent(message: any) {
        this.logoContent.next(message);
    }

    getBannerContent(): Observable<any> {
        return this.bannerContent.asObservable();
    }

    setBannerContent(message: any) {
        this.bannerContent.next(message);
    }

    getPriceContent(): Observable<any> {
        return this.priceContent.asObservable();
    }

    setPriceContent(message: any) {
        this.priceContent.next(message);
    }

    getAttractorLoop(): Observable<any> {
        return this.attractorLoop.asObservable();
    }

    setAttractorLoop(message: any) {
        this.attractorLoop.next(message);
    }

    getSegment(): Observable<any> {
        return this.segment.asObservable();
    }

    setSegment(message: any) {
        this.segment.next(message);
    }
}
