<ng-template #confirmModal>
  <div class="card confirmPopup">
    <div class="modal-header">
      <div (click)="dismiss()" class="close-icon">
        <img src="assets/images/popup_close_button.svg" alt="close icon" />
      </div>
    </div>
    <div class="modal-body">
      <h2>{{popupTitle}}?</h2>
      <div class="card-desc">
        {{popupDesc}}
      </div>
    </div>
    <div class="modal-footer card-footer">
      <button type="button" (click)="dismiss()" class="btn btn-outline-primary btn-cancel">
        Cancel
      </button>
      <button type="button" class="btn btn-primary btn-confirm" (click)="confirmAction()">
        {{popupAction}}
      </button>
    </div>
  </div>
</ng-template>