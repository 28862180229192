<div class="tab-pane active">
  <form  *ngIf="bannerURLgrpForm">
    <div class="row url-data-section">
      <label class="url_label">Note: This functionality requires a connected device to access the custom URL.</label>
      <div class="form-group col-md-6">
        <label for="customUrl">Custom URL<span class="text-danger">*</span></label>
          <input type="text" 
            class="form-control" 
            id="customUrl" 
            [(ngModel)]="bannerURLgrpForm.customURL"
            placeholder="Custom URL"
            (keyup) = "emitURLData()"
            name="customUrl" />
      </div>
    </div>
  </form>
</div>