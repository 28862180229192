<div>
    <section class="main_section_with_preview">
     <!-- Live preview - temporarly image -->
  <iposcen-live-preview *ngIf="tabIndex === 2 || tabIndex === 3 || tabIndex === 4  "></iposcen-live-preview>
  <!-- Layout of preview -->
  <div class="preview_layout" *ngIf="tabIndex === 2 || tabIndex === 3 || tabIndex === 4 ">
    <img [ngClass]="{'active_preview': segmentLayout === 1 ? true : false}" src="assets/images/SP_layout_1.png"
      alt="layout 1" (click)="setLayout(1)"  class="cursor-pointer" />

    <img [ngClass]="{'active_preview': segmentLayout === 2 ? true : false}" src="assets/images/SP_layout_2.png"
      alt="layout 2" (click)="setLayout(2)"  class="cursor-pointer" />

    <img [ngClass]="{'active_preview': segmentLayout === 3 ? true : false}" src="assets/images/SP_layout_3.png"
      alt="layout 3" (click)="setLayout(3)"  class="cursor-pointer" />
  </div>
  <section class="main_section al_storelogo">
    <ul *ngIf="(tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4)" class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 1}">{{editCheck? showSegmentName :'New Segment'}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 2}" (click)="clickable?setIndex(2):false">PAGE 1</a>
        </li>
        <li class="nav-item" *ngIf="pages && pages.page2">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 3}" (click)="clickable?setIndex(3):false">PAGE 2</a>
        </li>
        <li class="nav-item" *ngIf="pages && pages.page3">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 4}" (click)="clickable?setIndex(4):false">PAGE 3</a>
        </li>
        <li class="nav-item" *ngIf="clickable && !checkPages()">
          <a class="nav-link" data-toggle="tab" (click)="addPage()">
            <img src="assets/images/add_black_square_outline.svg" alt="add pages" />
          </a>
        </li>
        <li class="nav-item exit_preview">
          <a class="nav-link" (click)="cancel()">
            <img src="assets/images/exit_icon.svg" alt="exit without changes" />
          </a>
        </li>
      </ul>

    <section  *ngIf="tabIndex === 0" class="main_section al_storelogo">

        <div class="main_section_headers">
            <svg _ngcontent-mkp-c137="" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-mkp-c137="" fill-rule="evenodd" clip-rule="evenodd"
                    d="M23.0319 3.969C23.1018 4.03867 23.1572 4.12143 23.195 4.21255C23.2328 4.30367 23.2523 4.40135 23.2523 4.5C23.2523 4.59865 23.2328 4.69633 23.195 4.78745C23.1572 4.87857 23.1018 4.96133 23.0319 5.031L12.5319 15.531C12.4623 15.6008 12.3795 15.6563 12.2884 15.6941C12.1973 15.7319 12.0996 15.7513 12.0009 15.7513C11.9023 15.7513 11.8046 15.7319 11.7135 15.6941C11.6224 15.6563 11.5396 15.6008 11.4699 15.531L6.96995 11.031C6.90022 10.9613 6.8449 10.8785 6.80716 10.7874C6.76942 10.6963 6.75 10.5986 6.75 10.5C6.75 10.4014 6.76942 10.3037 6.80716 10.2126C6.8449 10.1215 6.90022 10.0387 6.96995 9.969C7.03968 9.89927 7.12246 9.84395 7.21357 9.80621C7.30468 9.76847 7.40233 9.74905 7.50095 9.74905C7.59956 9.74905 7.69721 9.76847 7.78832 9.80621C7.87943 9.84395 7.96222 9.89927 8.03195 9.969L12.0009 13.9395L21.9699 3.969C22.0396 3.89915 22.1224 3.84374 22.2135 3.80593C22.3046 3.76812 22.4023 3.74866 22.5009 3.74866C22.5996 3.74866 22.6973 3.76812 22.7884 3.80593C22.8795 3.84374 22.9623 3.89915 23.0319 3.969Z">
                </path>
                <path _ngcontent-mkp-c137="" fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 3.75005C10.3683 3.75005 8.77326 4.2339 7.41656 5.14042C6.05985 6.04694 5.00243 7.33542 4.37801 8.84291C3.75358 10.3504 3.59021 12.0092 3.90854 13.6095C4.22686 15.2099 5.0126 16.6799 6.16638 17.8337C7.32017 18.9875 8.79018 19.7732 10.3905 20.0915C11.9909 20.4099 13.6497 20.2465 15.1572 19.6221C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7662 15.2268 20.25 13.6317 20.25 12C20.25 11.8011 20.329 11.6104 20.4697 11.4697C20.6103 11.3291 20.8011 11.25 21 11.25C21.1989 11.25 21.3897 11.3291 21.5303 11.4697C21.671 11.6104 21.75 11.8011 21.75 12C21.7497 14.1463 21.0413 16.2325 19.7345 17.9351C18.4278 19.6377 16.5958 20.8615 14.5226 21.4168C12.4494 21.9721 10.2509 21.8278 8.26813 21.0063C6.28531 20.1848 4.62896 18.732 3.55595 16.8733C2.48294 15.0145 2.05324 12.8536 2.33349 10.7257C2.61373 8.59782 3.58826 6.62186 5.10594 5.10428C6.62362 3.5867 8.59964 2.61229 10.7275 2.33219C12.8554 2.05208 15.0163 2.48192 16.875 3.55505C16.9646 3.60193 17.0439 3.66646 17.1079 3.74476C17.1719 3.82306 17.2195 3.9135 17.2477 4.01064C17.2759 4.10779 17.2841 4.20963 17.2719 4.31005C17.2597 4.41046 17.2273 4.50737 17.1767 4.59494C17.1261 4.68252 17.0583 4.75894 16.9773 4.81963C16.8964 4.88032 16.8041 4.92401 16.7058 4.94807C16.6076 4.97214 16.5055 4.97608 16.4056 4.95966C16.3058 4.94325 16.2104 4.90681 16.125 4.85255C14.8714 4.12758 13.4482 3.74721 12 3.75005Z">
                </path>
            </svg>
            Segments
        </div>

        <!-- tab panes -->
        <div class="tab-content">

            <!-- Logo Content Group Tab -->
            <div  id="logoContentGroups" class="tab-pane active">
                <!-- Data header with info like no. of content groups, action buttons and filters -->
                <div class="data_header" [ngClass] = "(displaySegmentGroups.length > 0)?'':'display-block'">
                    <span *ngIf="displaySegmentGroups.length > 0">
                        <span *ngIf="displaySegmentGroups" id="dataTitle">{{displaySegmentGroups.length}}
                            {{segmentHeading}}
                        </span>
                        <span *ngIf="displaySegmentGroups.length > 1">
                            Sort by :&nbsp;
                            <div ngbDropdown class="d-inline-block">
                                <button ngbDropdownToggle class="btn btn-primary btn-sm dropdown-toggle" id="sortDropDown">{{sortValue}}
                                </button>
                                <div ngbDropdownMenu aria-labelledby="sortDropDown">
                                    <button ngbDropdownItem (click)="sortBy('segmentName')">Segment Name</button>
                                    <button ngbDropdownItem (click)="sortBy('retailerName')">Retailer Name</button>
                                </div>
                            </div>
                        </span>
                    </span>
                    <span *ngIf="displaySegmentGroups.length === 0">Create device segments to help with the sales process. Every segment is associated with a color.</span>
                    <span class="data_action">
                        <button type="button" id="uploadLogo" class="btn btn-primary btn-sm left_icon_btn"
                            (click)="addSegment()">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.5 3.5C8.63261 3.5 8.75979 3.55268 8.85355 3.64645C8.94732 3.74021 9 3.86739 9 4V8C9 8.13261 8.94732 8.25979 8.85355 8.35355C8.75979 8.44732 8.63261 8.5 8.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H8V4C8 3.86739 8.05268 3.74021 8.14645 3.64645C8.24021 3.55268 8.36739 3.5 8.5 3.5Z" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8 8C8 7.86739 8.05268 7.74021 8.14645 7.64645C8.24021 7.55268 8.36739 7.5 8.5 7.5H12.5C12.6326 7.5 12.7598 7.55268 12.8536 7.64645C12.9473 7.74021 13 7.86739 13 8C13 8.13261 12.9473 8.25979 12.8536 8.35355C12.7598 8.44732 12.6326 8.5 12.5 8.5H9V12C9 12.1326 8.94732 12.2598 8.85355 12.3536C8.75979 12.4473 8.63261 12.5 8.5 12.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12V8Z"
                                        fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.5 15C10.3565 15 12.137 14.2625 13.4497 12.9497C14.7625 11.637 15.5 9.85652 15.5 8C15.5 6.14348 14.7625 4.36301 13.4497 3.05025C12.137 1.7375 10.3565 1 8.5 1C6.64348 1 4.86301 1.7375 3.55025 3.05025C2.2375 4.36301 1.5 6.14348 1.5 8C1.5 9.85652 2.2375 11.637 3.55025 12.9497C4.86301 14.2625 6.64348 15 8.5 15ZM8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16Z" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" transform="translate(0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            New Segment Group
                        </button>
                    </span>
                </div>

                <!-- Data Content -->
                <div class="data_content">
                    <div class="accordion" id="logoContentAccordian">
                        <!-- Logo CG 3 panel -->
                        <div *ngFor="let segmentGroup of displaySegmentGroups | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
                            class="card">
                            <div class="card-header small_text" id="logoCGThree">
                                <div class="collapse_link collapsed" type="button"                                    
                                    [attr.aria-expanded]="segmentGroup.isExpanded" aria-controls="collapseThree">
                                    
                                    <div class="title_left">
                                        <div class="titleBox" [style.background]="segmentGroup.configurations.segmentBgColor">

                                        </div>
                                        <div>
                                        <div class="title">{{segmentGroup.configurations.publishedName}}</div>
                                        <iposcen-assetid-copy [assetID]="segmentGroup.id"></iposcen-assetid-copy>                                                                                
                                    </div>
                                    </div>
                                </div>
                                <div class="subtitle_group">
                                    <span class="subtitle">Last Edited By
                                        {{segmentGroup?.createdByUser}}:
                                        {{segmentGroup.updateTime | formatDate: "12hr"}}</span>
                                    <span class="subtitle">Pushed to all devices:
                                        {{segmentGroup?.ispushedToDevice ? 'Y' : 'N'}}</span>
                                </div>
                                <div class="button_wrapper">
                                    <button type="button" (click)="openDeleteModal(segmentGroup.id)"
                                        class="btn btn-outline-primary btn-sm left_icon_btn delete_btn">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                                                fill="#0068B5" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                                                fill="#0068B5" />
                                        </svg>
                                        Delete
                                    </button>
                                    <iposcen-delete-popup #deleteModal [contentName]="segmentToDeleteName" [buttonName]="'Content Group'"
                                        (delete)="deleteSegment(segmentToDelete)"></iposcen-delete-popup>
                                    <button  type="button" (click)="editSegment(segmentGroup.id)"
                                        class="btn btn-primary btn-sm left_icon_btn">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M11.293 1.29279C11.4805 1.10532 11.7348 1 12 1C12.2652 1 12.5195 1.10532 12.707 1.29279L14.707 3.29279C14.8945 3.48031 14.9998 3.73462 14.9998 3.99979C14.9998 4.26495 14.8945 4.51926 14.707 4.70679L5.707 13.7068C5.59752 13.8166 5.464 13.8994 5.317 13.9488L2.317 14.9488C2.14074 15.0078 1.95153 15.0165 1.7706 14.974C1.58966 14.9314 1.42417 14.8393 1.29268 14.7079C1.1612 14.5765 1.06894 14.4111 1.02625 14.2302C0.983555 14.0493 0.992126 13.8601 1.051 13.6838L2.051 10.6838C2.1002 10.5364 2.18305 10.4026 2.293 10.2928L11.293 1.29279ZM12 1.99979L14 3.99979L5 12.9998L2 13.9998L3 10.9998L12 1.99979Z"
                                                fill="white" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M12.146 6.35448L9.646 3.85448L10.354 3.14648L12.854 5.64648L12.147 6.35448H12.146ZM3 10.0005V10.5005C3 10.6331 3.05268 10.7603 3.14645 10.854C3.24021 10.9478 3.36739 11.0005 3.5 11.0005H4V11.5005C4 11.6331 4.05268 11.7603 4.14645 11.854C4.24021 11.9478 4.36739 12.0005 4.5 12.0005H5V12.5005C5 12.6331 5.05268 12.7603 5.14645 12.854C5.24021 12.9478 5.36739 13.0005 5.5 13.0005H6V11.5005C6 11.3679 5.94732 11.2407 5.85355 11.1469C5.75979 11.0532 5.63261 11.0005 5.5 11.0005H5V10.5005C5 10.3679 4.94732 10.2407 4.85355 10.1469C4.75979 10.0532 4.63261 10.0005 4.5 10.0005H3Z"
                                                fill="white" />
                                        </svg>
                                        Edit
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm right_icon_btn">
                                        Send
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z"
                                                fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <div class="custom-control custom-switch collapse_link collapsed">
                                    <input type="checkbox" class="custom-control-input" id="logoCGThreeSwitch"
                                        [checked]="segmentGroup?.islocked" />
                                    <label class="custom-control-label" for="logoCGThreeSwitch">
                                        Lock Content
                                    </label>
                                    <img [ngClass] = "segmentGroup.isExpanded?'transform-180':'cursor-pointer'" src="/assets/images/chevron_down.svg" 
                                    alt="click to expand" (click)="getDetailsOfSegment(segmentGroup?.id)"/>
                                </div>
                            </div>
                            <div id="collapseThree" #collapse="ngbCollapse"
                                [(ngbCollapse)]="!segmentGroup.isExpanded" aria-labelledby="logoCGThree"
                                data-parent="#logoContentAccordian">
                                <div class="card-body">
                                    <div class="table-container">
                                    <table class="table-borderless" aria-describedby="segment table">
                                        <tr>
                                            <th scope="col">Publish Date & Time</th>
                                            <th scope="col">Expiration Date & Time</th>
                                            <th scope="col">Retailer Name</th>
                                        </tr>
                                        <tr>
                                            <td>{{segmentGroup?.configurations?.publishTime | formatDate: "12hrz"}}
                                            </td>
                                            <td>{{segmentGroup?. configurations?.expireTime | formatDate: "12hrz"}}
                                            </td>
                                            <td>{{segmentGroup?. configurations?.retailerName}}
                                            </td>
                                        </tr>
                                    </table>
                                    <span>
                                        <button type="button" class="btn btn-primary btn-sm left_icon_btn">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2H11C11.5304 2 12.0391 2.21071 12.4142 2.58579C12.7893 2.96086 13 3.46957 13 4V14C13 14.5304 12.7893 15.0391 12.4142 15.4142C12.0391 15.7893 11.5304 16 11 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V4C1 3.46957 1.21071 2.96086 1.58579 2.58579C1.96086 2.21071 2.46957 2 3 2V2ZM3 3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15H11C11.2652 15 11.5196 14.8946 11.7071 14.7071C11.8946 14.5196 12 14.2652 12 14V4C12 3.73478 11.8946 3.48043 11.7071 3.29289C11.5196 3.10536 11.2652 3 11 3H3Z" fill="white"/>
                                                <path d="M5 0H13C13.5304 0 14.0391 0.210714 14.4142 0.585786C14.7893 0.960859 15 1.46957 15 2V12C15 12.5304 14.7893 13.0391 14.4142 13.4142C14.0391 13.7893 13.5304 14 13 14V13C13.2652 13 13.5196 12.8946 13.7071 12.7071C13.8946 12.5196 14 12.2652 14 12V2C14 1.73478 13.8946 1.48043 13.7071 1.29289C13.5196 1.10536 13.2652 1 13 1H5C4.73478 1 4.48043 1.10536 4.29289 1.29289C4.10536 1.48043 4 1.73478 4 2H3C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0V0Z" fill="white"/>
                                                </svg>
                                          Duplicate & Edit
                                        </button>
                                      </span>
                                    </div>
                                    <!--<div class="totalDiv">
                                        <ng-container *ngFor="let page of segmentGroup?.configurations?.contentGroupPages">
                                            <div *ngIf="page && page.image" class="tab_dropdown_img_container">
                                              <img src={{page.image}} alt="segment" (error)="page.image = null" />
                                            </div>
                                          </ng-container>
                                      </div> -->
                                      <div class="totalDiv"> 
                                        <ng-container *ngFor="let page of segmentGroup.configurations.contentGroupPages">
                                          <div class="eachPage">
                                            <iposcen-live-preview [data]="loadMiniPreview(segmentGroup.id,page)">
                                            </iposcen-live-preview>
                                          </div>
                                        </ng-container>
                                      </div>
                                    <div class="applicability_title">
                                        Content Group Applies To:
                                      </div>
                                      <div class="applicability_roles">
                                        <div><strong>Manufacturers:</strong> {{segmentGroup.configurations.formFactor?.manufacturer?.join(', ')}} </div>
                                        <div><strong>Device Types:</strong> {{segmentGroup.configurations.formFactor?.devices?.join(', ')}} </div>
                                        <div><strong>Processors:</strong> {{segmentGroup.configurations.formFactor?.processors?.join(', ')}} </div>
                                      </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Data Footer - Pagination -->
                <div class="data_footer">
                    <nav aria-label="logo content pagination"
                        *ngIf="displaySegmentGroups && displaySegmentGroups.length>0">
                        <ngb-pagination [collectionSize]="displaySegmentGroups.length" [(page)]="page"
                            [pageSize]="pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="false">
                        </ngb-pagination>
                    </nav>
                </div>
            </div>
        </div>

    </section>

    <section *ngIf="tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4" class="">
        <ng-container>
          <div *ngIf="tabIndex === 1">
            <iposcen-create-segments (goBacktoSegmentContent)="setCreatedGrpID($event)"></iposcen-create-segments>
          </div>
          <div *ngIf="tabIndex === 2">
            <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=1
            [mediaObjs]="mediaObjects" (goBacktoSegmentContent)="refreshSegmentdata()" (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
          </div>
          <div *ngIf="tabIndex === 3">
            <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=2
            [mediaObjs]="mediaObjects" (goBacktoSegmentContent)="refreshSegmentdata()" (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
          </div>
          <div *ngIf="tabIndex === 4">
            <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=3
            [mediaObjs]="mediaObjects" (goBacktoSegmentContent)="refreshSegmentdata()" (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
          </div>
        </ng-container>
        <div class="button_wrapper mb-4 mr-4" style="justify-content: flex-end"
            *ngIf="tabIndex === 2 || tabIndex === 3|| tabIndex === 4">
            <button type="button" class="btn btn-outline-primary left_icon_btn" (click)="addPage()" [disabled]="checkPages()">
                <img src="assets/images/plus_square_outline.svg" alt="add page" />
                Add Page
            </button>
            <button type="button" class="btn btn-primary" (click)="updateSegment()">
                Save Changes
            </button>
        </div>

      </section>
    </section>
    </section>

</div>