import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalConstants } from 'src/app/common/constants/global.constant';
import { CommonService } from '../../../../common/services/common.service';
import { LivePreview } from './../../../interfaces/live-preview.i';

@Component({
  selector: 'iposcen-live-preview',
  templateUrl: './live-preview.component.html',
  styleUrls: ['./live-preview.component.scss'],
})
export class LivePreviewComponent implements OnInit, OnDestroy {
  previewData: LivePreview;
  isListPreview: boolean;
  private ngUnsubscribe = new Subject<boolean>();
  constructor(private cmnSer: CommonService) {}
  @Input() data;
  ngOnInit(): void {
    this.isListPreview = false;
    //console.log(this.data);
    this.cmnSer.pageObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.previewData = data;
        // console.log('inside subscribe', this.previewData);
      });
    // passing default values
    this.previewData = {
      bgcolor: '#0068B5',
      heading: 'HEADING',
      hcolor: '#ffffff',
      body: 'Body',
      size: 'Medium',
      media: {
        name: '',
        url: '',
        format: '',
      },
      layout: 1,
      bcolor: '#ffffff',
    };
    if (this.data) {
      this.isListPreview = true;
      this.previewData = JSON.parse(JSON.stringify(this.data));
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  fontSize(size) {
    if (size === GlobalConstants.SMALL) {
      return GlobalConstants.FONTSIZE_12PX;
    }
    if (size === GlobalConstants.MEDIUM) {
      return GlobalConstants.FONTSIZE_14PX;
    }
    if (size === GlobalConstants.LARGE) {
      return GlobalConstants.FONTSIZE_16PX;
    }
  }
}
