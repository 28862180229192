import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterConstants } from '../../../../core/routing/constants/app-paths.constant';
import { ForgotPasswordConstants } from '../constants/forgot-password.constant';
import { NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'iposcen-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss']
})

export class EmailSentComponent implements OnInit {

  @Input() fromParent;

  loginhref: string;
  emailSentForm: FormGroup;
  emailSentHeading: string;
  email: string;
  firstDescription: string;
  secondDescription: string;
  modalRef: NgbModalRef;
  modalDiv; string;
  closeText: string;
  closeResult: string;
  private sub: any;

  constructor(private router: Router,
    public route: ActivatedRoute,
    private modal: NgbModal,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.email = this.fromParent;
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.closeText = ForgotPasswordConstants.CLOSE;
    this.emailSentHeading = ForgotPasswordConstants.EMAIL_SENT;
    this.firstDescription = ForgotPasswordConstants.EMAIL_SENT_DESC_ONE;
    this.firstDescription = this.firstDescription.replace('<[email]@[domain].com>', this.email);
    this.secondDescription = ForgotPasswordConstants.EMAIL_SENT_DESC_TWO;
  }
  close() {
    this.router.navigate([RouterConstants.NEW_PWD_LINK, this.email]);
    this.activeModal.close();

  }

}
