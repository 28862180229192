import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimepickerPopoverComponent } from './datetimepicker-popover/datetimepicker-popover.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DatetimepickerPopoverComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [DatetimepickerPopoverComponent]
})
export class DateTimePickerModule { }
