import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterConstants } from '../../../core/routing/constants/app-paths.constant';
import { LANGUAGE_MAPPINGS } from '../../constants/language-mappings.const';
import { SelectLanguageConstants } from './constants/select-lang.consts';

@Component({
  selector: 'iposcen-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.scss']
})
export class SelectLangComponent implements OnInit {

  pageName: string;
  languageHeading: string;
  langForm: FormGroup;
  selectedLanguage: string;
  buttonText: string;
  loginhref: string;
  languages: string[] = [];
  language: string;
  cancel: string;
  subHeading: string;
  selectLanguage: string;
  languageJSON = [] as any;
  browserLanguage: string;
  count = 0;
  private sub: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formbuilder: FormBuilder) { }

  ngOnInit(): void {
    this.languageHeading = SelectLanguageConstants.LANGUAGE_HEADING;
    this.language = SelectLanguageConstants.LANGUAGE;
    this.cancel = SelectLanguageConstants.CANCEL;
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.selectLanguage = SelectLanguageConstants.SELECT_LANGUAGE;
    this.buttonText = SelectLanguageConstants.LANGUAGE;
    this.subHeading = SelectLanguageConstants.LANGUAGE_SUB_HEADING_DESC;
    this.languages = LANGUAGE_MAPPINGS.map(lang => lang.langName);
    this.browserLanguage = navigator.language;
    this.langForm = this.formbuilder.group({
      lang: new FormControl('', [Validators.required])
    });
    this.sub = this.route.params.subscribe(params => {
      this.pageName = params.pageName;
    });
    if (localStorage.getItem('preferredLanguage') !== undefined && localStorage.getItem('preferredLanguage') !== null) {
      this.browserLanguage = localStorage.getItem('preferredLanguage');
    }

    this.loadJSONObject(this.browserLanguage);
  }

  redirectToAccount() {
    if (this.pageName === SelectLanguageConstants.LOGIN_DEFAULT) {
      this.router.navigateByUrl(RouterConstants.LOGIN_PAGE_LINK);
    } else {
      this.router.navigateByUrl(RouterConstants.CREAT_ACCOUNT_LINK);
    }
  }

  languageSelected() {
    this.redirectToAccount();
  }

  trackByLanguage(index: number, el: string): number {
    return index;
  }

  getButtonText() {
    this.buttonText = this.selectedLanguage;
  }

  setButtonText(selectedName: string, selectedCode: string) {
    this.selectedLanguage = selectedName;
    localStorage.setItem('preferredLanguage', selectedCode);
  }

  loadJSONObject(defaultLanguage: string): void {
    LANGUAGE_MAPPINGS.forEach(element => {
      let display = false;
      if (defaultLanguage === element.langCode) {
        display = true;
      }
      const language = {
        langCode: element.langCode,
        langName: element.langName,
        checked: display
      };
      this.languageJSON.push(language);
    });
  }

}
