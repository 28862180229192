import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { PriceContentService } from '../../../core/routing/services/asset-library/price-content.service';
import { CreatePriceContentResponse, PriceContentConfiguration, PriceContent, PriceContentViewModel } from '../price-content/interfaces/price-content.i';

@Component({
  selector: 'iposcen-create-price-content',
  templateUrl: './create-price-content.component.html',
  styleUrls: ['./create-price-content.component.scss']
})
export class CreatePriceContentComponent implements OnInit, OnDestroy {
  @Output() readonly goBacktoPriceContent = new EventEmitter<object>();

  priceContentgrpForm: FormGroup;
  isPublishedDateInCorrect: boolean;
  isExpiredDateIncorrect: boolean;
  isSubmitted: boolean;
  isFormValid: boolean;
  isEdit: boolean;
  selectedPriceContentId: string;
  selectedPriceContent: PriceContentViewModel;
  publishDateValue : string;
  dateTimeValue : string;
  expiryDateTime: string;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private priceContentService: PriceContentService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dateTimeValue='';
    this.expiryDateTime ='';
    this.dataService.getPriceContent()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((priceContentToEdit: any) => {
        if (priceContentToEdit) {
          this.isEdit = true;
          this.selectedPriceContent = priceContentToEdit;
          this.selectedPriceContentId = priceContentToEdit.id;
        }
      });
    this.isSubmitted = false;
    this.createPriceContentGroupFrom();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createPriceContentGroupFrom() {
    this.priceContentgrpForm = this.formBuilder.group({
      publishedName: new FormControl((this.selectedPriceContent) ? this.selectedPriceContent.configurations.publishedName : '',
        [Validators.required, Validators.maxLength(50)]),
      publishedTimeStamp: new FormControl((this.selectedPriceContent) ? this.selectedPriceContent.configurations.publishTime : GlobalConstants.NOW),
      internalName: new FormControl((this.selectedPriceContent) ? this.selectedPriceContent.configurations.internalName : ''),
      expiryTimeStamp: new FormControl((this.selectedPriceContent) ? this.selectedPriceContent.configurations.expireTime : GlobalConstants.NEVER)
    });
  }

  //Datetimepicker popover
  getPublishDate(selectedDate){
    this.dateTimeValue = `${selectedDate[0]}, ${selectedDate[1]}`;
  }

  getExpiryNever(time){
    this.expiryDateTime = GlobalConstants.NEVER;
  }
  
  getPublishSelectedTime(time){
    this.dateTimeValue = time;
  }

  getExpirySelectedTime(selectedDate) {
    let expiryDateSmaller = false;
    let expiryDateVal = selectedDate[0];
    if (this.dateTimeValue.toString() === '') {
      // check if expiry date is smaller than current date
      const today = this.getCurrentDate();
      expiryDateSmaller = Date.parse(expiryDateVal) <
        Date.parse(today);
    } else {
      const publishDateValue = this.dateTimeValue[0];
      expiryDateSmaller = Date.parse(expiryDateVal) <
        Date.parse(publishDateValue);
    }
    this.expiryDateTime = `${selectedDate[0]}, ${selectedDate[1]}`;
    this.isExpiredDateIncorrect = expiryDateSmaller;
  }

  getCurrentDate (){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minute = today.getMinutes();
    var dateInfo =  `${mm}/${dd}/${yyyy}`;
    var time = `${hour}:${minute}`;
    var timeZone = today.toTimeString().slice(9);
    if(timeZone.length > 30){
      timeZone = timeZone.substring(0, 30);
      timeZone = `${timeZone}...`;
    };
    var currentDateResult = `${dateInfo} ${time} ${timeZone}`;
    currentDateResult = currentDateResult.toString();
    return currentDateResult;
  }

  // check if published date is smaller than today's date
  checkPublishedDateTime() {
    const today = new Date();
    let isPublishedDateSmaller;
    if (this.priceContentgrpForm.controls.publishedTimeStamp.toString() !== '') {
      isPublishedDateSmaller = new Date(this.priceContentgrpForm.controls.publishedTimeStamp.value).getTime() < new Date().getTime();
    }

    this.isPublishedDateInCorrect = isPublishedDateSmaller;
  }

  // check if expiry date is smaller than published date
  checkExpiryDateTime() {
    let expiryDateSmaller = false;
    if (this.priceContentgrpForm.controls.expiryTimeStamp.value !== '') {
      expiryDateSmaller = Date.parse(this.priceContentgrpForm.controls.expiryTimeStamp.value) <
        Date.parse(this.priceContentgrpForm.controls.publishedTimeStamp.value);
    }

    this.isExpiredDateIncorrect = expiryDateSmaller;
  }

  updatePriceContent() {
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.getCurrentDate();
    }
    const priceContent = this.selectedPriceContent as unknown as PriceContent;
    const priceContantConfig: PriceContentConfiguration = {
      publishedName: this.priceContentgrpForm.controls.publishedName.value,
      internalName: this.priceContentgrpForm.controls.internalName.value,
      publishTime: (this.dateTimeValue.toString())?this.dateTimeValue.toString():this.priceContentgrpForm.controls.publishedTimeStamp.value,
      expireTime: (this.expiryDateTime.toString())?this.expiryDateTime.toString():this.priceContentgrpForm.controls.expiryTimeStamp.value,
      contentScheduleTimezoneCode: 'CST',
      formFactor: undefined,
      type: GlobalConstants.PRICE_CONTENT_GROUP,
      contentGroupPages: this.selectedPriceContent.configurations.contentGroupPages
    };
    priceContent.configurations = JSON.stringify(priceContantConfig);
    this.priceContentService.putPriceContentById(JSON.stringify(priceContent), this.selectedPriceContentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putPriceContentResponse => {
        const putPriceContentResponseObj: CreatePriceContentResponse = putPriceContentResponse as CreatePriceContentResponse;
        this.goBacktoPriceContent.emit(putPriceContentResponseObj);
      },
        error => {
        });
  }

  createNewPriceContent() {
    if(this.dateTimeValue.toString() === ''){
      this.dateTimeValue = this.getCurrentDate();
    }
    const priceContent: PriceContentConfiguration = {
      publishedName: this.priceContentgrpForm.controls.publishedName.value,
      internalName: this.priceContentgrpForm.controls.internalName.value,
      publishTime: (this.dateTimeValue.toString())?this.dateTimeValue.toString():this.priceContentgrpForm.controls.publishedTimeStamp.value,
      expireTime: (this.expiryDateTime.toString())?this.expiryDateTime.toString():this.priceContentgrpForm.controls.expiryTimeStamp.value,
      contentScheduleTimezoneCode: 'CST',
      formFactor: undefined,
      type: GlobalConstants.PRICE_CONTENT_GROUP,
      contentGroupPages: []
    };
    this.priceContentService.createPriceContent(JSON.stringify(priceContent))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createPriceContentResponse => {
        const createPriceContentResponseObj: CreatePriceContentResponse = createPriceContentResponse as CreatePriceContentResponse;
        this.goBacktoPriceContent.emit(createPriceContentResponseObj);
      },
        error => {
    });
  }

  cancel() {
    this.isSubmitted = false;
    this.goBacktoPriceContent.emit({ cancel: true });
    this.dataService.setPriceContent(undefined);
  }

  onAdd() {
    this.isSubmitted = true;
    this.isFormValid = !this.isPublishedDateInCorrect && !this.isExpiredDateIncorrect
      && this.priceContentgrpForm?.controls?.publishedName?.value.trim() !== ''
      && !this.priceContentgrpForm?.controls?.publishedName?.errors?.maxlength;
    if (this.isFormValid) {
      if (this.selectedPriceContentId) { // Since price content id exists, it is an update process
        this.updatePriceContent();
      } else {
        this.createNewPriceContent();
      }
    }
  }
}
