<div id="newLogoContent" class="tab-pane active">

  <div class="tab-content" id="newPromoTabs-content">
    <form [formGroup]="promoContentgrpForm">
      <div class="row">
        <div class="form-group col-md-4">
          <label for="publishedName">Published Name<span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="publishedName" placeholder="Published Name"
            formControlName="publishedName" name="publishedName" maxlength="50" required />
          <div *ngIf="isSubmitted && promoContentgrpForm?.controls?.publishedName?.value.trim() === ''"
            class="formfield_err_msg"> This is required field
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="publishedTimeStamp">Publish Date & Time<span class="text-danger">*</span></label>
          <div container="body" [ngbPopover]="dateTimePicker" placement="bottom-right" [autoClose]="false"
            popoverTitle="Publish Date/Time">
            <input class="form-control" value="{{dateTimeValue}}" formControlName="publishedTimeStamp"
              id="publishedTimeStamp">
            <em class="date_icon cursor-pointer"></em>
          </div>
          <em class="err_icon"></em>
          <div *ngIf="isPublishedDateInCorrect" class="formfield_err_msg"> Published date should not be less than today
          </div>
          <span class="formfield_err_msg"></span>
          <ng-template #dateTimePicker>
            <iposcen-datetimepicker-popover (currentDateTime)="getCurrentDate($event)"
              (selectedDateTime)="getSelectedTime($event)" popoverTitle="Publish" buttonText="Use Current Date & Time">
            </iposcen-datetimepicker-popover>
          </ng-template>
        </div>
        <div class="form-group col-md-4">
          <label for="">Apply content group to</label><br/>
          <button type="button" class="btn btn-primary left_icon_btn gtmFormFactorPromo" (click)="openFormFactorsModal()">
            <img src="assets/images/laptop_icon.svg" alt="select form factors" />
            Select Form Factors
          </button>
          <iposcen-form-factors #formFactorsModal [contentName]="promoCGToDeleteName"
            (applyFormFactor)="applyFormFactor($event)"></iposcen-form-factors>
          <div *ngIf="this.selectedFormFactors" class="selected_factors">
            <img src="assets/images/laptop_icon_blue.svg" alt="selected form factors" />
            <div *ngIf="this.selectedFormFactors.allDevices">
              <div style="display:flex">
                <div style="font-weight: 700;">All Devices</div>
              </div>
            </div>
            <div *ngIf="!this.selectedFormFactors.allDevices">
              <div style="display:flex">
                <div style="font-weight: 700;">Manufacturers:</div>&nbsp;{{this.selectedFormFactors.manufacturer}}
              </div>
              <div style="display:flex">
                <div style="font-weight: 700;">Device Types:</div>&nbsp;{{this.selectedFormFactors.devices}}
              </div>
              <div style="display:flex">
                <div style="font-weight: 700;">Processors:</div>&nbsp;{{this.selectedFormFactors.processors}}
              </div>
              <div style="display:flex">
                <div style="font-weight: 700;">Generation:</div>&nbsp;{{this.selectedFormFactors.generations}}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label for="internalName">Internal Name</label>
          <input type="text" class="form-control" id="internalName" placeholder="Internal Name"
            formControlName="internalName" />
        </div>
        <div class="form-group col-md-4">
          <label for="publishedTimeStamp">Expiry Date & Time<span class="text-danger">*</span></label>
          <div container="body" [ngbPopover]="dateTimePickereExpiry" placement="top-right" container="body"
            [autoClose]="false" popoverTitle="Expiry Date/Time">
            <input class="form-control" value="{{expiryDateTime}}" formControlName="expiryTimeStamp"
              id="expiryTimeStamp">
            <em class="date_icon cursor-pointer"></em>
            <div *ngIf="isExpiredDateIncorrect" class="formfield_err_msg"> Expiry should not be less than published date
            </div>
          </div>
          <em class="err_icon"></em>
          <span class="formfield_err_msg"></span>
          <ng-template #dateTimePickereExpiry>
            <iposcen-datetimepicker-popover (currentDateTime)="getExpiryNever($event)"
              (selectedDateTime)="getExpirySelectedTime($event)" popoverTitle="Expiry" buttonText="Expire Never">
            </iposcen-datetimepicker-popover>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
  <div class="button_wrapper" style="justify-content: flex-end">
    <button type="button" (click)="cancel()" class="btn btn-outline-primary gtmCancelPromo">
      Cancel
    </button>
    <button type="button" (click)="onAdd()" class="btn btn-primary gtmSavePromo"
      [disabled]="!promoContentgrpForm?.controls?.publishedName?.value">
      Save & Proceed
    </button>
  </div>
</div>