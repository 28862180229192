<!-- Login page content -->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <a class="central_logo" [href]="loginhref"> <img class="img-fluid" alt="IPOS Central LOGO" /> </a>
      <!-- Login Section -->
      <div class="card cnfReg_card">
        <form [formGroup]="regForm" class="reg-container" (ngSubmit)="onConfirmRegistration()">
          <div class="form_header" [innerHTML]="heading"></div>
          <div class="" *ngIf="!email">
            <label class="control-label" for="confirmationCode">Email</label>
            <input id="email" type="email" maxlength="50" class="form-control" formControlName="email">
          </div>
          <div class="help-block">
            <div id="confirmation_err" class="alert alert-success" 
              *ngIf="messageNotification">{{ messageNotification }}</div>
          </div>
          <label for="confirmationCode" [innerHTML]="code"></label>
            <input class="form-control" type="text" [placeholder]="placeholderConfirmationCode" formControlName="confirmationCode">
           
          <div *ngIf="errorMessage" [class]="alertFormat">
            {{ errorMessage }}
          </div>
          
          <div class="confm_btn">
            <button type="button" (click)="resendCode()" class="resend_code btn btn-outline-primary" [innerHTML]="resend"></button>
              <button type="submit" class="btn btn-primary" [disabled]="regForm.invalid" [innerHTML]="button"></button>
            

          </div>
        
          
        </form>
      </div>
      
    </div>
  </div>
</div>