<div>
  <!-- Live preview - temporarly image -->
  <div class="row topSection mt-3 mb-3">
    <iposcen-banner-function
      *ngIf="tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5" [sidenav]="sideNav"
      (goBacktoBannerContent)="setTab($event)" (selectedOption)="setTabNavigation($event)"></iposcen-banner-function>
    <!-- Layout of preview -->
    <div class="preview_layout" *ngIf="tabIndex === 3 || tabIndex === 4 || tabIndex === 5">
      <div class="card">
        <img src="{{lCGlayout === 1 ?  'assets/images/prev_layout_selected_1.svg': 'assets/images/prev_layout_1.svg'}}"
          alt="layout 1" (click)="setLayout(1)" />
      </div>
      <div class="card">
        <img src="{{lCGlayout === 2 ?  'assets/images/prev_layout_selected_2.svg': 'assets/images/prev_layout_2.svg'}}"
          alt="layout 2" (click)="setLayout(2)" />
      </div>
      <div class="card">
        <img src="{{lCGlayout === 3 ?  'assets/images/prev_layout_selected_3.svg': 'assets/images/prev_layout_3.svg'}}"
          alt="layout 3" (click)="setLayout(3)" />
      </div>
    </div>
  </div>

  <ul *ngIf="(tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5)"
    class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 1}">New Banner Carousel Group</a>
    </li>
    <li class="nav-item" *ngIf="tabIndex === 2">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 2}"
        (click)="clickable?setIndex(2):false">URL EXPERIENCE</a>
    </li>
    <li class="nav-item" *ngIf="!(tabIndex === 1 || tabIndex === 2)">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 3}"
        (click)="clickable?setIndex(3):false">PAGE 1</a>
    </li>
    <li class="nav-item" *ngIf="pages?.page2 && !(tabIndex === 1 || tabIndex === 2)">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 4}"
        (click)="clickable?setIndex(4):false">PAGE 2</a>
    </li>
    <li class="nav-item" *ngIf="pages?.page3 && !(tabIndex === 1 || tabIndex === 2)">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 5}"
        (click)="clickable?setIndex(5):false">PAGE 3</a>
    </li>
    <li class="nav-item" *ngIf="clickable && !checkPages() && !(tabIndex === 1 || tabIndex === 2)">
      <a class="nav-link" data-toggle="tab" (click)="addPage()">
        <img src="assets/images/add_black_square_outline.svg" alt="add pages" />
      </a>
    </li>
    <li class="nav-item exit_preview">
      <a class="nav-link" (click)="cancel()">
        <img src="assets/images/exit_icon.svg" alt="exit without changes" />
      </a>
    </li>
  </ul>

  <!-- Main section -->
  <section *ngIf="tabIndex === 0" class="main_section al_storebanner">
    <!-- Banner Content Group Tab -->
    <div *ngIf="tabIndex === 0" id="bannerContentGroups">
      <div class="main_section_headers">
        <svg class="header_icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9 1.5H1.5V22.5H9V1.5ZM22.5 1.5H15V9H22.5V1.5ZM22.5 15H15V22.5H22.5V15ZM0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0L9 0C9.39782 0 9.77936 0.158035 10.0607 0.43934C10.342 0.720644 10.5 1.10218 10.5 1.5V22.5C10.5 22.8978 10.342 23.2794 10.0607 23.5607C9.77936 23.842 9.39782 24 9 24H1.5C1.10218 24 0.720644 23.842 0.43934 23.5607C0.158035 23.2794 0 22.8978 0 22.5V1.5ZM13.5 1.5C13.5 1.10218 13.658 0.720644 13.9393 0.43934C14.2206 0.158035 14.6022 0 15 0L22.5 0C22.8978 0 23.2794 0.158035 23.5607 0.43934C23.842 0.720644 24 1.10218 24 1.5V9C24 9.39782 23.842 9.77936 23.5607 10.0607C23.2794 10.342 22.8978 10.5 22.5 10.5H15C14.6022 10.5 14.2206 10.342 13.9393 10.0607C13.658 9.77936 13.5 9.39782 13.5 9V1.5ZM15 13.5C14.6022 13.5 14.2206 13.658 13.9393 13.9393C13.658 14.2206 13.5 14.6022 13.5 15V22.5C13.5 22.8978 13.658 23.2794 13.9393 23.5607C14.2206 23.842 14.6022 24 15 24H22.5C22.8978 24 23.2794 23.842 23.5607 23.5607C23.842 23.2794 24 22.8978 24 22.5V15C24 14.6022 23.842 14.2206 23.5607 13.9393C23.2794 13.658 22.8978 13.5 22.5 13.5H15Z" />
        </svg>
        Banner Carousel
      </div>

      <!-- Data header with info like no. of content groups, action buttons and filters -->
      <div class="data_header" [ngClass]="(displayBannerContentGroups?.length > 0)?'':'display-block'">
        <span *ngIf="displayBannerContentGroups?.length > 0">
          <span id="dataTitle">{{displayBannerContentGroups?.length}} Banner
            Carousel
            Groups</span>
          <span *ngIf="displayBannerContentGroups?.length > 1">
            Sort by :&nbsp;
            <div ngbDropdown class="d-inline-block">
              <button ngbDropdownToggle class="btn btn-primary btn-sm" id="sortDropDown">{{sortValue}}
              </button>
              <div ngbDropdownMenu aria-labelledby="sortDropDown">
                <button ngbDropdownItem (click)="sortByDate()">Created Date</button>
                <button ngbDropdownItem (click)="sortByName()">Group Name</button>
              </div>
            </div>
          </span>
        </span>
        <span *ngIf="displayBannerContentGroups?.length === 0">Display up to 3 Banner Carousel content groups of up to 3
          pages on the home page of IPOS.</span>
        <span class="data_action">
          <button type="button" id="uploadBanner" class="btn btn-primary btn-sm left_icon_btn" (click)="addNewBannerCarousel()">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 3.5C8.63261 3.5 8.75979 3.55268 8.85355 3.64645C8.94732 3.74021 9 3.86739 9 4V8C9 8.13261 8.94732 8.25979 8.85355 8.35355C8.75979 8.44732 8.63261 8.5 8.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H8V4C8 3.86739 8.05268 3.74021 8.14645 3.64645C8.24021 3.55268 8.36739 3.5 8.5 3.5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 8C8 7.86739 8.05268 7.74021 8.14645 7.64645C8.24021 7.55268 8.36739 7.5 8.5 7.5H12.5C12.6326 7.5 12.7598 7.55268 12.8536 7.64645C12.9473 7.74021 13 7.86739 13 8C13 8.13261 12.9473 8.25979 12.8536 8.35355C12.7598 8.44732 12.6326 8.5 12.5 8.5H9V12C9 12.1326 8.94732 12.2598 8.85355 12.3536C8.75979 12.4473 8.63261 12.5 8.5 12.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12V8Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 15C10.3565 15 12.137 14.2625 13.4497 12.9497C14.7625 11.637 15.5 9.85652 15.5 8C15.5 6.14348 14.7625 4.36301 13.4497 3.05025C12.137 1.7375 10.3565 1 8.5 1C6.64348 1 4.86301 1.7375 3.55025 3.05025C2.2375 4.36301 1.5 6.14348 1.5 8C1.5 9.85652 2.2375 11.637 3.55025 12.9497C4.86301 14.2625 6.64348 15 8.5 15ZM8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16Z" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="16" height="16" transform="translate(0.5)" />
                </clipPath>
              </defs>
            </svg>
            New Banner Carousel Group
          </button>
        </span>
      </div>

      <!-- Data Content -->
      <div class="data_content">
        <div class="accordion" id="bannerContentAccordian">

          <!-- banner CG 3 panel -->
          <div
            *ngFor="let bannerContentGroup of displayBannerContentGroups | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
            class="card">
            <div class="card-header small_text" id="bannerCGThree">
              <div class="collapse_link collapsed" type="button" [attr.aria-expanded]="bannerContentGroup?.isExpanded"
                aria-controls="collapseThree">
                <div class="title_left">
                  <div class="title">{{bannerContentGroup?.configurations?.publishedName}}</div>
                  <iposcen-assetid-copy [assetID]="bannerContentGroup.id"></iposcen-assetid-copy>
                  <div class="subtitle_group">
                    <span class="subtitle">Created By: {{bannerContentGroup?.createdByUser}}</span>
                    <span class="subtitle">Last Edited By {{bannerContentGroup?.createdByUser}}:
                      {{bannerContentGroup?.updateTime | formatDate: "12hr"}}</span>
                    <span class="subtitle">Pushed to all devices:
                      {{bannerContentGroup?.ispushedToDevice ? 'Y' : 'N'}}</span>
                  </div>
                </div>
              </div>
              <div class="button_wrapper">
                <button (click)="openDeleteModal(bannerContentGroup?.id)" type="button"
                  class="btn btn-outline-primary btn-sm left_icon_btn delete_btn gtmDeleteBanner">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                      fill="#0068B5" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                      fill="#0068B5" />
                  </svg>
                  Delete
                </button>
                <iposcen-delete-popup #deleteModal [contentName]="bannerCGToDeleteName" [buttonName]="'Banner Carousel'"
                  (delete)="deleteBannerCG(bannerCGToDelete)"></iposcen-delete-popup>

                <button (click)="editBanner(bannerContentGroup?.id)" type="button"
                  class="btn btn-primary btn-sm left_icon_btn gtmEditBanner">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.293 1.29279C11.4805 1.10532 11.7348 1 12 1C12.2652 1 12.5195 1.10532 12.707 1.29279L14.707 3.29279C14.8945 3.48031 14.9998 3.73462 14.9998 3.99979C14.9998 4.26495 14.8945 4.51926 14.707 4.70679L5.707 13.7068C5.59752 13.8166 5.464 13.8994 5.317 13.9488L2.317 14.9488C2.14074 15.0078 1.95153 15.0165 1.7706 14.974C1.58966 14.9314 1.42417 14.8393 1.29268 14.7079C1.1612 14.5765 1.06894 14.4111 1.02625 14.2302C0.983555 14.0493 0.992126 13.8601 1.051 13.6838L2.051 10.6838C2.1002 10.5364 2.18305 10.4026 2.293 10.2928L11.293 1.29279ZM12 1.99979L14 3.99979L5 12.9998L2 13.9998L3 10.9998L12 1.99979Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.146 6.35448L9.646 3.85448L10.354 3.14648L12.854 5.64648L12.147 6.35448H12.146ZM3 10.0005V10.5005C3 10.6331 3.05268 10.7603 3.14645 10.854C3.24021 10.9478 3.36739 11.0005 3.5 11.0005H4V11.5005C4 11.6331 4.05268 11.7603 4.14645 11.854C4.24021 11.9478 4.36739 12.0005 4.5 12.0005H5V12.5005C5 12.6331 5.05268 12.7603 5.14645 12.854C5.24021 12.9478 5.36739 13.0005 5.5 13.0005H6V11.5005C6 11.3679 5.94732 11.2407 5.85355 11.1469C5.75979 11.0532 5.63261 11.0005 5.5 11.0005H5V10.5005C5 10.3679 4.94732 10.2407 4.85355 10.1469C4.75979 10.0532 4.63261 10.0005 4.5 10.0005H3Z"
                      fill="white" />
                  </svg>
                  Edit
                </button>
                <button type="button" class="btn btn-primary btn-sm right_icon_btn gtmSendBanner">
                  Send
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z"
                      fill="white" />
                  </svg>
                </button>
              </div>
              <div class="custom-control custom-switch collapse_link collapsed">
                <input type="checkbox" class="custom-control-input" id="bannerCGThreeSwitch"
                  [checked]="bannerContentGroup?.islocked" />
                <label class="custom-control-label" for="bannerCGThreeSwitch">
                  Lock Content
                </label>
                <img [ngClass]="bannerContentGroup?.isExpanded ? 'transform-180' : 'cursor-pointer'"
                  src="/assets/images/chevron_down.svg" alt="click to expand"
                  (click)="getDetailsOfBannerCG(bannerContentGroup?.id)" />
              </div>
            </div>
            <div id="collapseThree" #collapse="ngbCollapse" [(ngbCollapse)]="!bannerContentGroup.isExpanded"
              aria-labelledby="bannerCGThree" data-parent="#bannerContentAccordian">
              <div class="card-body">
                <!--<ng-container *ngFor="let page of bannerContentGroup.configurations.content_group_pages">
                  <div *ngIf="page && page.image" class="tab_dropdown_img_container">
                    <img src={{page.image}} alt="bannerCG3" (error)="page.image = null" />
                  </div>
                </ng-container> -->
                <div class="totalDiv">
                  <ng-container *ngFor="let page of bannerContentGroup?.configurations?.contentGroupPages">
                    <div class="eachPage">
                      <iposcen-live-preview [data]="loadMiniPreview(bannerContentGroup?.id,page)">
                      </iposcen-live-preview>
                    </div>
                  </ng-container>
                </div>
                <table class="table-borderless" aria-describedby="formFactors">
                  <tr>
                    <th scope="col">Content Group Applies To</th>
                  </tr>
                  <tr *ngIf="bannerContentGroup?.configurations?.formFactor?.allDevices">
                    <td><strong>All Devices</strong></td>
                  </tr>
                  <tr *ngIf="!bannerContentGroup?.configurations?.formFactor?.allDevices">
                    <td><strong>Manufacturers: </strong>{{bannerContentGroup?.configurations?.formFactor?.manufacturer}}
                    </td>
                  </tr>
                  <tr *ngIf="!bannerContentGroup?.configurations?.formFactor?.allDevices">
                    <td><strong>Device Types: </strong> {{bannerContentGroup?.configurations?.formFactor?.devices}}</td>
                  </tr>
                  <tr *ngIf="!bannerContentGroup?.configurations?.formFactor?.allDevices">
                    <td><strong>Processors: </strong> {{bannerContentGroup?.configurations?.formFactor?.processors}}
                    </td>
                  </tr>
                  <tr *ngIf="!bannerContentGroup?.configurations?.formFactor?.allDevices">
                    <td><strong>Generation: </strong> {{bannerContentGroup?.configurations?.formFactor?.generations}}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Data Footer - Pagination -->
      <div class="data_footer">
        <nav aria-label="banner content pagination" *ngIf="displayBannerContentGroups?.length > 0">
          <ngb-pagination [collectionSize]="displayBannerContentGroups?.length" [(page)]="page" [pageSize]="pageSize"
            [rotate]="true" [ellipses]="false" [boundaryLinks]="false"></ngb-pagination>
        </nav>
      </div>
    </div>
  </section>
  <!-- Create New banner section -->
  <section *ngIf="tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5"
    class="main_section al_newbanner">
    <ng-container>
      <div *ngIf="tabIndex === 1">
        <iposcen-create-banner-carousel [bannerFunction]="bannerFunction"
          (goBacktoBannerContent)="setCreatedGrpID($event)" (refreshBannerContentdata)="refreshBannerContentdata()">
        </iposcen-create-banner-carousel>
      </div>
      <div *ngIf="tabIndex === 2">
        <iposcen-banner-url [cntgrpdata]="contentGrpData" [contentGroupType]="pageType"></iposcen-banner-url>
      </div>
      <div *ngIf="tabIndex === 3">
        <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=1
          [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshBannerContentdata()"></iposcen-page>
      </div>
      <div *ngIf="tabIndex === 4">
        <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=2
          [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshBannerContentdata()"
          (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
      </div>
      <div *ngIf="tabIndex === 5">
        <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=3
          [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshBannerContentdata()"
          (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
      </div>
    </ng-container>
    <div class="url_button_wrapper" *ngIf="tabIndex === 2">
      <button type="button" (click)="cancel()" class="btn btn-outline-primary">
        Cancel
      </button>
      <button type="button" (click)="updateBCG()" class="btn btn-primary" [disabled]="!customURL.trim()">
        Save & Proceed
      </button>
    </div>
    <div class="button_wrapper mb-4 mr-4" style="justify-content: flex-end"
      *ngIf="tabIndex === 3 || tabIndex === 4 || tabIndex === 5">
      <button type="button" class="btn btn-outline-primary left_icon_btn gtmAddPageLogo" (click)="addPage()"
        [disabled]="checkPages()">
        <img src="assets/images/plus_square_outline.svg" alt="add page" />
        Add Page
      </button>
      <button type="button" class="btn btn-primary gtmSaveLogoContGrp" (click)="updateBCG()">
        Save & Use Content Group
      </button>
    </div>
  </section>
</div>
