import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../common/services/common.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { CreateLogoContentResponse, LogoContentConfiguration, LogoContentGroupPage } from '../logo-content/interfaces/logo-content.i';
import { LogoContentService } from '../../../core/routing/services/asset-library/logo-content.service';
import { CreatePromoContentResponse, PromoContentConfiguration } from '../promo-content/interfaces/promo-content.i';
import { PromoContentService } from '../../../core/routing/services/asset-library/promo-content.service';
import { AssetLibraryConstants } from '../../../core/routing/services/asset-library/constants/asset-library.constant';
import { environment } from '../../../../environments/environment';
import { CreatePriceContentResponse, PriceContentConfiguration } from '../price-content/interfaces/price-content.i';
import { BannerContentConfiguration, CreateBannerContentResponse } from '../banner-carousel/interface/banner-carousel.i';
import { CreateSegmentsResponse, SegmentsConfiguration, SegmentsGroupPage } from '../segments/interfaces/segments.i';
import { SegmentsService } from '../../../core/routing/services/asset-library/segments.service';
import { PriceContentService } from '../../../core/routing/services/asset-library/price-content.service';

@Component({
  selector: 'iposcen-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
  @ViewChild('colorpicker') colPicModal: ElementRef;
  @Input() cntgrpdata: any;
  @Input() contentGroupType: string;
  @Input() pageNumber: number;
  @Output() readonly goBacktoLogoContent = new EventEmitter();
  @Output() readonly goBacktoSegmentContent = new EventEmitter<object>();
  @Input() mediaObjs: any;
  @Output() readonly sendLayoutTocontentGrp = new EventEmitter<object>();
  pageGrpForm;
  isSubmitted: boolean;
  color: string;
  selSize: string;
  bgcolor: string;
  publishedName: string;
  selectedLogoContentGroupPage: LogoContentGroupPage;
  selectedContentGroupID: string;
  videoUrl: any;
  isImageSizeError: boolean;
  isImageTypeError: boolean;
  isVideoSizeError: boolean;
  isVideoTypeError: boolean;
  isVideoDurationError: boolean;
  isUploadError: boolean;

  userId = sessionStorage.getItem('userId');
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;

  private modalRef: NgbModalRef;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private promoContentService: PromoContentService,
    private cmnSer: CommonService,
    private logoContentService: LogoContentService,
    private segmentsService: SegmentsService,
    private priceContentService: PriceContentService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.contentGroupType === GlobalConstants.LOGO_CONTENT_GROUP) {
        const updateLogoContent: CreateLogoContentResponse = this.cntgrpdata as CreateLogoContentResponse;
        const logoContent = updateLogoContent.logoContent;
        this.selectedContentGroupID = logoContent.id;
        let  configurations;
        if (typeof logoContent.configurations === 'string') {
          configurations = JSON.parse(logoContent.configurations) as LogoContentConfiguration;
        } else {
          configurations = logoContent.configurations as LogoContentConfiguration;
        }
        this.selectedLogoContentGroupPage = configurations.contentGroupPages?.filter(ele => ele.pageNum === this.pageNumber)[0];
        this.publishedName = configurations.publishedName;
        this.url = this.url + AssetLibraryConstants.LOGO_CONTENTS + AssetLibraryConstants.SLASH;
      } else if (this.contentGroupType === GlobalConstants.PROMO_CONTENT_GROUP) {
        const updatePromoContent: CreatePromoContentResponse = this.cntgrpdata as CreatePromoContentResponse;
        const promoContent = updatePromoContent.promotionalContent;
        this.selectedContentGroupID = promoContent.id;
        let  configurations;

        if (typeof promoContent.configurations === 'string') {
          configurations = JSON.parse(promoContent.configurations) as PromoContentConfiguration;
        } else {
          configurations = promoContent.configurations as PromoContentConfiguration;
        }
        this.selectedLogoContentGroupPage = configurations.contentGroupPages?.filter(ele => ele.pageNum === this.pageNumber)[0];
        this.publishedName = configurations.publishedName;
        this.url = this.url + AssetLibraryConstants.PROMO_CONTENTS + AssetLibraryConstants.SLASH;
      } else if (this.contentGroupType === GlobalConstants.SEGMENTS) {
        const updateSegmentContent: CreateSegmentsResponse = this.cntgrpdata as CreateSegmentsResponse;
        const segments = updateSegmentContent.segment;
        this.selectedContentGroupID = segments.id;
        let configurations;
        if (typeof segments.configurations === 'string') {
          configurations = JSON.parse(segments.configurations) as SegmentsConfiguration;
          segments.configurations = configurations;
        } else {
          configurations = segments.configurations as SegmentsConfiguration;
        }
        this.selectedLogoContentGroupPage = configurations.contentGroupPages?.filter(ele => ele.pageNum === this.pageNumber)[0];
        this.publishedName = configurations.publishedName;
        this.url = this.url + AssetLibraryConstants.SEGMENTS + AssetLibraryConstants.SLASH;
      } else if (this.contentGroupType === GlobalConstants.PRICE_CONTENT_GROUP) {
        const updatePriceContent: CreatePriceContentResponse = this.cntgrpdata as CreatePriceContentResponse;
        const priceContent = updatePriceContent.priceContent;
        this.selectedContentGroupID = priceContent.id;
        let  configurations;

        if (typeof priceContent.configurations === 'string') {
          configurations = JSON.parse(priceContent.configurations) as PriceContentConfiguration;
        } else {
          configurations = priceContent.configurations as PriceContentConfiguration;
        }
        this.selectedLogoContentGroupPage = configurations.contentGroupPages?.filter(ele => ele.pageNum === this.pageNumber)[0];
        this.publishedName = configurations.publishedName;
        this.url = this.url + AssetLibraryConstants.PRICE_CONTENTS + AssetLibraryConstants.SLASH;
      } else if (this.contentGroupType === GlobalConstants.BANNER_CONTENT_GROUP) {
        const updateBannerContent: CreateBannerContentResponse = this.cntgrpdata as CreateBannerContentResponse;
        const bannerContent = updateBannerContent.bannerCarousel;
        this.selectedContentGroupID = bannerContent.id;
        let  configurations;
        if (typeof bannerContent.configurations === 'string') {
          configurations = JSON.parse(bannerContent.configurations) as BannerContentConfiguration;
        } else {
          configurations = bannerContent.configurations as PriceContentConfiguration;
        }
        this.selectedLogoContentGroupPage = configurations.contentGroupPages?.filter(ele => ele.pageNum === this.pageNumber)[0];
        this.publishedName = configurations.publishedName;
        this.url = this.url + AssetLibraryConstants.BANNER_CONTENTS + AssetLibraryConstants.SLASH;
      }
      // console.log('this.selectedLogoContentGroupPage:: ' + JSON.stringify(this.selectedLogoContentGroupPage));
      this.preparePageGrp();
    }, 0);
    this.cmnSer.layoutObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(selected => {
        this.pageGrpForm.layout = selected;
        this.emitPageDataToPreview();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  preparePageGrp() {

    // TODO - Currently the preview component is just using as url. So commenting this download task
    // if (this.selectedLogoContentGroupPage && this.selectedLogoContentGroupPage.linkedAsset) {
    //   // There is a linked asset present. Fetch the asset and set to the page group form
    //   if (this.contentGroupType === GlobalConstants.LOGO_CONTENT_GROUP) {
    //     this.fetchImagesForLogoCGPages(this.selectedContentGroupID, this.pageNumber.toString());
    //   } else {
    //     this.fetchImagesForPromoCGPages(this.selectedContentGroupID, this.pageNumber.toString());
    //   }
    // }
    this.pageGrpForm = {
      bgcolor: this.selectedLogoContentGroupPage ? this.selectedLogoContentGroupPage.bgColor : '#0068B5',
      heading: this.selectedLogoContentGroupPage ? this.selectedLogoContentGroupPage.title : '',
      hcolor: this.selectedLogoContentGroupPage ? this.selectedLogoContentGroupPage.titleFontColor : '#ffffff',
      body: this.selectedLogoContentGroupPage ? this.selectedLogoContentGroupPage.bodyCopy : '',
      bcolor: this.selectedLogoContentGroupPage ? this.selectedLogoContentGroupPage.bodyCopyFontColor : '#ffffff',
      size: this.selectedLogoContentGroupPage ? this.getFontSize(this.selectedLogoContentGroupPage.bodyCopyFontSize) : 'Medium',
      media: {
        url: (this.selectedLogoContentGroupPage && this.selectedLogoContentGroupPage.linkedAsset) ?
          environment.apiBaseUrlAsset + this.url + this.selectedContentGroupID + AssetLibraryConstants.SLASH + this.pageNumber.toString() : '',
        name: '',
        format: (this.selectedLogoContentGroupPage && this.selectedLogoContentGroupPage.linkedAsset) ?
        (this.selectedLogoContentGroupPage.linkedAssetFileType) ? this.selectedLogoContentGroupPage.linkedAssetFileType : '' : '',
        fileObj: ''
      },
      id: this.cntgrpdata.id,    // adding created group id,
      layout: this.selectedLogoContentGroupPage ? this.getUILayoutIDByName(this.selectedLogoContentGroupPage.UiLayout) : 1
    };
    if (this.mediaObjs.length > 0 ) {
      const thisFilteredPage = this.mediaObjs.filter(ele => (ele.pageNum === this.pageNumber) && ele.uploaded);
      if (thisFilteredPage.length > 0) {
        this.pageGrpForm.media = thisFilteredPage[0].media;
      }
    }
    this.emitPageDataToPreview('onlytopreview');
    this.sendLayoutTocontentGrp.emit(this.pageGrpForm.layout);
    // Theme - body color on top, bg color on bottom
  }

  chooseTheme(fontColor: string, bgColor: string) {
    [
      this.pageGrpForm.bgcolor,
      this.pageGrpForm.hcolor,
      this.pageGrpForm.bcolor
    ] =
      [
        bgColor,
        fontColor,
        fontColor
      ];
    this.dismiss();
    this.emitPageDataToPreview();
    // //console.log(this.pageGrpForm.bgcolor,this.pageGrpForm.heading_color, this.pageGrpForm.body_color);
  }

  openColPicModal() {
    this.modalRef = this.modalService.open(this.colPicModal);
    this.modalRef.result.then();
  }

  dismiss() {
    this.modalRef.close();
  }

  selectSize(size) {
    this.pageGrpForm.size = size;
    this.emitPageDataToPreview();
  }

  fontSize(size) {
    if (size === GlobalConstants.SMALL) { return GlobalConstants.FONTSIZE_12PX; }
    if (size ===  GlobalConstants.MEDIUM) { return GlobalConstants.FONTSIZE_14PX; }
    if (size === GlobalConstants.LARGE) { return GlobalConstants.FONTSIZE_16PX; }
  }

  fontSizeNumber(size) {
    if (size === GlobalConstants.SMALL) { return GlobalConstants.NUMBER_12; }
    if (size === GlobalConstants.MEDIUM) { return GlobalConstants.NUMBER_14; }
    if (size === GlobalConstants.LARGE) { return GlobalConstants.NUMBER_16; }
  }

  getFontSize(size) {
    if (size > GlobalConstants.NUMBER_0 && size <= GlobalConstants.NUMBER_12) { return GlobalConstants.SMALL; }
    if (size > GlobalConstants.NUMBER_12 && size <= GlobalConstants.NUMBER_14) { return GlobalConstants.MEDIUM; }
    if (size > GlobalConstants.NUMBER_14) { return GlobalConstants.LARGE; }
  }

  createLGCPage() {
    if (this.pageGrpForm.body.length > 250 || this.pageGrpForm.heading.length > 50) {
      return;
    }
    // console.log('pageGrpForm:: ' + this.pageGrpForm);
    if (this.contentGroupType === GlobalConstants.LOGO_CONTENT_GROUP) {
      const updateLogoContent: CreateLogoContentResponse = this.cntgrpdata as CreateLogoContentResponse;
      // Once page is updated.
      const logoContent = updateLogoContent.logoContent;
      const configurations = JSON.parse(logoContent.configurations) as LogoContentConfiguration;
      if (configurations.contentGroupPages[this.pageNumber - 1]) {// If there is configuration object already for the page number
        // Update the pages
        configurations.contentGroupPages[this.pageNumber - 1] = {
          pageNum: this.pageNumber,
          title: this.pageGrpForm.heading,
          titleFontColor: this.pageGrpForm.hcolor,
          bgColor: this.pageGrpForm.bgcolor,
          bodyCopy: this.pageGrpForm.body,
          bodyCopyFontSize: this.fontSizeNumber(this.pageGrpForm.size),
          bodyCopyFontColor: this.pageGrpForm.bcolor,
          UiLayout: this.getUILayoutNameByID(this.pageGrpForm.layout),
          linkedAsset: this.pageGrpForm.media.name !== '',
          linkedAssetFileType: ''
        };
      } else {
        const logoContentGroupPage: LogoContentGroupPage = {
          pageNum: this.pageNumber,
          title: this.pageGrpForm.heading,
          titleFontColor: this.pageGrpForm.hcolor,
          bgColor: this.pageGrpForm.bgcolor,
          bodyCopy: this.pageGrpForm.body,
          bodyCopyFontSize: this.fontSizeNumber(this.pageGrpForm.size),
          bodyCopyFontColor: this.pageGrpForm.bcolor,
          UiLayout: this.getUILayoutNameByID(this.pageGrpForm.layout),
          linkedAsset: this.pageGrpForm.media.name !== '',
          linkedAssetFileType: ''
        };
        configurations.contentGroupPages.push(logoContentGroupPage);
      }
      logoContent.configurations = JSON.stringify(configurations);

      this.logoContentService.putLogoContentById(JSON.stringify(logoContent), updateLogoContent.logoContent.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(first())
        .subscribe(putLogoContentResponse => {
          // console.log('putLogoContentResponse:: ' + JSON.stringify(putLogoContentResponse));
          // Once the update call is successful, call a PUT call for the logo
          if (this.pageGrpForm.media.url.indexOf(environment.apiBaseUrlAsset) < 0) { // Check if current url is already a S3 url, then dont make another put call
            this.logoContentService.putS3LogoContentByPageId(this.pageGrpForm.media.fileObj,
              this.pageGrpForm.media.name.split('.')
                .pop(), updateLogoContent.logoContent.id, this.pageNumber.toString())
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(() => {
                this.goBacktoLogoContent.emit(); // which will refresh  LCG dataset
              },
                error => {
                  // do something
                });
          } else {
            this.goBacktoLogoContent.emit(); // which will refresh  LCG dataset
          }
        },
          error => {
          });

    } else if (this.contentGroupType === GlobalConstants.PROMO_CONTENT_GROUP) {
      const updatePromoContent: CreatePromoContentResponse = this.cntgrpdata as CreatePromoContentResponse;
      // Once page is updated.
      const promoContent = updatePromoContent.promotionalContent;
      const configurations = JSON.parse(promoContent.configurations) as PromoContentConfiguration;
      if (configurations.contentGroupPages[this.pageNumber - 1]) {// If there is configuration object already for the page number
        // Update the pages
        configurations.contentGroupPages[this.pageNumber - 1] = {
          pageNum: this.pageNumber,
          title: this.pageGrpForm.heading,
          titleFontColor: this.pageGrpForm.hcolor,
          bgColor: this.pageGrpForm.bgcolor,
          bodyCopy: this.pageGrpForm.body,
          bodyCopyFontSize: this.fontSizeNumber(this.pageGrpForm.size),
          bodyCopyFontColor: this.pageGrpForm.bcolor,
          UiLayout: this.getUILayoutNameByID(this.pageGrpForm.layout),
          linkedAsset: this.pageGrpForm.media.name !== '',
          linkedAssetFileType: ''
        };
      } else {
        const logoContentGroupPage: LogoContentGroupPage = {
          pageNum: this.pageNumber,
          title: this.pageGrpForm.heading,
          titleFontColor: this.pageGrpForm.hcolor,
          bgColor: this.pageGrpForm.bgcolor,
          bodyCopy: this.pageGrpForm.body,
          bodyCopyFontSize: this.fontSizeNumber(this.pageGrpForm.size),
          bodyCopyFontColor: this.pageGrpForm.bcolor,
          UiLayout: this.getUILayoutNameByID(this.pageGrpForm.layout),
          linkedAsset: this.pageGrpForm.media.name !== '',
          linkedAssetFileType: ''
        };
        configurations.contentGroupPages.push(logoContentGroupPage);
      }
      promoContent.configurations = JSON.stringify(configurations);

      this.promoContentService.putPromoContentById(JSON.stringify(promoContent), updatePromoContent.promotionalContent.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(first())
        .subscribe(putLogoContentResponse => {
          // console.log('putLogoContentResponse:: ' + JSON.stringify(putLogoContentResponse));
          // Once the update call is successful, call a PUT call for the logo
          if (this.pageGrpForm.media.url.indexOf(environment.apiBaseUrlAsset) < 0) { // Check if current url is already a S3 url, then dont make another put call
            this.promoContentService.putS3PromoContentByPageId(this.pageGrpForm.media.fileObj,
              this.pageGrpForm.media.name.split('.')
                .pop(), updatePromoContent.promotionalContent.id, this.pageNumber.toString())
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(() => {
                this.goBacktoLogoContent.emit(); // which will refresh  LCG dataset
              },
                error => {
                  // do something
                });
          } else {
            this.goBacktoLogoContent.emit(); // which will refresh  LCG dataset
          }
        },
          error => {
          });
    } else if (this.contentGroupType === GlobalConstants.SEGMENTS) {
      const updateSegmentContent: CreateSegmentsResponse = this.cntgrpdata as CreateSegmentsResponse;
      // Once page is updated.
      const segmentContent = updateSegmentContent.segment;
      const configurations = JSON.parse(segmentContent.configurations) as SegmentsConfiguration;
      if (configurations.contentGroupPages[this.pageNumber - 1]) {// If there is configuration object already for the page number
        // Update the pages
        configurations.contentGroupPages[this.pageNumber - 1] = {
          pageNum: this.pageNumber,
          title: this.pageGrpForm.heading,
          titleFontColor: this.pageGrpForm.hcolor,
          bgColor: this.pageGrpForm.bgcolor,
          bodyCopy: this.pageGrpForm.body,
          bodyCopyFontSize: this.fontSizeNumber(this.pageGrpForm.size),
          bodyCopyFontColor: this.pageGrpForm.bcolor,
          UiLayout: this.getUILayoutNameByID(this.pageGrpForm.layout),
          linkedAsset: this.pageGrpForm.media.name !== '',
          linkedAssetFileType: ''
        };
      } else {
        const segmentsGroupPage: SegmentsGroupPage = {
          pageNum: this.pageNumber,
          title: this.pageGrpForm.heading,
          titleFontColor: this.pageGrpForm.hcolor,
          bgColor: this.pageGrpForm.bgcolor,
          bodyCopy: this.pageGrpForm.body,
          bodyCopyFontSize: this.fontSizeNumber(this.pageGrpForm.size),
          bodyCopyFontColor: this.pageGrpForm.bcolor,
          UiLayout: this.getUILayoutNameByID(this.pageGrpForm.layout),
          linkedAsset: this.pageGrpForm.media.name !== '',
          linkedAssetFileType: ''
        };
        configurations.contentGroupPages.push(segmentsGroupPage);
      }
      segmentContent.configurations = JSON.stringify(configurations);

      this.segmentsService.editSegmentById(JSON.stringify(segmentContent), updateSegmentContent.segment.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(first())
        .subscribe(putLogoContentResponse => {
          // console.log('putLogoContentResponse:: ' + JSON.stringify(putLogoContentResponse));
          // Once the update call is successful, call a PUT call for the logo
          if (this.pageGrpForm.media.url.indexOf(environment.apiBaseUrlAsset) < 0) { // Check if current url is already a S3 url, then dont make another put call
            this.segmentsService.editSegmentByPageId (this.pageGrpForm.media.fileObj,
              this.pageGrpForm.media.name.split('.')
                .pop(), updateSegmentContent.segment.id, this.pageNumber.toString())
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(() => {
                this.goBacktoSegmentContent.emit(); // which will refresh  LCG dataset
              },
                error => {
                  // do something
                });
          } else {
            this.goBacktoSegmentContent.emit(); // which will refresh  LCG dataset
          }
        },
          error => {
          });
    }
  }

  getUILayoutNameByID(id) {// TODO - Confirm with Sujay if layout name is correct
    if (id === 1) {
      return GlobalConstants.TWO_COL_RIGHT_ASSET;
    } else if (id === 2) {
      return GlobalConstants.TWO_COL_LEFT_ASSET;
    } else if (id === 3) {
      return  GlobalConstants.ONE_COL_MID_ASSET;
    }
  }

  getUILayoutIDByName(name) {// TODO - Confirm with Sujay if layout name is correct
    if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
      return 1;
    } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
      return 2;
    } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
      return 3;
    }
  }

  processFile(files) {
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.pageGrpForm.media.name = files[0].name;
    this.pageGrpForm.media.fileObj = files[0];
    if (files[0].type.indexOf(GlobalConstants.FILE_TYPE_IMAGE) > -1) {
      this.pageGrpForm.media.format = GlobalConstants.FILE_TYPE_IMAGE;
      const fileType = files[0].type.split('/')[1];
      this.isImageSizeError = files[0].size > 3145728; // TODO: Move this to constants
      this.isImageTypeError = fileType !== GlobalConstants.CONTENT_TYPE_PNG &&
        fileType !== GlobalConstants.CONTENT_TYPE_JPEG &&
        fileType !== GlobalConstants.CONTENT_TYPE_GIF;
      this.isUploadError = (this.isImageSizeError || this.isImageTypeError || (this.isImageSizeError && this.isImageTypeError));
      this.isVideoTypeError = false;
      this.isVideoSizeError = false;
      this.isVideoDurationError = false;
    } else if (files[0].type.indexOf(GlobalConstants.FILE_TYPE_VIDEO) > -1) {
      this.pageGrpForm.media.format = GlobalConstants.FILE_TYPE_VIDEO;
      const fileType = files[0].type.split('/')[1];
      this.isVideoTypeError = fileType !== GlobalConstants.CONTENT_TYPE_MP4;
      this.isVideoSizeError = files[0].size > 10485760; // TODO: Move this to constants
      this.isUploadError = (this.isVideoSizeError || this.isVideoTypeError || (this.isVideoSizeError && this.isVideoTypeError));
      this.isImageTypeError = false;
      this.isImageSizeError = false;
    } else {
      this.isImageTypeError = true;
      this.isVideoTypeError = true;
      this.isUploadError = true;
      this.isImageSizeError = false;
      this.isVideoSizeError = false;
      this.isVideoDurationError = false;
    }

    // ////console.log(files[0]);
    reader.onload = _event => {
      if (this.isUploadError) {
        this.pageGrpForm.media.url = '';
      } else {
      this.pageGrpForm.media.url = reader.result;
      }
      this.videoUrl = reader.result;
      this.emitPageDataToPreview();
    };
  }

  getVideoDuration(event) {
    const duration = event.target.duration;
    this.isVideoDurationError = duration > 60;
    this.isUploadError = this.isVideoSizeError || this.isVideoTypeError || this.isVideoDurationError ||
      (this.isVideoSizeError && this.isVideoTypeError && this.isVideoDurationError); // TODO: Simplify this
    if (this.isUploadError) {
      this.pageGrpForm.media.url = '';
      this.emitPageDataToPreview();
    }
  }

  emitPageDataToPreview(onlytopreview?: string) {
    // TODO
    // console.log('emitdata to preview');
    if (onlytopreview) {
      this.cmnSer.emitPageData(this.contentGroupType, this.pageNumber, this.pageGrpForm, onlytopreview);
    } else {
      this.cmnSer.emitPageData(this.contentGroupType, this.pageNumber, this.pageGrpForm);
    }
  }

  isSelected(bcolor, bgcolor) {
    if (this.pageGrpForm.bcolor === bcolor && this.pageGrpForm.bgcolor === bgcolor) {
      return true;
    }

    return false;
  }

  fetchImagesForLogoCGPages(logoCGId: string, pageNum: string) {
    const reader = new FileReader();
    this.logoContentService.getLogoContentByPageId(logoCGId, pageNum)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(imageData => {
        reader.addEventListener('load', () => {
          this.pageGrpForm.media.url = reader.result;
          this.pageGrpForm.media.name = 'name'; // TODO - Need way to get asset name
          this.pageGrpForm.media.format = 'image'; // TODO - Need way to get asset format - image or video
          this.emitPageDataToPreview();
        }, false);

        if (imageData) {
          reader.readAsDataURL(imageData);
        }
      },
        error => {
          // do something
        });
  }

  fetchImagesForPromoCGPages(promoCGId: string, pageNum: string) {
    const reader = new FileReader();

    this.promoContentService.getPromoContentByPageId(promoCGId, pageNum)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(imageData => {
        reader.addEventListener('load', () => {
          this.pageGrpForm.media.url = reader.result;
          this.pageGrpForm.media.name = 'name'; // TODO - Need way to get asset name
          this.pageGrpForm.media.format = 'image'; // TODO - Need way to get asset format - image or video
          this.emitPageDataToPreview();
        }, false);

        if (imageData) {
          reader.readAsDataURL(imageData);
        }
      },
      error => {
        // do something
      });
  }
}
