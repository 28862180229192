import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, format: string): any {
    if (format === '12hr') {
      return moment(value)
      .format('MMM DD, YYYY - hh:mm A');
    } else if (format === '12hrz') {
      return moment.tz(value, 'America/Los_Angeles')
      .format('MMM DD, YYYY - hh:mm A zz');
    } else if (format === 'MM/DD/YY z'){
      return moment.tz(value, 'America/Los_Angeles')
      .format('MM/DD/YY hh:mm A zz');
    }
  }

}
