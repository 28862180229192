import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../../core/data/data-service';
import { LogoContentService } from '../../../../core/routing/services/asset-library/logo-content.service';
import { CommonService } from '../../../../common/services/common.service';
import { StoreLogoService } from '../../../../core/routing/services/asset-library/store-logo.service';
import { GlobalConstants } from './../../../../common/constants/global.constant';
import { GetStoreLogoByUserResponse, StoreLogoViewModel, StoreLogoConfiguration, CreateStoreLogoResponse } from '../../../../modules/asset-library/logo-content/interfaces/store-logo.i';

@Component({
  selector: 'iposcen-profile-storelogo',
  templateUrl: './profile-storelogo.component.html',
  styleUrls: ['./profile-storelogo.component.scss']
})
export class ProfileStorelogoComponent implements OnInit, OnDestroy {
  @Output() readonly setLogoContentTab = new EventEmitter<any>();
  @Output() readonly setTabIndex = new EventEmitter<any>();
  @Output() readonly disabledLogoContentTab = new EventEmitter<any>();

  tabIndex = 0;
  defaultStoreLogos: StoreLogoViewModel[] = [];
  otherStoreLogos: StoreLogoViewModel[] = [];
  selectedLogoForProfile: any = [];
  showOnDevice: boolean;
  dataToProfile: any;
  storeLogoBgColor: string;
  isImageSizeError: boolean;
  isImageTypeError: boolean;
  isImageUploadError: boolean;
  isimageFormat: boolean;
  livePreviewData: any = [];
  skip: string;
  send: string;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private router: Router,
    private storeLogoService: StoreLogoService,
    private cmnService: CommonService,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.skip = GlobalConstants.SKIP;
    this.send = GlobalConstants.SEND;
    this.storeLogoBgColor = GlobalConstants.STORELOGO_BGCOLOR;
    this.getAllStoreLogosByUser();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setIndex(value: number) {
    this.tabIndex = value;
  }

  setStoreLogo(logo: any) {
    if (this.defaultStoreLogos.length < 1) {
      this.otherStoreLogos = this.otherStoreLogos.filter(({id}) => id !== logo.id);
      this.defaultStoreLogos.push(logo);
      this.defaultStoreLogos[0].isdefault = true;
    } else {
      this.otherStoreLogos = this.otherStoreLogos.filter(({id}) => id !== logo.id);
      const data = this.defaultStoreLogos.pop();
      this.defaultStoreLogos.push(logo);
      this.defaultStoreLogos[0].isdefault = true;
      this.otherStoreLogos.push(data);
    }
    this.setLivePreview();
  }

  getAllStoreLogosByUser() {
    this.storeLogoService.getAllStoreLogoByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllStoreLogoResponse => {
        const storeLogos = [];
        const getStoreLogoByUserResponse: GetStoreLogoByUserResponse = getAllStoreLogoResponse as GetStoreLogoByUserResponse;

        getStoreLogoByUserResponse.storeLogo.forEach(storeLogo => {
          const reader = new FileReader();
          const storeLogoViewModel: StoreLogoViewModel = storeLogo as StoreLogoViewModel;
          this.storeLogoService.getStoreLogoById(storeLogo.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .pipe(first())
            .subscribe(imageData => {
              reader.addEventListener('load', () => {
                storeLogoViewModel.imageData = reader.result;
                const storeLogoConfiguration = JSON.parse(storeLogo.configurations) as StoreLogoConfiguration;
                storeLogoViewModel.fileName = storeLogoConfiguration.fileName;
                storeLogos.push(storeLogoViewModel);
                storeLogos.forEach(ele => {
                  ele.updateTime = new Date(ele.updateTime);
                  ele.updateTimeDisplay = new Date(ele.updateTime).toLocaleString()
                    .replace(',', '');
                });
                this.defaultStoreLogos = storeLogos.filter(ele => ele.isdefault);
                this.otherStoreLogos = storeLogos.filter(ele => !ele.isdefault);
                this.setLivePreview();
                if (this.defaultStoreLogos.length === 0) {
                  this.disabledLogoContentTab.emit(true);
                }
              }, false);

              if (imageData) {
                reader.readAsDataURL(imageData);
              }
            },
              error => {
                // do something
              });
        });
      },
        error => {
          if (this.defaultStoreLogos.length === 0) {
            this.disabledLogoContentTab.emit(true);
          }
        });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const fileType = file.type.split('/')[1];
    if (file.type.split('/')[0] === GlobalConstants.FILE_TYPE_IMAGE ) {
    this.isImageSizeError = file.size > GlobalConstants.UPLOAD_LOGO_IMAGE_SIZE_3MB;
    this.isImageTypeError = fileType !== GlobalConstants.CONTENT_TYPE_JPEG &&
      fileType !== GlobalConstants.CONTENT_TYPE_PNG &&
      fileType !== GlobalConstants.CONTENT_TYPE_GIF;
    this.isImageUploadError = (this.isImageSizeError || this.isImageTypeError || (this.isImageSizeError && this.isImageTypeError));
    this.isimageFormat = true;
  } else {
    this.isimageFormat = false;
    this.isImageTypeError = true;
    this.isImageUploadError = true;
  }
    if (!this.isImageUploadError) {
      reader.addEventListener('load', (event: any) => {
        const storeLogo = {
          fileName: file.name
        };
        this.storeLogoService.createStoreLogo(JSON.stringify(storeLogo))
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(createStoreLogo => {
            const createStoreLogoResponse: CreateStoreLogoResponse = createStoreLogo as CreateStoreLogoResponse;
            const storeLogoViewModel: StoreLogoViewModel = createStoreLogoResponse.storeLogo as StoreLogoViewModel;
            this.storeLogoService.uploadImage(file, createStoreLogoResponse.storeLogo.id, file.name.split('.')
              .pop())
              .pipe(takeUntil(this.ngUnsubscribe))
              .pipe(first())
              .subscribe(() => {
                if (!this.otherStoreLogos || (this.otherStoreLogos && this.otherStoreLogos.length === 0)) {
                  this.otherStoreLogos = [];
                }
                storeLogoViewModel.imageData = event.target.result;
                storeLogoViewModel.fileName = file.name;
                storeLogoViewModel.updateTime = new Date(storeLogoViewModel.updateTime);
                storeLogoViewModel.updateTimeDisplay = new Date(storeLogoViewModel.updateTime).toLocaleString()
                  .replace(',', '');
                this.otherStoreLogos.push(storeLogoViewModel);
              },
              error => {
                // do something
              });
          },
          error => {
            // do something
          });
    });
  }

    reader.readAsDataURL(file);
  }

  sendToProfile(action: string) {
    if (action === GlobalConstants.SEND && this.defaultStoreLogos.length > 0) {
      this.selectedLogoForProfile = [];
      this.selectedLogoForProfile.push({
        storeLogoId: this.defaultStoreLogos[0].id,
        showOnDevice: true
      });
      this.cmnService.emitSlDataToProfile(this.selectedLogoForProfile);
      this.setLogoContentTab.emit(GlobalConstants.CREATE_PROFILE);
      this.setTabIndex.emit(1);
    }
    if (action === GlobalConstants.SKIP) {
      this.cmnService.emitSlDataToProfile(GlobalConstants.SKIP);
    }
  }

  setLivePreview() {
    this.livePreviewData = [];
    this.livePreviewData.push({
      imageData: this.defaultStoreLogos[0]?.imageData,
      bgColor: this.storeLogoBgColor
    });
    this.cmnService.emitLivePreviewDataToProfile(this.livePreviewData);
  }

  updateBgColor(value: string) {
    this.storeLogoBgColor = value;
    this.setLivePreview();
  }
}
