<section class="">
  <!-- Tabs section associated with preview -->
  <div class="preview_control">
    <div id="newSegmentTabs-content" class="tab-content newSegmentTabs-content">
      <div class="tab-pane fade show active" id="segmentAddContent" role="tabpanel">
        <div class="pages_default_tab">
          <form [formGroup]="segmentsForm">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="headline">Headline<span class="text-danger">*</span></label><span  class="formfield_limit_msg"> (Max 50 characters) </span>
                  <input type="text" class="form-control" id="headline" formControlName="headline" name="headline"
                    required placeholder="Enter Headline" maxlength="50" />
                  <div *ngIf="isSubmitted && segmentsForm?.controls?.headline?.value.trim() === ''"
                    class="formfield_err_msg"> This is a required field </div>
                  <div *ngIf="isSubmitted && segmentsForm?.controls?.headline?.errors?.maxlength"
                    class="formfield_err_msg">Headline may be up to 50 characters long</div>
                </div>
                <div class="form-group">
                  <label for="subHeadline">Subheadline</label><span  class="formfield_limit_msg"> (Max 50 characters) </span>
                  <input type="text" class="form-control" id="subHeadline" formControlName="subHeadline" name="subHeadline" placeholder="Enter Subheadline" maxlength="50" />
                  <div *ngIf="isSubmitted && segmentsForm?.controls?.subHeadline?.errors?.maxlength"
                    class="formfield_err_msg">Subheadline may be up to 50 characters long</div>
                </div>
                <div class="form-group">
                  <label for="">Retailer Name</label><span  class="formfield_limit_msg"> (Max 50 characters) </span>
                  <input type="text" class="form-control" id="retailerName" formControlName="retailerName" name="retailerName"  placeholder="Enter Retailer Name" maxlength="50" />
                  <div *ngIf="isSubmitted && segmentsForm?.controls?.retailerName?.errors?.maxlength"
                    class="formfield_err_msg">Retailer Name may be up to 50 characters long</div>
                </div>
              </div>
              <div class="col-md-3 segment_color_column">
                <div class="form-group">
                  <label for="">Segment Theme</label>
                  <div class="theme_picker_button default cursor-pointer" (click)="openColPicModal()" >
                    <span class="text_color" [style.background-color]="segmentThemeForm.hcolor"></span>
                    <span class="bg_color" [style.background-color]="segmentThemeForm.bgcolor"></span>
                  </div>
                </div>
                <div class="segment_color_row">
                  <div class="form-group">
                    <label for="">Segment Color</label>
                    <div class="color_picker_button cursor-pointer" [cpPosition]="'right'" [(colorPicker)]="segmentThemeForm.bgcolor"
                      [style.background]="segmentThemeForm.bgcolor" name="bgcolor"></div>
                  </div>
                  <div class="form-group">
                    <label for="">
                      Segment Font Color
                      <a class="info_icon" tabindex="0" ngbPopover="To help with legibility pick a font color that will contrast with the Segment color." triggers="mouseenter:mouseleave" >
                        <img src="" class="upload_req_img" alt="upload requirements" />
                      </a>
                    </label>
                    <div class="color_picker_button cursor-pointer" [cpPosition]="'right'" [(colorPicker)]="segmentThemeForm.hcolor"
                      [style.background]="segmentThemeForm.hcolor" name="hcolor"></div>
                  </div>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="isApplyToIpos" name="isApplyToIpos" formControlName="isApplyToIpos" />
                  <label class="form-check-label" for="isApplyToIpos" style="color: #262626">
                    Apply Segment Color to IPOS
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="segment_date_row">
                  <div class="form-group">
                    <label for="">Apply content group to</label>
                    <button type="button" class="btn btn-primary btn-sm left_icon_btn gtmFormFactorSeg">
                      <img src="assets/images/laptop_icon.svg" alt="select form factors" />
                      Select Form Factors
                    </button>
                    <!-- TODO  -->
                    <!-- <div class="selected_factors">
                    <img src="assets/images/laptop_icon_blue.svg" alt="selected form factors" />
                    <div>
                      <div><b>Manufacturers:</b>&nbsp;Acer, Asus</div>
                      <div><b>Device Types:</b>&nbsp;Laptop, 2 in 1</div>
                    </div>
                  </div> -->
                  </div>
                  <div class="date_group">
                    <div class="form-group factors_group">
                      <label for="">Publish Date & Time</label>
                      <input class="form-control timestamp_picker" type="date" class="form-control"
                        id="publishedTimeStamp" placeholder="Now" formControlName="publishedTimeStamp"
                        (change)="checkPublishedDateTime()">
                      <div *ngIf="isSubmitted && isPublishedDateInCorrect" class="formfield_err_msg"> Published date
                        should not be less than today </div>
                    </div>
                    <div class="form-group">
                      <label for="">Expiration Date & Time</label>
                      <input class="form-control timestamp_picker" type="date" id="expiryTimeStamp" placeholder="Never"
                        formControlName="expiryTimeStamp" (change)="checkExpiryDateTime()" />
                      <div *ngIf="isSubmitted && isExpiredDateIncorrect" class="formfield_err_msg"> Expiry should not be
                        less than published date</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="button_wrapper" style="justify-content: flex-end">
              <button type="button" (click)="cancel()" class="btn btn-outline-primary gtmCancelSeg">
                Cancel
              </button>
              <button type="button" class="btn btn-primary gtmSaveSeg" (click)="onAdd()"
                [disabled]="!segmentsForm?.controls?.headline?.value">
                Save & Proceed
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #colorpicker>
  <div class="card">
    <div class="modal-header p-0">
      <div (click)="dismiss()" class="close_icon popup_close cursor-pointer">
        <img src=".../../../../../../../assets/images/popup_close_button.svg" alt="close icon"/>
      </div>
    </div>
    <div class="modal-body p-0">
      <div id="themePick" class="card">
        <div class="theme_pick_header" [style.background-color]="segmentThemeForm.bgcolor">
          <div class="theme_pick_title" [style.color]="segmentThemeForm.hcolor">
            Segment Heading</div>
        </div>
        <div class="theme_color mb-3">
          <div>
            <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#0068B5')">
              <span class="text_color"></span>
              <span class="bg_color"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#0068B5' )}"></span>
          </div>
          <div>
            <div class="theme_picker_button default" (click)="chooseTheme('#0068B5','#ffffff')">
              <span class="text_color" style="background-color:#0068B5"></span>
              <span class="bg_color" style="background-color: #ffffff"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#0068B5','#ffffff')}"></span>
          </div>
          <div>
            <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#00C7FD')">
              <span class="text_color"></span>
              <span class="bg_color" style="background-color: #00C7FD"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#00C7FD')}"></span>
          </div>
          <div>
            <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#8BAE46')">
              <span class="text_color"></span>
              <span class="bg_color" style="background-color: #8BAE46"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#8BAE46')}"></span>
          </div>
        </div>
        <div class="theme_color">
          <div>
            <div class="theme_picker_button default" style="margin-bottom:0" (click)="chooseTheme('#ffffff','#8F5DA2')">
              <span class="text_color"></span>
              <span class="bg_color" style="background-color: #8F5DA2"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#8F5DA2')}"></span>
          </div>
          <div>
            <div class="theme_picker_button default" style="margin-bottom:0" (click)="chooseTheme('#ffffff','#EDB200')">
              <span class="text_color"></span>
              <span class="bg_color" style="background-color: #EDB200"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#EDB200')}"></span>
          </div>
          <div>
            <div class="theme_picker_button default" style="margin-bottom:0" (click)="chooseTheme('#ffffff','#1E2EB8')">
              <span class="text_color"></span>
              <span class="bg_color" style="background-color: #1E2EB8"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#1E2EB8')}"></span>
          </div>
          <div>
            <div class="theme_picker_button default" style="margin-right:0" (click)="chooseTheme('#ffffff','#D96930')">
              <span class="text_color"></span>
              <span class="bg_color" style="background-color: #D96930"></span>
            </div>
            <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#D96930')}"></span>
          </div>
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-outline-primary" (click)="chooseTheme('#ffffff','#0068B5')">
            Use Default
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>