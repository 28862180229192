export const countryConstants =  [
    {
        CountryName: 'Algeria'
    } ,
    {
        CountryName: 'Argentina'
    } ,
    {
        CountryName: 'Australia'
    } ,
    {
        CountryName: 'Austria'
    } ,
    {
        CountryName: 'Belgium'
    } ,
    {
        CountryName: 'Brazil'
    } ,
    {
        CountryName: 'Canada'
    } ,
    {
        CountryName: 'Chile'
    } ,
    {
        CountryName: 'China'
    } ,
    {
        CountryName: 'Colombia'
    } ,
    {
        CountryName: 'Czech Republic'
    },
    {
        CountryName: 'Denmark'
    },
    {
        CountryName: 'Egypt'
    },
    {
        CountryName: 'Finland'
    },
    {
        CountryName: 'France'
    },
    {
        CountryName: 'Germany'
    },
    {
        CountryName: 'Greece'
    },
    {
        CountryName: 'Hong Kong'
    },
    {
        CountryName: 'Hungary'
    },
    {
        CountryName: 'India'
    },
    {
        CountryName: 'Indonesia'
    },
    {
        CountryName: 'Ireland'
    },
    {
        CountryName: 'Israel'
    },
    {
        CountryName: 'Italy'
    },
    {
        CountryName: 'Japan'
    },
    {
        CountryName: 'Kazhakhstan'
    },
    {
        CountryName: 'Kenya'
    },
    {
        CountryName: 'Korea'
    },
    {
        CountryName: 'Lebanon'
    },
    {
        CountryName: 'Malaysia'
    },
    {
        CountryName: 'Mexico'
    },
    {
        CountryName: 'Morocco'
    },
    {
        CountryName: 'Netherlands'
    },
    {
        CountryName: 'New Zealand'
    },
    {
        CountryName: 'Norway'
    },
    {
        CountryName: 'Nigeria'
    },
    {
        CountryName: 'Pakistan'
    },
    {
        CountryName: 'Peru'
    },
    {
        CountryName: 'Philippines'
    },
    {
        CountryName: 'Poland'
    },
    {
        CountryName: 'Portugal'
    },
    {
        CountryName: 'Romania'
    },
    {
        CountryName: 'Russia'
    },
    {
        CountryName: 'Saudi Arabia'
    },
    {
        CountryName: 'Slovakia'
    },
    {
        CountryName: 'Singapore'
    },
    {
        CountryName: 'South Africa'
    },
    {
        CountryName: 'Spain'
    },
    {
        CountryName: 'Sri Lanka'
    },
    {
        CountryName: 'Sweden'
    },
    {
        CountryName: 'Switzerland'
    },
    {
        CountryName: 'Taiwan'
    },
    {
        CountryName: 'Thailand'
    },
    {
        CountryName: 'Turkey'
    },
    {
        CountryName: 'Ukraine'
    },
    {
        CountryName: 'United Arab Emirates'
    },
    {
        CountryName: 'United Kingdom'
    },
    {
        CountryName: 'United States'
    },
    {
        CountryName: 'Vietnam'
    }

];
