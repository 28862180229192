import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { GlobalConstants } from './../../../../common/constants/global.constant';
import { AssetLibraryConstants } from './constants/asset-library.constant';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {

  userId = sessionStorage.getItem(GlobalConstants.USER_ID);
  SEGMENTS_URL = AssetLibraryConstants.ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId + AssetLibraryConstants.SEGMENTS;

  SEGMENTS_URL_S3 = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId +
    AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId +
    AssetLibraryConstants.SEGMENTS + AssetLibraryConstants.SLASH;

  constructor(private httpMethodsservice: HttpMethodsService) { }

  getAllSegmentsByUser() {
    const url = this.SEGMENTS_URL;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) =>
        res));
  }

  deleteSegmentById(segmentId: string) {
    const url = this.SEGMENTS_URL
                + AssetLibraryConstants.SLASH + segmentId;

    return this.httpMethodsservice.delete(url)
      .pipe(map((res: any) =>
        res));
  }

  createSegment(configurationsJson: string) {
    const url = this.SEGMENTS_URL;
    const addObject = {
      configurations: configurationsJson
    };

    return this.httpMethodsservice.post(url, addObject)
      .pipe(map((res: any) =>
        res));
  }

  editSegmentById(inputJson: string, id: string) {
    const url = this.SEGMENTS_URL + AssetLibraryConstants.SLASH + id;

    return this.httpMethodsservice.put(url, inputJson)
      .pipe(map((res: any) =>
        res));
  }

  editSegmentByPageId(image: File, imageType: string, segmentId: string, pageId: string) {
    const url = this.SEGMENTS_URL_S3
      + segmentId + AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.putImage(url, image, imageType, true)
      .pipe(map((res: any) =>
        res));
  }
}
