<div id="newLogoContent" class="tab-pane active">
  <form [formGroup]="logoContentgrpForm">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="publishedName">Published Name <span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          id="publishedName"
          placeholder="Published Name"
          formControlName="publishedName"
          name="publishedName"
          required
        />
        <div *ngIf="isSubmitted && logoContentgrpForm?.controls?.publishedName?.value.trim() === ''" class="formfield_err_msg"> This is a required field </div>
        <div *ngIf="isSubmitted && logoContentgrpForm?.controls?.publishedName?.errors?.maxlength"  class="formfield_err_msg">Published name may be up to 50 characters long</div>
      </div>
      <div class="form-group col-md-6" >
        <label for="publishedTimeStamp">Publish Date & Time<span class="text-danger">*</span></label>
        <div container="body" [ngbPopover]="dateTimePicker" placement="bottom-right"  [autoClose]="false" popoverTitle="Publish Date/Time">
          <input class="form-control" value="{{dateTimeValue}}" formControlName="publishedTimeStamp" id="publishedTimeStamp">
          <em class="date_icon cursor-pointer"></em>
        </div>
        <em class="err_icon"></em>
        <div *ngIf="isPublishedDateInCorrect"  class="formfield_err_msg"> Published date should not be less than today </div>
        <span class="formfield_err_msg"></span>
        <ng-template  #dateTimePicker >
          <iposcen-datetimepicker-popover (currentDateTime)="getPublishDate($event)" (selectedDateTime)="getSelectedTime($event)" popoverTitle="Publish" buttonText="Use Current Date & Time"></iposcen-datetimepicker-popover>
        </ng-template>
     </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="internalName">Internal Name</label>
        <input
          type="text"
          class="form-control"
          id="internalName"
          placeholder="Internal Name"
          formControlName="internalName"
        />
      </div>
      <div class="form-group col-md-6" >
        <label for="publishedTimeStamp">Expiry Date & Time<span class="text-danger">*</span></label>
        <div container="body" [ngbPopover]="dateTimePickereExpiry" placement="top-right" container="body" [autoClose]="false" popoverTitle="Expiry Date/Time">
          <input class="form-control" value="{{expiryDateTime}}" formControlName="expiryTimeStamp" id="expiryTimeStamp">
          <em class="date_icon cursor-pointer"></em>
          <div *ngIf="isExpiredDateIncorrect" class="formfield_err_msg"> Expiry should not be less than published date</div>
        </div>
        <em class="err_icon"></em>
        <span class="formfield_err_msg"></span>
        <ng-template  #dateTimePickereExpiry>
          <iposcen-datetimepicker-popover (currentDateTime)="getExpiryNever($event)" (selectedDateTime)="getExpirySelectedTime($event)" popoverTitle="Expiry" buttonText="Expire Never"></iposcen-datetimepicker-popover>
        </ng-template>
     </div>
    </div>
  </form>
  <div class="button_wrapper" style="justify-content: flex-end">
    <button type="button" (click)="cancel()" class="btn btn-outline-primary gtmCancelLogo">
      Cancel
    </button>
    <button type="button" (click)="onAdd()" class="btn btn-primary gtmSaveLogo" [disabled]="!logoContentgrpForm?.controls?.publishedName?.value || isExpiredDateIncorrect">
      Save & Proceed
    </button>
  </div>
</div>
