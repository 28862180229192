import { Component, OnInit, ElementRef, EventEmitter, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DataService } from '../../../core/data/data-service';
import { AttractorLoopService } from '../../../core/routing/services/asset-library/attractor-loop.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { CreateAttractorLoopResponse, AttractorLoop, AttractorLoopConfiguration, AttractorLoopViewModel, AttractLoopVideoViewModel } from '../attractor-loop/interfaces/attractor-loop.i';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iposcen-create-attractor-loop',
  templateUrl: './create-attractor-loop.component.html',
  styleUrls: ['./create-attractor-loop.component.scss']
})
export class CreateAttractorLoopComponent implements OnInit {
  @Output() readonly goBacktoAttractorLoop = new EventEmitter<object>();
  @Output("refreshAttractorLoopdata") refreshAttractorLoopdata = new EventEmitter();
  @ViewChild('videoInput', { static: false }) videoInput: ElementRef;

  attractorLoopgrpForm: FormGroup;
  isSubmitted: boolean;
  isFormValid: boolean;
  selectedAttractorLoopId: string;
  file: any;
  videoFile: any;
  videoUrl: any;
  videoSizeError: any;
  videoLengthError: any;
  videoTypeError: any;
  videoFileType: string;
  videoFileName: string;
  videoFileSize: number;
  isVideoAvailable = false;
  isVideoFormat: boolean;
  selectedAttractorLoop: AttractorLoopViewModel;
  attractorLoopVideos: AttractLoopVideoViewModel[];
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private attractorLoopService: AttractorLoopService,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.dataService.getAttractorLoop()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((attractorLoopToEdit: any) => {
        if (attractorLoopToEdit) {
          this.isVideoFormat = true;
          this.selectedAttractorLoop = attractorLoopToEdit;
          this.selectedAttractorLoopId = attractorLoopToEdit.id;
          this.isVideoAvailable = (this.selectedAttractorLoop.configurations.uploadedVideoName !== '');
          this.videoFileName = this.isVideoAvailable ? this.selectedAttractorLoop.configurations.uploadedVideoName : '';
        }
      });
    this.isSubmitted = false;
    this.createAttractorLoopGroupForm();
  }

  cancel() {
    this.isSubmitted = false;
    this.goBacktoAttractorLoop.emit({ cancel: true });
    this.dataService.setAttractorLoop(undefined);
  }

  createAttractorLoopGroupForm() {
    // TODO -- need to add validations for form fields
    this.attractorLoopgrpForm = this.formBuilder.group({
      publishedName: new FormControl((this.selectedAttractorLoop) ? this.selectedAttractorLoop.configurations.publishedName : '', [Validators.required, Validators.maxLength(50)]),
      // publishedTimeStamp: new FormControl(''),
      internalName: new FormControl((this.selectedAttractorLoop) ? this.selectedAttractorLoop.configurations.internalName : ''),
      uploadedVideoName: new FormControl('')
      // expiryTimeStamp: new FormControl('', [Validators.minLength(5)])
    });
  }

  createNewAttractorLoop() {
    const attractorLoop: AttractorLoopConfiguration = {
      publishedName: this.attractorLoopgrpForm.controls.publishedName.value,
      internalName: this.attractorLoopgrpForm.controls.internalName.value,
      uploadedVideoName: (this.videoFile === undefined) ? '' : this.videoFile[0].name
    };
    this.attractorLoopService.createAttractorLoop(JSON.stringify(attractorLoop))

      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createAttractorLoopResponse => {
        const attractorLoopVideoViewModel: AttractLoopVideoViewModel = createAttractorLoopResponse.attractLoop as AttractLoopVideoViewModel;
        this.attractorLoopService.uploadVideo(this.videoFile, createAttractorLoopResponse.attractLoop.id, (this.videoFile === undefined) ? '' : this.videoFile[0].name.split('.')
          .pop())
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(() => {
            this.attractorLoopVideos = [];
            attractorLoopVideoViewModel.videoData = this.videoFile;
            attractorLoopVideoViewModel.fileName = (this.videoFile === undefined) ? '' : this.videoFile[0].name.split('.');
            this.attractorLoopVideos.push(attractorLoopVideoViewModel);
            this.refreshAttractorLoopdata.emit();
          },
            error => {
              // do something
            });
      },
        error => {

        });
  }

  updateAttractorLoop() {
    const attractorLoop = this.selectedAttractorLoop as unknown as AttractorLoop;
    const attractorLoopConfig: AttractorLoopConfiguration = {
      publishedName: this.attractorLoopgrpForm.controls.publishedName.value,
      internalName: this.attractorLoopgrpForm.controls.internalName.value,
      uploadedVideoName: (this.videoFile) ? this.videoFile[0].name : this.videoFileName
    };
    attractorLoop.configurations = JSON.stringify(attractorLoopConfig);
    this.attractorLoopService.putAttractorLoopById(JSON.stringify(attractorLoop), this.selectedAttractorLoopId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(updateAttractorLoopResponse => {
        const attarctorLoopVideoViewModel: AttractLoopVideoViewModel = updateAttractorLoopResponse.attractLoop as AttractLoopVideoViewModel;
        this.attractorLoopService.uploadVideo(this.videoFile, updateAttractorLoopResponse.attractLoop.id, (this.videoFile) ? this.videoFile[0].name.split('.') : this.videoFileName.split('.')
          .pop())
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(first())
          .subscribe(() => {
            this.attractorLoopVideos = [];
            attarctorLoopVideoViewModel.videoData = this.videoFile;
            attarctorLoopVideoViewModel.fileName = (this.videoFile) ? this.videoFile[0].name.split('.') : this.videoFileName.split('.');
            this.attractorLoopVideos.push(attarctorLoopVideoViewModel);
            this.refreshAttractorLoopdata.emit();
          },
            error => {
              // do something
            });
      },
        error => {

        });
  }

  readVideoUrl(event: any) {
    this.videoFile = event.target.files;
    if(this.videoFile[0].type  !== "video/mp4"){
      this.videoTypeError = true;
      this.isVideoFormat = false;
    }else{
      if (this.videoFile && this.videoFile[0]) {
        this.isVideoFormat = true;
        this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.videoFile[0]));
      }
    }
  }

  getDuration(e) {
    const duration = e.target.duration;
    this.videoFileType = this.videoFile[0].type.split('/')[1];
    this.videoFileName = this.videoFile[0].name;
    this.videoFileSize = this.videoFile[0].size;
    this.videoSizeError = this.videoFileSize > 10048576;
    this.videoLengthError = duration > 60;
    this.videoTypeError = (this.videoFileType !== 'mp4');
    this.isVideoAvailable = !(this.videoSizeError || this.videoLengthError || this.videoTypeError || (this.videoSizeError && this.videoLengthError && this.videoTypeError));
  }

  onAdd() {
    this.isSubmitted = true;
    this.isFormValid = this.attractorLoopgrpForm?.controls?.publishedName?.value.trim() !== '';
    if (this.isFormValid) {
      if (this.selectedAttractorLoopId) { // Since attractor loop id exists, it is an update process
        this.updateAttractorLoop();
      } else {                            // else new  Attractor loop will created
        this.createNewAttractorLoop();
      }
    }
    
  }
}
