 <div class="card email_sent_card">
   <form>
     <div class="form_header" [innerHTML]="emailSentHeading"></div>
     <p [innerHTML]="firstDescription">

     </p>

     <p [innerHTML]="secondDescription">

     </p>



     <div class="email_sent_btn">
       <button type="submit" class="btn btn-primary gtmClose" [innerHTML]="closeText" (click)="close()">
       </button>
     </div>

   </form>
 </div>
