export class AssetLibraryConstants {
    static readonly SLASH = '/';
    static readonly ASSET_LIBRARY_USERS = '/asset-library/users/';
    static readonly S3_ASSET_LIBRARY_USERS = '/users/';
    static readonly ASSET_LIBRARY_ASSET_LIBRARY = '/asset-library/asset-library-';
    static readonly LOGO_CONTENTS = '/logo-contents';
    static readonly STORE_LOGO = '/store-logos';
    static readonly PROMO_CONTENTS = '/promotional-contents';
    static readonly SEGMENTS = '/segments';
    static readonly BANNER_CONTENTS = '/banner-carousels';
    static readonly PRICE_CONTENTS = '/price-contents';
    static readonly ATTRACTOR_LOOP = '/attract-loops';

    // Sort Order Key-Values
    static readonly SORT_ORDER_SEGMANT_NAME = 'segmentName';
    static readonly SORT_ORDER_SEGMANT_NAME_LABEL = 'Segment Name';
    static readonly SORT_ORDER_RETAILER_NAME = 'retailerName';
    static readonly SORT_ORDER_RETAILER_NAME_LABEL = 'Retailer Name';
}
