import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'iposcen-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() popupTitle: string;
  @Input() popupDesc: string;
  @Input() popupAction: string;
  @Output() readonly confirm = new EventEmitter();
  @Output() readonly cancelConfirm = new EventEmitter();
  @ViewChild('confirmModal') private modalContent: TemplateRef<ConfirmDialogComponent>;
  private modalRef: NgbModalRef;
  private modalOption : NgbModalOptions = {};

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.open(this.modalContent, this.modalOption);
    this.modalRef.result.then();
  }

  close() {
    this.modalRef.close();
    this.cancelConfirm.emit();
  }

  dismiss() {
    this.modalRef.dismiss();
    this.cancelConfirm.emit();
  }

  confirmAction() {
    this.confirm.emit();
  }
}
