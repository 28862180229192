import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
// import { map } from 'rxjs/operators';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { AssetLibraryConstants } from './constants/asset-library.constant';

@Injectable({
  providedIn: 'root'
})
export class LogoContentService {

  userId = sessionStorage.getItem('userId');
  LOGO_CONTENTS_URL = AssetLibraryConstants.ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId + AssetLibraryConstants.LOGO_CONTENTS;
  GET_LOGO_CONTENTS_BY_ID_URL = this.LOGO_CONTENTS_URL + AssetLibraryConstants.SLASH;
  GET_LOGO_CONTENT_BY_PAGE_ID_URL = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId
    + AssetLibraryConstants.LOGO_CONTENTS + AssetLibraryConstants.SLASH;

  constructor(private httpMethodsservice: HttpMethodsService) {

  }

  createLogoContent(configurationsJson: string) {
    const url = this.LOGO_CONTENTS_URL;
    const addObject = {
      configurations: configurationsJson
    };

    return this.httpMethodsservice.post(url, addObject)
      .pipe(map((res: any) =>
        res));
  }

  putLogoContentById(inputJson: string, id: string) {
    const url = this.GET_LOGO_CONTENTS_BY_ID_URL + id;

    return this.httpMethodsservice.put(url, inputJson)
      .pipe(map((res: any) =>
        res));
  }

  getAllLogoContentByUser() {
    const url = this.LOGO_CONTENTS_URL;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) =>
        res));
  }

  getLogoContentById(id: string) {
    const url = this.GET_LOGO_CONTENTS_BY_ID_URL + id;

    return this.httpMethodsservice.get(url)
      .pipe(map((res: any) =>
        res));
  }

  getLogoContentByPageId(logoContentId: string, pageId: string) {
    const url = this.GET_LOGO_CONTENT_BY_PAGE_ID_URL
      + logoContentId + AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.getImage(url, true)
      .pipe(map((res: any) =>
        res));
  }

  putS3LogoContentByPageId(image: File, imageType: string, logoContentId: string, pageId: string) {
    const url = this.GET_LOGO_CONTENT_BY_PAGE_ID_URL
      + logoContentId + AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.putImage(url, image, imageType, true)
      .pipe(map((res: any) =>
        res));
  }

  deleteS3LogoContentByPageId(logoContentId: string, pageId: string) {
    const url = this.GET_LOGO_CONTENT_BY_PAGE_ID_URL
      + logoContentId + AssetLibraryConstants.SLASH + pageId;

    return this.httpMethodsservice.delete(url, true)
      .pipe(map((res: any) =>
        res));
  }

  deleteLogoContentById(logoContentId: string) {
    const url = this.LOGO_CONTENTS_URL
      + AssetLibraryConstants.SLASH + logoContentId;

    return this.httpMethodsservice.delete(url)
      .pipe(map((res: any) =>
        res));
  }
}
