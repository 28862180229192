import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { AssetLibraryConstants } from '../../../core/routing/services/asset-library/constants/asset-library.constant';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../core/data/data-service';
import { PromoContentService } from '../../../core/routing/services/asset-library/promo-content.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { CommonService } from './../../../common/services/common.service';
import { RouterConstants } from './../../../core/routing/constants/app-paths.constant';
import { Observable } from 'rxjs';
import { CreatePromoContentResponse, PromoContent, PromoContentConfiguration, PromoContentGroupPage, PromoContentViewModel } from './interfaces/promo-content.i';

@Component({
  selector: 'iposcen-promo-content',
  templateUrl: './promo-content.component.html',
  styleUrls: ['./promo-content.component.scss']
})
export class PromoContentComponent implements OnInit, OnDestroy {

  @ViewChild('deleteModal') deleteModal: ElementRef;
  @Output() readonly gotoBackCreateProfile = new EventEmitter<object>();
  userId = sessionStorage.getItem('userId');
  configurations: PromoContentConfiguration[];
  tabIndex = 0;
  checkIndex = 0;
  currentdetailPage: string;
  disabled: boolean;
  allPromoContentGroups: PromoContent[];
  displayPromoContentGroups = [];
  count$: Observable<number>;
  selectedFiles: FileList;
  sideNav = 'store';
  sortValue = GlobalConstants.CREATED_DATE;
  page = 1;
  pageSize = 5;
  LCGlayout = 1;
  promoCGToDelete: string;
  promoCGToDeleteName: string;
  contentGrpData: any;
  pageType = GlobalConstants.PROMO_CONTENT_GROUP;
  pages: any; // update the no of pages for edit.
  mediaObjects: any[] = [];
  editCheck: boolean;
  checkInd: number;
  checkInd2: number;
  presentPage: string;
  profile_page: string = GlobalConstants.PROFILE;
  assets_page: string = GlobalConstants.ASSET_LIBRARY
  clickable = false; // This is to enable tabs click only after clicking on Save & Proceed.TODO: will remove this later.
  showPromoContentName: string;// To Show logocontentname as tab title.
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
    + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;
  pageGrpForm: any;
  private ngUnsubscribe = new Subject<boolean>();
  selctedContGrpsForProfile:any = {
    contentGrps :[]
  };
  constructor(
    private router: Router,
    private cmnService: CommonService,
    private promoContentService: PromoContentService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.getAllPromoContentsByUser();
    this.pages = {};
    this.disabled = true;
    this.editCheck = false;
    this.currentdetailPage = this.router.url;
    //set presentpage
    const pageName = this.currentdetailPage.lastIndexOf('/');
    if (this.currentdetailPage.substring(pageName + 1) === GlobalConstants.ASSET_LIBRARY) {
      this.presentPage = GlobalConstants.ASSET_LIBRARY;
    }
    else if(this.currentdetailPage.substring(pageName + 1) === GlobalConstants.CREATE_PROFILE) {
      this.presentPage = GlobalConstants.PROFILE;
    }
    // else 
    this.cmnService.pageDataTologoObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => { });

    // dummy data to preview page
    this.cmnService.pageDataTologoObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        // console.log(res);
        // pages data. To-Do: update to common object ;
        // const updatePromoContent: CreatePromoContentResponse = this.contentGrpData as CreatePromoContentResponse;
        // Once page is updated.
        const promoContent = this.contentGrpData.promotionalContent;
        const configurations = promoContent.configurations;
        if (res.data.media.url !== '') {
          this.prepareMediaSet(res, promoContent.id);
        }
        const checkJson = this.checkExistance(configurations.contentGroupPages, 'pageNum', res.number);
        if (checkJson.hasMatch) {// If there is configuration object already for the page number
          // Update the pages
          configurations.contentGroupPages[checkJson.matchedIndex] = {
            pageNum: res.number,
            title: res.data.heading,
            titleFontColor: res.data.hcolor,
            bgColor: res.data.bgcolor,
            bodyCopy: res.data.body,
            bodyCopyFontSize: this.fontSizeNumber(res.data.size),
            bodyCopyFontColor: res.data.bcolor,
            UiLayout: this.getUILayoutNameByID(res.data.layout),
            linkedAsset: res.data.media.url !== '' ? true : false,
            linkedAssetFileType: res.data.media.format
          };
        } else {
          const promoContentGroupPage: PromoContentGroupPage = {
            pageNum: res.number,
            title: res.data.heading,
            titleFontColor: res.data.hcolor,
            bgColor: res.data.bgcolor,
            bodyCopy: res.data.body,
            bodyCopyFontSize: this.fontSizeNumber(res.data.size),
            bodyCopyFontColor: res.data.bcolor,
            UiLayout: this.getUILayoutNameByID(res.data.layout),
            linkedAsset: res.data.media.url !== '' ? true : false,
            linkedAssetFileType: res.data.media.format
          };
          configurations.contentGroupPages.push(promoContentGroupPage);
        }
        this.contentGrpData.promotionalContent = promoContent;
      });
  }

  checkExistance(data, key, compValue) {
    const checkJSON = { hasMatch: false, matchedIndex: -1 };
    for (let index = 0; index < data.length; index += 1) {
      if (data[index][key] === compValue) {
        checkJSON.hasMatch = true;
        checkJSON.matchedIndex = index;
        break;
      }
    }

    return checkJSON;
  }

  //minipreview image or video content
  loadMiniPreview(gId: string, pageContent: any) {
    this.pageGrpForm = {
      bgcolor: pageContent ? pageContent.bgColor : '#0068B5',
      heading: pageContent ? pageContent.title : '',
      hcolor: pageContent ? pageContent.titleFontColor : '#ffffff',
      body: pageContent ? pageContent.bodyCopy : '',
      bcolor: pageContent ? pageContent.bodyCopyFontColor : '#ffffff',
      size: pageContent ? this.getFontSize(pageContent.bodyCopyFontSize) : GlobalConstants.MEDIUM,
      media: {
        url: (pageContent && pageContent.linkedAsset) ?
          environment.apiBaseUrlAsset + this.url + AssetLibraryConstants.PROMO_CONTENTS +
          AssetLibraryConstants.SLASH + gId + AssetLibraryConstants.SLASH + pageContent.pageNum.toString() : '',
        name: '',
        format: (pageContent && pageContent.linkedAsset) ? (pageContent.linkedAssetFileType) ? pageContent.linkedAssetFileType : '' : '',
        fileObj: ''
      },
      id: gId,    // adding created group id,
      layout: pageContent ? this.getUILayoutIDByName(pageContent.UiLayout) : 1
    };

    return this.pageGrpForm;
  }
  getFontSize(size: number) {
    if (size === GlobalConstants.NUMBER_12) { return GlobalConstants.SMALL; }
    if (size === GlobalConstants.NUMBER_14) { return GlobalConstants.MEDIUM; }
    if (size === GlobalConstants.NUMBER_16) { return GlobalConstants.LARGE; }
  }
  getUILayoutIDByName(name: string) {
    if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
      return 1;
    } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
      return 2;
    } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
      return 3;
    }
  }

  // To hold all pages media objects and its metada.
  prepareMediaSet(res: any, promoContentId: string) {
    const checkJson = this.checkExistance(this.mediaObjects, 'pageNum', res.number);
    if (checkJson.hasMatch) {
      // Update the pages
      this.mediaObjects[checkJson.matchedIndex] = {
        id: promoContentId,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        this.mediaObjects[checkJson.matchedIndex].uploaded = true;
      }
    } else {
      const dataObj: any = {
        id: promoContentId,
        media: res.data.media,
        pageNum: res.number
      };
      if (typeof res.data.media.fileObj === 'object' && res.data.media.fileObj) {
        dataObj.uploaded = true;
      }
      this.mediaObjects.push(dataObj);
    }
    // console.log(this.mediaObjects);
  }

  setSideNav(value: string) {
    this.sideNav = value;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAllPromoContentsByUser() {
    this.promoContentService.getAllPromoContentByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllPromoContentResponse => {
        this.allPromoContentGroups = (getAllPromoContentResponse.promotionalContent as PromoContent[]);
        this.configurations = [];
        this.allPromoContentGroups.forEach(promoContentGroup => {
          this.configurations.push(JSON.parse(promoContentGroup.configurations) as PromoContentConfiguration);
        });
        // display Promo content groups using displayPromocontentgroups var as configurations is coming as string
        this.displayPromoContentGroups = [];
        this.allPromoContentGroups.forEach(ele => {
          ele.configurations = JSON.parse(ele.configurations);
          ele.createdByUser = (ele.createdByUser === this.userId) ? GlobalConstants.ME : ele.createdByUser;
          this.displayPromoContentGroups.push(ele);
        });
        this.displayPromoContentGroups.forEach(element => {
          // adding this custom property to know which Promo content is expanded
          // console.log('display promo content group:: ' + JSON.stringify(element));
          element.isExpanded = false;
          if (element.updateTime) {
            element.updateTime = new Date(element.updateTime);
            element.updateTimeDisplay = new Date(element.updateTime).toLocaleString()
              .replace(',', '');
          }
          // converting date for display purpose:
        });

        this.sortByDate();
      },
        error => {
          // do something
        });
  }

  cancel() {
    this.dataService.setPromoContent(undefined);
    if (this.contentGrpData) {
      for (let each of this.displayPromoContentGroups) {
        if (each.id === this.contentGrpData.promotionalContent.id) {
          if (typeof each.configurations === "string") {
            each.configurations = JSON.parse(each.configurations);
          }
          break;
        }
      }
    }
    this.setIndex(0);
    this.mediaObjects = [];
    this.pages = {};
    this.clickable = false;
    this.editCheck = false;
  }

  setCreatedGrpID(data) {
    // console.log('type of data:: '+ typeof data + ', value is: ' + JSON.stringify(data));
    if (typeof data === 'object' && data.cancel) {
      this.setIndex(0);
      this.pages = {};
      this.editCheck = false;
    } else {
      this.contentGrpData = data;
      if (typeof this.contentGrpData.promotionalContent.configurations === 'string') {
        this.contentGrpData.promotionalContent.configurations = JSON.parse(this.contentGrpData.promotionalContent.configurations) as PromoContentConfiguration;
      }
      this.showPromoContentName = this.contentGrpData.promotionalContent.configurations.publishedName;
      this.clickable = true;
      this.setIndex(2);
    }
    this.mediaObjects = [];
  }
  /*when user opens pages*/
  setPreviewLayout(data) {
    this.LCGlayout = data;
  }
  /*when users slects layout*/
  setIndex(value: number) {
    this.tabIndex = value;
  }

  createPromo() {
    this.dataService.setPromoContent(undefined);
    this.tabIndex = 1;
  }

  setLayout(selected) {
    this.LCGlayout = selected;
    this.cmnService.emitLayout(selected);
  }

  refreshPromoContentdata() {
    // console.log('inside refreshPromoContentdata');
    this.getAllPromoContentsByUser();
    this.setIndex(0);
    this.mediaObjects = [];
  }

  redirectToLogin() {
    this.router.navigateByUrl(RouterConstants.LOGIN_PAGE_LINK);
  }

  trackByConfiguration(index: number, el: string): number {
    return index;
  }

  // get details of Promo content by id when click on arrow besides particular Promo content group
  getPromoContentDetails(id: string) {
    // TODO check with Sujay or Magesh to fetch the id of existing Promo  - first ngfor if i can change
    this.promoContentService.getPromoContentById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getPromoContentById => {
        const promoContentGrpdetails = (getPromoContentById.PromoContent as PromoContent[]);
        // console.log('promoContentGrpdetails--', promoContentGrpdetails);
      },
        error => {
          // do something
        });
  }

  getDetailsOfPromoCG(id: string) {
    this.displayPromoContentGroups.forEach(element => {
      if (element.id === id) {
        element.isExpanded = !element.isExpanded;
      }
    });
  }

  sortByDate() {
    this.sortValue = GlobalConstants.CREATED_DATE;
    // this.displayPromoContentGroups.sort((promo1, promo2) => promo2.updateTime
    //   .localeCompare(promo1.updateTime));
    this.displayPromoContentGroups.sort((promo1, promo2) => promo2.updateTime - promo1.updateTime);
  }

  sortByName() {
    this.sortValue = GlobalConstants.GROUP_NAME;
    this.displayPromoContentGroups.sort((promo1, promo2) =>
      promo1.configurations.publishedName.localeCompare(promo2.configurations.publishedName));
  }

  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openDeleteModal(promoCGID: string) {
    this.promoCGToDelete = this.displayPromoContentGroups.find(ele => ele.id === promoCGID).id;
    this.promoCGToDeleteName = this.displayPromoContentGroups.find(ele => ele.id === promoCGID).configurations.publishedName;
    this.openModal(this.deleteModal);
  }

  editPromo(promoCGID: string) {
    this.pages = {};
    this.mediaObjects = [];
    const promoCGToEdit = this.displayPromoContentGroups.find(ele => ele.id === promoCGID);
    this.dataService.setPromoContent(promoCGToEdit);
    this.editCheck = true;
    for (let each of promoCGToEdit.configurations.contentGroupPages) {
      if (each.pageNum === 2) {
        this.pages.page2 = true;
      }
      if (each.pageNum === 3) {
        this.pages.page3 = true;
      }
    }
    const tempPromoCont = promoCGToEdit as PromoContent;
    this.contentGrpData = {
      promotionalContent: tempPromoCont,
      responseContext: {}
    }; // set data to cntGrpData to show in pages
    this.showPromoContentName = promoCGToEdit.configurations.publishedName;
    this.setIndex(1);
  }

  duplicateAndEditPromoContent(promoCGID: string) {
    const promoContentToEdit = this.displayPromoContentGroups.find(ele => ele.id === promoCGID);
    const promoContentConfig = promoContentToEdit.configurations;
    const promoContent: PromoContentConfiguration = {
      publishedName: `Copy of ${promoContentConfig.publishedName}`,
      internalName: promoContentConfig.internalName,
      publishTime: promoContentConfig.publishTime ? promoContentConfig.publishTime : new Date().toString(),
      expireTime: promoContentConfig.expireTime ? promoContentConfig.expireTime : GlobalConstants.NEVER,
      contentScheduleTimezoneCode: promoContentConfig.contentScheduleTimezoneCode,
      type: GlobalConstants.PROMO_CONTENT_GROUP,
      contentGroupPages: promoContentConfig.contentGroupPages,
      formFactor: promoContentConfig.formFactor
    };
    this.promoContentService.createPromoContent(JSON.stringify(promoContent))
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(createPromoContentResponse => {
        this.getDetailsOfPromoCG(promoCGID);
        const createPromoContentResponseObj: CreatePromoContentResponse = createPromoContentResponse as CreatePromoContentResponse;
        const promoContentViewModel: PromoContentViewModel =
          createPromoContentResponseObj.promotionalContent as unknown as PromoContentViewModel;
        promoContentViewModel.configurations =
          JSON.parse(createPromoContentResponseObj.promotionalContent.configurations) as PromoContentConfiguration;

        // Loop and duplicate images
        this.duplicatePromoContentImage(promoContentToEdit, promoContentViewModel);
        this.dataService.setPromoContent(promoContentViewModel);
        this.editCheck = true;
        for (let each of promoContentViewModel.configurations.contentGroupPages) {
          if (each.pageNum === 2) {
            this.pages.page2 = true;
          }
          if (each.pageNum === 3) {
            this.pages.page3 = true;
          }
        }
        this.contentGrpData = {
          promotionalContent: promoContentViewModel,
          responseContext: {}
        }; // set data to cntGrpData to show in pages
        const tempViewModel: any = promoContentViewModel;/*dummy instance to add all required  flags for expand,delete,sort by date&time format*/
        tempViewModel.createdByUser = (tempViewModel.createdByUser === this.userId) ? GlobalConstants.ME : tempViewModel.createdByUser;
        tempViewModel.isExpanded = false;
        if (tempViewModel.updateTime) {
          tempViewModel.updateTime = new Date(tempViewModel.updateTime);
          tempViewModel.updateTimeDisplay = new Date(tempViewModel.updateTime).toLocaleString()
            .replace(',', '');
        }
        this.displayPromoContentGroups.push(promoContentViewModel);
        if (this.sortValue === 'Created Date') {
          this.sortByDate();
        } else {
          this.sortByName();
        }
        this.showPromoContentName = promoContent.publishedName;
        this.page = 1;
        this.setIndex(1);
      },
        error => {
        }
      );
  }

  duplicatePromoContentImage(promoContent, newPromoContent) {
    if (promoContent.configurations && promoContent.configurations.contentGroupPages) {
      promoContent.configurations.contentGroupPages.forEach(page => {
        if (page.linkedAsset) {
          this.promoContentService.getPromoContentByPageId(promoContent.id, page.pageNum)
            .pipe(takeUntil(this.ngUnsubscribe))
            .pipe(first())
            .subscribe(imageData => {
              this.promoContentService.putS3PromoContentByPageId(imageData, 'png', newPromoContent.id, page.pageNum) // Check how to get image type
                .pipe(takeUntil(this.ngUnsubscribe))
                .pipe(first())
                .subscribe(() => {
                },
                  error => {
                    // do something
                  });
            },
              error => {
                // do something
              });
        }

      });
    }
  }

  deletePromoCG(promoCGID: string) {
    // delete Promo API call
    this.promoContentService.deletePromoContentById(promoCGID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {
        // console.log('Delete Store Promo success --', deleteResponse);
        const index = this.displayPromoContentGroups.findIndex(d => d.id === promoCGID); // find index in your array
        if (index >= 0) {
          this.displayPromoContentGroups.splice(index, 1); // remove element from array
        }
        this.closeModal(this.deleteModal);
      },
        error => {
          // do something
          // console.log('Delete Store Promo error --', error);
          this.closeModal(this.deleteModal);
        });
  }

  fontSizeNumber(size) {
    if (size === 'Small') { return 12; }
    if (size === 'Medium') { return 14; }
    if (size === 'Large') { return 16; }
  }

  getUILayoutNameByID(id) {// TODO - Confirm with Sujay if layout name is correct
    if (id === 1) {
      return '2_col_right_asset';
    } else if (id === 2) {
      return '2_col_left_asset';
    } else if (id === 3) {
      return '1_col_mid_asset';
    }
  }

  addPage() {
    let keyLen = Object.keys(this.pages).length;
    if (keyLen === 0) {
      this.pages.page2 = true;
      this.setIndex(3);
    } else {
      this.pages.page3 = true;
      this.setIndex(4);
    }
  }

  checkPages() {
    if (this.pages.page2 && this.pages.page3) {
      return true;
    }

    return false;
  }

  updatePromoContent() {
    const updatePromoContent: CreatePromoContentResponse = this.contentGrpData as CreatePromoContentResponse;
    // Once page is updated.
    const promoContent = updatePromoContent.promotionalContent;
    if (typeof promoContent.configurations !== 'string') {
      promoContent.configurations = JSON.stringify(promoContent.configurations);
    }
    this.promoContentService.putPromoContentById(JSON.stringify(promoContent), updatePromoContent.promotionalContent.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putPromoContentResponse => {
        //  console.log('putPromoContentResponse:: ' + JSON.stringify(putPromoContentResponse));
        // Once the update call is successful, call a PUT call for the logo
        for (const each of this.mediaObjects) {
          if (each.uploaded) {
            this.updateMedia(each);
          }
        }
        this.pages = {};
        this.setIndex(0);
        this.getAllPromoContentsByUser();
        this.clickable = false;
        this.gotoBackCreateProfile.emit();
      },
        error => {
        }
      );
  }

  updateMedia(each) {
    // console.log(each);
    this.promoContentService.putS3PromoContentByPageId(each.media.fileObj,
      each.media.name.split('.')
        .pop(), each.id, each.pageNum.toString())
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(() => {

      },
        error => { // do something
        }
      );
  }

  pushToSelectedGrps(ind: number) {
    this.selctedContGrpsForProfile.contentGrps.push({
      "promotionalContentId": this.displayPromoContentGroups[ind].id,
      "showOnDevice": this.displayPromoContentGroups[ind].showOnDevice
    });
  }
  setDeviceAndPackage(ind: number, value: boolean) {
    this.displayPromoContentGroups[ind].showOnDevice = this.displayPromoContentGroups[ind].addToPackage = value;
  }

  handleChange(event: any, ind: number, src: string) {
    if (src === GlobalConstants.SHOW_ON_DEVICE) {
      if (this.selctedContGrpsForProfile.contentGrps?.length === 10) {
        if (this.displayPromoContentGroups[ind].addToPackage) {
          this.displayPromoContentGroups[ind].showOnDevice = true;
          this.selctedContGrpsForProfile.showOnDevice = this.displayPromoContentGroups[ind].id;
        }
        else if (this.selctedContGrpsForProfile.showOnDevice) {
          let checkInd = this.displayPromoContentGroups.findIndex(d => d.id === this.selctedContGrpsForProfile.showOnDevice);
          this.setDeviceAndPackage(checkInd, false);
          this.selctedContGrpsForProfile.showOnDevice = undefined;
          this.selctedContGrpsForProfile.contentGrps.splice(checkInd, 1);
          this.setDeviceAndPackage(ind, true);
          this.selctedContGrpsForProfile.showOnDevice = this.displayPromoContentGroups[ind].id;
          this.pushToSelectedGrps(ind);
        }
      } else {
        if (this.selctedContGrpsForProfile.showOnDevice) {
          this.checkInd = this.selctedContGrpsForProfile.contentGrps
          .findIndex(d => d.promotionalContentId === this.selctedContGrpsForProfile.showOnDevice);
          this.selctedContGrpsForProfile.contentGrps.splice(this.checkInd, 1);
          this.checkInd2 = this.displayPromoContentGroups.findIndex(d => d.id === this.selctedContGrpsForProfile.showOnDevice);
          this.setDeviceAndPackage(this.checkInd2, false);
        }
        this.displayPromoContentGroups[ind].addToPackage = this.displayPromoContentGroups[ind].showOnDevice = true;
        let checkPrevIDInd = this.selctedContGrpsForProfile.contentGrps.findIndex(d => d.promotionalContentId === this.displayPromoContentGroups[ind].id);
        if (checkPrevIDInd === -1) {
          this.pushToSelectedGrps(ind);
        }
        this.selctedContGrpsForProfile.showOnDevice = this.displayPromoContentGroups[ind].id;
    }
    } else if (src === GlobalConstants.ADD_PACKAGES) {
      if (event.target.checked) {
        if (this.selctedContGrpsForProfile.contentGrps?.length <= 9) {
          this.displayPromoContentGroups[ind].addToPackage = event.target.checked;
          this.pushToSelectedGrps(ind);
        } else {
          event.target.checked = false;
        }
      } else {
        this.setDeviceAndPackage(ind, false);
        this.selctedContGrpsForProfile.showOnDevice = undefined;
        let checkInd = this.selctedContGrpsForProfile.contentGrps.findIndex(d => d.promotionalContentId === this.displayPromoContentGroups[ind].id);
        if (checkInd >= 0) {
          this.selctedContGrpsForProfile.contentGrps.splice(checkInd, 1);
        }
      }
    }
  }
  sendToProfile(action: string) {
    if (action === GlobalConstants.SEND) {
      this.cmnService.emitPromoDataToProfile(this.selctedContGrpsForProfile.contentGrps);
    } else {
      this.cmnService.emitPromoDataToProfile(GlobalConstants.SKIP);
    }

  }



}
