import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpMethodsService } from '../_helpers/http-methods.service';
import { map } from 'rxjs/internal/operators/map';
import { StoreApi } from '../stores_api/constants/stores.constant'



@Injectable({
  providedIn: 'root'
})
export class StoresService {
  userId = sessionStorage.getItem('userId');
  STORES_URL = StoreApi.SLASH_RETAILSTORE + StoreApi.SLASH_STORE + StoreApi.SLASH_USERS + StoreApi.SLASH + this.userId + StoreApi.STORES;
  COMMON_URL = StoreApi.SLASH_RETAILSTORE + StoreApi.SLASH_STORE + StoreApi.SLASH_USERS + StoreApi.SLASH;

  constructor(private httpClient: HttpClient, private httpMethod: HttpMethodsService) { }

  getAllStoresbyUserID() {
    const url = this.STORES_URL;
    return this.httpMethod.get(url)
      .pipe(map((res: any) =>
        res));
  }

  getAStoreData(storesdetailconstant: { STOREID_DATA: string; REATILERID_DATA: string; USERID_DATA: string; }) {
    return this.httpMethod.get(this.COMMON_URL + storesdetailconstant.USERID_DATA + StoreApi.SLASH_RETAILER +
      StoreApi.SLASH + storesdetailconstant.REATILERID_DATA + StoreApi.STORES + StoreApi.SLASH + storesdetailconstant.STOREID_DATA)
      .pipe(map((res: any) =>
        res))
  }

  getStoresbySearch(searchTag: string, searchData: string) {
    return this.httpMethod.get(this.COMMON_URL + this.userId + StoreApi.SLASH_STORE_SEARCH + searchTag + StoreApi.EQUALS + searchData)
      .pipe(map((res: any) =>
        res))
  }

  sort(globalsortValue: string) {
    return this.httpMethod.get(this.COMMON_URL + this.userId + StoreApi.SLASH_STORE_SEARCH + globalsortValue)
      .pipe(map((res: any) =>
        res));
  }

// create new stores userstory:429
  createNewStore(StoresContent: { storeName: any; retailerName: any; storeNumber: any; customStoreID: any; country: any; stateRegionProvince: any; address: any; postalCode: any; }, retailerID: string) {
    return this.httpMethod.post(this.COMMON_URL  + this.userId + StoreApi.SLASH_RETAILER + retailerID + StoreApi.SLASH_STORE,StoresContent)
  }

}
