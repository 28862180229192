import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../core/routing/services/login/login.service.ts.service';
import { CognitoCallback } from '../../../core/routing/interfaces/cognito.i';
import { RouterConstants } from '../../../core/routing/constants/app-paths.constant';
import { ForgotPasswordConstants } from './constants/forgot-password.constant';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmRegistrationConstants } from '../confirm-registration/constants/confirm-registration.constant';
import { IPOSValidator } from '../../../common/validators/validators';
import { EmailSentComponent } from './email-sent/email-sent.component';

@Component({
  selector: 'iposcen-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss']
})
export class ForgotPwdComponent implements OnInit, CognitoCallback {
  forgotPasswordForm: FormGroup;
  errorMessage: string;
  email: string;
  forgotPwdHeading: string;
  description: string;
  emailLabel: string;
  cancel: string;
  next: string;
  loginhref: string;
  modalClose: NgbModalRef;
  isSubmitted: boolean;
  placeholderEmail: string;
  validEmailId: string;
  pattern: string;
  requiredField: string;
  forgotButton: boolean;
  modalDiv: string;
  closeResult: string;

  constructor(private router: Router,
    private formbuilder: FormBuilder,
    private loginService: LoginService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.loginhref = RouterConstants.LOGIN_PAGE_LINK;
    this.errorMessage = undefined;
    this.forgotButton = false;
    const PAT_EMAIL = IPOSValidator.EMAIL;
    this.placeholderEmail = ForgotPasswordConstants.PLACEHOLDER_EMAIL;
    this.next = ForgotPasswordConstants.NEXT_BTN;
    this.cancel = ForgotPasswordConstants.CANCEL_BTN;
    this.forgotPwdHeading = ForgotPasswordConstants.FORGOT_PWD_HEADING;
    this.emailLabel = ForgotPasswordConstants.EMAIL_LABEL;
    this.description = ForgotPasswordConstants.PARAGRAPH_DESC;
    this.requiredField = ForgotPasswordConstants.REQUIRED_FIELD;
    this.validEmailId = ForgotPasswordConstants.VALID_EMAIL_ID;
    this.forgotPasswordForm = this.formbuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern(PAT_EMAIL)])
    });
  }

  forgotBtn() {
    this.forgotButton = false;
    if (this.forgotPasswordForm.get('email').value) {
      this.forgotButton = true;
    }
  }

  redirectToLogin() {
    this.router.navigateByUrl(RouterConstants.LOGIN_PAGE_LINK);
    this.activeModal.close();
  }
  goToVerificationCodeToReset() {
    this.isSubmitted = true;
    if (this.forgotPasswordForm.invalid || this.forgotPasswordForm.get('email').value === null ) {
      return;
    }
    this.errorMessage = undefined;
    this.email = this.forgotPasswordForm.get('email').value;
    this.loginService.forgotPassword(this.email, this);
    this.activeModal.close();
    this.open();
  }

  open(): void {
    const modalRef = this.modalService.open(EmailSentComponent);
    modalRef.componentInstance.fromParent = this.email;
  }
  getDismissReason(reason: any): string {
    let message: string;
    switch (reason) {
      case ModalDismissReasons.ESC: {
        message = ConfirmRegistrationConstants.DIMISS_REASON_ESC;
        break;
      }
      case ModalDismissReasons.BACKDROP_CLICK: {
        message = ConfirmRegistrationConstants.DISMISS_REASON_DROP;
        break;
      }
      default: {
        message = `with: ${reason}`;
        break;
      }
    }

    return message;
  }

  close() {
    this.modalClose.close();
  }

  cognitoCallback(message: string, result: any) {
    if (message === undefined && result === undefined) { // error
      this.email = this.forgotPasswordForm.get('email').value;
    } else { // success
      this.errorMessage = message;
    }
  }

}
