import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AwsSignature } from '../services/auth/aws-signature';
import { AwsSignatureInputData } from '../services/auth/interfaces/aws-signature-input.i';
import { APIConstants } from '../services/_helpers/constants/api.constant';
import { environment } from './../../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization headers
        const accessToken = sessionStorage.getItem('accessToken');
        const authorizationHeader = this.getAuthorizationHeader(request);

        if (accessToken ) {
            request = request.clone({
                setHeaders: {
                    // 'x-amz-access-token': sessionStorage.getItem('accessToken'),
                    // Authorization: sessionStorage.getItem('idToken')
    
                    'X-Amz-Date': authorizationHeader[0],
                    Authorization: authorizationHeader[1]
                }
            });
        }

        return next.handle(request);
    }

    getAuthorizationHeader(request: HttpRequest<any>) {
        const url = new URL(request.url);

        const awsSignatureInputData: AwsSignatureInputData = new AwsSignatureInputData();
        awsSignatureInputData.method = request.method;
        awsSignatureInputData.canonicalUri = url.pathname;
        awsSignatureInputData.host = url.hostname;
        awsSignatureInputData.region = environment.region;
        awsSignatureInputData.service = 'execute-api';
        awsSignatureInputData.accessKey = sessionStorage.getItem('AccessKeyId');
        awsSignatureInputData.secretKey = sessionStorage.getItem('SecretKey');
        awsSignatureInputData.contentType = request.headers.get(APIConstants.HEADER_NM_CONTENT_TYP);
        awsSignatureInputData.requestParameters = request.body;
        awsSignatureInputData.canonicalQuerystring = '';

        const signingTool = new AwsSignature();
        const output: any = signingTool.generateSignature(awsSignatureInputData);

        // return output.Authorization;
        return output;
    }
}
