export const timeZone = [
  {
    id: 1,
    value: 'Iran Standard Time (UTC+0330)',
    abbreviation: 'IRST',
    offset: '+0330',
    name: 'Iran Standard Time',
  },
  {
    id: 2,
    value: 'Afghanistan Time (UTC+0430)',
    abbreviation: 'AFT',
    offset: '+0430',
    name: 'Afghanistan Time',
  },
  {
    id: 3,
    value: 'Iran Daylight Time (UTC+0430)',
    abbreviation: 'IRDT',
    offset: '+0430',
    name: 'Iran Daylight Time',
  },
  {
    id: 4,
    value: 'Indian Standard Time (UTC+0530)',
    abbreviation: 'IST',
    offset: '+0530',
    name: 'Indian Standard Time',
  },
  {
    id: 5,
    value: 'Sri Lanka Standard Time (UTC+0530)',
    abbreviation: 'SLST',
    offset: '+0530',
    name: 'Sri Lanka Standard Time',
  },
  {
    id: 6,
    value: 'Nepal Time (UTC+0545)',
    abbreviation: 'NPT',
    offset: '+0545',
    name: 'Nepal Time',
  },
  {
    id: 7,
    value: 'Cocos Islands Time (UTC+0630)',
    abbreviation: 'CCT',
    offset: '+0630',
    name: 'Cocos Islands Time',
  },
  {
    id: 8,
    value: 'Myanmar Time (UTC+0630)',
    abbreviation: 'MMT',
    offset: '+0630',
    name: 'Myanmar Time',
  },
  {
    id: 9,
    value: 'Myanmar Standard Time (UTC+0630)',
    abbreviation: 'MST',
    offset: '+0630',
    name: 'Myanmar Standard Time',
  },
  {
    id: 10,
    value: 'Central Western Standard Time (Australia) unofficial (UTC+0845)',
    abbreviation: 'CWST',
    offset: '+0845',
    name: 'Central Western Standard Time (Australia) unofficial',
  },
  {
    id: 11,
    value: 'Australian Central Standard Time (UTC+0930)',
    abbreviation: 'ACST',
    offset: '+0930',
    name: 'Australian Central Standard Time',
  },
  {
    id: 12,
    value: 'Central Standard Time (Australia) (UTC+0930)',
    abbreviation: 'CST',
    offset: '+0930',
    name: 'Central Standard Time (Australia)',
  },
  {
    id: 13,
    value: 'Australian Central Daylight Savings Time (UTC+1030)',
    abbreviation: 'ACDT',
    offset: '+1030',
    name: 'Australian Central Daylight Savings Time',
  },
  {
    id: 14,
    value: 'Central Summer Time (Australia) (UTC+1030)',
    abbreviation: 'CST',
    offset: '+1030',
    name: 'Central Summer Time (Australia)',
  },
  {
    id: 15,
    value: 'Lord Howe Standard Time (UTC+1030)',
    abbreviation: 'LHST',
    offset: '+1030',
    name: 'Lord Howe Standard Time',
  },
  {
    id: 16,
    value: 'Norfolk Time (UTC+1100)',
    abbreviation: 'NFT',
    offset: '+1100',
    name: 'Norfolk Time',
  },
  {
    id: 17,
    value: 'Chatham Standard Time (UTC+1245)',
    abbreviation: 'CHAST',
    offset: '+1245',
    name: 'Chatham Standard Time',
  },
  {
    id: 18,
    value: 'Chatham Daylight Time (UTC+1345)',
    abbreviation: 'CHADT',
    offset: '+1345',
    name: 'Chatham Daylight Time',
  },
  {
    id: 19,
    value: 'Newfoundland Daylight Time (UTC-0230)',
    abbreviation: 'NDT',
    offset: '-0230',
    name: 'Newfoundland Daylight Time',
  },
  {
    id: 20,
    value: 'Newfoundland Standard Time (UTC-0330)',
    abbreviation: 'NST',
    offset: '-0330',
    name: 'Newfoundland Standard Time',
  },
  {
    id: 21,
    value: 'Newfoundland Time (UTC-0330)',
    abbreviation: 'NT',
    offset: '-0330',
    name: 'Newfoundland Time',
  },
  {
    id: 22,
    value: 'Marquesas Islands Time (UTC-0930)',
    abbreviation: 'MART',
    offset: '-0930',
    name: 'Marquesas Islands Time',
  },
  {
    id: 23,
    value: 'Greenwich Mean Time (UTC+0000)',
    abbreviation: 'GMT',
    offset: '+0000',
    name: 'Greenwich Mean Time',
  },
  {
    id: 24,
    value: 'International Business Standard Time (UTC+0000)',
    abbreviation: 'IBST',
    offset: '+0000',
    name: 'International Business Standard Time',
  },
  {
    id: 25,
    value: 'Coordinated Universal Time (UTC+0000)',
    abbreviation: 'UCT',
    offset: '+0000',
    name: 'Coordinated Universal Time',
  },
  {
    id: 26,
    value: 'Western European Time (UTC+0000)',
    abbreviation: 'WET',
    offset: '+0000',
    name: 'Western European Time',
  },
  {
    id: 27,
    value: 'Zulu Time (Coordinated Universal Time) (UTC+0000)',
    abbreviation: 'Z',
    offset: '+0000',
    name: 'Zulu Time (Coordinated Universal Time)',
  },
  {
    id: 28,
    value: 'Eastern Greenland Summer Time (UTC+0000)',
    abbreviation: 'EGST',
    offset: '+0000',
    name: 'Eastern Greenland Summer Time',
  },
  {
    id: 29,
    value:
      'British Summer Time (British Standard Time from Feb 1968 to Oct 1971) (UTC+0100)',
    abbreviation: 'BST',
    offset: '+0100',
    name:
      'British Summer Time (British Standard Time from Feb 1968 to Oct 1971)',
  },
  {
    id: 30,
    value: 'Central European Time (UTC+0100)',
    abbreviation: 'CET',
    offset: '+0100',
    name: 'Central European Time',
  },
  {
    id: 31,
    value: 'AIX specific equivalent of Central European Time[6] (UTC+0100)',
    abbreviation: 'DFT',
    offset: '+0100',
    name: 'AIX specific equivalent of Central European Time[6]',
  },
  {
    id: 32,
    value: 'Irish Standard Time[7] (UTC+0100)',
    abbreviation: 'IST',
    offset: '+0100',
    name: 'Irish Standard Time[7]',
  },
  {
    id: 33,
    value: 'Middle European Time Same zone as CET (UTC+0100)',
    abbreviation: 'MET',
    offset: '+0100',
    name: 'Middle European Time Same zone as CET',
  },
  {
    id: 34,
    value: 'West Africa Time (UTC+0100)',
    abbreviation: 'WAT',
    offset: '+0100',
    name: 'West Africa Time',
  },
  {
    id: 35,
    value: 'Western European Daylight Time (UTC+0100)',
    abbreviation: 'WEDT',
    offset: '+0100',
    name: 'Western European Daylight Time',
  },
  {
    id: 36,
    value: 'Western European Summer Time (UTC+0100)',
    abbreviation: 'WEST',
    offset: '+0100',
    name: 'Western European Summer Time',
  },
  {
    id: 37,
    value: 'Central Africa Time (UTC+0200)',
    abbreviation: 'CAT',
    offset: '+0200',
    name: 'Central Africa Time',
  },
  {
    id: 38,
    value: 'Central European Daylight Time (UTC+0200)',
    abbreviation: 'CEDT',
    offset: '+0200',
    name: 'Central European Daylight Time',
  },
  {
    id: 39,
    value: 'Central European Summer Time (Cf. HAEC) (UTC+0200)',
    abbreviation: 'CEST',
    offset: '+0200',
    name: 'Central European Summer Time (Cf. HAEC)',
  },
  {
    id: 40,
    value: 'Eastern European Time (UTC+0200)',
    abbreviation: 'EET',
    offset: '+0200',
    name: 'Eastern European Time',
  },
  {
    id: 41,
    value: "Heure Avanc e d'Europe Centrale francised name for CEST (UTC+0200)",
    abbreviation: 'HAEC',
    offset: '+0200',
    name: "Heure Avanc e d'Europe Centrale francised name for CEST",
  },
  {
    id: 42,
    value: 'Israel Standard Time (UTC+0200)',
    abbreviation: 'IST',
    offset: '+0200',
    name: 'Israel Standard Time',
  },
  {
    id: 43,
    value: 'Middle European Summer Time Same zone as CEST (UTC+0200)',
    abbreviation: 'MEST',
    offset: '+0200',
    name: 'Middle European Summer Time Same zone as CEST',
  },
  {
    id: 44,
    value: 'South African Standard Time (UTC+0200)',
    abbreviation: 'SAST',
    offset: '+0200',
    name: 'South African Standard Time',
  },
  {
    id: 45,
    value: 'Kaliningrad Time (UTC+0200)',
    abbreviation: 'USZ1',
    offset: '+0200',
    name: 'Kaliningrad Time',
  },
  {
    id: 46,
    value: 'West Africa Summer Time (UTC+0200)',
    abbreviation: 'WAST',
    offset: '+0200',
    name: 'West Africa Summer Time',
  },
  {
    id: 47,
    value: 'Arabia Standard Time (UTC+0300)',
    abbreviation: 'AST',
    offset: '+0300',
    name: 'Arabia Standard Time',
  },
  {
    id: 48,
    value: 'East Africa Time (UTC+0300)',
    abbreviation: 'EAT',
    offset: '+0300',
    name: 'East Africa Time',
  },
  {
    id: 49,
    value: 'Eastern European Daylight Time (UTC+0300)',
    abbreviation: 'EEDT',
    offset: '+0300',
    name: 'Eastern European Daylight Time',
  },
  {
    id: 50,
    value: 'Eastern European Summer Time (UTC+0300)',
    abbreviation: 'EEST',
    offset: '+0300',
    name: 'Eastern European Summer Time',
  },
  {
    id: 51,
    value: 'Further-eastern European Time (UTC+0300)',
    abbreviation: 'FET',
    offset: '+0300',
    name: 'Further-eastern European Time',
  },
  {
    id: 52,
    value: 'Israel Daylight Time (UTC+0300)',
    abbreviation: 'IDT',
    offset: '+0300',
    name: 'Israel Daylight Time',
  },
  {
    id: 53,
    value: 'Indian Ocean Time (UTC+0300)',
    abbreviation: 'IOT',
    offset: '+0300',
    name: 'Indian Ocean Time',
  },
  {
    id: 54,
    value: 'Moscow Time (UTC+0300)',
    abbreviation: 'MSK',
    offset: '+0300',
    name: 'Moscow Time',
  },
  {
    id: 55,
    value: 'Showa Station Time (UTC+0300)',
    abbreviation: 'SYOT',
    offset: '+0300',
    name: 'Showa Station Time',
  },
  {
    id: 56,
    value: 'Armenia Time (UTC+0400)',
    abbreviation: 'AMT',
    offset: '+0400',
    name: 'Armenia Time',
  },
  {
    id: 57,
    value: 'Azerbaijan Time (UTC+0400)',
    abbreviation: 'AZT',
    offset: '+0400',
    name: 'Azerbaijan Time',
  },
  {
    id: 58,
    value: 'Georgia Standard Time (UTC+0400)',
    abbreviation: 'GET',
    offset: '+0400',
    name: 'Georgia Standard Time',
  },
  {
    id: 59,
    value: 'Gulf Standard Time (UTC+0400)',
    abbreviation: 'GST',
    offset: '+0400',
    name: 'Gulf Standard Time',
  },
  {
    id: 60,
    value: 'Mauritius Time (UTC+0400)',
    abbreviation: 'MUT',
    offset: '+0400',
    name: 'Mauritius Time',
  },
  {
    id: 61,
    value: 'R union Time (UTC+0400)',
    abbreviation: 'RET',
    offset: '+0400',
    name: 'R union Time',
  },
  {
    id: 62,
    value: 'Samara Time (UTC+0400)',
    abbreviation: 'SAMT',
    offset: '+0400',
    name: 'Samara Time',
  },
  {
    id: 63,
    value: 'Seychelles Time (UTC+0400)',
    abbreviation: 'SCT',
    offset: '+0400',
    name: 'Seychelles Time',
  },
  {
    id: 64,
    value: 'Volgograd Time (UTC+0400)',
    abbreviation: 'VOLT',
    offset: '+0400',
    name: 'Volgograd Time',
  },
  {
    id: 65,
    value: 'Heard and McDonald Islands Time (UTC+0500)',
    abbreviation: 'HMT',
    offset: '+0500',
    name: 'Heard and McDonald Islands Time',
  },
  {
    id: 66,
    value: 'Mawson Station Time (UTC+0500)',
    abbreviation: 'MAWT',
    offset: '+0500',
    name: 'Mawson Station Time',
  },
  {
    id: 67,
    value: 'Maldives Time (UTC+0500)',
    abbreviation: 'MVT',
    offset: '+0500',
    name: 'Maldives Time',
  },
  {
    id: 68,
    value: 'Oral Time (UTC+0500)',
    abbreviation: 'ORAT',
    offset: '+0500',
    name: 'Oral Time',
  },
  {
    id: 69,
    value: 'Pakistan Standard Time (UTC+0500)',
    abbreviation: 'PKT',
    offset: '+0500',
    name: 'Pakistan Standard Time',
  },
  {
    id: 70,
    value: 'Indian/Kerguelen (UTC+0500)',
    abbreviation: 'TFT',
    offset: '+0500',
    name: 'Indian/Kerguelen',
  },
  {
    id: 71,
    value: 'Tajikistan Time (UTC+0500)',
    abbreviation: 'TJT',
    offset: '+0500',
    name: 'Tajikistan Time',
  },
  {
    id: 72,
    value: 'Turkmenistan Time (UTC+0500)',
    abbreviation: 'TMT',
    offset: '+0500',
    name: 'Turkmenistan Time',
  },
  {
    id: 73,
    value: 'Uzbekistan Time (UTC+0500)',
    abbreviation: 'UZT',
    offset: '+0500',
    name: 'Uzbekistan Time',
  },
  {
    id: 74,
    value: 'Yekaterinburg Time (UTC+0500)',
    abbreviation: 'YEKT',
    offset: '+0500',
    name: 'Yekaterinburg Time',
  },
  {
    id: 75,
    value:
      'Bangladesh Daylight Time (Bangladesh Daylight saving time keeps UTC+06 offset) [3] (UTC+0600)',
    abbreviation: 'BDT',
    offset: '+0600',
    name:
      'Bangladesh Daylight Time (Bangladesh Daylight saving time keeps UTC+06 offset) [3]',
  },
  {
    id: 76,
    value: 'British Indian Ocean Time (UTC+0600)',
    abbreviation: 'BIOT',
    offset: '+0600',
    name: 'British Indian Ocean Time',
  },
  {
    id: 77,
    value: 'Bangladesh Standard Time (UTC+0600)',
    abbreviation: 'BST',
    offset: '+0600',
    name: 'Bangladesh Standard Time',
  },
  {
    id: 78,
    value: 'Bhutan Time (UTC+0600)',
    abbreviation: 'BTT',
    offset: '+0600',
    name: 'Bhutan Time',
  },
  {
    id: 79,
    value: 'Kyrgyzstan time (UTC+0600)',
    abbreviation: 'KGT',
    offset: '+0600',
    name: 'Kyrgyzstan time',
  },
  {
    id: 80,
    value: 'Omsk Time (UTC+0600)',
    abbreviation: 'OMST',
    offset: '+0600',
    name: 'Omsk Time',
  },
  {
    id: 81,
    value: 'Vostok Station Time (UTC+0600)',
    abbreviation: 'VOST',
    offset: '+0600',
    name: 'Vostok Station Time',
  },
  {
    id: 82,
    value: 'Christmas Island Time (UTC+0700)',
    abbreviation: 'CXT',
    offset: '+0700',
    name: 'Christmas Island Time',
  },
  {
    id: 83,
    value: 'Davis Time (UTC+0700)',
    abbreviation: 'DAVT',
    offset: '+0700',
    name: 'Davis Time',
  },
  {
    id: 84,
    value: 'Khovd Time (UTC+0700)',
    abbreviation: 'HOVT',
    offset: '+0700',
    name: 'Khovd Time',
  },
  {
    id: 85,
    value: 'Indochina Time (UTC+0700)',
    abbreviation: 'ICT',
    offset: '+0700',
    name: 'Indochina Time',
  },
  {
    id: 86,
    value: 'Krasnoyarsk Time (UTC+0700)',
    abbreviation: 'KRAT',
    offset: '+0700',
    name: 'Krasnoyarsk Time',
  },
  {
    id: 87,
    value: 'Thailand Standard Time (UTC+0700)',
    abbreviation: 'THA',
    offset: '+0700',
    name: 'Thailand Standard Time',
  },
  {
    id: 88,
    value: 'Western Indonesian Time (UTC+0700)',
    abbreviation: 'WIT',
    offset: '+0700',
    name: 'Western Indonesian Time',
  },
  {
    id: 89,
    value: 'ASEAN Common Time (UTC+0800)',
    abbreviation: 'ACT',
    offset: '+0800',
    name: 'ASEAN Common Time',
  },
  {
    id: 90,
    value: 'Australian Western Standard Time (UTC+0800)',
    abbreviation: 'AWST',
    offset: '+0800',
    name: 'Australian Western Standard Time',
  },
  {
    id: 91,
    value: 'Brunei Time (UTC+0800)',
    abbreviation: 'BDT',
    offset: '+0800',
    name: 'Brunei Time',
  },
  {
    id: 92,
    value: 'Choibalsan (UTC+0800)',
    abbreviation: 'CHOT',
    offset: '+0800',
    name: 'Choibalsan',
  },
  {
    id: 93,
    value: 'Central Indonesia Time (UTC+0800)',
    abbreviation: 'CIT',
    offset: '+0800',
    name: 'Central Indonesia Time',
  },
  {
    id: 94,
    value: 'China Standard Time (UTC+0800)',
    abbreviation: 'CST',
    offset: '+0800',
    name: 'China Standard Time',
  },
  {
    id: 95,
    value: 'China time (UTC+0800)',
    abbreviation: 'CT',
    offset: '+0800',
    name: 'China time',
  },
  {
    id: 96,
    value: 'Hong Kong Time (UTC+0800)',
    abbreviation: 'HKT',
    offset: '+0800',
    name: 'Hong Kong Time',
  },
  {
    id: 97,
    value: 'Irkutsk Time (UTC+0800)',
    abbreviation: 'IRKT',
    offset: '+0800',
    name: 'Irkutsk Time',
  },
  {
    id: 98,
    value: 'Malaysia Standard Time (UTC+0800)',
    abbreviation: 'MST',
    offset: '+0800',
    name: 'Malaysia Standard Time',
  },
  {
    id: 99,
    value: 'Malaysia Time (UTC+0800)',
    abbreviation: 'MYT',
    offset: '+0800',
    name: 'Malaysia Time',
  },
  {
    id: 100,
    value: 'Philippine Standard Time (UTC+0800)',
    abbreviation: 'PST',
    offset: '+0800',
    name: 'Philippine Standard Time',
  },
  {
    id: 101,
    value: 'Singapore Time (UTC+0800)',
    abbreviation: 'SGT',
    offset: '+0800',
    name: 'Singapore Time',
  },
  {
    id: 102,
    value: 'Singapore Standard Time (UTC+0800)',
    abbreviation: 'SST',
    offset: '+0800',
    name: 'Singapore Standard Time',
  },
  {
    id: 103,
    value: 'Ulaanbaatar Time (UTC+0800)',
    abbreviation: 'ULAT',
    offset: '+0800',
    name: 'Ulaanbaatar Time',
  },
  {
    id: 104,
    value: 'Western Standard Time (UTC+0800)',
    abbreviation: 'WST',
    offset: '+0800',
    name: 'Western Standard Time',
  },
  {
    id: 105,
    value: 'Australian Western Daylight Time (UTC+0900)',
    abbreviation: 'AWDT',
    offset: '+0900',
    name: 'Australian Western Daylight Time',
  },
  {
    id: 106,
    value: 'Eastern Indonesian Time (UTC+0900)',
    abbreviation: 'EIT',
    offset: '+0900',
    name: 'Eastern Indonesian Time',
  },
  {
    id: 107,
    value: 'Japan Standard Time (UTC+0900)',
    abbreviation: 'JST',
    offset: '+0900',
    name: 'Japan Standard Time',
  },
  {
    id: 108,
    value: 'Korea Standard Time (UTC+0900)',
    abbreviation: 'KST',
    offset: '+0900',
    name: 'Korea Standard Time',
  },
  {
    id: 109,
    value: 'Timor Leste Time (UTC+0900)',
    abbreviation: 'TLT',
    offset: '+0900',
    name: 'Timor Leste Time',
  },
  {
    id: 110,
    value: 'Yakutsk Time (UTC+0900)',
    abbreviation: 'YAKT',
    offset: '+0900',
    name: 'Yakutsk Time',
  },
  {
    id: 111,
    value: 'Australian Eastern Standard Time (UTC-1000)',
    abbreviation: 'AEST',
    offset: '-1000',
    name: 'Australian Eastern Standard Time',
  },
  {
    id: 112,
    value: 'Chamorro Standard Time (UTC+1000)',
    abbreviation: 'ChST',
    offset: '+1000',
    name: 'Chamorro Standard Time',
  },
  {
    id: 113,
    value: 'Chuuk Time (UTC+1000)',
    abbreviation: 'CHUT',
    offset: '+1000',
    name: 'Chuuk Time',
  },
  {
    id: 114,
    value: "Dumont d'Urville Time (UTC+1000)",
    abbreviation: 'DDUT',
    offset: '+1000',
    name: "Dumont d'Urville Time",
  },
  {
    id: 115,
    value: 'Eastern Standard Time (Australia) (UTC+1000)',
    abbreviation: 'EST',
    offset: '+1000',
    name: 'Eastern Standard Time (Australia)',
  },
  {
    id: 116,
    value: 'Papua New Guinea Time (UTC+1000)',
    abbreviation: 'PGT',
    offset: '+1000',
    name: 'Papua New Guinea Time',
  },
  {
    id: 117,
    value: 'Vladivostok Time (UTC+1000)',
    abbreviation: 'VLAT',
    offset: '+1000',
    name: 'Vladivostok Time',
  },
  {
    id: 118,
    value: 'Australian Eastern Daylight Savings Time (UTC+1100)',
    abbreviation: 'AEDT',
    offset: '+1100',
    name: 'Australian Eastern Daylight Savings Time',
  },
  {
    id: 119,
    value: 'Bougainville Standard Time[4] (UTC+1100)',
    abbreviation: 'BST',
    offset: '+1100',
    name: 'Bougainville Standard Time[4]',
  },
  {
    id: 120,
    value: 'Kosrae Time (UTC+1100)',
    abbreviation: 'KOST',
    offset: '+1100',
    name: 'Kosrae Time',
  },
  {
    id: 121,
    value: 'Lord Howe Summer Time (UTC+1100)',
    abbreviation: 'LHST',
    offset: '+1100',
    name: 'Lord Howe Summer Time',
  },
  {
    id: 122,
    value: 'Macquarie Island Station Time (UTC+1100)',
    abbreviation: 'MIST',
    offset: '+1100',
    name: 'Macquarie Island Station Time',
  },
  {
    id: 123,
    value: 'New Caledonia Time (UTC+1100)',
    abbreviation: 'NCT',
    offset: '+1100',
    name: 'New Caledonia Time',
  },
  {
    id: 124,
    value: 'Pohnpei Standard Time (UTC+1100)',
    abbreviation: 'PONT',
    offset: '+1100',
    name: 'Pohnpei Standard Time',
  },
  {
    id: 125,
    value: 'Sakhalin Island time (UTC+1100)',
    abbreviation: 'SAKT',
    offset: '+1100',
    name: 'Sakhalin Island time',
  },
  {
    id: 126,
    value: 'Solomon Islands Time (UTC+1100)',
    abbreviation: 'SBT',
    offset: '+1100',
    name: 'Solomon Islands Time',
  },
  {
    id: 127,
    value: 'Srednekolymsk Time (UTC+1100)',
    abbreviation: 'SRET',
    offset: '+1100',
    name: 'Srednekolymsk Time',
  },
  {
    id: 128,
    value: 'Vanuatu Time (UTC+1100)',
    abbreviation: 'VUT',
    offset: '+1100',
    name: 'Vanuatu Time',
  },
  {
    id: 129,
    value: 'Fiji Time (UTC+1200)',
    abbreviation: 'FJT',
    offset: '+1200',
    name: 'Fiji Time',
  },
  {
    id: 130,
    value: 'Gilbert Island Time (UTC+1200)',
    abbreviation: 'GILT',
    offset: '+1200',
    name: 'Gilbert Island Time',
  },
  {
    id: 131,
    value: 'Magadan Time (UTC+1200)',
    abbreviation: 'MAGT',
    offset: '+1200',
    name: 'Magadan Time',
  },
  {
    id: 132,
    value: 'Marshall Islands (UTC+1200)',
    abbreviation: 'MHT',
    offset: '+1200',
    name: 'Marshall Islands',
  },
  {
    id: 133,
    value: 'New Zealand Standard Time (UTC+1200)',
    abbreviation: 'NZST',
    offset: '+1200',
    name: 'New Zealand Standard Time',
  },
  {
    id: 134,
    value: 'Kamchatka Time (UTC+1200)',
    abbreviation: 'PETT',
    offset: '+1200',
    name: 'Kamchatka Time',
  },
  {
    id: 135,
    value: 'Tuvalu Time (UTC+1200)',
    abbreviation: 'TVT',
    offset: '+1200',
    name: 'Tuvalu Time',
  },
  {
    id: 136,
    value: 'Wake Island Time (UTC+1200)',
    abbreviation: 'WAKT',
    offset: '+1200',
    name: 'Wake Island Time',
  },
  {
    id: 137,
    value: 'New Zealand Daylight Time (UTC+1300)',
    abbreviation: 'NZDT',
    offset: '+1300',
    name: 'New Zealand Daylight Time',
  },
  {
    id: 138,
    value: 'Phoenix Island Time (UTC+1300)',
    abbreviation: 'PHOT',
    offset: '+1300',
    name: 'Phoenix Island Time',
  },
  {
    id: 139,
    value: 'Tokelau Time (UTC+1300)',
    abbreviation: 'TKT',
    offset: '+1300',
    name: 'Tokelau Time',
  },
  {
    id: 140,
    value: 'Tonga Time (UTC+1300)',
    abbreviation: 'TOT',
    offset: '+1300',
    name: 'Tonga Time',
  },
  {
    id: 141,
    value: 'Line Islands Time (UTC+1400)',
    abbreviation: 'LINT',
    offset: '+1400',
    name: 'Line Islands Time',
  },
  {
    id: 142,
    value: 'Azores Standard Time (UTC-0100)',
    abbreviation: 'AZOST',
    offset: '-0100',
    name: 'Azores Standard Time',
  },
  {
    id: 143,
    value: 'Cape Verde Time (UTC-0100)',
    abbreviation: 'CVT',
    offset: '-0100',
    name: 'Cape Verde Time',
  },
  {
    id: 144,
    value: 'Eastern Greenland Time (UTC-0100)',
    abbreviation: 'EGT',
    offset: '-0100',
    name: 'Eastern Greenland Time',
  },
  {
    id: 145,
    value: 'Brasilia Summer Time (UTC-0200)',
    abbreviation: 'BRST',
    offset: '-0200',
    name: 'Brasilia Summer Time',
  },
  {
    id: 146,
    value: 'Fernando de Noronha Time (UTC-0200)',
    abbreviation: 'FNT',
    offset: '-0200',
    name: 'Fernando de Noronha Time',
  },
  {
    id: 147,
    value: 'South Georgia and the South Sandwich Islands (UTC-0200)',
    abbreviation: 'GST',
    offset: '-0200',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    id: 148,
    value: 'Saint Pierre and Miquelon Daylight time (UTC-0200)',
    abbreviation: 'PMDT',
    offset: '-0200',
    name: 'Saint Pierre and Miquelon Daylight time',
  },
  {
    id: 149,
    value: 'Uruguay Summer Time (UTC-0200)',
    abbreviation: 'UYST',
    offset: '-0200',
    name: 'Uruguay Summer Time',
  },
  {
    id: 150,
    value: 'Atlantic Daylight Time (UTC-0300)',
    abbreviation: 'ADT',
    offset: '-0300',
    name: 'Atlantic Daylight Time',
  },
  {
    id: 151,
    value: 'Amazon Summer Time (Brazil)[1] (UTC-0300)',
    abbreviation: 'AMST',
    offset: '-0300',
    name: 'Amazon Summer Time (Brazil)[1]',
  },
  {
    id: 152,
    value: 'Argentina Time (UTC-0300)',
    abbreviation: 'ART',
    offset: '-0300',
    name: 'Argentina Time',
  },
  {
    id: 153,
    value: 'Brasilia Time (UTC-0300)',
    abbreviation: 'BRT',
    offset: '-0300',
    name: 'Brasilia Time',
  },
  {
    id: 154,
    value: 'Chile Summer Time (UTC-0300)',
    abbreviation: 'CLST',
    offset: '-0300',
    name: 'Chile Summer Time',
  },
  {
    id: 155,
    value: 'Falkland Islands Standard Time (UTC-0300)',
    abbreviation: 'FKST',
    offset: '-0300',
    name: 'Falkland Islands Standard Time',
  },
  {
    id: 156,
    value: 'Falkland Islands Summer Time (UTC-0300)',
    abbreviation: 'FKST',
    offset: '-0300',
    name: 'Falkland Islands Summer Time',
  },
  {
    id: 157,
    value: 'French Guiana Time (UTC-0300)',
    abbreviation: 'GFT',
    offset: '-0300',
    name: 'French Guiana Time',
  },
  {
    id: 158,
    value: 'Saint Pierre and Miquelon Standard Time (UTC-0300)',
    abbreviation: 'PMST',
    offset: '-0300',
    name: 'Saint Pierre and Miquelon Standard Time',
  },
  {
    id: 159,
    value: 'Paraguay Summer Time (South America)[8] (UTC-0300)',
    abbreviation: 'PYST',
    offset: '-0300',
    name: 'Paraguay Summer Time (South America)[8]',
  },
  {
    id: 160,
    value: 'Rothera Research Station Time (UTC-0300)',
    abbreviation: 'ROTT',
    offset: '-0300',
    name: 'Rothera Research Station Time',
  },
  {
    id: 161,
    value: 'Suriname Time (UTC-0300)',
    abbreviation: 'SRT',
    offset: '-0300',
    name: 'Suriname Time',
  },
  {
    id: 162,
    value: 'Uruguay Standard Time (UTC-0300)',
    abbreviation: 'UYT',
    offset: '-0300',
    name: 'Uruguay Standard Time',
  },
  {
    id: 163,
    value: 'Amazon Time (Brazil)[2] (UTC-0400)',
    abbreviation: 'AMT',
    offset: '-0400',
    name: 'Amazon Time (Brazil)[2]',
  },
  {
    id: 164,
    value: 'Atlantic Standard Time (UTC-0400)',
    abbreviation: 'AST',
    offset: '-0400',
    name: 'Atlantic Standard Time',
  },
  {
    id: 165,
    value: 'Bolivia Time (UTC-0400)',
    abbreviation: 'BOT',
    offset: '-0400',
    name: 'Bolivia Time',
  },
  {
    id: 166,
    value: 'Cuba Daylight Time[5] (UTC-0400)',
    abbreviation: 'CDT',
    offset: '-0400',
    name: 'Cuba Daylight Time[5]',
  },
  {
    id: 167,
    value: 'Chile Standard Time (UTC-0400)',
    abbreviation: 'CLT',
    offset: '-0400',
    name: 'Chile Standard Time',
  },
  {
    id: 168,
    value: 'Colombia Summer Time (UTC-0400)',
    abbreviation: 'COST',
    offset: '-0400',
    name: 'Colombia Summer Time',
  },
  {
    id: 169,
    value: 'Eastern Caribbean Time (does not recognise DST) (UTC-0400)',
    abbreviation: 'ECT',
    offset: '-0400',
    name: 'Eastern Caribbean Time (does not recognise DST)',
  },
  {
    id: 170,
    value: 'Eastern Daylight Time (North America) (UTC-0400)',
    abbreviation: 'EDT',
    offset: '-0400',
    name: 'Eastern Daylight Time (North America)',
  },
  {
    id: 171,
    value: 'Falkland Islands Time (UTC-0400)',
    abbreviation: 'FKT',
    offset: '-0400',
    name: 'Falkland Islands Time',
  },
  {
    id: 172,
    value: 'Guyana Time (UTC-0400)',
    abbreviation: 'GYT',
    offset: '-0400',
    name: 'Guyana Time',
  },
  {
    id: 173,
    value: 'Paraguay Time (South America)[9] (UTC-0400)',
    abbreviation: 'PYT',
    offset: '-0400',
    name: 'Paraguay Time (South America)[9]',
  },
  {
    id: 174,
    value: 'Venezuelan Standard Time (UTC-0400)',
    abbreviation: 'VET',
    offset: '-0400',
    name: 'Venezuelan Standard Time',
  },
  {
    id: 175,
    value: 'Acre Time (UTC-0400)',
    abbreviation: 'ACT',
    offset: '-0400',
    name: 'Acre Time',
  },
  {
    id: 176,
    value: 'Central Daylight Time (North America) (UTC-0500)',
    abbreviation: 'CDT',
    offset: '-0500',
    name: 'Central Daylight Time (North America)',
  },
  {
    id: 177,
    value: 'Colombia Time (UTC-0500)',
    abbreviation: 'COT',
    offset: '-0500',
    name: 'Colombia Time',
  },
  {
    id: 178,
    value: 'Cuba Standard Time (UTC-0500)',
    abbreviation: 'CST',
    offset: '-0500',
    name: 'Cuba Standard Time',
  },
  {
    id: 179,
    value: 'Easter Island Standard Summer Time (UTC-0500)',
    abbreviation: 'EASST',
    offset: '-0500',
    name: 'Easter Island Standard Summer Time',
  },
  {
    id: 180,
    value: 'Ecuador Time (UTC-0500)',
    abbreviation: 'ECT',
    offset: '-0500',
    name: 'Ecuador Time',
  },
  {
    id: 181,
    value: 'Eastern Standard Time (North America) (UTC-0500)',
    abbreviation: 'EST',
    offset: '-0500',
    name: 'Eastern Standard Time (North America)',
  },
  {
    id: 182,
    value: 'Peru Time (UTC-0500)',
    abbreviation: 'PET',
    offset: '-0500',
    name: 'Peru Time',
  },
  {
    id: 183,
    value: 'Central Standard Time (North America) (UTC-0600)',
    abbreviation: 'CST',
    offset: '-0600',
    name: 'Central Standard Time (North America)',
  },
  {
    id: 184,
    value: 'Easter Island Standard Time (UTC-0600)',
    abbreviation: 'EAST',
    offset: '-0600',
    name: 'Easter Island Standard Time',
  },
  {
    id: 185,
    value: 'Galapagos Time (UTC-0600)',
    abbreviation: 'GALT',
    offset: '-0600',
    name: 'Galapagos Time',
  },
  {
    id: 186,
    value: 'Mountain Daylight Time (North America) (UTC-0600)',
    abbreviation: 'MDT',
    offset: '-0600',
    name: 'Mountain Daylight Time (North America)',
  },
  {
    id: 187,
    value: 'Mountain Standard Time (North America) (UTC-0700)',
    abbreviation: 'MST',
    offset: '-0700',
    name: 'Mountain Standard Time (North America)',
  },
  {
    id: 188,
    value: 'Pacific Daylight Time (North America) (UTC-0700)',
    abbreviation: 'PDT',
    offset: '-0700',
    name: 'Pacific Daylight Time (North America)',
  },
  {
    id: 189,
    value: 'Alaska Daylight Time (UTC-0800)',
    abbreviation: 'AKDT',
    offset: '-0800',
    name: 'Alaska Daylight Time',
  },
  {
    id: 190,
    value: 'Clipperton Island Standard Time (UTC-0800)',
    abbreviation: 'CIST',
    offset: '-0800',
    name: 'Clipperton Island Standard Time',
  },
  {
    id: 191,
    value: 'Pacific Standard Time (North America) (UTC-0800)',
    abbreviation: 'PST',
    offset: '-0800',
    name: 'Pacific Standard Time (North America)',
  },
  {
    id: 192,
    value: 'Alaska Standard Time (UTC-0900)',
    abbreviation: 'AKST',
    offset: '-0900',
    name: 'Alaska Standard Time',
  },
  {
    id: 193,
    value: 'Gambier Islands (UTC-0900)',
    abbreviation: 'GAMT',
    offset: '-0900',
    name: 'Gambier Islands',
  },
  {
    id: 194,
    value: 'Gambier Island Time (UTC-0900)',
    abbreviation: 'GIT',
    offset: '-0900',
    name: 'Gambier Island Time',
  },
  {
    id: 195,
    value: 'Hawaii-Aleutian Daylight Time (UTC-0900)',
    abbreviation: 'HADT',
    offset: '-0900',
    name: 'Hawaii-Aleutian Daylight Time',
  },
  {
    id: 196,
    value: 'Cook Island Time (UTC-1000)',
    abbreviation: 'CKT',
    offset: '-1000',
    name: 'Cook Island Time',
  },
  {
    id: 197,
    value: 'Hawaii-Aleutian Standard Time (UTC-1000)',
    abbreviation: 'HAST',
    offset: '-1000',
    name: 'Hawaii-Aleutian Standard Time',
  },
  {
    id: 198,
    value: 'Hawaii Standard Time (UTC-1000)',
    abbreviation: 'HST',
    offset: '-1000',
    name: 'Hawaii Standard Time',
  },
  {
    id: 199,
    value: 'Tahiti Time (UTC-1000)',
    abbreviation: 'TAHT',
    offset: '-1000',
    name: 'Tahiti Time',
  },
  {
    id: 200,
    value: 'Niue Time (UTC-1100)',
    abbreviation: 'NUT',
    offset: '-1100',
    name: 'Niue Time',
  },
  {
    id: 201,
    value: 'Samoa Standard Time (UTC-1100)',
    abbreviation: 'SST',
    offset: '-1100',
    name: 'Samoa Standard Time',
  },
  {
    id: 202,
    value: 'Baker Island Time (UTC-1200)',
    abbreviation: 'BIT',
    offset: '-1200',
    name: 'Baker Island Time',
  },
];
