import { AssetLibraryModule } from './../asset-library/asset-library.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from './../../core/data/data-service';
import { CreateProfileComponent } from './create-profile.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { ProfileStorelogoContainerComponent } from './profile-storelogo-container/profile-storelogo-container.component';
import { ProfileStorelogoModule } from '../../common/components/profile-storelogo/profile-storelogo.module';
import { LangMultiSelectComponent } from '../../common/components/lang-multi-select/lang-multi-select.component';

@NgModule({
  declarations: [
    CreateProfileComponent,
    ProfileInfoComponent,
    ProfileStorelogoContainerComponent,
    LangMultiSelectComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ProfileStorelogoModule,
    AssetLibraryModule, // TODO: Change this name  to a more common name
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    DataService
  ]
})
export class CreateProfileModule { }
