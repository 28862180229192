export  class  User {
  email: string;
  userId: string;
  firstName: string;
  lastName: string;

  constructor(userId?: string, email?: string, firstName?: string, lastName?: string) {
    this.email = email;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
  }

}
