import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalConstants } from '../../../app/common/constants/global.constant';
import { RouterConstants } from '../../core/routing/constants/app-paths.constant';
import { ProfileService } from '../../core/routing/services/profile_api/profile.service';
import { Profile } from '../../modules/profile/interface/profile.i';
import { DeleteComponent } from './delete/delete.component';
import { DuplicateComponent } from './duplicate/duplicate.component';

@Component({
  selector: 'iposcen-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  profileData: Profile[] = [];
  page: number;
  count: number;
  tableSize: number;
  message: number;
  showFrom: number;
  showTo: number;
  exisitingProfiles: string;
  profileName: string;
  created: string;
  languages: string;
  operatingSystem: string;
  noRecordFound: string;
  preview: string;
  download: string;
  delete: string;
  duplicate: string;
  edit: string;
  maxlength: any;
  showing: string;
  of: string;
  profilesHeading: string;
  createProfile: string;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getAllProfilesByUserId();
    this.profilesHeading = GlobalConstants.PROFILES_HEADING;
    this.of = GlobalConstants.OF;
    this.showing = GlobalConstants.SHOWING;
    this.createProfile = GlobalConstants.CREAT_PROFILES;
    this.page = 1;
    this.count = 0;
    this.tableSize = 10;
    this.maxlength = this.profileData.length;
    this.showFrom = ((this.page - 1) * this.tableSize) + 1;
    this.showTo =  Math.min(this.page * this.tableSize);
    this.exisitingProfiles = GlobalConstants.EXISTING_PROFILES;
    this.download = GlobalConstants.DOWNLOAD;
    this.preview = GlobalConstants.PREVIEW;
    this.noRecordFound = GlobalConstants.NO_RECORDS_FOUND;
    this.edit = GlobalConstants.EDIT;
    this.duplicate = GlobalConstants.DUPLICATE;
    this.delete = GlobalConstants.DELETE;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAllProfilesByUserId() {
    this.profileService.getAllProfilesByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(getAllProfileContentResponse => {
       this.profileData = (getAllProfileContentResponse.profile as Profile []);
      });
  }

  openDelete() {
    this.modalService.open(DeleteComponent);
  }

  openDuplicate() {
    this.modalService.open(DuplicateComponent);
  }

  onTableDataChange(event) {
    this.page = event;
    this.getAllProfilesByUserId();
    this.showFrom = ((this.page - 1) * this.tableSize) + 1;
    this.showTo =  Math.min(this.page * this.tableSize, this.profileData.length);
  }

  showCreateProfile(){
    this.router.navigate([RouterConstants.CREATE_PROFILE_LINK]);
  }
}
