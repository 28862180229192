<div class="tab-pane users">
    <!-- Data heading and info -->
    <div class="data_header">
        <div class="left_side_section">
            <div class="mr-3">
                <div class="title">Retail HQs</div>
                <div class="results" *ngIf="displayRetailHqList.length">
                    <span class="showing">showing</span>&nbsp;
                    <strong>{{ showFrom }} - {{ showTo }}</strong>&nbsp;
                    <span class="of">of</span>&nbsp;{{ displayRetailHqList.length }}&nbsp;
                    <strong>Retail HQs</strong>
                </div>
            </div>
            <div class="input-group">
                <input #searchValue type="text" value="" id="retailSearch" class="form-control"
                    placeholder="Search by Name..." />
            </div>
            <button type="button" class="btn btn-primary ml-2" (click)="getAllRetailerInfoSearchByName()">
                <img src="assets/images/search_icon.svg" alt="search" />
            </button>
        </div>
        <div class="action_buttons">
            <button type="button" class="btn btn-primary btn-lg left_icon_btn" (click)="openCreateModal()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M21 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V3C22.5 2.60218 22.342 2.22064 22.0607 1.93934C21.7794 1.65804 21.3978 1.5 21 1.5ZM3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0Z" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span>New Retail HQ</span>
            </button>
        </div>
    </div>
    <hr />

    <!-- Table data -->
    <div class="table-responsive">
        <table id="retailHQTable" class="table table-borderless" aria-describedby="retailhqs">
            <thead>
                <tr *ngIf="displayRetailHqList?.length">
                    <th #retailerId scope="col" data-order="desc" data-name="retailerId">
                        <span class="table_headings">
                            <span>Retail HQ ID</span>
                            <span class="chevron_expand" (click)="getAllRetailerInfoSortById('retailerId')"></span>
                        </span>
                    </th>
                    <th #retailerName scope="col" data-order="desc" data-name="retailerName">
                        <span class="table_headings">
                            <span>Name</span>
                            <span class="chevron_expand" (click)="getAllRetailerInfoSortByName('retailerName')"></span>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="table_headings">
                            <span>Created By</span>
                        </span>
                    </th>
                    <th #createdTime scope="col" data-order="desc" data-name="createdTime">
                        <span class="table_headings">
                            <span>Created On</span>
                            <span class="chevron_expand"
                                (click)="getAllRetailerInfoSortByCreateTime('createdTime')"></span>
                        </span>
                    </th>
                    <th #updatedTime scope="col" data-order="desc" data-name="updatedTime">
                        <span class="table_headings">
                            <span>Last Updated</span>
                            <span class="chevron_expand"
                                (click)="getAllRetailerInfoSortByUpdateTime('updatedTime')"></span>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="table_headings">
                            <span>Store Count</span>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let new of displayRetailHqList | slice: (page-1) * tableSize : page * tableSize">
                    <tr>
                        <td>{{ new?.retailerId }}</td>
                        <td>{{ new?.retailerName }}</td>
                        <td>{{ new?.createdByName }}</td>
                        <td>{{ new?.createdTime | date: 'MM/dd/yy'}}</td>
                        <td>{{ new?.updatedTime | date: 'MM/dd/yy'}}</td>
                        <td>{{new?.storeCount}}</td>
                        <td>
                            <button data-toggle="collapse" [attr.data-target]="'#' + new.retailerId" class="tooltip"
                                [attr.aria-expanded]="new.isExpanded" aria-controls="collapseThree" *ngIf="!new.isEdit"
                                type="button" class="btn btn-link left_icon_btn"
                                (click)="editRetailDetails(new?.retailerId)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M14.1159 1.61598C14.3503 1.38164 14.6682 1.25 14.9996 1.25C15.3311 1.25 15.649 1.38164 15.8834 1.61598L18.3834 4.11598C18.6177 4.35039 18.7494 4.66828 18.7494 4.99973C18.7494 5.33119 18.6177 5.64907 18.3834 5.88348L7.13338 17.1335C6.99653 17.2707 6.82964 17.3743 6.64588 17.436L2.89588 18.686C2.67556 18.7597 2.43905 18.7706 2.21288 18.7175C1.98671 18.6643 1.77984 18.5491 1.61549 18.3849C1.45114 18.2207 1.33581 18.0139 1.28244 17.7878C1.22908 17.5616 1.23979 17.3251 1.31338 17.1047L2.56338 13.3547C2.62488 13.1705 2.72845 13.0032 2.86588 12.866L14.1159 1.61598ZM14.9996 2.49973L17.4996 4.99973L6.24963 16.2497L2.49963 17.4997L3.74963 13.7497L14.9996 2.49973Z"
                                        fill="#0068B5" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M15.1825 7.94164L12.0575 4.81664L12.9425 3.93164L16.0675 7.05664L15.1838 7.94164H15.1825ZM3.75 12.4991V13.1241C3.75 13.2899 3.81585 13.4489 3.93306 13.5661C4.05027 13.6833 4.20924 13.7491 4.375 13.7491H5V14.3741C5 14.5399 5.06585 14.6989 5.18306 14.8161C5.30027 14.9333 5.45924 14.9991 5.625 14.9991H6.25V15.6241C6.25 15.7899 6.31585 15.9489 6.43306 16.0661C6.55027 16.1833 6.70924 16.2491 6.875 16.2491H7.5V14.3741C7.5 14.2084 7.43415 14.0494 7.31694 13.9322C7.19973 13.815 7.04076 13.7491 6.875 13.7491H6.25V13.1241C6.25 12.9584 6.18415 12.7994 6.06694 12.6822C5.94973 12.565 5.79076 12.4991 5.625 12.4991H3.75Z"
                                        fill="#0068B5" />
                                </svg>
                                Edit
                            </button>
                            <div class="button_wrapper" style="justify-content: flex-end">
                                <button *ngIf="new.isEdit" type="button" (click)="cancelEdit(new?.retailerId)"
                                    class="btn btn-outline-primary btn-sm left_icon_btn delete_btn"> Cancel </button>
                                <button *ngIf="new.isEdit" type="button" id="uploadprice"
                                    class="btn btn-primary btn-sm left_icon_btn" [disabled]="!new?.retailerName"
                                    (click)="updateRetail(new)"> Save
                                    Changes </button>
                            </div>
                        </td>
                        <td>
                            <span class="table_collapse_link collapsed" data-toggle="collapse"
                                [attr.data-target]="'#' + new.retailerId" [attr.aria-expanded]="new.isExpanded"
                                aria-controls="collapseThree">
                                <img class="table_row_expanded" src="/assets/images/table_row_collapse.svg" alt="expand"
                                    [ngClass]="new.isExpanded?'transform-180':''"
                                    (click)="getDetailsOfRetailHq(new?.retailerId)" />
                            </span>
                        </td>
                    </tr>
                    <tr class="collapse" id="collapseThree" data-parent="#retailHQTable" #collapse="ngbCollapse"
                        [(ngbCollapse)]="!new.isExpanded" aria-labelledby="retailHqcollapse">
                        <td colspan="8">
                            <div class="d-flex">
                                <div class="border-right">
                                    <div class="data_title">Details</div>
                                    <div class="editable_data d-flex">
                                        <div style="min-width: 190px">
                                            <div class="form-group">
                                                <label for="">Name</label>
                                                <div *ngIf="!new.isEdit">{{ new?.retailerName }}</div>
                                                <div *ngIf="new.isEdit"><input type="text" [(ngModel)]="retailName"
                                                        [ngClass]="{'change-border':retailHqForm?.controls?.retailerName?.value}"
                                                        class="form-control"></div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Created By</label>
                                                <div>{{ new?.createdByName }}</div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Created On</label>
                                                <div>{{ new?.createdTime | date: 'MM/dd/yy'}}</div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Last Updated</label>
                                                <div>{{new?.updatedTime | date: 'MM/dd/yy'}}</div>
                                            </div>
                                        </div>
                                        <div style="margin: 0px 30px">
                                            <div class="form-group">
                                                <label for="">Store Count</label>
                                                <div>{{new?.storeCount}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin: 0px 30px">
                                    <div class="data_title">Settings</div>
                                    <div class="editable_data">
                                        <div style="min-width: 190px">
                                            <div class="form-group">
                                                <label for="">Device Expiration Days</label>
                                                <div *ngIf="!new.isEdit">
                                                    {{new?.configurations?.deviceExpirationDays || ''}}
                                                </div>
                                                <div *ngIf="new.isEdit"><input type="number" min="0"
                                                        [(ngModel)]="new.configurations.deviceExpirationDays"
                                                        [ngClass]="{'change-border':new?.configurations?.deviceExpirationDays}"
                                                        class="form-control"></div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Block Windows Updates</label>
                                                <div *ngIf="!new.isEdit">{{new?.configurations?.blockWindowsUpdate ?
                                                    'Enabled':'Disabled'}}</div>
                                                <div *ngIf="new.isEdit" class="custom-control custom-switch mt-2">
                                                    <input type="checkbox"
                                                        [(ngModel)]="new.configurations.blockWindowsUpdate"
                                                        class="custom-control-input" id="blockWindows" />
                                                    <label class="custom-control-label" for="blockWindows">
                                                        {{new?.configurations?.blockWindowsUpdate ?
                                                        'Enabled':'Disabled'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">SRT on Hero Tile</label>
                                                <div *ngIf="!new.isEdit">{{new?.srtEnabled ? 'Enabled':'Disabled'}}
                                                </div>
                                                <div *ngIf="new.isEdit" class="custom-control custom-switch mt-2">
                                                    <input type="checkbox" [(ngModel)]="new.srtEnabled"
                                                        class="custom-control-input" id="srtTile" />
                                                    <label class="custom-control-label" for="srtTile">
                                                        {{new?.srtEnabled ? 'Enabled':'Disabled'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Custom Brand color</label>
                                                <div *ngIf="!new.isEdit">{{new?.configurations?.bandColor ?
                                                    'Enabled':'Disabled'}}</div>
                                                <div *ngIf="new.isEdit" class="custom-control custom-switch mt-2">
                                                    <input type="checkbox" [(ngModel)]="new.configurations.bandColor"
                                                        (change)="brandEditCheck(new)" class="custom-control-input"
                                                        id="segmentColor" />
                                                    <label class="custom-control-label" for="segmentColor"
                                                        style="color: #262626">
                                                        {{new?.configurations?.bandColor ? 'Enabled':'Disabled'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="segment_color_row" *ngIf="new.configurations.bandColor">
                                                    <div class="form-group">
                                                        <label for="">Brand Color</label>
                                                        <div class="color_picker_button" [cpPosition]="'right'"
                                                            [(colorPicker)]="new.configurations.bgcolor"
                                                            [style.background]="new.configurations.bgcolor"
                                                            name="bgcolor"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>

                <tr *ngIf="!displayRetailHqList.length">
                    <td class="profile_Record">No Records Found</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- pagination -->
    <div class="data_footer position-relative">
        <button type="button" class="btn btn-outline-primary left_icon_btn download_btn">
            <img src="/assets/images/download_icon.svg" alt="download" />
            Download CSV
        </button>
        <div class="d-flex flex-column align-items-center" *ngIf="displayRetailHqList.length">
            <nav aria-label="retail hq pagination">
                <ngb-pagination [collectionSize]="displayRetailHqList.length" [(page)]="page" [pageSize]="tableSize"
                    [rotate]="false" [ellipses]="false" [boundaryLinks]="false"
                    (pageChange)="onTableDataChange($event)">
                </ngb-pagination>
            </nav>
            <div class="results mt-2">
                <span class="showing">showing</span>&nbsp;
                <strong>{{ showFrom }} - {{ showTo }}</strong>&nbsp;
                <span class="of">of</span>&nbsp;{{ displayRetailHqList?.length }}&nbsp;
                <strong>Retail HQs</strong>
            </div>
        </div>
    </div>
</div>




<div class="container">
    <div class="modal-dialog modal-dialog-centered" *ngIf="openModal">
        <div class="modal-backdrop show"></div>
        <div class="modal" id="myModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close" data-dismiss="modal" (click)="CloseRetailModal()">
                        <img src="/assets/images/modal_close_icon.svg" alt="close" />
                    </div>
                    <div class="modal-body">
                        <div class=heading>New Retail HQ</div>

                        <form [formGroup]="retailHqForm">
                            <div class="row">
                                <div class="form-group col-8">
                                    <label for="">Retail HQ Name *</label>
                                    <input type="text"
                                        [ngClass]="{'change-border':retailHqForm?.controls?.retailerName?.value}"
                                        formControlName="retailerName" placeholder="Retail HQ Name"
                                        class="form-control">
                                </div>
                                <div class="form-group col-8 mt-4">
                                    <label for="">Device Expiration Days</label>
                                    <input type="number" min="0"
                                        [ngClass]="{'change-border':retailHqForm?.controls?.expirationDays?.value}"
                                        formControlName="expirationDays" value="30" class="form-control">
                                </div>
                            </div>
                            <div class=mt-3 style="border-bottom: 1px solid #e9e9e9;">
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="checkboxes_column">
                                    <div class="form-check mt-4">
                                        <input class="form-check-input" type="checkbox" value=""
                                            formControlName="blockWindows" id="segmentBlock" checked>
                                        <label class="form-check-label" for="segmentBlock" style="color: #262626">
                                            Block Windows
                                            Updates
                                        </label>
                                    </div>
                                    <div class="form-check mt-4">
                                        <input class="form-check-input" formControlName="srtEnabled" type="checkbox"
                                            value="" id="segmentSrt">
                                        <label class="form-check-label" for="segmentSrt" style="color: #262626">
                                            SRT on Hero Tile
                                        </label>
                                    </div>
                                    <div class="form-check mt-4">
                                        <input class="form-check-input " formControlName="bandColor" type="checkbox"
                                            value="" id="segmentColor" (change)="brandChecks()">
                                        <label class="form-check-label" for="segmentColor" style="color: #262626">
                                            Custom Brand Background Color
                                        </label>
                                    </div>
                                    <div class=mt-3 *ngIf="brandCheck">
                                        <div class="segment_color_row">
                                            <div class="form-group">
                                                <label for="">Brand Color</label>
                                                <div class="color_picker_button" [cpPosition]="'right'"
                                                    [(colorPicker)]="retailTheme.bgcolor"
                                                    [style.background]="retailTheme.bgcolor" name="bgcolor"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="preview_example mt-3">
                                    <img style="width: 100%;" src="./assets/images/Home i7 Comet 1.png" alt="preview" />
                                    <strong>Custom Brand Background Color:</strong> Allow Retail HQs to apply their
                                    brand color to
                                    the IPOS
                                    background.
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <div class="button_wrapper" style="justify-content: flex-end">
                            <button type="button" class="btn btn-outline-primary"
                                (click)="CloseRetailModal()">Cancel</button>
                            <button type="button" [disabled]="!retailHqForm?.controls?.retailerName?.value"
                                class="btn btn-primary" (click)="createRetailHq()">Create Retail
                                HQ</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #colorpicker>
    <div class="card">
        <div class="modal-header p-0">
            <div (click)="dismiss()" class="close_icon popup_close">
                <img src=".../../../../../../../assets/images/popup_close_button.svg" alt="close icon" />
            </div>
        </div>
        <div class="modal-body p-0">
            <div id="themePick" class="card">
                <div class="theme_pick_header">
                    <div class="theme_pick_title">
                        Segment Heading</div>
                </div>
                <div class="theme_color mb-3">
                    <div>
                        <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#0068B5')">
                            <span class="text_color"></span>
                            <span class="bg_color"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#0068B5' )}"></span>
                    </div>
                    <div>
                        <div class="theme_picker_button default" (click)="chooseTheme('#0068B5','#ffffff')">
                            <span class="text_color" style="background-color:#0068B5"></span>
                            <span class="bg_color" style="background-color: #ffffff"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#0068B5','#ffffff')}"></span>
                    </div>
                    <div>
                        <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#00C7FD')">
                            <span class="text_color"></span>
                            <span class="bg_color" style="background-color: #00C7FD"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#00C7FD')}"></span>
                    </div>
                    <div>
                        <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#8BAE46')">
                            <span class="text_color"></span>
                            <span class="bg_color" style="background-color: #8BAE46"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#8BAE46')}"></span>
                    </div>
                </div>
                <div class="theme_color">
                    <div>
                        <div class="theme_picker_button default" style="margin-bottom:0"
                            (click)="chooseTheme('#ffffff','#8F5DA2')">
                            <span class="text_color"></span>
                            <span class="bg_color" style="background-color: #8F5DA2"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#8F5DA2')}"></span>
                    </div>
                    <div>
                        <div class="theme_picker_button default" style="margin-bottom:0"
                            (click)="chooseTheme('#ffffff','#EDB200')">
                            <span class="text_color"></span>
                            <span class="bg_color" style="background-color: #EDB200"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#EDB200')}"></span>
                    </div>
                    <div>
                        <div class="theme_picker_button default" style="margin-bottom:0"
                            (click)="chooseTheme('#ffffff','#1E2EB8')">
                            <span class="text_color"></span>
                            <span class="bg_color" style="background-color: #1E2EB8"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#1E2EB8')}"></span>
                    </div>
                    <div>
                        <div class="theme_picker_button default" style="margin-right:0"
                            (click)="chooseTheme('#ffffff','#D96930')">
                            <span class="text_color"></span>
                            <span class="bg_color" style="background-color: #D96930"></span>
                        </div>
                        <span [ngClass]="{'color_highlighter': isSelected('#ffffff','#D96930')}"></span>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-outline-primary" (click)="chooseTheme('#ffffff','#0068B5')">
                        Use Default
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>