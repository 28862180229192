import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Sort } from '../common/utils/sort';

@Directive({
  selector: '[iposcenSort]'
})
export class SortDirective {

  @Input() iposcenSort: any[];
  constructor(private renderer: Renderer2,
    private targetElem: ElementRef) { }

    @HostListener('click')
    sortData() {
      const sort = new Sort();
      const elem = this.targetElem.nativeElement;
      const order = elem.getAttribute('data-order');
      const type = elem.getAttribute('data-type');
      const property = elem.getAttribute('data-name');
      if (order === 'desc') {
        this.iposcenSort.sort(sort.startSort(property, order, type));
        elem.setAttribute('data-order', 'asc');
      } else {
        this.iposcenSort.sort(sort.startSort(property, order, type));
        elem.setAttribute('data-order', 'desc');
      }

    }

}
