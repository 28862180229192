import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountRoutingModule } from './account-routing.module';
import { LoginComponent } from './login/login.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { ResetPwdComponent } from './forgot-pwd/new-password/reset-pwd/reset-pwd.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NewPasswordComponent } from './forgot-pwd/new-password/new-password.component';
import { AccountCreatedComponent } from './confirm-registration/account-created/account-created.component';
import { EmailSentComponent } from './forgot-pwd/email-sent/email-sent.component';

@NgModule({
  declarations: [LoginComponent,
    CreateAccountComponent,
    ForgotPwdComponent,
    ResetPwdComponent,
    ConfirmRegistrationComponent,
    NewPasswordComponent,
    AccountCreatedComponent,
    EmailSentComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ]
})
export class AccountModule { }
