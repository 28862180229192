<div>
  <section class="main_section_with_preview">
    <!-- Live preview - temporarly image -->
    <iposcen-live-preview *ngIf="tabIndex === 4 || tabIndex === 5 || tabIndex === 6 "></iposcen-live-preview>
    <!-- Layout of preview -->
    <div class="preview_layout" *ngIf="tabIndex === 4 || tabIndex === 5 || tabIndex === 6 ">
      <img [ngClass]="{'active_preview': LCGlayout === 1 ? true : false}" src="assets/images/SP_layout_1.png"
        alt="layout 1" (click)="setLayout(1)"  class="cursor-pointer" />

      <img [ngClass]="{'active_preview': LCGlayout === 2 }" src="assets/images/SP_layout_2.png" alt="layout 2"
        (click)="setLayout(2)"  class="cursor-pointer" />

      <img [ngClass]="{'active_preview': LCGlayout === 3 }" src="assets/images/SP_layout_3.png" alt="layout 3"
        (click)="setLayout(3)"  class="cursor-pointer" />
    </div>
    <section class="main_section al_storelogo">
      <!-- Navtabs -->
      <ul *ngIf="tabIndex !== 3 && tabIndex !== 4 && tabIndex !== 5 && tabIndex !== 6 " class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" (click)="setIndex(0)" [ngClass]="{'active': tabIndex === 0}">Store
            Logos</a>
        </li>
        <li class="nav-item"  [ngClass]="(isDisabled)?'inActive':''">
          <a class="nav-link" data-toggle="tab" (click)="setIndex(1)" [ngClass]="{'active': tabIndex === 1}">logo
            content groups</a>
        </li>
      </ul>
      <ul *ngIf="(tabIndex === 3 || tabIndex === 4 || tabIndex === 5 || tabIndex === 6)" class="nav nav-tabs"
        role="tablist">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab"
            [ngClass]="{'active': tabIndex === 3}">{{editCheck? showLogContentName :'New Logo Content Group'}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 4}"
            (click)="clickable?setIndex(4):false">PAGE 1</a>
        </li>
        <li class="nav-item" *ngIf="pages && pages.page2">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 5}"
            (click)="clickable?setIndex(5):false">PAGE 2</a>
        </li>
        <li class="nav-item" *ngIf="pages && pages.page3">
          <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 6}"
            (click)="clickable?setIndex(6):false">PAGE 3</a>
        </li>
        <li class="nav-item" *ngIf="clickable && !checkPages()">
          <a class="nav-link" data-toggle="tab" (click)="addPage()">
            <img src="assets/images/add_black_square_outline.svg" alt="exit without changes" />
          </a>
        </li>
        <li class="nav-item exit_preview">
          <a class="nav-link" (click)="close()">
            <img src="assets/images/exit_icon.svg" alt="exit without changes" />
          </a>
        </li>
      </ul>

      <!-- tab panes -->
      <div class="tab-content">
        <!-- Store Promo Tab -->
        <div *ngIf="tabIndex === 0" id="storeLogos" class="tab-pane" [ngClass]="{'active': tabIndex === 0}">
          <ng-container *ngIf="presentPage === assets_page">
            <!-- Data header with info like no. of content groups, action buttons and filters -->
            <div class="data_header" [ngClass] = "(otherStoreLogos?.length || defaultStoreLogos?.length) ? '' : 'display-block'">
              <span *ngIf="(otherStoreLogos?.length || defaultStoreLogos?.length)">
                <span id="dataTitle">{{otherStoreLogos?.length + defaultStoreLogos?.length}} Store Logos</span>
                <span>
                  Sort by :&nbsp;
                  <div ngbDropdown class="btn-group">
                    <button ngbDropdownToggle type="button" class="btn btn-primary btn-sm dropdown-toggle"
                      id="storeDropDown">
                      {{storeLogoSortValue}}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="storeDropDown">
                      <a ngbDropdownItem (click)="sortBy('uploadDate')">Upload Date</a>
                      <a ngbDropdownItem (click)="sortBy('fileName')">File Name</a>
                    </div>
                  </div>
                </span>
              </span>
              <span *ngIf="!otherStoreLogos?.length && !defaultStoreLogos?.length">You don’t have any logos saved.</span>
              <span class="data_action">
                <!-- <input type="file" name="fileToUpload" id="fileToUpload"
                                (change)="selectFile($event)"> -->
                <!-- <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" > -->
                <div id="uploadButton">
                  <label>
                    <input #imageInput id="uploadLogo" type="file"
                      accept="image/jpg,image/jfif,image/x-png,image/jpeg,image/gif" (change)="processFile(imageInput)">
                    <span class="btn btn-primary btn-sm left_icon_btn"><svg width="16" height="17" viewBox="0 0 16 17"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0.5 8.25822C0.632608 8.25822 0.759785 8.3109 0.853553 8.40467C0.947322 8.49844 1 8.62561 1 8.75822V12.2582C1 12.5234 1.10536 12.7778 1.29289 12.9653C1.48043 13.1529 1.73478 13.2582 2 13.2582H14C14.2652 13.2582 14.5196 13.1529 14.7071 12.9653C14.8946 12.7778 15 12.5234 15 12.2582V8.75822C15 8.62561 15.0527 8.49844 15.1464 8.40467C15.2402 8.3109 15.3674 8.25822 15.5 8.25822C15.6326 8.25822 15.7598 8.3109 15.8536 8.40467C15.9473 8.49844 16 8.62561 16 8.75822V12.2582C16 12.7887 15.7893 13.2974 15.4142 13.6724C15.0391 14.0475 14.5304 14.2582 14 14.2582H2C1.46957 14.2582 0.960859 14.0475 0.585786 13.6724C0.210714 13.2974 0 12.7887 0 12.2582V8.75822C0 8.62561 0.0526784 8.49844 0.146447 8.40467C0.240215 8.3109 0.367392 8.25822 0.5 8.25822ZM5 5.11222C5.09376 5.20596 5.22092 5.25862 5.3535 5.25862C5.48608 5.25862 5.61324 5.20596 5.707 5.11222L8 2.81822L10.293 5.11122C10.3391 5.15898 10.3943 5.19707 10.4553 5.22327C10.5163 5.24948 10.5819 5.26327 10.6483 5.26385C10.7147 5.26442 10.7805 5.25177 10.842 5.22663C10.9034 5.20149 10.9593 5.16437 11.0062 5.11742C11.0531 5.07047 11.0903 5.01465 11.1154 4.9532C11.1406 4.89175 11.1532 4.82591 11.1526 4.75952C11.152 4.69313 11.1383 4.62752 11.1121 4.56652C11.0858 4.50552 11.0478 4.45035 11 4.40422L8.354 1.75822C8.30755 1.71166 8.25238 1.67472 8.19163 1.64951C8.13089 1.6243 8.06577 1.61133 8 1.61133C7.93423 1.61133 7.86911 1.6243 7.80837 1.64951C7.74762 1.67472 7.69245 1.71166 7.646 1.75822L5 4.40422C4.95344 4.45067 4.91649 4.50584 4.89129 4.56659C4.86608 4.62733 4.85311 4.69246 4.85311 4.75822C4.85311 4.82399 4.86608 4.88911 4.89129 4.94986C4.91649 5.0106 4.95344 5.06578 5 5.11222Z" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8 2.25781C8.13261 2.25781 8.25979 2.31049 8.35355 2.40426C8.44732 2.49803 8.5 2.6252 8.5 2.75781V10.7578C8.5 10.8904 8.44732 11.0176 8.35355 11.1114C8.25979 11.2051 8.13261 11.2578 8 11.2578C7.86739 11.2578 7.74021 11.2051 7.64645 11.1114C7.55268 11.0176 7.5 10.8904 7.5 10.7578V2.75781C7.5 2.6252 7.55268 2.49803 7.64645 2.40426C7.74021 2.31049 7.86739 2.25781 8 2.25781Z" />
                      </svg> Upload Logo</span>
                  </label>
                  <ng-template #popContent>Images must be JPG, PNG, JPEG <div> JFIF or GIF format.</div></ng-template>
                  <img class="info_icon" src="assets/images/info_circle_icon.svg" alt="hover for help" placement="top-right"
                    [ngbPopover]="popContent" triggers="mouseenter:mouseleave" />
                    <div *ngIf="isImageTypeError" class="uploadfile_err_msg">Incorrect file type received. Image <div> must be <strong>JPG, PNG </strong> or <strong>GIF </strong> format.</div></div>
                    <div *ngIf="isImageSizeError && isimageFormat" class="uploadfile_err_msg">File exceeds maximum size <div><strong> (3 MB).</strong></div></div>
                </div>
              </span>
            </div>

            <!-- Data content with logo tiles in a row -->
            <div class="data_content">
              <!-- When no default store logo available-->
              <div *ngIf="defaultStoreLogos?.length < 1 && otherStoreLogos?.length" class="accordion">
                <span class="logo_category">Default Logos</span>
                <div class="default_logos_border">
                  <div class="card">
                    <div class="default_img_icon"></div>
                    <div class="default_card_text">Set a logo as your default</div>
                  </div>
                </div>
              </div>
              <!-- Default Logo-->
              <div *ngIf="defaultStoreLogos && defaultStoreLogos?.length" class="accordion">
                <span class="logo_category">Default Logos</span>
                <div class="default_logos_tiles">
                  <div *ngFor="let storeLogo of defaultStoreLogos; trackBy: trackByStoreLogo">
                    <div class="default_logo_tile">
                      <!-- Other Logo - 2 -->
                      <div class="card">
                        <button (click)="openDeleteModalSL(storeLogo.id)" id="logoDeleteButton"
                          class="btn btn-outline-primary btn-sm left_icon_btn" type="button">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                              fill="#0068B5" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                              fill="#0068B5" />
                          </svg>
                          Delete
                        </button>
                        <iposcen-delete-popup #deleteStoreLogoModal [contentName]="logoToDeleteName"
                          [buttonName]="logoDeleteButton" (delete)="deleteStoreLogo(logoToDelete)"></iposcen-delete-popup>
                        <div class="store_logo_img">
                          <img [src]="storeLogo.imageData" width="100" alt="Image Logo" class="store-logo-image" />
                        </div>
                        <div class="store_logo_caption small_text store-logo-name">
                          {{storeLogo.fileName}}
                        </div>
                        <div class="card-body">
                          <div class="small_text">
                            <strong>Uploaded</strong> {{storeLogo.updateTimeDisplay}}
                          </div>
                          <div *ngIf="storeLogo.ispushedToDevice" class="small_text"><strong>Sent</strong>
                            {{storeLogo.sentTime}}
                          </div>
                          <div *ngIf="!storeLogo.ispushedToDevice" class="small_text">Not sent to stores yet</div>
                          <div class="action_buttons">
                            <button type="button" class="btn btn-primary right_icon_btn gtmSendLogoDflt">
                              Send
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z"
                                  fill="white" />
                              </svg>
                            </button>
                            <button *ngIf="!storeLogo?.isdefault" type="button"
                              (click)="setAsDefaultStoreLogo(storeLogo.id, true)" class="btn btn-outline-primary gtmSetLogoDefault">
                              Set as Default
                            </button>
                            <button *ngIf="storeLogo?.isdefault" type="button"
                              (click)="setAsDefaultStoreLogo(storeLogo.id, false)" class="btn btn-outline-primary gtmRemoveDefault">
                              Remove as Default
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Other logo ends here -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- Other Logos-->
              <div *ngIf="otherStoreLogos && otherStoreLogos?.length" class="other_logos_column">
                <span class="logo_category">Other Logos</span>
                <div class="other_logos_tiles">
                  <div *ngFor="let storeLogo of otherStoreLogos; trackBy: trackByStoreLogo">
                    <div>
                      <!-- Other Logo - 2 -->
                      <div class="card">
                        <button (click)="openDeleteModalSL(storeLogo.id)" id="logoDeleteButton"
                          class="btn btn-outline-primary btn-sm left_icon_btn" type="button">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                              fill="#0068B5" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                              fill="#0068B5" />
                          </svg>
                          Delete
                        </button>
                        <iposcen-delete-popup #deleteStoreLogoModal [contentName]="logoToDeleteName"
                          [buttonName]="logoDeleteButton" (delete)="deleteStoreLogo(logoToDelete)"></iposcen-delete-popup>
                        <div class="store_logo_img">
                          <img [src]="storeLogo.imageData" width="100" alt="Image Logo" class="store-logo-image" />
                        </div>
                        <div class="store_logo_caption small_text store-logo-name">
                          {{storeLogo.fileName}}
                        </div>
                        <div class="card-body">
                          <div class="small_text">
                            <strong>Uploaded</strong> {{storeLogo.updateTimeDisplay}}
                          </div>
                          <div *ngIf="storeLogo.ispushedToDevice" class="small_text"><strong>Sent</strong>
                            {{storeLogo.sentTime}}
                          </div>
                          <div *ngIf="!storeLogo.ispushedToDevice" class="small_text">Not sent to stores yet</div>
                          <div class="action_buttons">
                            <button type="button" class="btn btn-primary right_icon_btn gtmSendLogoOthr">
                              Send
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z"
                                  fill="white" />
                              </svg>
                            </button>
                            <button *ngIf="!storeLogo?.isdefault" type="button"
                              (click)="setAsDefaultStoreLogo(storeLogo.id, true)" class="btn btn-outline-primary gtmSetLogoDefaultOthr">
                              Set as Default
                            </button>
                            <button *ngIf="storeLogo?.isdefault" type="button"
                              (click)="setAsDefaultStoreLogo(storeLogo.id, false)" class="btn btn-outline-primary gtmRemoveDefaultOthr">
                              Remove as Default
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Other logo ends here -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="presentPage === profile_page">
            <iposcen-profile-storelogo (setLogoContentTab)="presentPage === profile_page"
            (setTabIndex)="setIndex(1)" (disabledLogoContentTab)="disabledLogoContent(isDisabled)"></iposcen-profile-storelogo>
         </ng-container>
        </div>
        <!-- Logo Content Group Tab -->
        <div *ngIf="tabIndex === 1" id="logoContentGroups" class="tab-pane" [ngClass]="{'active': tabIndex === 1}">
          <!-- Data header with info like no. of content groups, action buttons and filters -->
          <div class="data_header" [ngClass] = "(displayLogoContentGroups?.length) ? '' : 'display-block' ">
            <ng-container *ngIf="presentPage === assets_page">
              <span  *ngIf="displayLogoContentGroups?.length">
                <span *ngIf="displayLogoContentGroups" id="dataTitle">{{displayLogoContentGroups?.length}} Logo Content
                  Groups</span>
                <span *ngIf="displayLogoContentGroups?.length > 1">
                  Sort by :&nbsp;
                  <div ngbDropdown class="btn-group">
                    <button ngbDropdownToggle class="btn btn-primary btn-sm dropdown-toggle" id="sortDropDown">{{sortValue}}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="sortDropDown">
                      <button ngbDropdownItem (click)="sortBy('createDate')">Created Date</button>
                      <button ngbDropdownItem (click)="sortBy('groupName')">Group Name</button>
                    </div>
                  </div>
                </span>
              </span>
              <span *ngIf="!displayLogoContentGroups?.length">Add a Logo Content Group of up to 3 pages to your logo.</span>
            </ng-container>
            <ng-container *ngIf="presentPage === profile_page">
              <div>
                Add a Logo Content Group of up to 3 pages to your logo.<br />
                You may <strong>add up to 3 Logo Content Groups.</strong>
              </div>
            </ng-container>
            <span class="data_action">
              <button type="button" id="uploadLogo" class="btn btn-primary btn-sm left_icon_btn" (click)="createNewLogoContent()">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.5 3.5C8.63261 3.5 8.75979 3.55268 8.85355 3.64645C8.94732 3.74021 9 3.86739 9 4V8C9 8.13261 8.94732 8.25979 8.85355 8.35355C8.75979 8.44732 8.63261 8.5 8.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H8V4C8 3.86739 8.05268 3.74021 8.14645 3.64645C8.24021 3.55268 8.36739 3.5 8.5 3.5Z" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8 8C8 7.86739 8.05268 7.74021 8.14645 7.64645C8.24021 7.55268 8.36739 7.5 8.5 7.5H12.5C12.6326 7.5 12.7598 7.55268 12.8536 7.64645C12.9473 7.74021 13 7.86739 13 8C13 8.13261 12.9473 8.25979 12.8536 8.35355C12.7598 8.44732 12.6326 8.5 12.5 8.5H9V12C9 12.1326 8.94732 12.2598 8.85355 12.3536C8.75979 12.4473 8.63261 12.5 8.5 12.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12V8Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.5 15C10.3565 15 12.137 14.2625 13.4497 12.9497C14.7625 11.637 15.5 9.85652 15.5 8C15.5 6.14348 14.7625 4.36301 13.4497 3.05025C12.137 1.7375 10.3565 1 8.5 1C6.64348 1 4.86301 1.7375 3.55025 3.05025C2.2375 4.36301 1.5 6.14348 1.5 8C1.5 9.85652 2.2375 11.637 3.55025 12.9497C4.86301 14.2625 6.64348 15 8.5 15ZM8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" transform="translate(0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                New Logo Content Group
              </button>
            </span>
          </div>
          <ng-container *ngIf="presentPage === profile_page">
            <div class="mt-3">
              <div><strong>Added to Package</strong> : {{(selctedContGrpsForProfile.contentGrps?.length)? selctedContGrpsForProfile.contentGrps?.length :'0'}} Content Groups</div>
              <div><strong>Show on Device</strong> : {{(selctedContGrpsForProfile.showOnDevice) ? '1' : '0'}} Content Groups</div>
            </div>
            <div class="mt-3 mb-3">
              <strong>Show on<br />Device</strong>
            </div>
          </ng-container>

          <!-- Data Content -->
          <div class="data_content">
            <div class="accordion align-items-start" id="logoContentAccordian">
              <!-- Logo CG 3 panel -->
              <div
                *ngFor="let logoContentGroup of displayLogoContentGroups | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i"
                class="card">
                <div class="acc_check" *ngIf="presentPage === profile_page">
                  <div class="form-check">
                    <input
                      type="radio"
                      name="cpLCGSelect"
                      class="form-check-input"
                      [value]="logoContentGroup.showOnDevice"
                      (change)="addToProfile($event,i,'radio')"
                    />
                  </div>
                </div>
                <div [ngClass]="presentPage === profile_page ? 'card_content_wrapper' : ''">
                  <div class="card-header small_text" id="logoCGThree">
                    <div class="collapse_link collapsed card_header_content" type="button" [attr.aria-expanded]="logoContentGroup.isExpanded"
                      aria-controls="collapseThree">
                      <div class="title_left">
                        <div class="title">{{logoContentGroup.configurations.publishedName}}</div>
                        <iposcen-assetid-copy [assetID]="logoContentGroup.id" *ngIf="presentPage === assets_page"></iposcen-assetid-copy>
                        <div class="subtitle_group">
                          <span class="subtitle">Created By: {{logoContentGroup?.createdByUser}}</span>
                          <span class="subtitle">Last Edited By {{logoContentGroup?.createdByUser}}:
                            {{logoContentGroup.updateTimeDisplay | formatDate: "12hr"}}</span>
                          <span class="subtitle" *ngIf="presentPage === assets_page">Pushed to all devices:
                            {{logoContentGroup?.ispushedToDevice ? 'Y' : 'N'}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="button_wrapper" *ngIf="presentPage === assets_page">
                      <button (click)="openDeleteModal(logoContentGroup.id)" type="button"
                        class="btn btn-outline-primary btn-sm left_icon_btn delete_btn gtmDltLogoContGrp">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                            fill="#0068B5" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                            fill="#0068B5" />
                        </svg>
                        Delete
                      </button>
                      <iposcen-delete-popup #deleteModal [contentName]="logoCGToDeleteName"
                        [buttonName]="logoContentDeleteButton" (delete)="deleteLogoCG(logoCGToDelete)">
                      </iposcen-delete-popup>
                      <button (click)="editLogoContent(logoContentGroup.id)" type="button"
                        class="btn btn-primary btn-sm left_icon_btn gtmEditLogoContGrp">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.293 1.29279C11.4805 1.10532 11.7348 1 12 1C12.2652 1 12.5195 1.10532 12.707 1.29279L14.707 3.29279C14.8945 3.48031 14.9998 3.73462 14.9998 3.99979C14.9998 4.26495 14.8945 4.51926 14.707 4.70679L5.707 13.7068C5.59752 13.8166 5.464 13.8994 5.317 13.9488L2.317 14.9488C2.14074 15.0078 1.95153 15.0165 1.7706 14.974C1.58966 14.9314 1.42417 14.8393 1.29268 14.7079C1.1612 14.5765 1.06894 14.4111 1.02625 14.2302C0.983555 14.0493 0.992126 13.8601 1.051 13.6838L2.051 10.6838C2.1002 10.5364 2.18305 10.4026 2.293 10.2928L11.293 1.29279ZM12 1.99979L14 3.99979L5 12.9998L2 13.9998L3 10.9998L12 1.99979Z"
                            fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12.146 6.35448L9.646 3.85448L10.354 3.14648L12.854 5.64648L12.147 6.35448H12.146ZM3 10.0005V10.5005C3 10.6331 3.05268 10.7603 3.14645 10.854C3.24021 10.9478 3.36739 11.0005 3.5 11.0005H4V11.5005C4 11.6331 4.05268 11.7603 4.14645 11.854C4.24021 11.9478 4.36739 12.0005 4.5 12.0005H5V12.5005C5 12.6331 5.05268 12.7603 5.14645 12.854C5.24021 12.9478 5.36739 13.0005 5.5 13.0005H6V11.5005C6 11.3679 5.94732 11.2407 5.85355 11.1469C5.75979 11.0532 5.63261 11.0005 5.5 11.0005H5V10.5005C5 10.3679 4.94732 10.2407 4.85355 10.1469C4.75979 10.0532 4.63261 10.0005 4.5 10.0005H3Z"
                            fill="white" />
                        </svg>
                        Edit
                      </button>
                      <button type="button" class="btn btn-primary btn-sm right_icon_btn gtmSendLogoContGrp">
                        Send
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z"
                            fill="white" />
                        </svg>
                      </button>
                    </div>
                    <div class="custom-control custom-switch collapse_link collapsed" *ngIf="presentPage === assets_page">
                        <input type="checkbox" class="custom-control-input" id="logoCGThreeSwitch"
                          [checked]="logoContentGroup?.islocked" />
                        <label class="custom-control-label" for="logoCGThreeSwitch">
                          Lock Content
                        </label>
                      <img [ngClass] = "logoContentGroup.isExpanded ? 'transform-180' : ''" src="assets/images/chevron_down.svg"
                      alt="click to expand" (click)="getDetailsOfLogoCG(logoContentGroup?.id)"/>
                    </div>
                    <div class="custom-contro collapse_link collapsed" *ngIf="presentPage === profile_page">
                      <div class="form-check mt-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [value]="logoContentGroup.addToPackage"
                          [checked]="logoContentGroup.addToPackage"
                          (change)="addToProfile($event,i,'check')"
                          id="addToPackOne"
                        />
                        <label class="form-check-label" for="addToPackOne">
                          Add to Package
                        </label>
                      </div>
                      <img [ngClass] = "logoContentGroup.isExpanded ? 'transform-180' : ''" src="assets/images/chevron_down.svg"
                      alt="click to expand" (click)="getDetailsOfLogoCG(logoContentGroup?.id)"/>
                    </div>
                  </div>
                  <div id="collapseThree" #collapse="ngbCollapse" [(ngbCollapse)]="!logoContentGroup.isExpanded"
                    aria-labelledby="logoCGThree" data-parent="#logoContentAccordian">
                    <div class="card-body">
                      <div class="table-container">
                        <table class="table-borderless" aria-describedby="Preview content">
                          <tr>
                            <th scope="col">Publish Date & Time</th>
                            <th scope="col">Expiration Date & Time</th>
                          </tr>
                          <tr>
                            <!-- <td>{{logoContentGroup?.configurations?.publishTime | formatDate: "MM/DD/YY z"}}</td>
                          <td>{{logoContentGroup?. configurations?.expireTime | formatDate: "MM/DD/YY z"}}</td> -->
                            <td>{{logoContentGroup?.configurations?.publishTime }}</td>
                            <td>{{logoContentGroup?. configurations?.expireTime }}</td>
                          </tr>
                        </table>
                        <span>
                          <button (click)="duplicateAndEditLogoContent(logoContentGroup.id)" type="button"
                            class="btn btn-primary btn-sm left_icon_btn gtmDuplicateContGrp">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3 2H11C11.5304 2 12.0391 2.21071 12.4142 2.58579C12.7893 2.96086 13 3.46957 13 4V14C13 14.5304 12.7893 15.0391 12.4142 15.4142C12.0391 15.7893 11.5304 16 11 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V4C1 3.46957 1.21071 2.96086 1.58579 2.58579C1.96086 2.21071 2.46957 2 3 2V2ZM3 3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15H11C11.2652 15 11.5196 14.8946 11.7071 14.7071C11.8946 14.5196 12 14.2652 12 14V4C12 3.73478 11.8946 3.48043 11.7071 3.29289C11.5196 3.10536 11.2652 3 11 3H3Z"
                                fill="white" />
                              <path
                                d="M5 0H13C13.5304 0 14.0391 0.210714 14.4142 0.585786C14.7893 0.960859 15 1.46957 15 2V12C15 12.5304 14.7893 13.0391 14.4142 13.4142C14.0391 13.7893 13.5304 14 13 14V13C13.2652 13 13.5196 12.8946 13.7071 12.7071C13.8946 12.5196 14 12.2652 14 12V2C14 1.73478 13.8946 1.48043 13.7071 1.29289C13.5196 1.10536 13.2652 1 13 1H5C4.73478 1 4.48043 1.10536 4.29289 1.29289C4.10536 1.48043 4 1.73478 4 2H3C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0V0Z"
                                fill="white" />
                            </svg>
                            Duplicate & Edit
                          </button>
                        </span>
                      </div>
                      <!-- ===={{logoContentGroup?.configurations?.contentGroupPages}} -->
                      <div class="totalDiv">
                        <ng-container *ngFor="let page of logoContentGroup.configurations.contentGroupPages">
                          <div class="eachPage">
                            <iposcen-live-preview [data]="loadMiniPreview(logoContentGroup?.id,page)">
                            </iposcen-live-preview>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Data Footer - Pagination -->
          <div class="data_footer">
            <nav aria-label="logo content pagination"
              *ngIf="displayLogoContentGroups && displayLogoContentGroups?.length">
              <ngb-pagination [collectionSize]="displayLogoContentGroups?.length" [(page)]="page" [pageSize]="pageSize"
                [rotate]="true" [ellipses]="false" [boundaryLinks]="false"></ngb-pagination>
            </nav>
          </div>
          <ng-container *ngIf="presentPage === profile_page">
            <div class="button_wrapper justify-content-end">
              <button type="button" class="btn btn-outline-primary btn-lg"  (click)="sendToProfile('skip')">
                Skip
              </button>
              <button type="button" class="btn btn-primary btn-lg" (click)="sendToProfile('send')">
                Save & Continue
              </button>
            </div>
          </ng-container>
        </div>

        <ng-container>
          <div *ngIf="tabIndex === 3">

            <!-- <iposcen-create-logo-content (goBacktoLogoContent)="reloadLogoContent(1)"></iposcen-create-logo-content> -->
            <iposcen-create-logo-content (goBacktoLogoContent)="setCreatedGrpID($event)"></iposcen-create-logo-content>
          </div>
          <div *ngIf="tabIndex === 4">
            <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=1
              [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshLGCdata()"
              (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
          </div>
          <div *ngIf="tabIndex === 5">
            <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=2
              [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshLGCdata()"
              (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
          </div>
          <div *ngIf="tabIndex === 6">
            <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=3
              [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshLGCdata()"
              (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
          </div>
        </ng-container>
        <!-- TODO: Move style to a class below -->
        <div class="button_wrapper mb-4 mr-4" style="justify-content: flex-end"
          *ngIf="tabIndex === 4 || tabIndex === 5|| tabIndex === 6">
          <button type="button" class="btn btn-outline-primary left_icon_btn gtmAddPageLogo" (click)="addPage()" [disabled]="checkPages()">
            <img src="assets/images/plus_square_outline.svg" alt="add page" />
            Add Page
          </button>
          <button type="button" class="btn btn-primary gtmSaveLogoContGrp" (click)="updateLCG()">
            Save & Use Content Group
          </button>
        </div>
      </div>
    </section>
  </section>
</div>