import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterConstants } from '../../../../../core/routing/constants/app-paths.constant';
import { ForgotPasswordConstants } from '../../constants/forgot-password.constant';

@Component({
  selector: 'iposcen-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss']
})
export class ResetPwdComponent implements OnInit {
  modalTitle: string;
  modalBody: string;
  href: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate([RouterConstants.LOGIN_PAGE_LINK]);
    }, ForgotPasswordConstants.AUTO_CLOSING_SECONDS);
    this.href = RouterConstants.LOGIN_PAGE_LINK;
    this.modalTitle = ForgotPasswordConstants.RESET_PWD_MODAL_TITLE;
    this.modalBody = ForgotPasswordConstants.RESET_MODAL_BODY;
  }

  close(): void {
    this.router.navigate([RouterConstants.LOGIN_PAGE_LINK]);
  }

}
