<div class="home_unconnected_content_wrapper">
  <div class="container-fluid">
    <h2 class="loggedin_user">Welcome, {{ firstName }}</h2>
    <div class="home_tiles">
      <div class="card">
        <div class="card-header">create a profile</div>
        <div class="card-body">
          <img
            src="assets/images/unconct_create_profile.png"
            alt="create profile"
            class="img-fluid"
          />
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary gtmCreateProfile">
            Create a Profile &nbsp;
            <em class="chevron_right"></em>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">download standard ipos</div>
        <div class="card-body">
          <img
            src="assets/images/unconct_download_ipos.png"
            alt="download standard ipos"
            class="img-fluid"
          />
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary gtmDwnldStdIpos">
            Download standard IPOS &nbsp;
            <em class="chevron_right"></em>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">ipos help</div>
        <div class="card-body">
          <img
            src="assets/images/unconct_ipos_help.png"
            alt="IPOS help"
            class="img-fluid"
          />
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary gtmIposHelp">
            IPOS Help &nbsp;
            <em class="chevron_right"></em>
          </button>
        </div>
      </div>
    </div>

    <!-- Updates Heading -->
    <div class="updates_header">
      <span>
        <h2>Updates</h2>
        <div class="form-check">
          <input class="form-check-input gtmHideUpdates" type="checkbox" value="" id="hideUpdates" />
          <label class="form-check-label" for="hideUpdates">
            Hide updates until the next update
          </label>
        </div>
      </span>
      <span>Last Update: 7/22/20</span>
    </div>

    <!-- Updates -->

    <div class="card update_content">
      <span>
        <h5>A NEW IPOS HAS ARRIVED!</h5>
        <!-- This image loads for small and x-small devices -->
        <span style="display: flex; justify-content: center">
          <img src="assets/images/updates_tileipos.png" alt="A new IPOS has arrived!"
            class="d-block d-sm-block d-md-none d-lg-none d-xl-none" />
        </span>

        <p class="update_desc">
          A new version of IPOS has been released to highlight the launch of
          the 10th Gen Intel&reg; Core&trade; processors. Inspired by the
          success of the recent gaming IPOS, this new design brings an
          updated look, a greater focus on the way shoppers will use their
          new PC and all of the reasons to get excited for this new
          processor generation.
        </p>
      </span>
      <!-- This image loads for medium and above devices -->
      <img src="assets/images/updates_tileipos.png" alt="A new IPOS has arrived!"
        class="d-none d-sm-none d-md-block d-lg-block d-xl-block" />
    </div>

    <div class="card update_content">
      <span>
        <h5>INTRODUCING CHROMEBOOK* IPOS</h5>
        <!-- This image loads for small and x-small devices -->
        <span style="display: flex; justify-content: center">
          <img src="assets/images/updates_chromebook.png" alt="A new IPOS has arrived!"
            class="d-block d-sm-block d-md-none d-lg-none d-xl-none" />
        </span>

        <p class="update_desc">
          Because a Chromebook* is such a unique PC, simply showing a
          shopper the specs might not tell the full story. Instead,
          Chromebook* IPOS focuses on what makes a Chromebook* so special
          with a simplified, lighter design that lets shoppers know that
          this PC isn't quite like the others.'
        </p>
      </span>
      <!-- This image loads for medium and above devices -->
      <img src="assets/images/updates_chromebook.png" alt="A new IPOS has arrived!"
        class="d-none d-sm-none d-md-block d-lg-block d-xl-block" />
    </div>

    <div class="card update_content">
      <span>
        <h5>NEW CONTENT IN GAMING IPOS</h5>
        <!-- This image loads for small and x-small devices -->
        <span style="display: flex; justify-content: center">
          <img src="assets/images/updates_gaming.png" alt="A new IPOS has arrived!"
            class="d-block d-sm-block d-md-none d-lg-none d-xl-none" />
        </span>

        <p class="update_desc">
          Gaming IPOS has been updated to feature the 10th Gen Intel&reg;
          Core&trade; processors and the impact they have on these amazing
          PCs. All versions of Gaming IPOS also feature a new playable demo,
          the award-winning Total War*: Three Kingdoms*.
        </p>
      </span>
      <!-- This image loads for medium and above devices -->
      <img src="assets/images/updates_gaming.png" alt="A new IPOS has arrived!"
        class="d-none d-sm-none d-md-block d-lg-block d-xl-block" />
    </div>

    <div class="card update_content">
      <span>
        <h5>UPDATES TO STANDARD IPOS</h5>
        <!-- This image loads for small and x-small devices -->
        <span style="display: flex; justify-content: center">
          <img src="assets/images/updates_gaming.png" alt="A new IPOS has arrived!"
            class="d-block d-sm-block d-md-none d-lg-none d-xl-none" />
        </span>

        <p class="update_desc">
          The version of IPOS for mainstream shoppers also has new content
          to showcase Intel's latest generation of desktop processors. In
          addition to the processor pages, the content throughout the
          experience has been optimised to highlight the benifits of an
          All-In-One or desktop PC to your shoppers.'
        </p>
      </span>
      <!-- This image loads for medium and above devices -->
      <img src="assets/images/updates_latest.png" alt="A new IPOS has arrived!"
        class="d-none d-sm-none d-md-block d-lg-block d-xl-block" />
    </div>
  </div>
</div>
