<div class=" container pages_tabs">
    <div class="heading">{{publishedName}} - page  {{pageNumber}}</div>
    <div class="container-fluid">
      <form *ngIf="pageGrpForm">
        <div class="row">
          <div class="col-xl-2 col-sm-3" id="themeColumn">
            <div class="form-group">
              <label for="">Theme</label>
              <div class="theme_picker_button default" (click)="openColPicModal()">
                <span class="text_color" [style.background-color]="pageGrpForm.bcolor"></span>
                <span class="bg_color" [style.background-color]="pageGrpForm.bgcolor"></span>
              </div>
            </div>
            <div class="form-group">
              <label for="">Background color</label>
              <!-- <div class="color_picker_button default"></div> -->
              <input readonly [(colorPicker)]="pageGrpForm.bgcolor" [cpPosition]="'right'" [style.background]="pageGrpForm.bgcolor" class="color_picker_button" name="bgcolor" (colorPickerChange)="emitPageDataToPreview()"/>
            </div>
          </div>
          <div class="col-xl-10 col-sm-9">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-7">
                        <div class="form-row">
                          <div class="form-group col-10">
                            <label for="">Heading</label><span  class="formfield_limit_msg"> (Max 50 characters) </span>
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              placeholder="Enter Heading"
                              [(ngModel)]="pageGrpForm.heading"
                              name="heading"
                              (keyup)="emitPageDataToPreview()"
                              maxlength="50"
                            />
                            <div  class="formfield_err_msg" *ngIf="pageGrpForm.heading.length>50"> 
                              Heading cannot be more than 50 Characters
                            </div>
                          </div>
                          <div class="form-group col-2">
                            <label for="">Color</label>
                            <input readonly [(colorPicker)]="pageGrpForm.hcolor" [cpPosition]="'right'" [style.background]="pageGrpForm.hcolor" class="color_picker_button" name="hcolor" (colorPickerChange)="emitPageDataToPreview()"/>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-10">
                            <label for="">Body</label><span  class="formfield_limit_msg"> (Max 250 characters) </span>
                            <textarea
                              class="form-control"
                              id=""
                              rows="3"
                              placeholder="Enter body copy here..."
                              [(ngModel)]="pageGrpForm.body"
                              name="body"
                              (keyup)="emitPageDataToPreview()"
                              maxlength="250"
                            ></textarea>
                            <div class="standDiv"> 
                              <span class="formfield_err_msg" *ngIf="pageGrpForm.body.length>250">Body Text cannot be more than 250 Characters</span>
                            </div>
                            <div ngbDropdown class="btn-group">
                              <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                                id="sizeDropDown" aria-haspopup="true" aria-expanded="false">
                                {{pageGrpForm.size}}</button>
                              <div ngbDropdownMenu aria-labelledby="sizeDropDown">
                                <a ngbDropdownItem (click)="selectSize('Small')">Small</a>
                                <a ngbDropdownItem (click)="selectSize('Medium')">Medium</a>
                                <a ngbDropdownItem (click)="selectSize('Large')">Large</a>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-2">
                            <label for="expiry">Color</label>
                            <input readonly [(colorPicker)]="pageGrpForm.bcolor" [cpPosition]="'right'" [style.background]="pageGrpForm.bcolor" class="color_picker_button" name=bcolor (colorPickerChange)="emitPageDataToPreview()"/>
                          </div>
                        </div>
                    </div>
                    <div class="col-xl-5">
                      <div class="form-group">
                        
                        <ng-template #popContent>Images must be JPG, PNG <br/>JPEG, JFIF or GIF format.<br/>Videos must be MP4 format and<br/>less than 60 seconds <br/>with size less than 10MB </ng-template>
                        <label for="">
                          Image/Video
                          <img
                            class="info_icon"
                            src="../../../../assets/images/info_circle_icon.svg"
                            alt="hover for help"
                            placement="left"
                            [ngbPopover]="popContent"
                            triggers="mouseenter:mouseleave"
                          />
                        </label><br/>
                        <!-- <span  class="formfield_limit_msg"> (Max image size - 5 MB/ Max Video size - 10 MB) </span> -->
                        <label>
                          <input #file id="uploadLogo" type="file" accept="video/mp4,image/jpg,image/jfif,image/x-png,image/jpeg,image/gif" (change)="processFile(file.files)">
                          <video #video style="display: none;"  *ngIf="videoUrl" controls src="{{videoUrl}}"
                          (loadedmetadata)="getVideoDuration($event)">
                          </video>
                          <span class="btn btn-primary left_icon_btn gtmUploadImgVideo">
                            <img
                              src="../../../../assets/images/image_icon.svg"
                              alt="upload image"
                            />
                            Upload Image/Videos
                          </span>
                        </label>
                        <div *ngIf="isVideoTypeError || isImageTypeError" class="uploadfile_err_msg">Incorrect file type received. Image <div>must be <strong>JPG, PNG,</strong> or <strong>GIF</strong> format.</div>
                           <div> Videos must be <strong>MP4</strong> format.</div></div>
                        <div *ngIf="isImageSizeError" class="uploadfile_err_msg">File exceeds maximum size <div><strong>(3 MB).</strong></div></div>
                        <div *ngIf="isVideoSizeError" class="uploadfile_err_msg">Video exceeds maximum size <div><strong>(10 MB).</strong></div></div>
                        <div *ngIf="isVideoDurationError" class="uploadfile_err_msg">Videos must be <strong> less than 60 </strong> <div><strong> seconds long.</strong></div></div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>
<ng-template #colorpicker>
  <div class="card">
    <div class="modal-header p-0">
      <div (click)="dismiss()" class="close_icon popup_close">
        <img src="/assets/images/popup_close_button.svg" alt="color picker" class="cursor-pointer" />
      </div>
    </div>
    <div class="modal-body p-0">
      <div id="themePick" class="card">
        <div class="theme_pick_header" [style.background-color]="pageGrpForm.bgcolor">
            <!-- <a href="./AL-storeLogo.html" class="popup_close">
                <img
                  src="../../../../assets/assets/images/popup_close_button.svg"
                  alt="go to profile page"
                />
              </a> -->
          <div class="theme_pick_title" [style.color]="pageGrpForm.hcolor">{{pageGrpForm.heading === ''? 'Heading':pageGrpForm.heading}}</div>
          <div class="theme_pick_subtitle"  [ngStyle]="{'color':pageGrpForm.bcolor,'font-size':fontSize(pageGrpForm.size)}">{{pageGrpForm.body === ''?'Body':pageGrpForm.body}}</div>
        </div>
        <div class="theme_pick_text">
          <span style="font-weight: bold;"> Optional:</span> Ensure accessibility by choosing
          from WCAG 2.0 AA-compliant themes.
        </div>
        <div class="theme_color mb-3">
            <div>
              <div class="theme_picker_button default"  (click)="chooseTheme('#ffffff','#0068B5')">
                  <span class="text_color"></span>
                  <span class="bg_color"></span>
              </div>
              <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#0068B5' )}"></span>
            </div>
            <div>
              <div class="theme_picker_button default" (click)="chooseTheme('#0068B5','#ffffff')">
                  <span class="text_color" style="background-color:#0068B5"></span>
                  <span class="bg_color" style="background-color: #ffffff"></span>
              </div>
              <span [ngClass]="{'color_highlighter': isSelected('#0068B5','#ffffff')}" ></span>
            </div>
            <div>
              <div class="theme_picker_button default" (click)="chooseTheme('#ffffff','#00C7FD')">
                  <span class="text_color"></span>
                  <span class="bg_color" style="background-color: #00C7FD"></span>
              </div>
              <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#00C7FD')}"></span>
            </div>
            <div>
              <div class="theme_picker_button default"  (click)="chooseTheme('#ffffff','#8BAE46')">
                  <span class="text_color"></span>
                  <span class="bg_color" style="background-color: #8BAE46"></span>
              </div>
              <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#8BAE46')}"></span>
            </div>
            <div>
              <div class="theme_picker_button default" style="margin-right:0"  (click)="chooseTheme('#ffffff','#D96930')">
                  <span class="text_color"></span>
                  <span class="bg_color" style="background-color: #D96930"></span>
              </div>  
              <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#D96930')}"></span>
            </div>
        </div>
        <div class="theme_color">
          <div>
            <div class="theme_picker_button default" style="margin-bottom:0" (click)="chooseTheme('#ffffff','#8F5DA2')">
                <span class="text_color"></span>
                <span class="bg_color" style="background-color: #8F5DA2"></span>
            </div> 
            <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#8F5DA2')}"></span>
          </div>
            <div>
              <div class="theme_picker_button default" style="margin-bottom:0" (click)="chooseTheme('#ffffff','#EDB200')">
                  <span class="text_color"></span>
                  <span class="bg_color" style="background-color: #EDB200"></span>
              </div>
              <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#EDB200')}"></span>
            </div>            
            <div>
              <div class="theme_picker_button default" style="margin-bottom:0" (click)="chooseTheme('#ffffff','#1E2EB8')">
                  <span class="text_color"></span>
                  <span class="bg_color" style="background-color: #1E2EB8"></span>
              </div> 
              <span  [ngClass]="{'color_highlighter': isSelected('#ffffff','#1E2EB8')}"></span>
            </div>            
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-outline-primary" (click)="chooseTheme('#ffffff','#0068B5')">
            Use Default
          </button>
        </div>
      </div>
    </div>
  </div>  
</ng-template>
