<div>
  <!-- Live preview - temporarly image -->
  <div class="row topSection mt-3 mb-3" *ngIf="tabIndex === 2 || tabIndex === 3 || tabIndex === 4">
    <iposcen-live-preview class="forPricePreview"></iposcen-live-preview>
    <!-- Layout of preview -->
    <div class="preview_layout">
      <img [ngClass]="{'active_preview': LCGlayout === 1}" src="assets/images/SP_layout_1.png"
        alt="layout 1" (click)="setLayout(1)" class="cursor-pointer" />

      <img [ngClass]="{'active_preview': LCGlayout === 2}" src="assets/images/SP_layout_2.png"
        alt="layout 2" (click)="setLayout(2)" class="cursor-pointer" />

      <img [ngClass]="{'active_preview': LCGlayout === 3}" src="assets/images/SP_layout_3.png"
        alt="layout 3" (click)="setLayout(3)" class="cursor-pointer" />
    </div>
  </div>
  <ul *ngIf="(tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4)" class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 1}">{{editCheck? showPriceContentName :'New price Content Group'}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 2}" (click)="clickable?setIndex(2):false">PAGE 1</a>
    </li>
    <li class="nav-item" *ngIf="pages?.page2">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 3}" (click)="clickable?setIndex(3):false">PAGE 2</a>
    </li>
    <li class="nav-item" *ngIf="pages?.page3">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tabIndex === 4}" (click)="clickable?setIndex(4):false">PAGE 3</a>
    </li>
    <li class="nav-item" *ngIf="clickable && !checkPages()">
      <a class="nav-link" data-toggle="tab" (click)="addPage()">
        <img src="assets/images/add_black_square_outline.svg" alt="add pages" />
      </a>
    </li>
    <li class="nav-item exit_preview">
      <a class="nav-link" (click)="cancel()">
        <img src="assets/images/exit_icon.svg" alt="exit without changes" />
      </a>
    </li>
  </ul>

  <!-- Main section -->
  <section *ngIf="tabIndex === 0" class="main_section al_storepromo">

    <!-- Price Content Group Tab -->
    <div *ngIf="tabIndex === 0" id="priceContentGroups">
      <div class="main_section_headers">
        <svg class="header_icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.75 3C0.75 2.40326 0.987053 1.83097 1.40901 1.40901C1.83097 0.987053 2.40326 0.75 3 0.75H9.879C10.4755 0.750525 11.0474 0.987923 11.469 1.41L21.969 11.91C22.3904 12.3319 22.627 12.9037 22.627 13.5C22.627 14.0963 22.3904 14.6681 21.969 15.09L15.0915 21.969C14.8825 22.1781 14.6344 22.3439 14.3614 22.4571C14.0883 22.5703 13.7956 22.6285 13.5 22.6285C13.2044 22.6285 12.9117 22.5703 12.6386 22.4571C12.3656 22.3439 12.1175 22.1781 11.9085 21.969L1.4085 11.469C0.986973 11.0472 0.750127 10.4753 0.75 9.879V3ZM3 2.25C2.80109 2.25 2.61032 2.32902 2.46967 2.46967C2.32902 2.61032 2.25 2.80109 2.25 3V9.879C2.25004 10.0775 2.3288 10.2679 2.469 10.4085L12.969 20.9085C13.0387 20.9783 13.1214 21.0338 13.2126 21.0716C13.3037 21.1094 13.4013 21.1288 13.5 21.1288C13.5987 21.1288 13.6963 21.1094 13.7874 21.0716C13.8786 21.0338 13.9613 20.9783 14.031 20.9085L20.9085 14.031C20.9783 13.9613 21.0338 13.8786 21.0716 13.7875C21.1094 13.6963 21.1288 13.5987 21.1288 13.5C21.1288 13.4014 21.1094 13.3037 21.0716 13.2126C21.0338 13.1214 20.9783 13.0387 20.9085 12.969L10.4085 2.469C10.2679 2.3288 10.0775 2.25004 9.879 2.25H3Z" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.75 6.75C3.75 5.95435 4.06607 5.19129 4.62868 4.62868C5.19129 4.06607 5.95435 3.75 6.75 3.75C7.54565 3.75 8.30871 4.06607 8.87132 4.62868C9.43393 5.19129 9.75 5.95435 9.75 6.75C9.75 7.54565 9.43393 8.30871 8.87132 8.87132C8.30871 9.43393 7.54565 9.75 6.75 9.75C5.95435 9.75 5.19129 9.43393 4.62868 8.87132C4.06607 8.30871 3.75 7.54565 3.75 6.75ZM6.75 5.25C6.35218 5.25 5.97064 5.40804 5.68934 5.68934C5.40804 5.97064 5.25 6.35218 5.25 6.75C5.25 7.14782 5.40804 7.52936 5.68934 7.81066C5.97064 8.09196 6.35218 8.25 6.75 8.25C7.14782 8.25 7.52936 8.09196 7.81066 7.81066C8.09196 7.52936 8.25 7.14782 8.25 6.75C8.25 6.35218 8.09196 5.97064 7.81066 5.68934C7.52936 5.40804 7.14782 5.25 6.75 5.25Z" />
        </svg>
        Price Content
      </div>
      <!-- Data header with info like no. of content groups, action buttons and filters -->
      <div class="data_header" [ngClass]="(displayPriceContentGroups?.length > 0)?'':'display-block'">
        <span *ngIf="displayPriceContentGroups?.length > 0">
          <span *ngIf="displayPriceContentGroups" id="dataTitle">{{displayPriceContentGroups?.length}} Price
            Content
            Groups</span>
          <span *ngIf="displayPriceContentGroups?.length > 1">
            Sort by :&nbsp;
            <div ngbDropdown class="d-inline-block">
              <button ngbDropdownToggle class="btn btn-primary btn-sm" id="sortDropDown">{{sortValue}}
              </button>
              <div ngbDropdownMenu aria-labelledby="sortDropDown">
                <button ngbDropdownItem (click)="sortByDate()">{{createdDateLabel}}</button>
                <button ngbDropdownItem (click)="sortByName()">{{groupNameLabel}}</button>
              </div>
            </div>
          </span>
        </span>
        <span *ngIf="displayPriceContentGroups?.length === 0">Add a Price Content Group of up to 3 pages to your device
          price in the IPOS footer.</span>
        <span class="data_action">
          <button type="button" id="uploadprice" class="btn btn-primary btn-sm left_icon_btn" (click)="addNewPriceContent()">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 3.5C8.63261 3.5 8.75979 3.55268 8.85355 3.64645C8.94732 3.74021 9 3.86739 9 4V8C9 8.13261 8.94732 8.25979 8.85355 8.35355C8.75979 8.44732 8.63261 8.5 8.5 8.5H4.5C4.36739 8.5 4.24021 8.44732 4.14645 8.35355C4.05268 8.25979 4 8.13261 4 8C4 7.86739 4.05268 7.74021 4.14645 7.64645C4.24021 7.55268 4.36739 7.5 4.5 7.5H8V4C8 3.86739 8.05268 3.74021 8.14645 3.64645C8.24021 3.55268 8.36739 3.5 8.5 3.5Z" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 8C8 7.86739 8.05268 7.74021 8.14645 7.64645C8.24021 7.55268 8.36739 7.5 8.5 7.5H12.5C12.6326 7.5 12.7598 7.55268 12.8536 7.64645C12.9473 7.74021 13 7.86739 13 8C13 8.13261 12.9473 8.25979 12.8536 8.35355C12.7598 8.44732 12.6326 8.5 12.5 8.5H9V12C9 12.1326 8.94732 12.2598 8.85355 12.3536C8.75979 12.4473 8.63261 12.5 8.5 12.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12V8Z"
                  fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 15C10.3565 15 12.137 14.2625 13.4497 12.9497C14.7625 11.637 15.5 9.85652 15.5 8C15.5 6.14348 14.7625 4.36301 13.4497 3.05025C12.137 1.7375 10.3565 1 8.5 1C6.64348 1 4.86301 1.7375 3.55025 3.05025C2.2375 4.36301 1.5 6.14348 1.5 8C1.5 9.85652 2.2375 11.637 3.55025 12.9497C4.86301 14.2625 6.64348 15 8.5 15ZM8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16Z" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="16" height="16" transform="translate(0.5)" />
                </clipPath>
              </defs>
            </svg>
            New Price Content Group
          </button>
        </span>
      </div>
      <!-- Data Content -->
      <div class="data_content">
        <div class="accordion" id="priceContentAccordian">

          <!-- price CG 3 panel -->
          <div
            *ngFor="let priceContentGroup of displayPriceContentGroups | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
            class="card">
            <div class="card-header small_text" id="priceCGThree">
              <div class="collapse_link collapsed" type="button" [attr.aria-expanded]="priceContentGroup?.isExpanded"
                aria-controls="collapseThree">
                <div class="title_left">
                  <div class="title">{{priceContentGroup?.configurations.publishedName}}</div>
                  <iposcen-assetid-copy [assetID]="priceContentGroup?.id"></iposcen-assetid-copy>
                  <div class="subtitle_group">
                    <span class="subtitle">Created By: {{priceContentGroup?.createdByUser}}</span>
                    <span class="subtitle">Last Edited By {{priceContentGroup?.createdByUser}}:
                      {{priceContentGroup?.updateTime | formatDate: "12hr"}}</span>
                    <!-- <span class="subtitle">Last Sent {{priceContentGroup.updateTime | formatDate: "12hr"}}</span> -->
                    <span class="subtitle">Pushed to all devices:
                      {{priceContentGroup?.ispushedToDevice ? 'Y' : 'N'}}</span>
                  </div>
                </div>
              </div>
              <div class="button_wrapper">
                <button (click)="openDeleteModal(priceContentGroup?.id)" type="button"
                class="btn btn-outline-primary btn-sm left_icon_btn delete_btn gtmDeletePrice">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 5.5C5.63261 5.5 5.75979 5.55268 5.85355 5.64645C5.94732 5.74021 6 5.86739 6 6V12C6 12.1326 5.94732 12.2598 5.85355 12.3536C5.75979 12.4473 5.63261 12.5 5.5 12.5C5.36739 12.5 5.24021 12.4473 5.14645 12.3536C5.05268 12.2598 5 12.1326 5 12V6C5 5.86739 5.05268 5.74021 5.14645 5.64645C5.24021 5.55268 5.36739 5.5 5.5 5.5ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V6C7.5 5.86739 7.55268 5.74021 7.64645 5.64645C7.74021 5.55268 7.86739 5.5 8 5.5ZM11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5C10.3674 5.5 10.2402 5.55268 10.1464 5.64645C10.0527 5.74021 10 5.86739 10 6V12C10 12.1326 10.0527 12.2598 10.1464 12.3536C10.2402 12.4473 10.3674 12.5 10.5 12.5C10.6326 12.5 10.7598 12.4473 10.8536 12.3536C10.9473 12.2598 11 12.1326 11 12V6Z"
                      fill="#0068B5" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.5 3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4H13V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V4H2.5C2.23478 4 1.98043 3.89464 1.79289 3.70711C1.60536 3.51957 1.5 3.26522 1.5 3V2C1.5 1.73478 1.60536 1.48043 1.79289 1.29289C1.98043 1.10536 2.23478 1 2.5 1H6C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0L9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1H13.5C13.7652 1 14.0196 1.10536 14.2071 1.29289C14.3946 1.48043 14.5 1.73478 14.5 2V3ZM4.118 4L4 4.059V13C4 13.2652 4.10536 13.5196 4.29289 13.7071C4.48043 13.8946 4.73478 14 5 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V4.059L11.882 4H4.118ZM2.5 3V2H13.5V3H2.5Z"
                      fill="#0068B5" />
                  </svg>
                  Delete
                </button>
                <iposcen-delete-popup #deleteModal [contentName]="priceCGToDeleteName" [buttonName]="'Content Group'"
                  (delete)="deletePriceCG(priceCGToDelete)"></iposcen-delete-popup>

                <button type="button"
                  class="btn btn-primary btn-sm left_icon_btn gtmEditPrice" (click)="editPrice(priceContentGroup?.id)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.293 1.29279C11.4805 1.10532 11.7348 1 12 1C12.2652 1 12.5195 1.10532 12.707 1.29279L14.707 3.29279C14.8945 3.48031 14.9998 3.73462 14.9998 3.99979C14.9998 4.26495 14.8945 4.51926 14.707 4.70679L5.707 13.7068C5.59752 13.8166 5.464 13.8994 5.317 13.9488L2.317 14.9488C2.14074 15.0078 1.95153 15.0165 1.7706 14.974C1.58966 14.9314 1.42417 14.8393 1.29268 14.7079C1.1612 14.5765 1.06894 14.4111 1.02625 14.2302C0.983555 14.0493 0.992126 13.8601 1.051 13.6838L2.051 10.6838C2.1002 10.5364 2.18305 10.4026 2.293 10.2928L11.293 1.29279ZM12 1.99979L14 3.99979L5 12.9998L2 13.9998L3 10.9998L12 1.99979Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.146 6.35448L9.646 3.85448L10.354 3.14648L12.854 5.64648L12.147 6.35448H12.146ZM3 10.0005V10.5005C3 10.6331 3.05268 10.7603 3.14645 10.854C3.24021 10.9478 3.36739 11.0005 3.5 11.0005H4V11.5005C4 11.6331 4.05268 11.7603 4.14645 11.854C4.24021 11.9478 4.36739 12.0005 4.5 12.0005H5V12.5005C5 12.6331 5.05268 12.7603 5.14645 12.854C5.24021 12.9478 5.36739 13.0005 5.5 13.0005H6V11.5005C6 11.3679 5.94732 11.2407 5.85355 11.1469C5.75979 11.0532 5.63261 11.0005 5.5 11.0005H5V10.5005C5 10.3679 4.94732 10.2407 4.85355 10.1469C4.75979 10.0532 4.63261 10.0005 4.5 10.0005H3Z"
                      fill="white" />
                  </svg>
                  Edit
                </button>
                <button type="button" class="btn btn-primary btn-sm right_icon_btn gtmSendPrice">
                  Send
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.64592 1.64592C4.69236 1.59935 4.74754 1.56241 4.80828 1.5372C4.86903 1.512 4.93415 1.49902 4.99992 1.49902C5.06568 1.49902 5.13081 1.512 5.19155 1.5372C5.2523 1.56241 5.30747 1.59935 5.35392 1.64592L11.3539 7.64592C11.4005 7.69236 11.4374 7.74754 11.4626 7.80828C11.4878 7.86903 11.5008 7.93415 11.5008 7.99992C11.5008 8.06568 11.4878 8.1308 11.4626 8.19155C11.4374 8.2523 11.4005 8.30747 11.3539 8.35392L5.35392 14.3539C5.26003 14.4478 5.13269 14.5005 4.99992 14.5005C4.86714 14.5005 4.7398 14.4478 4.64592 14.3539C4.55203 14.26 4.49929 14.1327 4.49929 13.9999C4.49929 13.8671 4.55203 13.7398 4.64592 13.6459L10.2929 7.99992L4.64592 2.35392C4.59935 2.30747 4.56241 2.2523 4.5372 2.19155C4.512 2.13081 4.49902 2.06568 4.49902 1.99992C4.49902 1.93415 4.512 1.86903 4.5372 1.80828C4.56241 1.74754 4.59935 1.69236 4.64592 1.64592Z"
                      fill="white" />
                  </svg>
                </button>
              </div>
              <div class="custom-control custom-switch collapse_link collapsed">
                <input type="checkbox" class="custom-control-input" id="priceCGThreeSwitch"
                  [checked]="priceContentGroup?.islocked" />
                <label class="custom-control-label" for="priceCGThreeSwitch">
                  Lock Content
                </label>
                <img [ngClass]="priceContentGroup?.isExpanded ? 'transform-180' : 'cursor-pointer'"
                  src="/assets/images/chevron_down.svg" alt="click to expand"
                  (click)="getDetailsOfPriceCG(priceContentGroup?.id)" />
              </div>
            </div>
            <div id="collapseThree" #collapse="ngbCollapse" [(ngbCollapse)]="!priceContentGroup.isExpanded"
              aria-labelledby="priceCGThree" data-parent="#priceContentAccordian">
              <div class="card-body">
                <div class="table-container">
                  <table class="table-borderless" aria-describedby="price content table">
                    <tr>
                      <th scope="col">Publish Date & Time</th>
                      <th scope="col">Expiration Date & Time</th>
                    </tr>
                    <tr>
                      <td>{{priceContentGroup?.configurations?.publishTime}}</td>
                      <td>{{priceContentGroup?.configurations?.expireTime}}</td>
                    </tr>
                  </table>
                  <span>
                    <button (click)="duplicateAndEditPriceContent(priceContentGroup.id)" type="button" class="btn btn-primary btn-sm left_icon_btn">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2H11C11.5304 2 12.0391 2.21071 12.4142 2.58579C12.7893 2.96086 13 3.46957 13 4V14C13 14.5304 12.7893 15.0391 12.4142 15.4142C12.0391 15.7893 11.5304 16 11 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V4C1 3.46957 1.21071 2.96086 1.58579 2.58579C1.96086 2.21071 2.46957 2 3 2V2ZM3 3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15H11C11.2652 15 11.5196 14.8946 11.7071 14.7071C11.8946 14.5196 12 14.2652 12 14V4C12 3.73478 11.8946 3.48043 11.7071 3.29289C11.5196 3.10536 11.2652 3 11 3H3Z" fill="white"/>
                        <path d="M5 0H13C13.5304 0 14.0391 0.210714 14.4142 0.585786C14.7893 0.960859 15 1.46957 15 2V12C15 12.5304 14.7893 13.0391 14.4142 13.4142C14.0391 13.7893 13.5304 14 13 14V13C13.2652 13 13.5196 12.8946 13.7071 12.7071C13.8946 12.5196 14 12.2652 14 12V2C14 1.73478 13.8946 1.48043 13.7071 1.29289C13.5196 1.10536 13.2652 1 13 1H5C4.73478 1 4.48043 1.10536 4.29289 1.29289C4.10536 1.48043 4 1.73478 4 2H3C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0V0Z" fill="white"/>
                        </svg>
                        Duplicate & Edit
                    </button>
                  </span>
                </div>
               <!-- <ng-container *ngFor="let page of priceContentGroup?.configurations?.content_group_pages">
                  <div *ngIf="page && page.image" class="tab_dropdown_img_container">
                    <img src={{page.image}} alt="priceCG3" (error)="page.image = null" />
                  </div>
                </ng-container> -->
                <div class="totalDiv">
                  <ng-container *ngFor="let page of priceContentGroup?.configurations?.contentGroupPages">
                    <div class="eachPage">
                      <iposcen-live-preview [data]="loadMiniPreview(priceContentGroup?.id,page)">
                      </iposcen-live-preview>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Data Footer - Pagination -->
      <div class="data_footer">
        <nav aria-label="price content pagination"
          *ngIf="displayPriceContentGroups && displayPriceContentGroups.length>0">
          <ngb-pagination [collectionSize]="displayPriceContentGroups.length" [(page)]="page" [maxSize]="maxSize" [pageSize]="pageSize"
            [rotate]="true" [boundaryLinks]="false" ></ngb-pagination>
        </nav>
      </div>
    </div>

  </section>

  <!-- Create New Price section -->
  <section *ngIf="tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4" class="main_section al_newpromo">
    <ng-container>
      <div *ngIf="tabIndex === 1">
        <iposcen-create-price-content (goBacktoPriceContent)="setCreatedGrpID($event)"></iposcen-create-price-content>
      </div>
      <div *ngIf="tabIndex === 2">
        <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=1
        [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshPriceContentdata()" (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
      </div>
      <div *ngIf="tabIndex === 3">
        <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=2
        [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshPriceContentdata()" (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
      </div>
      <div *ngIf="tabIndex === 4">
        <iposcen-page [cntgrpdata]="contentGrpData" [contentGroupType]="pageType" [pageNumber]=3
        [mediaObjs]="mediaObjects" (goBacktoLogoContent)="refreshPriceContentdata()" (sendLayoutTocontentGrp)="setPreviewLayout($event)"></iposcen-page>
      </div>
    </ng-container>
    <div class="button_wrapper priceActBtn mb-4 mr-4" *ngIf="tabIndex === 2 || tabIndex === 3|| tabIndex === 4">
      <button type="button" class="btn btn-outline-primary left_icon_btn gtmAddPagePrice" (click)="addPage()" [disabled]="checkPages()">
        <img src="assets/images/plus_square_outline.svg" alt="add page" />
        Add Page
      </button>
      <button type="button" class="btn btn-primary gtmSavePriceContGrp" (click)="updatePriceContent()">
        Save & Use Content Group
      </button>
    </div>
  </section>
</div>
