import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { CommonService } from '../../../../common/services/common.service';
import { BannerContentConfiguration, CreateBannerContentResponse } from '../../banner-carousel/interface/banner-carousel.i';
import { GlobalConstants } from './../../../../common/constants/global.constant';

@Component({
  selector: 'iposcen-banner-url',
  templateUrl: './banner-url.component.html',
  styleUrls: ['./banner-url.component.scss']
})
export class BannerUrlComponent implements OnInit, OnDestroy {
  @Input() cntgrpdata: any;
  @Input() contentGroupType: string;
  bannerURLgrpForm;
  private ngUnsubscribe = new Subject<boolean>();

  constructor(private cmnSer: CommonService,) { }

  ngOnInit(): void {
    this.bannerURLgrpForm = {
      customURL : ''
    };

    if(this.contentGroupType === GlobalConstants.BANNER_CONTENT_GROUP){
      const updateBannerContent: CreateBannerContentResponse = this.cntgrpdata as CreateBannerContentResponse;
      const bannerContent = updateBannerContent.bannerCarousel;
      let  configurations;
      if (typeof bannerContent.configurations === 'string') {
        configurations = JSON.parse(bannerContent.configurations) as BannerContentConfiguration;
      } else {
        configurations = bannerContent.configurations as BannerContentConfiguration;
      }
    }
  }

  emitURLData(){
    this.cmnSer.emitURLData(this.contentGroupType, this.bannerURLgrpForm.customURL);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
