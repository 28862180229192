<div class="card delete_laptop">
    <a (click)="close()" class="popup_close">
      <img
        alt="go to profile page"
      />
    </a>

    <div class="form_header" [innerHTML]="deleteLaptop"></div>
    <div [innerHTML]="deletedDesc">
       
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-outline-primary" [innerHTML]="cancel">
        </button>
        <button type="button" class="btn btn-primary" [innerHTML]="deleteProfile">
        </button>
    </div>
  </div>

