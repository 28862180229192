import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../common/services/common.service';
import { DataService } from '../../../core/data/data-service';
import { SegmentsService } from '../../../core/routing/services/asset-library/segments.service';
import { GlobalConstants } from './../../../common/constants/global.constant';
import { AssetLibraryConstants } from './../../../core/routing/services/asset-library/constants/asset-library.constant';
import { Segment, CreateSegmentsResponse, SegmentsGroupPage, SegmentsConfiguration } from './interfaces/segments.i';

@Component({
  selector: 'iposcen-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal') deleteModal: ElementRef;
  userId = sessionStorage.getItem('userId');
  tabIndex = 0;
  segmentsGroups: Segment[];
  displaySegmentGroups = [];
  selectedFiles: FileList;
  sortValue = 'Segment Name';
  segmentsData: Segment;
  // TODO create object
  page = 1;
  pageSize = 5;
  color = '#ffffff';
  contentGrpData: any;
  pageType = GlobalConstants.SEGMENTS;
  segmentToDelete: string;
  segmentToDeleteName: string;
  segmentHeading: string;
  pages: any;
  mediaObjects: any[] = [];
  segmentLayout = 1;
  clickable = false;
  editCheck: boolean;
  disabled: boolean;
  pageGrpForm: any;
  showSegmentName: string;
  url = AssetLibraryConstants.S3_ASSET_LIBRARY_USERS + this.userId
  + AssetLibraryConstants.ASSET_LIBRARY_ASSET_LIBRARY + this.userId;
  private ngUnsubscribe = new Subject<boolean>();
  // TODO - Sandeep to check with neeraj for page n pagesize

  constructor(private router: Router,
    private segmentsService: SegmentsService,
    private cmnService: CommonService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.getAllSegmentsByUser();
    this.pages = {};
    this.disabled = true;
    this.editCheck = false;
    // dummy data to preview page
    this.cmnService.pageDataTologoObser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
      // console.log(res);
      // pages data. To-Do: update to common object ;
      // const updatePromoContent: CreatePromoContentResponse = this.contentGrpData as CreatePromoContentResponse;
      // Once page is updated.
      const segment = this.contentGrpData.segment;
      // console.log(logoContent.configurations,"...ss")
      const configurations = segment.configurations;
      if (res.data.media.url !== '') {
        this.prepareMediaSet(res, segment.id);
      }
      const checkJson = this.checkExistance(configurations.contentGroupPages, 'pageNum', res.number);
      // console.log(checkJson);
      if (checkJson.hasMatch) {// If there is configuration object already for the page number
        // Update the pages
        configurations.contentGroupPages[checkJson.matchedIndex] = {
          pageNum: res.number,
          title: res.data.heading,
          titleFontColor: res.data.hcolor,
          bgColor: res.data.bgcolor,
          bodyCopy: res.data.body,
          bodyCopyFontSize: this.fontSizeNumber(res.data.size),
          bodyCopyFontColor: res.data.bcolor,
          UiLayout: this.getUILayoutNameByID(res.data.layout),
          linkedAsset: res.data.media.url !== '' ? true : false,
          linkedAssetFileType: res.data.media.format
        };
      } else {
        const segmentGroupPage: SegmentsGroupPage = {
          pageNum: res.number,
          title: res.data.heading,
          titleFontColor: res.data.hcolor,
          bgColor: res.data.bgcolor,
          bodyCopy: res.data.body,
          bodyCopyFontSize: this.fontSizeNumber(res.data.size),
          bodyCopyFontColor: res.data.bcolor,
          UiLayout: this.getUILayoutNameByID(res.data.layout),
          linkedAsset: res.data.media.url !== '' ? true : false,
          linkedAssetFileType: res.data.media.format
        };
        configurations.contentGroupPages.push(segmentGroupPage);
      }
      this.contentGrpData.segment = segment;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAllSegmentsByUser() {
    // console.log('In component, My userid:: ' + sessionStorage.getItem('userId'));

    this.segmentsService.getAllSegmentsByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(getAllLogoContentResponse => {

        this.segmentsGroups = (getAllLogoContentResponse.segment as Segment[]);
        this.displaySegmentGroups = [];
        this.segmentsGroups.forEach(ele => {
          ele.configurations = JSON.parse(ele.configurations);
          ele.createdByUser = (ele.createdByUser === this.userId) ? GlobalConstants.ME : ele.createdByUser;
          this.displaySegmentGroups.push(ele);
        });
        this.displaySegmentGroups.forEach(element => {
          element.isExpanded = false;
          if (element.updateTime) {
            element.updateTime = new Date(element.updateTime).toLocaleString()
              .replace(',', '');
          }
          // if deleting make it true
          element.isDeleted = false;

          // converting date for display purpose:
          if (element && element.configurations) {
            if (element.configurations.publishTime) {
              element.configurations.publishTime = new Date(element.configurations.publishTime).toLocaleString()
                .replace(',', '');
            }
            if (element.configurations.expireTime && element.configurations.expireTime !== GlobalConstants.NEVER) {
              element.configurations.expireTime = new Date(element.configurations.expireTime).toLocaleString()
                .replace(',', '');
            }
          this.segmentHeading = this.displaySegmentGroups.length <= 1 ? 'Segment' : 'Segments';
          }
        });
        this.sortBy(GlobalConstants.CREATED_DATE);
      },
        error => {
          // do something
        }
      );
  }

  getDetailsOfSegment(id: string) {
    this.displaySegmentGroups.forEach(element => {
      if (element.id === id) {
        element.isExpanded = !element.isExpanded;
      }
    });
  }

  sortBy(sortValue: string) {
    switch (sortValue) {
      case AssetLibraryConstants.SORT_ORDER_SEGMANT_NAME: {
        this.sortValue = AssetLibraryConstants.SORT_ORDER_SEGMANT_NAME_LABEL;
        this.displaySegmentGroups.sort((segment1, segment2) =>
        segment1.configurations.publishedName.localeCompare(segment2.configurations.publishedName));
        break;
      }
      case AssetLibraryConstants.SORT_ORDER_RETAILER_NAME: {
        this.sortValue = AssetLibraryConstants.SORT_ORDER_RETAILER_NAME_LABEL;
        this.displaySegmentGroups.sort((segment1, segment2) =>
        segment1.configurations.retailerName.localeCompare(segment2.configurations.retailerName));
        break;
      }
      case GlobalConstants.CREATED_DATE: {
        this.displaySegmentGroups.sort((segment1, segment2) =>
        new Date(segment2.updateTime)?.getTime()  - new Date(segment1.updateTime)?.getTime());
        break;
      }
      default:
        break;
    }
  }

  openModal(modal: any) { modal.open(); }
  closeModal(modal: any) { modal.close(); }

  openDeleteModal(segmentId: string) {
    this.segmentToDelete = this.displaySegmentGroups.find(ele => ele.id === segmentId).id;
    this.segmentToDeleteName = this.displaySegmentGroups.find(ele => ele.id === segmentId).configurations.publishedName;
    this.openModal(this.deleteModal);
  }

  deleteSegment(segmentId: string) {
    // delete Promo API call
    this.segmentsService.deleteSegmentById(segmentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(deleteResponse => {
        // console.log('Delete Store Promo success --', deleteResponse);
        const index = this.displaySegmentGroups.findIndex(d => d.id === segmentId); // find index in your array
        if (index >= 0) {
          this.displaySegmentGroups.splice(index, 1); // remove element from array
          this.segmentHeading = this.displaySegmentGroups.length <= 1 ? 'Segment Group' : 'Segments Groups';
        }
        this.closeModal(this.deleteModal);
      },
        error => {
          // do something
          // console.log('Delete Store Promo error --', error);
          this.closeModal(this.deleteModal);
        });
  }

  setIndex(value: number) {
    this.tabIndex = value;
  }

  setCreatedGrpID(data) {
    if (typeof data === 'object' && data.cancel) {
      this.setIndex(0);
      this.pages = {};
      this.editCheck = false;
    } else {
      this.contentGrpData = data; if (typeof this.contentGrpData.segment.configurations === 'string') {
        this.contentGrpData.segment.configurations = JSON.parse(this.contentGrpData.segment.configurations) as SegmentsConfiguration;
      }
      this.showSegmentName = this.contentGrpData.segment.configurations.publishedName;
      this.clickable = true;
      this.mediaObjects = [];
      this.setIndex(2);
    }
  }

  refreshSegmentdata() {
    this.getAllSegmentsByUser();
    this.setIndex(0);
    this.mediaObjects = [];
  }

  addPage() {
    const keyLen = Object.keys(this.pages).length;
    if (keyLen === 0) {
      this.pages.page2 = true;
      this.setIndex(3);
    } else {
      this.pages.page3 = true;
      this.setIndex(4);
    }
  }

  setPreviewLayout(data) {
    this.segmentLayout = data;
  }

  checkPages() {
    if (this.pages.page2 && this.pages.page3) {
      return true;
    }

    return false;
  }
  cancel() {
    this.dataService.setSegment(undefined);
    for (const each of this.displaySegmentGroups) {
      if (each.id === this.contentGrpData?.segment?.id) {
        if (typeof each.configurations === 'string') {
          each.configurations = JSON.parse(each.configurations);
        }
        break;
      }
    }
    this.setIndex(0);
    this.mediaObjects = [];
    this.pages = {};
    this.clickable = false;
    this.editCheck = false;
  }

  updateSegment() {
    const updateSegment: CreateSegmentsResponse = this.contentGrpData as CreateSegmentsResponse;
    // Once page is updated.
    const segment = updateSegment.segment;
    if (typeof segment.configurations !== 'string') {
      segment.configurations = JSON.stringify(segment.configurations);
    }
    this.segmentsService.editSegmentById(JSON.stringify(segment), updateSegment.segment.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(putSegmentResponse => {
        for (const each of this.mediaObjects) {
          if (each.uploaded) {
            this.updateMedia(each);
          }
        }
        this.pages = {};
        this.setIndex(0);
        this.getAllSegmentsByUser();
        this.clickable = false;
        this.editCheck = false;
      },
        error => {
        }
      );
  }

  updateMedia(each) {
    // console.log(each);
    this.segmentsService.editSegmentByPageId(each.media.fileObj,
      each.media.name.split('.')
        .pop(), each.id, each.pageNum.toString())
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(first())
      .subscribe(() => {

      },
      error => { // do something
      }
    );
  }

  prepareMediaSet(res, segmentId) {
    const checkJson = this.checkExistance(this.mediaObjects, 'pageNum', res.number);
    if (checkJson.hasMatch) {
      // Update the pages
      this.mediaObjects[checkJson.matchedIndex] = { id: segmentId, media: res.data.media, pageNum: res.number };
      if (res.data.media.fileObj !== '') {
        this.mediaObjects[checkJson.matchedIndex].uploaded = true;
      }
    } else {
      const dataObj = { id: segmentId, media: res.data.media, pageNum: res.number, uploaded: true };
      if (res.data.media.fileObj !== '') {
        dataObj.uploaded = true;
      }
      this.mediaObjects.push(dataObj);
    }
  }

  checkExistance(data, key, compValue) {
    const checkJSON = { hasMatch: false, matchedIndex: -1 };
    for (let index = 0; index < data.length; index += 1) {
      if (data[index][key] === compValue) {
        checkJSON.hasMatch = true;
        checkJSON.matchedIndex = index;
        break;
      }
    }

    return checkJSON;
  }

  fontSizeNumber(size) {
    if (size === GlobalConstants.SMALL) { return GlobalConstants.NUMBER_12; }
    if (size === GlobalConstants.MEDIUM) { return GlobalConstants.NUMBER_14; }
    if (size === GlobalConstants.LARGE) { return GlobalConstants.NUMBER_16; }
  }

  getUILayoutNameByID(id) {// TODO - Confirm with Sujay if layout name is correct
    if (id === 1) {
      return GlobalConstants.TWO_COL_RIGHT_ASSET;
    } else if (id === 2) {
      return GlobalConstants.TWO_COL_LEFT_ASSET;
    } else if (id === 3) {
      return GlobalConstants.ONE_COL_MID_ASSET;
    }
  }

  setLayout(selected) {
    this.segmentLayout = selected;
    this.cmnService.emitLayout(selected);
  }

  loadMiniPreview(gId: string, pageContent: any) {
    this.pageGrpForm = {
      bgcolor: pageContent ? pageContent.bgColor : '#0068B5',
      heading:  pageContent ? pageContent.title : '',
      hcolor: pageContent ? pageContent.titleFontColor : '#ffffff',
      body: pageContent ? pageContent.bodyCopy : '',
      bcolor: pageContent ? pageContent.bodyCopyFontColor : '#ffffff',
      size: pageContent ? this.getFontSize(pageContent.bodyCopyFontSize) : GlobalConstants.MEDIUM,
      media: {
        url: (pageContent && pageContent.linkedAsset) ?
        environment.apiBaseUrlAsset + this.url + AssetLibraryConstants.SEGMENTS + AssetLibraryConstants.SLASH +
		    gId + AssetLibraryConstants.SLASH + pageContent.pageNum.toString() : '',
        name: '',
        format: (pageContent && pageContent.linkedAsset) ? (pageContent.linkedAssetFileType) ? pageContent.linkedAssetFileType : '' : '',
        fileObj: ''
      },
      id: gId,    // adding created group id,
      layout: pageContent ? this.getUILayoutIDByName(pageContent.UiLayout) : 1
    };

    return this.pageGrpForm;
  }
  getFontSize(size) {
    if (size === GlobalConstants.NUMBER_12) { return GlobalConstants.SMALL; }
    if (size === GlobalConstants.NUMBER_14) { return GlobalConstants.MEDIUM; }
    if (size === GlobalConstants.NUMBER_16) { return GlobalConstants.LARGE; }
  }
  getUILayoutIDByName(name: string) {
    if (name === GlobalConstants.TWO_COL_RIGHT_ASSET) {
      return 1;
    } else if (name === GlobalConstants.TWO_COL_LEFT_ASSET) {
      return 2;
    } else if (name === GlobalConstants.ONE_COL_MID_ASSET) {
      return 3;
    }
  }

  editSegment(segmentId: string) {
    this.pages = {};
    this.mediaObjects = [];
    const segmentToEdit = this.displaySegmentGroups.find(ele => ele.id === segmentId);
    this.dataService.setSegment(segmentToEdit);
    this.editCheck = true;
    for (const each of segmentToEdit.configurations?.contentGroupPages) {
      if (each.pageNum === 2) {
        this.pages.page2 = true;
      }
      if (each.pageNum === 3) {
        this.pages.page3 = true;
      }
    }
    const tempSegment = segmentToEdit as Segment;
    this.contentGrpData = {
      segment: tempSegment,
      responseContext: {}
    }; // set data to cntGrpData to show in pages
    this.showSegmentName = segmentToEdit?.configurations?.publishedName;
    this.setIndex(1);
  }

  addSegment() {
    this.dataService.setSegment(undefined);
    this.tabIndex = 1;
  }

}
